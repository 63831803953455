.newsletter-wrapper
    margin: 0
    padding: 20px 0 135px 0
    width: 100%
    background-color: transparent
    // min-height: 535px
    display: flex
    flex-flow: column
    overflow: hidden
    position: relative
    .bottom-pictograms
        display: block
        height: 117px
        background-repeat: repeat-x
        background-position: bottom
        background-size: contain
        position: absolute
        padding: 100px 0 0 0
        bottom: -4%
        left: 0
        width: 100%
        z-index: 20
        @media scr1024
            background-size: cover
    .newsletter-box
        box-sizing: border-box
        max-width: 1032px
        margin: 0 auto 
        display: flex
        border: none
        flex-flow: row
        @media (max-width: 1440px)
            max-width:  768px
            padding: 0
        @media scr1024
            // margin: 0 10% 
            // max-width: 100%
            flex-flow: column
            justify-content: center
            align-items: center
        @media tablet
            margin: 0 3%
        @media mobi
            margin: 0 6%
        .newsletter
            display: flex
            justify-content: center
            align-items: center
            flex-flow: row
            width: 100%
            margin: 0 auto
            @media scr1024
                flex-flow: column
                justify-content: center
                align-items: center
            .left-box
                width: 60%
                padding: 0
                display: flex
                align-items: flex-start
                justify-content: flex-start
                flex-flow: column
                // border: 1px solid red
                @media scr1024
                    padding: 0
                    width: 100%
                .description
                    color: #ffffff
                    font-family: 'GloberRegular'
                    margin: 0 0 60px 0
                    // padding: 0 0 0 5%
                    line-height: 2
                    @media scr1024
                        margin: 20px 0 60px 0
                    @media tablet
                        padding: 0


            .right-box
                width: 40%
                display: flex
                flex-flow: column
                align-items: center
                justify-content: center
                @media scr1024
                    justify-content: center
                    width: 100%
                .box-for-input
                    width: 80%
                    display: flex
                    flex-flow: column
                    align-items: center
                    justify-content: flex-end
                    margin:0
                    @media scr1024
                        justify-content: center
                    .newsletter-form
                        display: flex
                        flex-flow: column
                        align-items: center
                        justify-content: flex-end
                        width: 100%
                        .email-input
                            width: 309px
                            background-color: transparent
                            border-radius: 0 !important
                            outline: none
                            padding: 5px
                            color: #ffffff
                            border: none
                            border-bottom: 2px solid #ffffff
                            @media mobi 
                                width: 100%
                            &::-webkit-input-placeholder
                                color: #ffffff
                                text-transform: lowercase
                                text-align: center
                                font-family: 'GloberRegular'
                            &::-moz-placeholder
                                color: #ffffff
                                text-transform: lowercase
                                text-align: center
                                font-family: 'GloberRegular'
                            &:-ms-input-placeholder
                                color: #ffffff
                                text-transform: lowercase
                                text-align: center
                                font-family: 'GloberRegular'
                            &:-moz-placeholder
                                color: #ffffff
                                text-transform: lowercase
                                text-align: center
                                font-family: 'GloberRegular'
                            &:placeholder
                                color: #ffffff
                                text-transform: lowercase
                                text-align: center
                                font-family: 'GloberRegular'
                                &:focus
                                    color: pleo-fiolet
                            &:-webkit-autofill,
                            &:-webkit-autofill:hover,
                            &:-webkit-autofill:focus
                                border-bottom: 2px solid #ffffff
                                text-align: center
                                -webkit-text-fill-color: white;
                                -webkit-box-shadow: 0 0 0px 1000px transparent inset;
                                transition: background-color 5000s ease-in-out 0s;

                        .button-violet
                            width: 168px
                            cursor: pointer
                            color: #fffffff
                            background-color: pleo-filoet2
                            font-family: 'globersemibold' 
                            font-size: 12px
                            margin: 50px 0 0 0
                            padding: 0
                            text-align: center
                            align-items: center
                            display: flex
                            justify-content: center
                            -webkit-box-shadow: 0px 3px 0px 0px rgba(116,97,211,1);
                            -moz-box-shadow: 0px 3px 0px 0px rgba(116,97,211,1);
                            box-shadow: 0px 3px 0px 0px rgba(116,97,211,1);
                            .button-elements
                                width: 100%
                                display: block
                                text-align: center
                                .arrow-on-price-button
                                    width: 6px
                                    height: 9px
                                    margin: -4px 11px 0 0 
                                    img
                                        width: 6px
                                        height: 16px
                    


