@media (min-width: 1441px)
    section.blog-banner-wrapper
        .blog-wrapper
            max-width: 1400px !important

section.blog-banner-wrapper
    container-position()
    padding-top: 40px
    border-top: 2px solid #e8e8e8
    .blog-wrapper
        max-width: 1140px
        display: flex
        margin: 0px auto
        padding: 0px 0px
        flex-direction: column
        .blog-go-back
            position: relative
            &:after
                content: ''
                position: absolute
                height: 2px
                width: 40px
                background: pleo-fiolet
                top: -13px
                left: 6px
            &:before
                content: ''
                position: absolute
                width: 0
                height: 0
                border-top: 6px solid transparent
                border-bottom: 6px solid transparent
                border-right: 6px solid pleo-fiolet
                top: -18px
                left: 0px
            .blog-go-back-to-main-page
                position: absolute
                height: 50px
                width: 115px
                top: -18px
            p
                color: pleo-fiolet
                font-family: 'GloberRegular'
                font-size: 14px
                line-height: 17px
        h1.blog-header
            font-family: 'GloberHeavy'
            font-size: 72px
            line-height: 65px
            position: relative
            padding-bottom: 5px
            margin-top: 40px
            &:after
                content: ''
                background-color: white
                position: absolute
                width: 101px
                height: 7px
                left: 2px
                top: -29px

@media (max-width: 1145px)
    section.blog-banner-wrapper
        .blog-wrapper
            padding: 0px 20px