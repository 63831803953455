.menu-under-jumbotron-empathy.sticky
    max-height: 85px
    @media (max-width: 769px)
        position: static !important
    .menu-purple-element
        max-height: 85px


.menu-under-jumbotron-empathy
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    outline: none
    padding: 0
    margin: 0
    background-color: pleo-fiolet
    min-height: 136px
    position: -webkit-sticky;
    position: sticky
    bottom: 0
    @media (max-width: 685px)
        position: static
    @media mobi
        min-height: 90px
    .menu-purple-element
        display: flex
        width: 100%
        max-width: 1400px 
        margin: 0 auto
        flex-flow: row
        align-items: center
        justify-content: center
        justify-content: space-between
        position: relative
        outline: none
        @media (max-width: 1440px)
            margin: 0 10%
        @media scr1024
            margin: 0 9%
        @media tablet
            margin: 0 3%
        @media mobi
            margin: 0 9%
        .about-cours
            display: flex
            flex-flow: row
            align-items: center
            justify-content: center
            color: pleo-dark-green
            font-family: 'GloberHeavy'
            width: 100%
            @media (max-width: 685px) 
                flex-flow: column
                justify-content: flex-start
                align-items: flex-start
            .term
                display: flex
                flex-flow: row
                align-items: center
                justify-content: flex-start
                border: none 
                outline: none
                margin: none 
                @media (max-width: 900px)
                    margin: 35px 0
                @media mobi 
                    display: none
                .text
                    height: 100%
                    display: flex
                    flex-flow: row
                    align-items: flex-start
                    justify-content: flex-start
                    border: none 
                    outline: none
                    .project-faze
                        display: flex
                        flex-flow: column
                        justify-content: center
                        font-size: 13px
                        font-family: 'GloberRegular'
                        color: pleo-dark-green
                        padding: 0 25px 0 0 
                        min-height: 100%
                        margin: 0 
                        p
                            padding: 0
                            margin: 0
                        :last-child
                            font-family: 'GloberHeavy'
                            margin: 0
                    .description
                        display: flex
                        width: 50%
                        font-size: 13px
                        font-family: 'GloberHeavy'
                        text-transform: uppercase 
                        color: pleo-dark-green
                        padding: 0
                        margin: 0 50px 0 0
                        @media  (max-width: 685px)
                            margin: 0 50px 0 -20px
                        img
                            width: 100px
                            height: 35px
                            margin: 0
            .buttons
                .total-white
                    background-color: #fff
                    // width: 20%
                    outline: none 
                    overflow: hidden
                    height: 33px
                    display: flex
                    font-family: 'GloberxBold'
                    flex-flow: row
                    align-items: center
                    justify-content: center
                    border-radius: 20px
                    text-transform: lowercase
                    font-size: 12px
                    color: pleo-dark-green
                    margin: 0
                    width: 180px
                    padding: 0
                    border: none
                    -webkit-box-shadow: 0px 3px 0px 0px rgba(116,97,211,1);
                    -moz-box-shadow: 0px 3px 0px 0px rgba(116,97,211,1);
                    box-shadow: 0px 3px 0px 0px rgba(116,97,211,1)
                    @media mobi 
                        display: none
                    .button-elements
                        width: 100%
                        height: 100%
                        display: block
                        text-align: center
                        display: flex
                        flex-flow: row
                        border: none
                        color: pleo-dark-green
                        justify-content: center
                        align-items: center
                        .svg
                            margin: 0 10px 0 0 
                            display: flex
                            width: 10px
                            height: 10px
                        p
                            display: flex
                            padding: 0
                            margin: 0
                            justify-content: center
                            align-items: center
                            font-family: 'GloberxBold'
                            height: 100%
                            color: pleo-dark-green !important
            .share
                width: 100%
                display: flex
                flex-flow: row
                justify-content: flex-end
                align-items: center
                @media (max-width: 685px) 
                    justify-content: flex-start
                    margin: 35px 0
                @media mobi 
                    margin: 0
                .text
                    font-size: 13px
                    font-family: 'GloberRegular'
                    margin: 0 10px 0 0 
                ul
                    list-style-type none
                    display: flex
                    flex-flow: row
                    padding: 0
                    margin: 0
                    li
                        padding: 0
                        margin: 0
                        a
                            padding: 0
                            margin: 0 10px 0 0
                            color: white
                            .svg
                                width: 33px
                                height: 32px
                                fill: white
                                &, & * 
                                    fill: white !important
                        &:hover
                            .svg
                                &, & * 
                                    fill: pleo-dark-green !important