
.jumbotron
    background-position: center 
    background-repeat: no-repeat
    background-size: cover 
    margin: 0
    background-color: pleo-dark-green
    align-items: center 
    justify-content: center
    width: 100%
    position: relative
    display: flex
    min-height: 81vh
    @media (max-height: 250px)
        min-height: auto
        padding: 0
        align-items: flex-start
    .banner 
        max-width: 1400px
        width: 100%
        height: 100%
        margin: 0 auto
        display: flex
        flex-flow: column
        align-items: flex-start
        justify-content: center
        @media (max-width: 1440px)
            margin: 0 10%
        @media scr1024
            margin: 0 10%
        @media tablet
            margin: 0 3%
        @media mobi
            margin: 0 6%
        .pleo-h1
            font-size: 72px
            display: flex
            flex-flow: column 
            padding: 0
            margin: 0
            text-transform: uppercase
            font-family: 'GloberHeavy'
            line-height: 1.2
            @media scr1024
                font-size: 48px
            @media tablet
                font-size: 36px
            p
                display: inline-block
                padding: 0
                margin: 0
            &::before
                content: ''
                display: block
                margin: 0 0 20px 0
                width: 104px
                height: 7px
                background-color: #fff
            .small-header
                font-size: 36px
                padding: 0
                margin: 0
                font-family: 'GloberxBold'
                display: inline-block
                @media tablet
                    font-size: 24px
        .subtitle
            font-size: 24px
            padding: 26px 0 0 0
            width: 70%
            position: relative
            @media (max-width: 1366)
                width: 80%
            @media scr1024
                font-size: 18px
                width: 60%
            @media tablet
                font-size: 16px
                width: 100%
            p
                display: inline-block
                font-family: 'GloberLight'
                color: #ffffff

        .jumbotron-background
            width: 100%
            height: 100%

