.portfolio-wrapper
    width: 100%
    background-color: transparent
    .portfolio-elements
        max-width: 1400px
        display: flex
        flex-flow: row
        margin: 0 auto
        min-height: 600px
        @media (max-width: 1440px)
            // margin: 0 6%
            max-width: 1040px
        @media scr1024
            max-width: 836px !important
            // margin: 0 8%
        @media tablet
            flex-flow: column 
            margin: 0 3%
        @media mobi
            margin: 0 6%
        .image
            width: 100%
            display: flex
            flex-flow: column
            // border: 1px solid orange
            a
                // border: 1px solid red
                display: block
                // width: 100%
                height: 100%
                .image2
                    @media tablet
                        margin: 70px 0 0 0
                .one-image
                    min-height: 470px
                    width: 100%
                    background-position: center
                    background-repeat: no-repeat
                    background-size: cover
                    // border: 1px solid pink
                    display: flex
                    justify-content: center
                    align-items: center
                    position: relative
                    @media mobi
                        min-height: 40vh
                    .button-element
                        display: none
                        height: 100%
                        .method-coord-img-outline 
                            cursor: pointer
                            opacity: 1
                            width: 116px
                            height: 116px
                            border-radius: 100%
                            display: flex
                            align-items: center
                            justify-content: center
                            position: relative
                            flex-direction: column
                            color: white
                            box-shadow: 0px 8px 5px 0px rgba(20,20,20,0.55)
                            @media mobi 
                                opacity: 0
                            .box-knowledge
                                width: 116px
                                height: 58px
                                background: linear-gradient(97deg, rgba(117,101,214,1) 0%, rgba(69,135,207,1) 100%)
                                border-top-left-radius: 95px
                                border-top-right-radius: 95px
                                position: absolute
                                top: 0px
                            .box-knowledge2
                                width: 116px
                                height: 58px
                                background: linear-gradient(90deg, rgba(195,81,73,1) 0%, rgba(69,135,207,1) 100%)
                                border-bottom-left-radius: 95px
                                border-bottom-right-radius: 95px
                                position: absolute
                                bottom: 0px
                            .method-coord-img
                                width: 110px
                                height: 110px
                                border-radius: 100%
                                z-index: 2
                                background-color: pleo-dark-green
                                background-position: center
                                background-size: contain
                                background-repeat: no-repeat
                                display: flex
                                justify-content: center
                                align-items: center
                                position: relative
                                .arrow
                                    position: absolute
                                    top: 15px
                                    left: 20px
                                    transform: rotate(-180deg)
                                    img 
                                        width: 41px
                                        height: 2
                                .text
                                    position: absolute
                                    top: 33px
                                    left: 20px
                                    font-size: 16px
                                    width: 50%
                                    color: white
                                    font-family: 'GloberxBold'
                                    color: white
                                    z-index: 100
                                    width: 100%
                                    min-height: 180px
                            
                &:hover
                    .button-element
                        display: flex
        .description
            // border: 1px solid green
            .name
                font-size: 27px
                font-family: 'GloberRegular'
                padding: 31px 0 35px 0
                @media tablet
                    padding: 31px 0 18px 0
                    font-size: 24px

            .text-description
                font-size: 42px
                font-family: 'GloberxBold'
                line-height: 1
                @media tablet
                    font-size: 36px

