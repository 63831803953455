pleo-dark-green = #04232f
pleo-fiolet = #8a73fb
pleo-fiolet-buttons = #d8d6f1
purple-counting-down-color = #4f4d96
pleo-filoet2 = #9b87ff
pleo-blog-font = #142832
// pleo-h1 = 48px
// pleo-h2 = 34px
// pleo-h3 = 24px
// pleo-h4 = 18px


// mediaQuery
scr1024 = 'screen and (max-width: 1024px)'
tablet = 'screen and (max-width: 768px)'
mobi = 'screen and (max-width: 375px)'



// wtyczka FontColor Craft
 
.black
    color: pleo-dark-green
    overflow: hidden
.pleo-fiolet
    color: #8a73fb
    
.white
    color: #ffffff

//linki
a
    outline: none
    border: none

a
    &:link
        text-decoration: none
        outline: none
        border: none
        cursor: pointer
        color: pleo-fiolet 
a
    &:visited
        text-decoration: none
        outline: none
        border: none
        cursor: pointer
        color: pleo-fiolet 
a
    &:hover
        text-decoration: none
        outline: none
        border: none
        cursor: pointer
        color: pleo-fiolet 
a
    &:active
        text-decoration: none
        outline: none
        border: none
        cursor: pointer 
        color: pleo-fiolet



//@MJ

*
    margin: 0
    padding: 0

body
    background-color: pleo-dark-green
    height: 100%
    width: 100%
    font-size: 20px
    font-family: 'GloberRegular'
    color:#ffffff

container-position()
    width: 100%
    overflow: hidden

// mixin do elementu how-to-use

howToUSeWrapper()
    width: 100%
    position: relative
    -webkit-box-shadow: 0px 13px 0px 0px rgba(0,0,0,0.2)
    -moz-box-shadow: 0px 13px 0px 0px rgba(0,0,0,0.2)
    box-shadow: 0px 13px 0px 0px rgba(0,0,0,0.2)

howToUseImageOption()
    background-size: cover
    background-position: center
    background-repeat: no-repeat
    width: 100%
    margin: 0
    border-image: linear-gradient(to right, #b861c6 0%,#ffc7c7 100%)
    border-image-slice: 1
    border-width: 3px

howToUseCubeOptions()
    .cube1
        background: rgb(138,115,251)
        background: linear-gradient(68deg, rgba(138,115,251,1) 0%, rgba(44,186,216,1) 63%)
        position: absolute
        top: 0px
        height: 50%
        width: 100%
    .cube2
        background: rgb(235,89,77)
        background: linear-gradient(68deg, rgba(235,89,77,1) 0%, rgba(44,186,216,1) 100%)
        position: absolute
        bottom: 0px
        height: 50%
        width: 100%

howToUseHeader()
    min-height: 84px
    font-family: 'GloberxBold'
    color: pleo-dark-green
    padding: 0
    margin: 0

howToUseKardOptions()
    flex-flow: column
    display: flex

@media (max-width: 1024px)
    body
        #wrapper-1440px
            padding: 0px

.height-auto
    height: auto !important
.display-none
    display: none !important
.faq-inline
    max-height: 10000px !important
    padding: 37px 0px !important
.faq-border
    border-bottom: 1px solid  #2d3f47 !important
.faq-pleo-fiolet-text
    color:#ffffff !important
.display-inline 
    max-height: 10000px !important
    // padding: 37px 0px !important
.visible
    visibility: visible
    height auto !important
.visibleMenu 
    display: flex
.hidden
    // visibility: hidden
    opacity: 0 !important
    height: 1px !important
.index1
    z-index: 0 !important
.backgroundOdd
    background: #192b3a !important
.close-tab 
    &:before
        transform: translate(-50%,50%) rotate(-90deg) !important
        transition: transform .3s !important
    &:after
        transform: translate(-50%,-50%)  rotate(-180deg) !important 
        transition: transform .3s !important

.active
    a
        color: pleo-fiolet !important
        font-family: 'GloberBlack' !important

// ogólne ustawienia dla list

.menu-list
    list-style-type none
    padding: 0
    margin: 0
    display: flex
    flex-flow: row wrap
    li
        align-items: center
        display: flex
        a
            text-decoration: none
            display: flex
            color: #ffffff
            height:100%
            cursor pointer
            font-family: 'GloberRegular'
        :hover
            text-decoration: none

// ustawienia buttonów

.button
    min-width: 132px
    outline: none 
    overflow: hidden
    font-weight: 400px
    height: 33px
    display: flex
    font-family: 'GloberxBold'
    flex-flow: row
    align-items: center
    justify-content: center
    border-radius: 20px
    border: none
    font-size: 12px
    text-transform: lowercase
    cursor: pointer
    &:focus
        outline: none
        cursor: pointer
    &.button-violet
        background-color: pleo-fiolet
        color: #ffffff
        width: 133px
        cursor: pointer
    &.button-violet-price
        background-color: pleo-filoet2 
        color: #ffffff
        min-width: 171px
        cursor: pointer
    &.button-white-grey-border
        background-color: transparent
        border: 2px solid pleo-fiolet-buttons
        cursor: default !important
    &.button-without-grey-border
        background-color: transparent
        font-size: 13px  
        border: 1px solid pleo-fiolet-buttons
        width: 133px
        cursor: pointer
    &.total-white
        background-color: #fff
        font-size: 13px  
        border: 1px solid pleo-fiolet-buttons
        width: 133px
        -webkit-box-shadow: 0px 3px 0px 0px rgba(116,97,211,1);
        -moz-box-shadow: 0px 3px 0px 0px rgba(116,97,211,1);
        box-shadow: 0px 3px 0px 0px rgba(116,97,211,1)
        cursor: pointer
        

// gradient

.jumbotron-gradient
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    z-index: -1
    background: linear-gradient(to right,rgba(19,39,48,.8) 0%,rgba(19,39,48,0) 60%);


    
// error

.errors
    color: red
    list-style-type none

//datapicker
.datepicker 
    background-color: #e0e0e0
    .datepicker-days
        font-family: 'GloberRegular'
        color: pleo-dark-green
        .table-condensed
            thead
                tr
                    .datepicker-switch
                        font-family: 'GloberRegular'
                        font-size: 12px
                    .dow
                        font-size: 12px
                        font-family: 'GloberRegular'
                    .next
                        color: pleo-fiolet
                    .prev
                        color: pleo-fiolet

            tbody
                tr
                    font-size: 12px
                    .today
                        color: white
                        background-color: pleo-fiolet
                        background-image: linear-gradient(to bottom,pleo-fiolet,pleo-fiolet);
                    .day
                        .active 
                            background-color: pleo-fiolet
                            background-image: linear-gradient(to bottom,pleo-dark-green,pleo-dark-green);
                    .active 
                        background-color: pleo-fiolet
                        background-image: linear-gradient(to bottom,pleo-dark-green,pleo-dark-green);
                    
            tfoot
                tr
                    .today
                        color: pleo-fiolet
                        background-color: pleo-fiolet
    table 
        tr 
            td.active.active
                background-image: linear-gradient(pleo-fiolet);

// sticky
.sticky
    position: fixed
    left: 0
    top: 0
    width: 100%
    min-height: 85px !important
    z-index: 1000
    -webkit-box-shadow: 0px 2px 0px 0px rgba(19,39,48,1);
    -moz-box-shadow: 0px 2px 0px 0px rgba(19,39,48,1);
    box-shadow: 0px 2px 0px 0px rgba(19,39,48,1);
    .term
        &::after
            left: 100% !important
.sticked 
    min-height: 134px


.disabled
    pointer-events: none;
    cursor: default;
    color: red




