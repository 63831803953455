.black {
  color: #04232f;
  overflow: hidden;
}
.pleo-fiolet {
  color: #8a73fb;
}
.white {
  color: #fff;
}
a {
  outline: none;
  border: none;
}
a:link {
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;
  color: #8a73fb;
}
a:visited {
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;
  color: #8a73fb;
}
a:hover {
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;
  color: #8a73fb;
}
a:active {
  text-decoration: none;
  outline: none;
  border: none;
  cursor: pointer;
  color: #8a73fb;
}
* {
  margin: 0;
  padding: 0;
}
body {
  background-color: #04232f;
  height: 100%;
  width: 100%;
  font-size: 20px;
  font-family: 'GloberRegular';
  color: #fff;
}
@media (max-width: 1024px) {
  body #wrapper-1440px {
    padding: 0px;
  }
}
.height-auto {
  height: auto !important;
}
.display-none {
  display: none !important;
}
.faq-inline {
  max-height: 10000px !important;
  padding: 37px 0px !important;
}
.faq-border {
  border-bottom: 1px solid #2d3f47 !important;
}
.faq-pleo-fiolet-text {
  color: #fff !important;
}
.display-inline {
  max-height: 10000px !important;
}
.visible {
  visibility: visible;
  height: auto !important;
}
.visibleMenu {
  display: flex;
}
.hidden {
  opacity: 0 !important;
  height: 1px !important;
}
.index1 {
  z-index: 0 !important;
}
.backgroundOdd {
  background: #192b3a !important;
}
.close-tab:before {
  transform: translate(-50%, 50%) rotate(-90deg) !important;
  transition: transform 0.3s !important;
}
.close-tab:after {
  transform: translate(-50%, -50%) rotate(-180deg) !important;
  transition: transform 0.3s !important;
}
.active a {
  color: #8a73fb !important;
  font-family: 'GloberBlack' !important;
}
.menu-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: row wrap;
}
.menu-list li {
  align-items: center;
  display: flex;
}
.menu-list li a {
  text-decoration: none;
  display: flex;
  color: #fff;
  height: 100%;
  cursor: pointer;
  font-family: 'GloberRegular';
}
.menu-list li :hover {
  text-decoration: none;
}
.button {
  min-width: 132px;
  outline: none;
  overflow: hidden;
  font-weight: 400px;
  height: 33px;
  display: flex;
  font-family: 'GloberxBold';
  flex-flow: row;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  border: none;
  font-size: 12px;
  text-transform: lowercase;
  cursor: pointer;
}
.button:focus {
  outline: none;
  cursor: pointer;
}
.button.button-violet {
  background-color: #8a73fb;
  color: #fff;
  width: 133px;
  cursor: pointer;
}
.button.button-violet-price {
  background-color: #9b87ff;
  color: #fff;
  min-width: 171px;
  cursor: pointer;
}
.button.button-white-grey-border {
  background-color: transparent;
  border: 2px solid #d8d6f1;
  cursor: default !important;
}
.button.button-without-grey-border {
  background-color: transparent;
  font-size: 13px;
  border: 1px solid #d8d6f1;
  width: 133px;
  cursor: pointer;
}
.button.total-white {
  background-color: #fff;
  font-size: 13px;
  border: 1px solid #d8d6f1;
  width: 133px;
  -webkit-box-shadow: 0px 3px 0px 0px #7461d3;
  -moz-box-shadow: 0px 3px 0px 0px #7461d3;
  box-shadow: 0px 3px 0px 0px #7461d3;
  cursor: pointer;
}
.jumbotron-gradient {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  background: linear-gradient(to right, rgba(19,39,48,0.8) 0%, rgba(19,39,48,0) 60%);
}
.errors {
  color: #f00;
  list-style-type: none;
}
.datepicker {
  background-color: #e0e0e0;
}
.datepicker .datepicker-days {
  font-family: 'GloberRegular';
  color: #04232f;
}
.datepicker .datepicker-days .table-condensed thead tr .datepicker-switch {
  font-family: 'GloberRegular';
  font-size: 12px;
}
.datepicker .datepicker-days .table-condensed thead tr .dow {
  font-size: 12px;
  font-family: 'GloberRegular';
}
.datepicker .datepicker-days .table-condensed thead tr .next {
  color: #8a73fb;
}
.datepicker .datepicker-days .table-condensed thead tr .prev {
  color: #8a73fb;
}
.datepicker .datepicker-days .table-condensed tbody tr {
  font-size: 12px;
}
.datepicker .datepicker-days .table-condensed tbody tr .today {
  color: #fff;
  background-color: #8a73fb;
  background-image: linear-gradient(to bottom, #8a73fb, #8a73fb);
}
.datepicker .datepicker-days .table-condensed tbody tr .day .active {
  background-color: #8a73fb;
  background-image: linear-gradient(to bottom, #04232f, #04232f);
}
.datepicker .datepicker-days .table-condensed tbody tr .active {
  background-color: #8a73fb;
  background-image: linear-gradient(to bottom, #04232f, #04232f);
}
.datepicker .datepicker-days .table-condensed tfoot tr .today {
  color: #8a73fb;
  background-color: #8a73fb;
}
.datepicker table tr td.active.active {
  background-image: linear-gradient(#8a73fb);
}
.sticky {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 85px !important;
  z-index: 1000;
  -webkit-box-shadow: 0px 2px 0px 0px #132730;
  -moz-box-shadow: 0px 2px 0px 0px #132730;
  box-shadow: 0px 2px 0px 0px #132730;
}
.sticky .term::after {
  left: 100% !important;
}
.sticked {
  min-height: 134px;
}
.disabled {
  pointer-events: none;
  cursor: default;
  color: #f00;
}
.margin.Extra.Small {
  margin-bottom: 40px;
}
.margin.Small {
  margin-bottom: 60px;
}
.margin.Medium {
  margin-bottom: 85px;
}
@media screen and (max-width: 375px) {
  .margin.Medium {
    margin-bottom: 35px;
  }
}
.margin.Large {
  margin-bottom: 120px;
}
@media screen and (max-width: 1024px) {
  .margin.Large {
    margin-bottom: 80px;
  }
}
@media screen and (max-width: 375px) {
  .margin.Large {
    margin-bottom: 50px;
  }
}
.margin.Extra.Large {
  margin-bottom: 240px;
}
@media screen and (max-width: 1024px) {
  .margin.Extra.Large {
    margin-bottom: 120px;
  }
}
@media screen and (max-width: 375px) {
  .margin.Extra.Large {
    margin-bottom: 60px;
  }
}
.h1-global {
  font-size: 72px !important;
}
.h2-global {
  font-size: 48px !important;
}
.h3-global {
  font-size: 36px !important;
}
.h4-global {
  font-size: 24px !important;
}
.h5-global {
  font-size: 18px !important;
}
.h6-global {
  font-size: 14px !important;
}
.p1-global {
  font-size: 24px !important;
  line-height: 41px;
  font-family: 'GloberRegular' !important;
}
.p2-global {
  font-size: 18px !important;
  line-height: 1.5 !important;
  line-height: 29px !important;
}
.p3-global {
  font-size: 16px !important;
  line-height: 1.5 !important;
}
.p4-global {
  font-size: 14px !important;
  line-height: 1.5 !important;
}
.p5-global {
  font-size: 12px !important;
  line-height: 1.5 !important;
}
@media (max-width: 768px) {
  .h1-global {
    font-size: 48px !important;
  }
  .h2-global {
    font-size: 36px !important;
  }
  .h3-global {
    font-size: 28px !important;
  }
  .h4-global {
    font-size: 18px !important;
  }
  .h5-global {
    font-size: 16px !important;
  }
  .h6-global {
    font-size: 14px;
  }
  .p1-global {
    font-size: 18px !important;
    line-height: 1.5 !important;
  }
  .p2-global {
    font-size: 14px !important;
  }
  .p3-global {
    font-size: 14px;
  }
  .p4-global {
    font-size: 14px;
  }
}
@media (max-width: 375px) {
  .h1-global {
    font-size: 36px !important;
    line-height: 1.5 !important;
  }
}
@font-face {
  font-family: 'GloberRegular';
  src: url("../font/globerregular.eot");
  src: url("../font/globerregular.eot?#iefix") format('embedded-opentype'), url("../font/globerregular.woff2") format('woff2'), url("../font/globerregular.woff") format('woff'), url("../font/GloberRegular.otf") format("opentype"), url("../font/globerregular.svg") format('svg');
}
@font-face {
  font-family: 'GloberHeavy';
  src: url("../font/globerheavy.eot");
  src: url("../font/globerheavy.eot?#iefix") format('embedded-opentype'), url("../font/globerheavy.woff2") format('woff2'), url("../font/globerheavy.woff") format('woff'), url("../font/GloberHeavy.otf") format("opentype"), url("../font/globerheavy.svg") format('svg');
}
@font-face {
  font-family: 'GloberBlack';
  src: url("../font/globerblack.eot");
  src: url("../font/globerblack.eot?#iefix") format('embedded-opentype'), url("../font/globerblack.woff2") format('woff2'), url("../font/globerblack.woff") format('woff'), url("../font/GloberBlack.otf") format("opentype"), url("../font/globerblack.svg") format('svg');
}
@font-face {
  font-family: 'GloberxBold';
  src: url("../font/globerxbold.eot");
  src: url("../font/globerxbold.eot?#iefix") format('embedded-opentype'), url("../font/globerxbold.woff2") format('woff2'), url("../font/globerxbold.woff") format('woff'), url("../font/GloberxBold.otf") format("opentype"), url("../font/globerxbold.svg") format('svg');
}
@font-face {
  font-family: 'GloberBook';
  src: url("../font/globerbook.eot");
  src: url("../font/globerbook.eot?#iefix") format('embedded-opentype'), url("../font/globerbook.woff2") format('woff2'), url("../font/globerbook.woff") format('woff'), url("../font/GloberBook.otf") format("opentype"), url("../font/globerbook.svg") format('svg');
}
@font-face {
  font-family: 'GloberSemiBold';
  src: url("../font/globersemibold.eot");
  src: url("../font/globersemibold.eot?#iefix") format('embedded-opentype'), url("../font/globersemibold.woff2") format('woff2'), url("../font/globersemibold.woff") format('woff'), url("../font/GloberSemiBold.otf") format("opentype"), url("../font/globersemibold.svg") format('svg');
}
@font-face {
  font-family: 'GloberLight';
  src: url("../font/globerlight.eot");
  src: url("../font/globerlight.eot?#iefix") format('embedded-opentype'), url("../font/globerlight.woff2") format('woff2'), url("../font/globerlight.woff") format('woff'), url("../font/GloberLight.otf") format("opentype"), url("../font/globerlight.svg") format('svg');
}
@font-face {
  font-family: 'Sansation_Light';
  src: url("../font/Sansation_Light.eot");
  src: url("../font/Sansation_Light.eot?#iefix") format('embedded-opentype'), url("../font/Sansation_Light.woff2") format('woff2'), url("../font/Sansation_Light.woff") format('woff'), url("../font/SansationLight.otf") format("opentype"), url("../font/SansationLight.svg") format('svg');
}
@font-face {
  font-family: 'Sansation_Bold';
  src: url("../font/Sansation_Bold.eot");
  src: url("../font/Sansation_Bold.eot?#iefix") format('embedded-opentype'), url("../font/Sansation_Bold.woff2") format('woff2'), url("../font/Sansation_Bold.woff") format('woff'), url("../font/SansationBold.otf") format("opentype"), url("../font/SansationBold.svg") format('svg');
}
@font-face {
  font-family: 'Sansation_Regular';
  src: url("../font/Sansation_Regular.eot");
  src: url("../font/Sansation_Regular.eot?#iefix") format('embedded-opentype'), url("../font/Sansation_Regular.woff2") format('woff2'), url("../font/Sansation_Regular.woff") format('woff'), url("../font/SansationRegular.otf") format("opentype"), url("../font/SansationRegular.svg") format('svg');
}
.wrapper-1440px {
  max-width: 1400px;
  margin: 0 auto;
  min-height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1440px) {
  .wrapper-1440px {
    margin: 0 10%;
  }
}
@media screen and (max-width: 1024px) {
  .wrapper-1440px {
    margin: 0 10%;
  }
}
@media screen and (max-width: 768px) {
  .wrapper-1440px {
    margin: 0 3%;
  }
}
@media screen and (max-width: 375px) {
  .wrapper-1440px {
    margin: 0 6%;
  }
}
.wrapper-1440px .top-bar {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  max-height: 130px;
}
.wrapper-1440px .top-bar .logo {
  width: 209px;
  min-height: 67px;
}
.wrapper-1440px .top-bar nav {
  display: flex;
  flex-flow: row;
  align-items: center;
}
.wrapper-1440px .top-bar nav .top-menu {
  font-size: 14px;
  padding: 10px 0 0 0;
  cursor: pointer;
}
@media (max-width: 1600px) {
  .wrapper-1440px .top-bar nav .top-menu {
    font-size: 12px;
  }
}
.wrapper-1440px .top-bar nav .top-menu li {
  font-size: 14px;
  cursor: pointer;
}
@media (max-width: 1600px) {
  .wrapper-1440px .top-bar nav .top-menu li {
    font-size: 12px;
  }
}
.wrapper-1440px .top-bar nav .top-menu li a {
  padding: 33px 0 40px 32px;
  font-size: 14px;
  font-family: 'GloberRegular';
}
@media (max-width: 1600px) {
  .wrapper-1440px .top-bar nav .top-menu li a {
    font-size: 12px;
  }
}
.wrapper-1440px .top-bar nav .top-menu li :hover {
  color: #8a73fb;
  font-size: 14px;
  font-family: 'GloberxBold';
}
@media (max-width: 1600px) {
  .wrapper-1440px .top-bar nav .top-menu li :hover {
    font-size: 12px;
  }
}
@media (max-width: 876px) {
  .wrapper-1440px .top-bar nav .top-menu {
    display: none;
  }
}
.wrapper-1440px .top-bar nav .collaps-menu-icon {
  display: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
@media (max-width: 876px) {
  .wrapper-1440px .top-bar nav .collaps-menu-icon {
    display: block;
  }
}
.wrapper-1440px .top-bar nav .collaps-menu-icon .svg {
  width: 100%;
  height: 100%;
  fill: #fff;
}
.wrapper-1440px .top-bar nav .collaps-menu-icon .svg,
.wrapper-1440px .top-bar nav .collaps-menu-icon .svg * {
  fill: #fff;
}
.hamburger-menu {
  display: flex;
  flex-flow: column;
  padding: 0;
  width: 100%;
  height: 0;
  height: auto;
  visibility: hidden;
  position: relative;
  z-index: 999;
  cursor: pointer;
}
.hamburger-menu .collaps-menu {
  display: flex;
  position: absolute;
  background-color: #04232f;
  flex-flow: column;
  font-size: 14px;
  width: 100%;
  min-height: 100vh;
  cursor: pointer;
}
@media (max-width: 1600px) {
  .hamburger-menu .collaps-menu {
    font-size: 12px;
  }
}
.hamburger-menu .collaps-menu li {
  text-transform: lowercase;
  cursor: pointer;
}
.hamburger-menu .collaps-menu li a {
  padding: 15px 0;
  display: block;
  text-align: center;
  font-family: 'GloberxBold';
  width: 100%;
}
.hamburger-menu .collaps-menu li :hover {
  color: #8a73fb;
}
.hamburger-menu .collaps-menu .menu-down-elemets {
  display: none;
}
.hamburger-menu .collaps-menu .menu-down-elemets .button {
  margin: 0 0 10px 0;
}
.hamburger-menu .collaps-menu .menu-down-elemets .button a {
  color: #fff;
  text-decoration: none;
  font-family: 'GloberRegular';
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hamburger-menu .collaps-menu .menu-down-elemets .button a .button-elements {
  width: 100%;
  height: 100%;
  padding: 7px 0 0 0;
  margin: 0;
  display: block;
  text-align: center;
}
@media (max-width: 850px) {
  .hamburger-menu .collaps-menu .menu-down-elemets {
    display: flex;
    justify-content: center;
  }
}
.main-menu {
  background-color: #fff;
  width: 100%;
  max-height: 60px;
  min-height: 60px;
  display: flex;
  align-items: center;
}
@media (max-width: 840px) {
  .main-menu {
    display: none;
  }
}
.main-menu nav {
  max-width: 1400px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  align-items: center;
  background-color: #fff;
  justify-content: space-between;
}
@media (max-width: 1440px) {
  .main-menu nav {
    margin: 0 10%;
  }
}
@media screen and (max-width: 1024px) {
  .main-menu nav {
    margin: 0 10%;
  }
}
@media screen and (max-width: 768px) {
  .main-menu nav {
    margin: 0 3%;
  }
}
@media screen and (max-width: 375px) {
  .main-menu nav {
    margin: 0 6%;
  }
}
.main-menu nav .bottom-menu {
  min-height: 100%;
  display: flex;
  align-items: center;
}
.main-menu nav .bottom-menu li {
  height: 100%;
  margin: 0;
  font-weight: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-menu nav .bottom-menu li :hover {
  text-decoration: underline #8a73fb;
  color: #8a73fb;
}
.main-menu nav .bottom-menu li a {
  text-transform: uppercase;
  line-height: 1;
  color: #04232f;
  display: flex;
  height: 100%;
  font-weight: 400;
  font-size: 14px;
  font-family: 'GloberxBold';
  padding: 0;
  margin: 0 20px 0 0;
}
@media (max-width: 1600px) {
  .main-menu nav .bottom-menu li a {
    font-size: 12px;
  }
}
.main-menu nav .client-panel {
  display: flex;
  justify-content: flex-end;
}
.main-menu nav .client-panel li {
  height: 100%;
}
.main-menu nav .client-panel li a {
  color: #04232f;
  font-size: 14px;
  font-family: 'GloberRegular';
  padding: 0;
  margin: 0 10px 0 0;
  outline: none;
  line-height: 1;
}
@media (max-width: 1600px) {
  .main-menu nav .client-panel li a {
    font-size: 12px;
  }
}
@media (max-width: 934px) {
  .main-menu nav .client-panel li a {
    display: none;
  }
}
.main-menu nav .client-panel li .button-violet {
  -webkit-box-shadow: 0px 5px 0px 0px #dadada;
  -moz-box-shadow: 0px 5px 0px 0px #dadada;
  box-shadow: 0px 5px 0px 0px #dadada;
  outline: none;
  font-family: 'GloberRegular';
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-menu nav .client-panel li .button-violet a {
  padding: 0;
  margin: 0;
  color: #fff;
  text-decoration: none;
  font-family: 'GloberRegular';
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-menu nav .client-panel li .button-violet a .button-elements {
  width: 100%;
  display: block;
  text-align: center;
}
.jumbotron {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  background-color: #04232f;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  display: flex;
  min-height: 81vh;
}
@media (max-height: 250px) {
  .jumbotron {
    min-height: auto;
    padding: 0;
    align-items: flex-start;
  }
}
.jumbotron .banner {
  max-width: 1400px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: center;
}
@media (max-width: 1440px) {
  .jumbotron .banner {
    margin: 0 10%;
  }
}
@media screen and (max-width: 1024px) {
  .jumbotron .banner {
    margin: 0 10%;
  }
}
@media screen and (max-width: 768px) {
  .jumbotron .banner {
    margin: 0 3%;
  }
}
@media screen and (max-width: 375px) {
  .jumbotron .banner {
    margin: 0 6%;
  }
}
.jumbotron .banner .pleo-h1 {
  font-size: 72px;
  display: flex;
  flex-flow: column;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  font-family: 'GloberHeavy';
  line-height: 1.2;
}
@media screen and (max-width: 1024px) {
  .jumbotron .banner .pleo-h1 {
    font-size: 48px;
  }
}
@media screen and (max-width: 768px) {
  .jumbotron .banner .pleo-h1 {
    font-size: 36px;
  }
}
.jumbotron .banner .pleo-h1 p {
  display: inline-block;
  padding: 0;
  margin: 0;
}
.jumbotron .banner .pleo-h1::before {
  content: '';
  display: block;
  margin: 0 0 20px 0;
  width: 104px;
  height: 7px;
  background-color: #fff;
}
.jumbotron .banner .pleo-h1 .small-header {
  font-size: 36px;
  padding: 0;
  margin: 0;
  font-family: 'GloberxBold';
  display: inline-block;
}
@media screen and (max-width: 768px) {
  .jumbotron .banner .pleo-h1 .small-header {
    font-size: 24px;
  }
}
.jumbotron .banner .subtitle {
  font-size: 24px;
  padding: 26px 0 0 0;
  width: 70%;
  position: relative;
}
@media (max-width: 1366) {
  .jumbotron .banner .subtitle {
    width: 80%;
  }
}
@media screen and (max-width: 1024px) {
  .jumbotron .banner .subtitle {
    font-size: 18px;
    width: 60%;
  }
}
@media screen and (max-width: 768px) {
  .jumbotron .banner .subtitle {
    font-size: 16px;
    width: 100%;
  }
}
.jumbotron .banner .subtitle p {
  display: inline-block;
  font-family: 'GloberLight';
  color: #fff;
}
.jumbotron .banner .jumbotron-background {
  width: 100%;
  height: 100%;
}
footer {
  max-width: 1440px;
  margin: 0px auto;
}
footer #scroll-up {
  display: none;
  position: fixed;
  z-index: 199;
  border: none;
  outline: none;
  background-color: #9b87ff;
  font-size: 18px;
  position: fixed;
  bottom: 20px;
  right: 90px;
  -webkit-transition: 0.8s all ease;
  transition: 0.8s all ease;
  z-index: 99;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  height: 42px;
  width: 42px;
  -webkit-box-shadow: 0px 3px 0px 0px #7461d3;
  -moz-box-shadow: 0px 3px 0px 0px #7461d3;
  box-shadow: 0px 3px 0px 0px #7461d3;
}
@media screen and (max-width: 375px) {
  footer #scroll-up {
    display: none !important;
  }
}
footer #scroll-up .fa-chevron-up {
  font-size: 12px;
  color: #fff;
  padding: 0 0 12px 0;
}
footer .footer-wrapper {
  margin: 0px auto;
  display: flex;
  flex-direction: column;
}
footer .footer-wrapper .contact-wrapper {
  border-bottom: 1px solid #8a73fb;
}
footer .footer-wrapper .contact-wrapper .contact {
  width: 1245px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 160px 16px 70px 16px;
  margin: 0px auto;
}
footer .footer-wrapper .contact-wrapper .contact ul {
  list-style-type: none;
  margin-bottom: 0px;
}
footer .footer-wrapper .contact-wrapper .contact ul li {
  font-family: 'GloberRegular';
  font-size: 19px;
}
footer .footer-wrapper .contact-wrapper .contact ul li:first-child {
  margin-bottom: 35px;
}
footer .footer-wrapper .contact-wrapper .contact ul li p {
  margin-bottom: 0px !important;
}
footer .footer-wrapper .contact-wrapper .contact ul li a {
  color: #fff;
  cursor: pointer;
}
footer .footer-wrapper .pleo-group {
  width: 1245px;
  margin: 0px auto;
  padding: 50px 0 50px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
footer .footer-wrapper .pleo-group .pleo-group-list .pleo-group-napis {
  padding: 0;
  font-family: 'GloberRegular';
  font-size: 19px;
}
footer .footer-wrapper .pleo-group .logo-wrapper {
  display: flex;
  flex-flow: row;
  width: 100%;
}
footer .footer-wrapper .pleo-group .logo-wrapper :nth-child(4) {
  overflow: hidden;
}
footer .footer-wrapper .pleo-group .logo-wrapper :nth-child(4) .footer-img {
  width: 100%;
  height: 86%;
}
footer .footer-wrapper .pleo-group .logo-wrapper a {
  width: 100px;
  margin: 0 16px;
  padding: 0;
  display: flex;
}
footer .footer-wrapper .pleo-group .logo-wrapper a .footer-img {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
footer .footer-wrapper .pleo-group .all-rights {
  font-family: 'GloberRegular';
  font-size: 11px;
  padding: 0px;
  margin: 0px;
  text-align: right;
  width: auto;
}
@media (max-width: 1440px) {
  footer .footer-wrapper .contact-wrapper .contact {
    max-width: 1024px;
  }
  footer .footer-wrapper .pleo-group {
    max-width: 1024px;
  }
}
@media (max-width: 1245px) {
  footer .footer-wrapper .contact-wrapper .contact {
    width: auto;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding-top: 100px;
  }
}
@media (max-width: 1024px) {
  footer .footer-wrapper .contact-wrapper .contact {
    padding: 160px 0px 70px 0px;
  }
  footer .footer-wrapper .contact-wrapper .contact ul {
    padding: 20px 20px;
  }
  footer .footer-wrapper .pleo-group {
    width: 100%;
    padding: 50px 20px;
    flex-flow: column wrap;
    align-items: flex-start;
  }
  footer .footer-wrapper .pleo-group .logo-wrapper {
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 40px 40px 25px 40px;
  }
  footer .footer-wrapper .pleo-group .logo-wrapper a {
    margin: 40px 16px;
  }
  footer .footer-wrapper .pleo-group .all-rights {
    width: 100%;
    padding: 0 16px;
  }
}
@media (max-width: 375px) {
  footer .footer-wrapper .pleo-group {
    width: 100%;
    padding: 50px 5px;
    flex-flow: column wrap;
    align-items: flex-start;
  }
  footer .footer-wrapper .pleo-group .pleo-group-list .pleo-group-napis {
    padding: 0 20px;
    font-family: 'GloberRegular';
    font-size: 19px;
  }
  footer .footer-wrapper .pleo-group .logo-wrapper {
    flex-flow: row wrap;
    padding: 0px 40px 25px 10px;
  }
  footer .footer-wrapper .pleo-group .logo-wrapper a {
    margin: 30px 16px;
  }
  footer .footer-wrapper .pleo-group .all-rights {
    width: 100%;
  }
}
@media (max-width: 360px) {
  footer .footer-wrapper .pleo-group .logo-wrapper {
    padding: 40px 10px 25px 10px;
  }
}
.background-white {
  background-color: #fff;
}
.background-white .module-title header.module-header-title .section-number .nr-text .nr-name {
  color: #04232f;
}
.background-white .module-title header.module-header-title h2 {
  color: #04232f;
}
.background-white .opis-wrapper .opis-content p {
  color: #04232f;
}
.background-fiolet {
  background-color: #8a73fb;
}
.background-fiolet .module-title header.module-header-title .section-number .line:after {
  background-color: #04232f;
}
.background-fiolet .module-title header.module-header-title .section-number .nr-text .nr-number {
  color: #04232f;
}
.background-fiolet .module-title header.module-header-title .section-number .nr-text .nr-name {
  color: #fff;
}
.background-fiolet .module-title header.module-header-title h2 {
  color: #fff;
}
.purple-counting-down {
  width: 100%;
  display: flex;
  flex-flow: row;
  color: #8a73fb;
  justify-content: center;
  align-items: center;
  font-family: 'GloberxBold';
  font-size: 16px;
  width: 100%;
  height: 53px;
  padding: 10px 0;
  margin: 0;
  border: 0;
  outline: 0;
  background-color: #4f4d96;
}
@media (max-width: 1600px) {
  .purple-counting-down {
    font-size: 14px;
  }
}
.purple-counting-down .how-much-left {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin: 0 10px;
}
.purple-counting-down .how-much-left .number-of-place p {
  margin: 0;
}
.purple-counting-down .how-much-left .how-much-let-avatars {
  margin: 0 10px;
  display: flex;
  flex-flow: row;
  align-items: center;
}
@media (max-width: 478px) {
  .purple-counting-down .how-much-left .how-much-let-avatars {
    display: none;
  }
}
.purple-counting-down .how-much-left .how-much-let-avatars .avatar1 {
  width: 40px;
  height: 40px;
  padding: 0 0 0 20px;
}
@media (max-width: 769px) {
  .menu-under-jumbotron.sticky {
    position: static !important;
  }
}
@media (min-width: 769px) {
  .menu-under-jumbotron.sticky {
    max-height: 85px;
  }
  .menu-under-jumbotron.sticky .menu-white-element {
    max-height: 85px;
  }
  .menu-under-jumbotron.sticky .menu-white-element .about-cours {
    max-height: 85px;
  }
  .menu-under-jumbotron.sticky .menu-white-element .about-cours .term {
    max-height: 85px;
    line-height: 1;
  }
  .menu-under-jumbotron.sticky .menu-white-element .about-cours .basic-iformation {
    border: none;
    background-color: #fff;
    min-height: 85px;
    margin: 0;
    width: 80% !important;
    padding: 0px;
  }
  .menu-under-jumbotron.sticky .menu-white-element .about-cours .basic-iformation .information {
    max-height: 60px;
  }
  .menu-under-jumbotron.sticky .menu-white-element .about-cours .basic-iformation .button-white-grey-border {
    border: none;
    margin: 0 !important;
    line-height: 1;
    font-size: 12px !important;
    padding: 0 0 0 25px;
  }
  .menu-under-jumbotron.sticky .menu-white-element .about-cours .price {
    max-height: 85px;
    display: flex;
    flex-direction: row;
    padding-left: 25px;
  }
  .menu-under-jumbotron.sticky .menu-white-element .about-cours .price .price-amound {
    flex-direction: column;
  }
  .menu-under-jumbotron.sticky .menu-white-element .about-cours .price span {
    padding: 0px !important;
    margin: -10px 0 0 0 !important;
  }
  .menu-under-jumbotron.sticky .menu-white-element .about-cours .price a {
    width: 72px;
    padding-left: 10px;
  }
  .menu-under-jumbotron.sticky .menu-white-element .about-cours .price a .button-violet-price {
    width: 100px;
    min-width: 100px;
    margin: 0 0 0 50px;
  }
}
.menu-under-jumbotron {
  width: 100%;
  background: linear-gradient(284deg, #8a73fb 0%, #8a73fb 37%, #fff 58%);
}
@media (min-width: 768px) {
  .menu-under-jumbotron {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }
}
.menu-under-jumbotron .menu-white-element {
  max-width: 1400px;
  margin: 0 auto !important;
}
@media (max-width: 1440px) {
  .menu-under-jumbotron .menu-white-element {
    margin: 0 10% !important;
  }
}
@media screen and (max-width: 1024px) {
  .menu-under-jumbotron .menu-white-element {
    margin: 0 9% 0 3% !important;
  }
}
@media screen and (max-width: 768px) {
  .menu-under-jumbotron .menu-white-element {
    margin: 0 3% !important;
  }
}
@media (max-width: 626px) {
  .menu-under-jumbotron .menu-white-element {
    margin: 0 !important;
  }
}
.menu-under-jumbotron .menu-white-element .about-cours {
  display: flex;
}
@media (max-width: 626px) {
  .menu-under-jumbotron .menu-white-element .about-cours {
    flex-flow: column;
  }
}
.menu-under-jumbotron .menu-white-element .about-cours .term {
  border: 1px solid;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  outline: none;
  background-color: #fff;
}
.menu-under-jumbotron .menu-white-element .about-cours .term::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 2px;
  background-color: #04232f;
  left: 185px;
}
@media screen and (max-width: 1024px) {
  .menu-under-jumbotron .menu-white-element .about-cours .term {
    display: none;
  }
}
.menu-under-jumbotron .menu-white-element .about-cours .term .description-header {
  font-size: 21px;
  font-family: 'GloberHeavy';
  text-transform: uppercase;
  color: #04232f;
}
.menu-under-jumbotron .menu-white-element .about-cours .term .description {
  font-size: 17px;
  color: #04232f;
  font-family: 'GloberRegular';
}
.menu-under-jumbotron .menu-white-element .about-cours .term .more-date {
  display: flex;
  font-family: 'GloberHeavy';
  flex-flow: row wrap;
  color: #04232f;
}
.menu-under-jumbotron .menu-white-element .about-cours .term .more-date p {
  padding: 0;
  margin: 0;
  font-size: 14px;
}
.menu-under-jumbotron .menu-white-element .about-cours .term .more-date p :hover {
  text-decoration: underline #8a73fb;
  padding: 4px 0 0 0;
}
.menu-under-jumbotron .menu-white-element .about-cours .basic-iformation {
  line-height: 1;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 25px 0;
  background-color: #fff;
}
@media (max-width: 1160px) {
  .menu-under-jumbotron .menu-white-element .about-cours .basic-iformation {
    margin: 0;
    width: 70%;
  }
}
@media (max-width: 1277px) {
  .menu-under-jumbotron .menu-white-element .about-cours .basic-iformation {
    margin: 0 0 0 60px;
  }
}
@media (max-width: 1024px) {
  .menu-under-jumbotron .menu-white-element .about-cours .basic-iformation {
    margin: 0;
  }
}
@media (max-width: 626px) {
  .menu-under-jumbotron .menu-white-element .about-cours .basic-iformation {
    width: 100%;
    padding: 25px 0 35px 3%;
  }
}
.menu-under-jumbotron .menu-white-element .about-cours .basic-iformation .information {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row wrap;
  line-height: 1;
  align-items: center;
}
.menu-under-jumbotron .menu-white-element .about-cours .basic-iformation .information .place p::first-letter {
  text-transform: uppercase !important;
}
.menu-under-jumbotron .menu-white-element .about-cours .basic-iformation .information .button-white-grey-border {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 9px 10px 0 0;
  font-family: 'GloberRegular';
  color: #04232f;
  padding: 0 20px;
}
@media screen and (max-width: 1024px) {
  .menu-under-jumbotron .menu-white-element .about-cours .basic-iformation .information .button-white-grey-border {
    margin: 9px 10px 0 0;
  }
}
@media screen and (max-width: 768px) {
  .menu-under-jumbotron .menu-white-element .about-cours .basic-iformation .information .button-white-grey-border {
    margin: 5px 5px 0 0;
    padding: 0 15px;
  }
}
.menu-under-jumbotron .menu-white-element .about-cours .basic-iformation .information .button-white-grey-border .fas {
  width: 20px;
  height: 20px;
  margin: 2px 3px;
}
.menu-under-jumbotron .menu-white-element .about-cours .basic-iformation .information .button-white-grey-border p {
  padding: 0 10px;
  margin: 0;
}
.menu-under-jumbotron .menu-white-element .about-cours .price {
  background: #8a73fb;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 35px;
  width: 30%;
}
@media screen and (max-width: 1024px) {
  .menu-under-jumbotron .menu-white-element .about-cours .price {
    width: 40%;
  }
}
@media (max-width: 646px) {
  .menu-under-jumbotron .menu-white-element .about-cours .price {
    width: 60%;
    text-align: right;
    padding: 0 3%;
  }
}
@media (max-width: 626px) {
  .menu-under-jumbotron .menu-white-element .about-cours .price {
    text-align: left;
    align-items: flex-start;
    justify-content: space-between;
    text-align: left;
    width: 100%;
    padding: 20px 3%;
    flex-flow: row;
  }
}
.menu-under-jumbotron .menu-white-element .about-cours .price .price-amound {
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-flow: row;
  font-size: 30px;
  font-family: 'GloberHeavy';
  color: #04232f;
  line-height: 2;
  text-align: center;
}
@media (max-width: 626px) {
  .menu-under-jumbotron .menu-white-element .about-cours .price .price-amound {
    text-align: left;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    line-height: 1;
  }
}
@media screen and (max-width: 375px) {
  .menu-under-jumbotron .menu-white-element .about-cours .price .price-amound {
    flex-flow: column;
    align-items: flex-start;
  }
}
.menu-under-jumbotron .menu-white-element .about-cours .price .price-amound span {
  font-size: 14px;
  font-family: 'GloberRegular';
  padding: 11px 0 0 10px;
  color: #fff;
  line-height: 1;
}
@media screen and (max-width: 375px) {
  .menu-under-jumbotron .menu-white-element .about-cours .price .price-amound span {
    padding: 0;
  }
}
.menu-under-jumbotron .menu-white-element .about-cours .price a {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0;
}
@media (max-width: 626px) {
  .menu-under-jumbotron .menu-white-element .about-cours .price a {
    text-align: left;
    align-items: flex-start;
    justify-content: flex-end;
    text-align: left;
  }
}
@media screen and (max-width: 375px) {
  .menu-under-jumbotron .menu-white-element .about-cours .price a {
    margin: 5px 0 0 0;
  }
}
.menu-under-jumbotron .menu-white-element .about-cours .price a .button-violet-price {
  cursor: pointer;
  width: 171px;
  color: #04232f;
  background-color: #fff;
  font-family: 'globersemibold';
  font-size: 12px;
  margin: 0;
  padding: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px 3px 0px 0px #7461d3;
  -moz-box-shadow: 0px 3px 0px 0px #7461d3;
  box-shadow: 0px 3px 0px 0px #7461d3;
}
.menu-under-jumbotron .menu-white-element .about-cours .price a .button-violet-price .button-elements {
  width: 100%;
  display: block;
  text-align: center;
}
.menu-under-jumbotron .menu-white-element .about-cours .price a .button-violet-price .button-elements .arrow-on-price-button {
  width: 6px;
  height: 9px;
  margin: -4px 11px 0 0;
}
.menu-under-jumbotron .menu-white-element .about-cours .price a .button-violet-price .button-elements .arrow-on-price-button img {
  width: 6px;
  height: 16px;
}
@media (min-width: 769px) and (max-width: 1024px) {
  .menu-white-element {
    margin: 0 5% !important;
  }
}
@media (min-width: 769px) and (min-width: 887px) {
  .menu-white-element {
    margin: 0 9% 0 2% !important;
  }
}
@media (min-width: 769px) and (max-width: 1160px) {
  .term {
    display: none !important;
  }
}
@media (min-width: 769px) and (max-width: 1160px) {
  .basic-iformation {
    margin: 0 !important;
    width: 100% !important;
  }
}
@media (min-width: 769px) and (max-width: 773px) {
  .button-white-grey-border {
    padding: 0 11px !important;
  }
}
@media (min-width: 769px) and (max-width: 1100px) {
  .price {
    padding-left: 10px !important;
    width: 35% !important;
  }
}
@media (min-width: 769px) and (max-width: 807px) {
  .price {
    padding-left: 10px !important;
    width: 29% !important;
  }
}
@media (min-width: 769px) and (max-width: 860px) {
  a {
    padding: 0;
  }
}
.video-wrapper {
  width: 100%;
  height: 550px;
  position: relative;
  display: inline-block;
}
.video-wrapper video {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.video-wrapper .video-blend {
  background-color: rgba(131,114,215,0.8);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  display: flex;
  opacity: 1;
  overflow: hidden;
  transition-property: height, opacity;
  transition-duration: 0.1s, 0.3s;
  transition-delay: 0.3s, 0s;
  transition-timing-function: ease-in-out;
}
.video-wrapper .video-blend:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(131,114,215,0.8);
}
.video-wrapper .video-blend .play-wrapper {
  padding-right: 60px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.video-wrapper .video-blend .play-wrapper p.video-time {
  margin-bottom: 0px;
}
.video-wrapper .video-blend .play-wrapper .play-button {
  background-color: rgba(171,164,251,0.6);
  background-image: url("../img/arrow-play.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px;
  box-shadow: 0px 4px 0px 0px rgba(0,0,0,0.21);
  width: 60px;
  height: 60px;
  border-radius: 100%;
  margin: 20px;
  cursor: pointer;
}
.video-wrapper .video-blend .info-wrapper {
  padding-left: 15px;
  padding-right: 280px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.video-wrapper .video-blend .info-wrapper h2 {
  font-family: 'GloberxBold';
  line-height: 53px;
  color: #04232f;
  padding-bottom: 20px;
}
@media (max-width: 1024px) {
  .video-wrapper {
    height: 300px;
  }
  .video-wrapper .video-blend .play-wrapper {
    padding-right: 0px;
    justify-content: center;
  }
  .video-wrapper .video-blend .info-wrapper {
    padding-left: 0px;
    padding-right: 70px;
  }
}
@media (max-width: 768px) {
  .video-wrapper {
    height: auto;
    display: flex;
    flex-direction: column;
  }
  .video-wrapper .info-wrapper-rwd {
    height: 325px;
    display: inline-block;
  }
  .video-wrapper .info-wrapper-rwd .info-wrapper2 {
    display: flex;
    flex-direction: column;
    padding: 90px 20px 0px 20px;
  }
  .video-wrapper .info-wrapper-rwd .info-wrapper2 h1 {
    color: #fff;
    font-family: 'GloberxBold';
    font-size: 40px !important;
  }
  .video-wrapper .info-wrapper-rwd .info-wrapper2 p {
    font-family: 'GloberRegular';
    font-size: 18px !important;
  }
  .video-wrapper video {
    height: 225px;
  }
  .video-wrapper .video-blend {
    height: 225px;
    top: 325px;
  }
  .video-wrapper .video-blend .play-wrapper {
    width: 100%;
  }
  .video-wrapper .video-blend .info-wrapper {
    display: none;
  }
}
section.faq-wrapper {
  max-width: 1024px;
  margin: 0px auto;
}
section.faq-wrapper .faq-content {
  display: flex;
  flex-direction: row;
}
section.faq-wrapper .faq-content ul.faq-questions {
  width: 56%;
  list-style-type: none;
  padding-top: 25px;
}
section.faq-wrapper .faq-content ul.faq-questions li.faq-questions-item:first-child header.faq-quest-title {
  border-top: 1px solid transparent;
}
section.faq-wrapper .faq-content ul.faq-questions li.faq-questions-item:nth-last-child(2) {
  border-bottom: 1px solid #2d3f47;
}
section.faq-wrapper .faq-content ul.faq-questions li.faq-questions-item:last-child {
  border-bottom: 1px solid #2d3f47;
  display: none;
}
section.faq-wrapper .faq-content ul.faq-questions li.faq-questions-item header.faq-quest-title {
  cursor: pointer;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #2d3f47;
  border-bottom: 1px solid transparent;
}
section.faq-wrapper .faq-content ul.faq-questions li.faq-questions-item header.faq-quest-title .faq-quest-text {
  display: flex;
  align-items: center;
  width: calc(100% - 42px) !important;
}
section.faq-wrapper .faq-content ul.faq-questions li.faq-questions-item header.faq-quest-title .faq-quest-text h5.faq-quest-text-name {
  font-family: 'GloberxBold';
  color: #8a73fb;
  margin: 0px;
}
section.faq-wrapper .faq-content ul.faq-questions li.faq-questions-item header.faq-quest-title span.title-arrow {
  width: 42px;
  height: 42px;
  border-radius: 100%;
  background-color: transparent;
  position: relative;
  background: transparent;
  border: 1px solid #8a73fb;
}
section.faq-wrapper .faq-content ul.faq-questions li.faq-questions-item header.faq-quest-title span.title-arrow:before {
  content: '';
  width: 2px;
  height: 23px;
  background-color: #8a73fb;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%) rotate(0deg);
  transform-origin: center;
  transition: transform 0.3s;
  z-index: 1;
}
section.faq-wrapper .faq-content ul.faq-questions li.faq-questions-item header.faq-quest-title span.title-arrow:after {
  content: '';
  width: 23px;
  height: 2px;
  background-color: #8a73fb;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  transform-origin: center;
  transition: transform 0.3s;
}
section.faq-wrapper .faq-content ul.faq-questions li.faq-questions-item .faq-quest-answer {
  display: flex;
  margin-bottom: 0px;
  max-height: 0px;
  overflow: hidden;
  padding: 0px;
  transition: 0.3s;
  font-family: 'GloberRegular';
  line-height: 29px;
}
section.faq-wrapper .faq-content article.faq-coord-wrapper {
  width: 44%;
  padding-left: 95px;
  margin-top: -85px;
}
section.faq-wrapper .faq-content article.faq-coord-wrapper .faq-coord-img-outline {
  width: 190px;
  height: 190px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
}
section.faq-wrapper .faq-content article.faq-coord-wrapper .faq-coord-img-outline .box-faq {
  width: 190px;
  height: 96px;
  background: linear-gradient(97deg, #7565d6 0%, #4587cf 100%);
  border-top-left-radius: 95px;
  border-top-right-radius: 95px;
  position: absolute;
  top: 0px;
}
section.faq-wrapper .faq-content article.faq-coord-wrapper .faq-coord-img-outline .box2-faq {
  width: 190px;
  height: 95px;
  background: linear-gradient(90deg, #c35149 0%, #4587cf 100%);
  border-bottom-left-radius: 95px;
  border-bottom-right-radius: 95px;
  position: absolute;
  bottom: 0px;
}
section.faq-wrapper .faq-content article.faq-coord-wrapper .faq-coord-img-outline .faq-coord-img {
  width: 180px;
  height: 180px;
  border-radius: 100%;
  z-index: 2;
  background-color: #fff;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
section.faq-wrapper .faq-content article.faq-coord-wrapper .faq-coord-text h4.faq-coord-text-h4 {
  font-family: 'GloberxBold';
  font-size: 24px;
  margin: 20px 0px;
}
section.faq-wrapper .faq-content article.faq-coord-wrapper .faq-coord-text .faq-coord-text-p {
  font-family: 'GloberRegular';
  margin-bottom: 20px;
}
section.faq-wrapper .faq-content article.faq-coord-wrapper .faq-coord-contact .p1 {
  font-family: 'GloberxBold';
  color: #8a73fb;
}
section.faq-wrapper .faq-content article.faq-coord-wrapper .faq-coord-contact .p2 {
  font-family: 'GloberRegular';
  margin: 0px;
}
@media (max-width: 1440px) {
  section.faq-wrapper .faq-content {
    max-width: 768px;
    margin: 0px auto;
  }
}
@media (max-width: 768px) {
  section.faq-wrapper {
    padding: 0px 20px;
  }
  section.faq-wrapper header.faq-title .section-number {
    margin-left: 0px;
    padding-bottom: 60px;
  }
  section.faq-wrapper header.faq-title h1 {
    font-size: 36px;
    width: auto;
    padding-right: 20px;
  }
  section.faq-wrapper .faq-content {
    flex-direction: column;
  }
  section.faq-wrapper .faq-content ul.faq-questions {
    width: 100%;
  }
  section.faq-wrapper .faq-content article.faq-coord-wrapper {
    width: 100%;
    margin-top: 0px;
    padding-left: 0px;
    padding-top: 50px;
  }
  section.faq-wrapper .faq-content article.faq-coord-wrapper .faq-coord-text {
    max-width: 70%;
  }
}
.gallery {
  display: grid;
  border: 1px solid transparent;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "Big-Photo Big-Photo Foto-2" "Big-Photo Big-Photo Foto-3";
  width: 100%;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  max-width: 1024px;
  height: 500px;
  margin: 0 auto;
}
@media (max-width: 768px) {
  .gallery {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "Big-Photo Big-Photo" "Foto-2 Foto-3";
    height: 800px;
  }
}
@media (max-width: 376px) {
  .gallery {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 300px 200px;
    grid-template-areas: "Big-Photo Big-Photo" "Foto-2 Foto-3";
  }
}
@media (max-width: 376px) {
  .gallery {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 300px 200px;
    grid-template-areas: "Big-Photo Big-Photo" "Foto-2 Foto-3";
    height: 550px;
  }
}
.gallery :hover .fa-search {
  color: #fff;
  transform: rotate(90deg);
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
}
.gallery a {
  display: none;
  background-position: center;
  background-size: cover;
  position: relative;
}
.gallery a .fa-search {
  display: none;
}
.gallery a:nth-child(1) {
  display: grid;
  grid-area: Big-Photo;
}
.gallery a:nth-child(2) {
  display: grid;
  grid-area: Foto-2;
}
.gallery a:nth-child(3) {
  display: grid;
  grid-area: Foto-3;
}
.gallery a .wrapper-for-box .box-header-one {
  top: -60px;
  left: -85px;
  position: absolute;
  border: 1px solid #fff;
  color: #fff;
  width: 100px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  padding: 5px 0 0 0;
}
.gallery a .wrapper-for-box .box-header-two {
  padding: 5px 0 0 0;
  top: -28px;
  left: 0px;
  position: absolute;
  border: 1px solid #fff;
  color: #fff;
  width: 100px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  position: absolute;
}
.blueimp-gallery {
  background-color: #04232f;
}
.blueimp-gallery .next,
.blueimp-gallery .prev,
.blueimp-gallery .close {
  color: #8a73fb;
}
a:not([href]):not([tabindex]) {
  color: #8a73fb;
}
.newsletter-wrapper {
  margin: 0;
  padding: 20px 0 135px 0;
  width: 100%;
  background-color: transparent;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  position: relative;
}
.newsletter-wrapper .bottom-pictograms {
  display: block;
  height: 117px;
  background-repeat: repeat-x;
  background-position: bottom;
  background-size: contain;
  position: absolute;
  padding: 100px 0 0 0;
  bottom: -4%;
  left: 0;
  width: 100%;
  z-index: 20;
}
@media screen and (max-width: 1024px) {
  .newsletter-wrapper .bottom-pictograms {
    background-size: cover;
  }
}
.newsletter-wrapper .newsletter-box {
  box-sizing: border-box;
  max-width: 1032px;
  margin: 0 auto;
  display: flex;
  border: none;
  flex-flow: row;
}
@media (max-width: 1440px) {
  .newsletter-wrapper .newsletter-box {
    max-width: 768px;
    padding: 0;
  }
}
@media screen and (max-width: 1024px) {
  .newsletter-wrapper .newsletter-box {
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 768px) {
  .newsletter-wrapper .newsletter-box {
    margin: 0 3%;
  }
}
@media screen and (max-width: 375px) {
  .newsletter-wrapper .newsletter-box {
    margin: 0 6%;
  }
}
.newsletter-wrapper .newsletter-box .newsletter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row;
  width: 100%;
  margin: 0 auto;
}
@media screen and (max-width: 1024px) {
  .newsletter-wrapper .newsletter-box .newsletter {
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}
.newsletter-wrapper .newsletter-box .newsletter .left-box {
  width: 60%;
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column;
}
@media screen and (max-width: 1024px) {
  .newsletter-wrapper .newsletter-box .newsletter .left-box {
    padding: 0;
    width: 100%;
  }
}
.newsletter-wrapper .newsletter-box .newsletter .left-box .description {
  color: #fff;
  font-family: 'GloberRegular';
  margin: 0 0 60px 0;
  line-height: 2;
}
@media screen and (max-width: 1024px) {
  .newsletter-wrapper .newsletter-box .newsletter .left-box .description {
    margin: 20px 0 60px 0;
  }
}
@media screen and (max-width: 768px) {
  .newsletter-wrapper .newsletter-box .newsletter .left-box .description {
    padding: 0;
  }
}
.newsletter-wrapper .newsletter-box .newsletter .right-box {
  width: 40%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 1024px) {
  .newsletter-wrapper .newsletter-box .newsletter .right-box {
    justify-content: center;
    width: 100%;
  }
}
.newsletter-wrapper .newsletter-box .newsletter .right-box .box-for-input {
  width: 80%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
}
@media screen and (max-width: 1024px) {
  .newsletter-wrapper .newsletter-box .newsletter .right-box .box-for-input {
    justify-content: center;
  }
}
.newsletter-wrapper .newsletter-box .newsletter .right-box .box-for-input .newsletter-form {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}
.newsletter-wrapper .newsletter-box .newsletter .right-box .box-for-input .newsletter-form .email-input {
  width: 309px;
  background-color: transparent;
  border-radius: 0 !important;
  outline: none;
  padding: 5px;
  color: #fff;
  border: none;
  border-bottom: 2px solid #fff;
}
@media screen and (max-width: 375px) {
  .newsletter-wrapper .newsletter-box .newsletter .right-box .box-for-input .newsletter-form .email-input {
    width: 100%;
  }
}
.newsletter-wrapper .newsletter-box .newsletter .right-box .box-for-input .newsletter-form .email-input::-webkit-input-placeholder {
  color: #fff;
  text-transform: lowercase;
  text-align: center;
  font-family: 'GloberRegular';
}
.newsletter-wrapper .newsletter-box .newsletter .right-box .box-for-input .newsletter-form .email-input::-moz-placeholder {
  color: #fff;
  text-transform: lowercase;
  text-align: center;
  font-family: 'GloberRegular';
}
.newsletter-wrapper .newsletter-box .newsletter .right-box .box-for-input .newsletter-form .email-input:-ms-input-placeholder {
  color: #fff;
  text-transform: lowercase;
  text-align: center;
  font-family: 'GloberRegular';
}
.newsletter-wrapper .newsletter-box .newsletter .right-box .box-for-input .newsletter-form .email-input:-moz-placeholder {
  color: #fff;
  text-transform: lowercase;
  text-align: center;
  font-family: 'GloberRegular';
}
.newsletter-wrapper .newsletter-box .newsletter .right-box .box-for-input .newsletter-form .email-input:placeholder {
  color: #fff;
  text-transform: lowercase;
  text-align: center;
  font-family: 'GloberRegular';
}
.newsletter-wrapper .newsletter-box .newsletter .right-box .box-for-input .newsletter-form .email-input:placeholder:focus {
  color: #8a73fb;
}
.newsletter-wrapper .newsletter-box .newsletter .right-box .box-for-input .newsletter-form .email-input:-webkit-autofill,
.newsletter-wrapper .newsletter-box .newsletter .right-box .box-for-input .newsletter-form .email-input:-webkit-autofill:hover,
.newsletter-wrapper .newsletter-box .newsletter .right-box .box-for-input .newsletter-form .email-input:-webkit-autofill:focus {
  border-bottom: 2px solid #fff;
  text-align: center;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
.newsletter-wrapper .newsletter-box .newsletter .right-box .box-for-input .newsletter-form .button-violet {
  width: 168px;
  cursor: pointer;
  color: #fff f;
  background-color: #9b87ff;
  font-family: 'globersemibold';
  font-size: 12px;
  margin: 50px 0 0 0;
  padding: 0;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  -webkit-box-shadow: 0px 3px 0px 0px #7461d3;
  -moz-box-shadow: 0px 3px 0px 0px #7461d3;
  box-shadow: 0px 3px 0px 0px #7461d3;
}
.newsletter-wrapper .newsletter-box .newsletter .right-box .box-for-input .newsletter-form .button-violet .button-elements {
  width: 100%;
  display: block;
  text-align: center;
}
.newsletter-wrapper .newsletter-box .newsletter .right-box .box-for-input .newsletter-form .button-violet .button-elements .arrow-on-price-button {
  width: 6px;
  height: 9px;
  margin: -4px 11px 0 0;
}
.newsletter-wrapper .newsletter-box .newsletter .right-box .box-for-input .newsletter-form .button-violet .button-elements .arrow-on-price-button img {
  width: 6px;
  height: 16px;
}
.opinie-wrapper {
  max-width: 100%;
  margin: 0px auto;
  padding-top: 20px;
  padding-bottom: 0px;
  overflow: hidden !important;
}
.opinie-wrapper ul.opinie-list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 65px 0px 35px 0px;
  flex-wrap: wrap;
  max-width: 1024px;
  margin: 0px auto;
}
.opinie-wrapper ul.opinie-list li {
  height: auto;
  width: 50%;
  list-style-type: none;
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.opinie-wrapper ul.opinie-list li:nth-child(3) {
  width: 98%;
}
.opinie-wrapper ul.opinie-list li:nth-child(4) {
  width: 98%;
}
.opinie-wrapper ul.opinie-list li p {
  text-align: left;
  margin-top: 16px;
}
.opinie-wrapper ul.opinie-list li .quote-name {
  border-left: 2px solid #8a73fb;
  height: 30px;
  padding-left: 15px;
  color: #8a73fb;
  display: flex;
}
.opinie-wrapper ul.opinie-list li .quote-name p {
  margin: 0px;
  margin-top: -5px;
  font-weight: 600;
  font-size: 18px;
}
@media (max-width: 1440px) {
  .opinie-wrapper ul.opinie-list {
    max-width: 768px;
  }
}
@media (max-width: 1245px) {
  .opinie-wrapper ul.opinie-list li {
    width: 49%;
  }
  .opinie-wrapper ul.opinie-list li:nth-child(3) {
    width: 98%;
  }
  .opinie-wrapper ul.opinie-list li:nth-child(4) {
    width: 98%;
  }
}
@media (max-width: 1024px) {
  .opinie-wrapper ul.opinie-list li {
    width: 49%;
  }
  .opinie-wrapper ul.opinie-list li:nth-child(3) {
    width: 98%;
  }
  .opinie-wrapper ul.opinie-list li:nth-child(4) {
    width: 98%;
  }
}
@media (max-width: 768px) {
  .opinie-wrapper {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .opinie-wrapper ul.opinie-list {
    padding: 35px 20px 0px 20px;
  }
  .opinie-wrapper ul.opinie-list li {
    width: auto;
    padding-left: 5px;
  }
}
.opis-wrapper {
  max-width: 100%;
  margin: 0px auto;
  padding: 20px 0 0 0;
  overflow: hidden !important;
}
.opis-wrapper .opis-content {
  padding: 20px 0px 0 0px;
  max-width: 1024px;
  margin: 0px auto;
}
.opis-wrapper .opis-content ul {
  list-style-type: none;
  padding-left: 25px;
}
.opis-wrapper .opis-content ul li {
  font-family: 'GloberRegular';
  line-height: 41px;
  position: relative;
}
.opis-wrapper .opis-content ul li:after {
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 100%;
  background-color: #04232f;
  position: absolute;
  left: -21px;
  top: 19px;
}
.opis-wrapper .opis-content ul li:before {
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background: linear-gradient(45deg, #c35049 20%, #4587cf 55%, #5e75d3 92%);
  position: absolute;
  left: -22px;
  top: 18px;
}
.opis-wrapper .opis-content ol {
  padding-left: 25px;
}
.opis-wrapper .opis-content ol li {
  font-family: 'GloberRegular';
  line-height: 41px;
  position: relative;
}
@media (max-width: 1440px) {
  .opis-wrapper .opis-content {
    max-width: 768px;
  }
}
@media (max-width: 768px) {
  .opis-wrapper {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .opis-wrapper .opis-content {
    padding: 35px 20px 0px 20px;
  }
}
.course-plan-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-top: -44px;
}
.course-plan-wrapper .plan-outline {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 1024px;
}
.course-plan-wrapper .plan-outline ul.plan-ul {
  list-style-type: none;
  width: 100%;
}
.course-plan-wrapper .plan-outline ul.plan-ul li.course-plan {
  width: 100%;
  margin: 0px auto;
  border-bottom: 1px solid #8a73fb;
  position: relative;
  overflow: hidden;
  z-index: none;
}
.course-plan-wrapper .plan-outline ul.plan-ul li.course-plan .article-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 35px 0px;
  margin: 0px auto;
  cursor: pointer;
}
.course-plan-wrapper .plan-outline ul.plan-ul li.course-plan .article-title .article-title-text {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: calc(100% - 43px);
}
.course-plan-wrapper .plan-outline ul.plan-ul li.course-plan .article-title .article-title-text .title-number {
  font-family: 'GloberxBold';
  margin-right: 10px;
  margin-bottom: 0px;
}
.course-plan-wrapper .plan-outline ul.plan-ul li.course-plan .article-title .article-title-text .title-date {
  font-family: 'GloberSemiBold';
  font-size: pleo-h3;
}
.course-plan-wrapper .plan-outline ul.plan-ul li.course-plan .article-title .title-arrow {
  width: 43px;
  height: 43px;
  border-radius: 100%;
  background-color: transparent;
  position: relative;
}
.course-plan-wrapper .plan-outline ul.plan-ul li.course-plan .article-title .title-arrow .box-plan {
  width: 43px;
  height: 22px;
  background: linear-gradient(97deg, #7565d6 0%, #4587cf 100%);
  border-top-left-radius: 95px;
  border-top-right-radius: 95px;
  position: absolute;
  top: 0px;
}
.course-plan-wrapper .plan-outline ul.plan-ul li.course-plan .article-title .title-arrow .box2-plan {
  width: 43px;
  height: 21px;
  background: linear-gradient(90deg, #c35149 0%, #4587cf 100%);
  border-bottom-left-radius: 95px;
  border-bottom-right-radius: 95px;
  position: absolute;
  bottom: 0px;
}
.course-plan-wrapper .plan-outline ul.plan-ul li.course-plan .article-title .title-arrow:before {
  content: '';
  width: 2px;
  height: 23px;
  background-color: #8a73fb;
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%) rotate(0deg);
  transform-origin: center;
  transition: transform 0.3s;
  z-index: 1;
}
.course-plan-wrapper .plan-outline ul.plan-ul li.course-plan .article-title .title-arrow:after {
  content: '';
  width: 23px;
  height: 2px;
  background-color: #8a73fb;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
  transform-origin: center;
  transition: transform 0.3s;
}
.course-plan-wrapper .plan-outline ul.plan-ul li.course-plan .article-title .title-arrow .title-arrow-bg {
  position: absolute;
  width: 41px;
  height: 41px;
  border-radius: 100%;
  background-color: #04232f;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.course-plan-wrapper .plan-outline ul.plan-ul li.course-plan ul.article-content {
  flex-direction: column;
  max-height: 0;
  overflow: hidden;
  padding: 0px;
  transition: 0.3s;
}
.course-plan-wrapper .plan-outline ul.plan-ul li.course-plan ul.article-content li {
  max-width: 1140px;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 1140px;
  width: 100%;
  border-top: 1px solid rgba(255,255,255,0.1);
  border-bottom: 1px solid #2d3f47;
}
.course-plan-wrapper .plan-outline ul.plan-ul li.course-plan ul.article-content li:last-child {
  border-bottom: none;
}
.course-plan-wrapper .plan-outline ul.plan-ul li.course-plan ul.article-content li .tag-title {
  font-family: 'GloberxBold' !important;
  width: 40%;
  padding: 40px;
  padding-left: 0px;
  color: #8a73fb;
}
.course-plan-wrapper .plan-outline ul.plan-ul li.course-plan ul.article-content li .tag-text {
  width: 60%;
  padding: 40px 30px;
}
.course-plan-wrapper .plan-outline ul.plan-ul li.course-plan ul.article-content li .tag-text p.tag-content {
  margin: 0px;
  line-height: 35px;
}
.course-plan-wrapper .plan-outline .download-pdf-wrapper-btn {
  font-family: 'GloberBlack';
  width: 100%;
  padding: 100px 0 0 0;
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.course-plan-wrapper .plan-outline .download-pdf-wrapper-btn .form-wrapper {
  display: flex;
  flex-flow: row;
  z-index: 9;
  padding: 5px;
}
.course-plan-wrapper .plan-outline .download-pdf-wrapper-btn .form-wrapper .button-violet-price {
  cursor: pointer;
  width: 171px;
  color: #fff;
  font-family: 'globersemibold';
  background-color: #8a73fb;
  font-size: 12px;
  margin: 0;
  padding: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px 3px 0px 0px #0b171c;
  -moz-box-shadow: 0px 3px 0px 0px #0b171c;
  box-shadow: 0px 3px 0px 0px #0b171c;
  text-align: center;
}
.course-plan-wrapper .plan-outline .download-pdf-wrapper-btn .form-wrapper .button-violet-price .button-elements {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 2;
}
.course-plan-wrapper .plan-outline .download-pdf-wrapper-btn .form-wrapper .button-violet-price .button-elements .arrow-on-price-button {
  width: 6px;
  height: 9px;
  margin: 2px 9px 0 0;
  transform: rotate(90deg);
}
.course-plan-wrapper .plan-outline .download-pdf-wrapper-btn .form-wrapper .button-violet-price .button-elements .arrow-on-price-button img {
  width: 6px;
  height: 16px;
}
@media (max-width: 1440px) {
  .course-plan-wrapper .plan-outline {
    max-width: 768px;
  }
}
@media (max-width: 1245px) {
  .course-plan-wrapper .plan-outline ul.plan-ul .course-plan ul.article-content li .tag-title {
    width: 50%;
    padding: 40px 10px 40px 0px;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul .course-plan ul.article-content li .tag-title h3 {
    font-size: 24px;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul .course-plan ul.article-content li .tag-text {
    width: 50%;
  }
}
@media (max-width: 1024px) {
  .course-plan-wrapper .plan-outline ul.plan-ul .course-plan {
    width: 100%;
    padding: 0px;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul .course-plan .article-title {
    padding: 35px 0px;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul .course-plan .article-title .article-title-text {
    flex-direction: row;
    align-items: flex-end;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul .course-plan ul.article-content li {
    flex-direction: row;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul .course-plan ul.article-content li .tag-title {
    width: 50%;
    padding: 35px 10px 40px 0px;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul .course-plan ul.article-content li .tag-text {
    width: 50%;
    padding: 35px 20px 40px 0px;
  }
}
@media (max-width: 768px) {
  .course-plan-wrapper .plan-outline {
    width: 100%;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul .course-plan .article-title {
    padding: 35px 25px;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul .course-plan .article-title h1.title-number {
    font-size: 36px !important;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul .course-plan .article-title h2.title-date {
    font-size: 18px !important;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul .course-plan ul.article-content li {
    flex-direction: column;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul .course-plan ul.article-content li:nth-child(odd) {
    background: transparent;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul .course-plan ul.article-content li .tag-title {
    width: 100%;
    padding: 35px 30px 10px 30px;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul .course-plan ul.article-content li .tag-title h3 {
    font-size: 18px !important;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul .course-plan ul.article-content li .tag-text {
    width: 100%;
    padding: 10px 30px 40px 30px;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul .course-plan ul.article-content li .tag-text p {
    font-size: 14px !important;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul .download-pdf-wrapper-btn {
    width: 100%;
  }
}
@media (max-width: 540px) {
  .course-plan-wrapper .plan-outline ul.plan-ul {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul li.course-plan .article-title {
    padding-bottom: 20px;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul li.course-plan .article-title .article-title-text {
    flex-direction: column;
    align-items: flex-start;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul li.course-plan ul.article-content li {
    flex-direction: column;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul li.course-plan ul.article-content li .tag-title {
    padding: 40px 0px 0px 0px;
    width: 100%;
  }
  .course-plan-wrapper .plan-outline ul.plan-ul li.course-plan ul.article-content li .tag-text {
    padding: 0px 0px 40px 0px;
    width: 100%;
  }
}
section.square-header-text-wrap {
  max-width: 100%;
  margin: 0px auto;
  position: relative;
  padding: 0 0 90px 0;
}
section.square-header-text-wrap .img-photo {
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  top: -156px;
  right: 0px;
  width: 555px;
  height: 534px;
  overflow: hidden;
}
@media (max-width: 1440px) {
  section.square-header-text-wrap .img-photo {
    right: -210px;
  }
}
@media (max-width: 1245px) {
  section.square-header-text-wrap .img-photo {
    display: none;
  }
}
section.square-header-text-wrap .img-photo img {
  transform: rotate(90deg);
}
section.square-header-text-wrap .sq-box {
  position: absolute;
  height: 800px;
  background: #04232f;
  left: calc(50% + 600px);
  bottom: -1px;
  width: 100%;
}
section.square-header-text-wrap .square-color-box {
  display: block;
  position: absolute;
  background: transparent;
  height: 100%;
  width: 80%;
  top: 0;
}
section.square-header-text-wrap .square-content-wrap {
  max-width: 1024px;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  position: inherit;
}
section.square-header-text-wrap .square-content-wrap .square-text-wrap {
  padding-top: 40px;
  width: 70%;
}
section.square-header-text-wrap .square-content-wrap .square-text-wrap .square-text {
  padding-right: 90px;
}
section.square-header-text-wrap .square-content-wrap .square-text-wrap .square-text p {
  margin-bottom: 0px;
}
@media (max-width: 1440px) {
  section.square-header-text-wrap .sq-box {
    left: calc(50% + 600px);
  }
  section.square-header-text-wrap .square-color-box {
    width: 100%;
  }
  section.square-header-text-wrap header.square-title {
    max-width: 768px;
  }
  section.square-header-text-wrap header.square-title .section-number {
    left: -140px;
  }
  section.square-header-text-wrap .square-content-wrap {
    max-width: 768px;
  }
  section.square-header-text-wrap .square-content-wrap .square-text-wrap {
    width: 80%;
  }
  section.square-header-text-wrap .square-content-wrap .square-text-wrap .square-text {
    padding-right: 0px;
  }
}
@media (max-width: 1245px) {
  section.square-header-text-wrap .test {
    display: none;
  }
  section.square-header-text-wrap .square-content-wrap {
    max-width: 768px;
  }
  section.square-header-text-wrap .square-content-wrap .square-text-wrap {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  section.square-header-text-wrap header.square-title {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
  }
  section.square-header-text-wrap header.square-title .section-number {
    position: relative;
    left: 0px !important;
    padding-bottom: 40px;
    margin-left: -40px;
  }
  section.square-header-text-wrap header.square-title .section-number .line {
    top: 8px;
  }
  section.square-header-text-wrap header.square-title .section-number .nr-text {
    flex-direction: row;
  }
}
@media (max-width: 768px) {
  section.square-header-text-wrap header.square-title {
    padding-left: 25px;
  }
  section.square-header-text-wrap header.square-title .section-number {
    margin-left: 0px;
    padding-bottom: 60px;
  }
  section.square-header-text-wrap header.square-title h3 {
    font-size: 36px;
    width: auto;
  }
  section.square-header-text-wrap .square-content-wrap {
    padding: 0px 20px;
  }
  section.square-header-text-wrap .square-content-wrap .square-text-wrap {
    padding-top: 35px;
  }
  section.square-header-text-wrap .square-content-wrap .square-text-wrap .square-text {
    padding-right: 0px;
  }
}
section.six-pic-wrap {
  max-width: 1440px;
  margin: 0px auto;
  padding-top: 0px;
  overflow: hidden;
}
section.six-pic-wrap ul.six-pic {
  list-style-type: none;
  margin: 0px auto;
  padding-top: 100px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
  grid-row-gap: 60px;
  justify-items: center;
  align-items: center;
}
section.six-pic-wrap ul.six-pic li.six-pic-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
}
section.six-pic-wrap ul.six-pic li.six-pic-item .six-pic-p {
  text-align: center;
  max-width: 300px;
  margin-bottom: 0px;
  margin: 0px auto;
  padding-top: 50px;
}
@media (max-width: 1440px) {
  section.six-pic-wrap header.six-pic-title {
    max-width: 768px;
  }
  section.six-pic-wrap header.six-pic-title .section-number {
    left: -140px;
  }
  section.six-pic-wrap ul.six-pic {
    margin: 0px auto;
    padding: 100px 100px 0px 100px;
  }
}
@media (max-width: 1245px) {
  section.six-pic-wrap ul.six-pic {
    margin: 0px auto;
    padding: 0px;
    padding-top: 100px;
  }
}
@media (max-width: 1024px) {
  section.six-pic-wrap ul.six-pic {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto auto;
  }
  section.six-pic-wrap ul.six-pic li.six-pic-item {
    display: flex;
  }
  section.six-pic-wrap ul.six-pic li.six-pic-item .six-pic-p {
    padding-top: 40px;
  }
}
@media (max-width: 767px) {
  section.six-pic-wrap {
    padding-bottom: 0px;
  }
  section.six-pic-wrap header.six-pic-title {
    padding-left: 25px;
  }
  section.six-pic-wrap header.six-pic-title .section-number {
    margin-left: 0px;
    padding-bottom: 60px;
  }
  section.six-pic-wrap header.six-pic-title h3 {
    font-size: 36px;
    width: auto;
    padding-right: 20px;
    margin: 0px;
  }
  section.six-pic-wrap ul.six-pic {
    padding: 30px 30px 0 0;
    grid-template-columns: auto;
    justify-content: flex-start;
    grid-row-gap: 0px;
    width: 100%;
  }
  section.six-pic-wrap ul.six-pic li.six-pic-item {
    padding: 5px 0;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    min-height: 100px;
  }
  section.six-pic-wrap ul.six-pic li.six-pic-item .six-pic-img {
    width: 100px;
    min-width: 101px;
    display: flex;
    justify-content: center;
  }
  section.six-pic-wrap ul.six-pic li.six-pic-item .six-pic-img img {
    transform: scale(0.6);
  }
  section.six-pic-wrap ul.six-pic li.six-pic-item .six-pic-p {
    text-align: left;
    padding-left: 10px;
    padding-top: 0px;
    max-width: 100%;
    margin: 0px 0px;
    font-size: 18px !important;
  }
}
.co-zyskujesz-wrapper {
  background: transparent;
  padding: 0px;
  padding-top: 85px;
  position: relative;
}
.co-zyskujesz-wrapper ul.co-zyskujesz-list {
  max-width: 1440px;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0px 120px 0 100px;
}
@media (max-width: 1440px) {
  .co-zyskujesz-wrapper ul.co-zyskujesz-list {
    margin: 0 6%;
  }
}
@media screen and (max-width: 1024px) {
  .co-zyskujesz-wrapper ul.co-zyskujesz-list {
    margin: 0 9%;
  }
}
@media screen and (max-width: 768px) {
  .co-zyskujesz-wrapper ul.co-zyskujesz-list {
    margin: 0 3%;
  }
}
@media screen and (max-width: 375px) {
  .co-zyskujesz-wrapper ul.co-zyskujesz-list {
    margin: 0 6%;
  }
}
.co-zyskujesz-wrapper ul.co-zyskujesz-list li {
  height: 250px;
  width: 250px;
  margin: 20px 30px;
  margin-left: 45px;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  color: #04232f;
  border-radius: 100%;
  position: relative;
}
.co-zyskujesz-wrapper ul.co-zyskujesz-list li:hover .arrow-wrapper {
  background: linear-gradient(235deg, #8a73fb 0%, #2cbad8 68%, #eb594d 100%);
}
.co-zyskujesz-wrapper ul.co-zyskujesz-list li:hover .arrow-wrapper .arrow1 {
  animation: rotate 0.2s linear 1;
  transform-origin: bottom;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
.co-zyskujesz-wrapper ul.co-zyskujesz-list li .arrow-wrapper {
  height: 170px;
  width: 170px;
  border-radius: 100%;
  background: transparent;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.co-zyskujesz-wrapper ul.co-zyskujesz-list li .arrow-wrapper .arrow-wrapper-bg {
  height: 168px;
  width: 168px;
  border-radius: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.co-zyskujesz-wrapper ul.co-zyskujesz-list li .arrow-wrapper .arrow1 {
  position: absolute;
  width: 1px;
  height: 57%;
  left: 50%;
  right: 50%;
  bottom: 50%;
  animation: no-rotate 0.2s linear 1;
  transform-origin: bottom;
  animation-direction: alternate;
  animation-fill-mode: forwards;
}
.co-zyskujesz-wrapper ul.co-zyskujesz-list li .arrow-wrapper .arrow1 span {
  width: 16px;
  height: 26px;
  background: url("../img/right-b-arrow.svg");
  background-repeat: no-repeat;
  position: absolute;
  left: -10px;
  transform: rotate(180deg);
}
.co-zyskujesz-wrapper ul.co-zyskujesz-list li .arrow-wrapper .arrow2 {
  position: absolute;
  width: 1px;
  height: 57%;
  left: 50%;
  right: 50%;
  bottom: 50%;
}
.co-zyskujesz-wrapper ul.co-zyskujesz-list li .arrow-wrapper .arrow2 span {
  background: url("../img/right-v-arrow.svg");
  background-repeat: no-repeat;
  width: 16px;
  height: 26px;
  position: absolute;
  left: -10px;
}
.co-zyskujesz-wrapper ul.co-zyskujesz-list li .arrow-wrapper .text6 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  position: relative;
  width: 100%;
}
.co-zyskujesz-wrapper ul.co-zyskujesz-list li .arrow-wrapper .text6 .textp2 {
  font-size: 18px;
  text-align: center;
  max-height: 60px;
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -1%);
  text-justify: top;
  width: 300px;
  top: -12px;
  line-height: 42px !important;
}
@media (max-width: 1245px) {
  .co-zyskujesz-wrapper ul.co-zyskujesz-list {
    padding: 0px;
  }
  .co-zyskujesz-wrapper ul.co-zyskujesz-list li {
    margin-left: 30px;
  }
}
@media (max-width: 768px) {
  .co-zyskujesz-wrapper {
    padding-top: 25px;
  }
  .co-zyskujesz-wrapper ul.co-zyskujesz-list {
    flex-direction: column;
    padding: 0px;
    justify-content: flex-start;
    align-items: center;
  }
  .co-zyskujesz-wrapper ul.co-zyskujesz-list li {
    margin: 0px;
    justify-content: flex-end;
    flex-direction: row-reverse;
    border-radius: 0px;
    width: 100%;
    height: 100px;
    padding-left: 10px;
  }
  .co-zyskujesz-wrapper ul.co-zyskujesz-list li:hover .arrow-wrapper {
    background: transparent;
  }
  .co-zyskujesz-wrapper ul.co-zyskujesz-list li:hover .arrow-wrapper .arrow1 {
    animation: none;
    transform: none;
  }
  .co-zyskujesz-wrapper ul.co-zyskujesz-list li .arrow-wrapper {
    justify-content: flex-start;
    flex-direction: row;
    margin: 0px;
    height: 100%;
    width: 100%;
    position: relative;
    border-radius: 0px;
  }
  .co-zyskujesz-wrapper ul.co-zyskujesz-list li .arrow-wrapper .arrow-wrapper-bg {
    display: none;
  }
  .co-zyskujesz-wrapper ul.co-zyskujesz-list li .arrow-wrapper .arrow1 {
    width: 0px;
    position: relative;
    left: 15px;
    right: 0px;
    bottom: 0px;
    height: 100px;
    animation: none;
  }
  .co-zyskujesz-wrapper ul.co-zyskujesz-list li .arrow-wrapper .arrow1 span {
    left: 0px;
    transform: translateY(-50%);
    top: 50%;
  }
  .co-zyskujesz-wrapper ul.co-zyskujesz-list li .arrow-wrapper .arrow2 {
    width: 0px;
    position: relative;
    left: 15px;
    right: 0px;
    bottom: 0px;
    height: 100px;
  }
  .co-zyskujesz-wrapper ul.co-zyskujesz-list li .arrow-wrapper .arrow2 span {
    left: 0px;
    transform: translateY(-50%);
    top: 50%;
  }
  .co-zyskujesz-wrapper ul.co-zyskujesz-list li .arrow-wrapper .text6 {
    position: relative;
    width: 100%;
    justify-content: flex-start;
    min-width: 300px;
    height: 100px;
    align-items: center;
    padding-left: 60px;
  }
  .co-zyskujesz-wrapper ul.co-zyskujesz-list li .arrow-wrapper .text6 .textp2 {
    text-align: left;
    top: 0px;
    margin-bottom: 0px;
    max-height: inherit;
    position: relative;
    padding-bottom: 5px;
    padding-left: 20px;
    width: 100%;
    line-height: 27px !important;
    font-size: 18px !important;
  }
}
@-moz-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}
@-webkit-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}
@-o-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}
@-moz-keyframes no-rotate {
  0% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes no-rotate {
  0% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@-o-keyframes no-rotate {
  0% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes no-rotate {
  0% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.image-on-right {
  width: 100%;
  margin: 0px auto;
  padding-top: 0px;
  background: transparent;
  color: #04232f;
  overflow: hidden;
  height: auto;
}
.image-on-right .trener-wrap {
  height: auto;
  display: flex;
  flex-direction: row;
  max-width: 1024px;
  margin: 0px auto;
}
@media (max-width: 1440px) {
  .image-on-right .trener-wrap {
    max-width: 771px;
  }
}
@media screen and (max-width: 768px) {
  .image-on-right .trener-wrap {
    margin: 0 3%;
    flex-flow: column;
  }
}
@media screen and (max-width: 375px) {
  .image-on-right .trener-wrap {
    margin: 0 6%;
  }
}
.image-on-right .trener-wrap .trener-wrap-text {
  width: 56%;
  padding-top: 30px;
  padding-bottom: 130px;
}
@media screen and (max-width: 768px) {
  .image-on-right .trener-wrap .trener-wrap-text {
    width: 100%;
    padding-bottom: 30px;
  }
}
.image-on-right .trener-wrap .trener-wrap-text h3.trainer-name {
  font-family: 'GloberBlack';
  color: #8a73fb;
  margin-bottom: 0px;
}
.image-on-right .trener-wrap .trener-wrap-text p.trainer-short {
  font-family: 'GloberRegular';
  margin-bottom: 50px;
}
.image-on-right .trener-wrap .trener-wrap-text p.trainer-info {
  font-family: 'GloberRegular';
}
.image-on-right .trener-wrap .trener-wrap-text .trainer-social-icons-wrap {
  height: 90px;
  width: 140px;
  display: flex;
  justify-content: flex-start;
  padding: 0px;
  padding-top: 20px;
}
.image-on-right .trener-wrap .trener-wrap-text .trainer-social-icons-wrap li {
  height: 35px;
  width: 35px;
  border-radius: 20px;
  list-style-type: none;
  margin-right: 18px;
}
.image-on-right .trener-wrap .trener-wrap-text .trainer-social-icons-wrap li a .icon {
  height: 35px;
  width: 35px;
}
.image-on-right .trener-wrap .trener-wrap-img {
  width: 44%;
  height: auto;
  margin-top: 0px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .image-on-right .trener-wrap .trener-wrap-img {
    width: 100%;
    height: 500px;
  }
}
.image-on-right .trener-wrap .trener-wrap-img .trainer-photo {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 120%;
  height: 120%;
  position: absolute;
  left: 97px;
}
@media screen and (max-width: 768px) {
  .image-on-right .trener-wrap .trener-wrap-img .trainer-photo {
    width: 100%;
    height: 500px;
    left: 0;
  }
}
@media screen and (max-width: 375px) {
  .image-on-right .trener-wrap .trener-wrap-img .trainer-photo {
    height: 570px;
  }
}
.image-on-right .trener-wrap .trener-wrap-img .partner-photo {
  background-repeat: no-repeat;
  background-position: center;
  width: 120%;
  height: 120%;
  position: absolute;
  left: 97px;
  top: -150px;
}
@media (max-width: 1080px) {
  .image-on-right .trener-wrap .trener-wrap-img .partner-photo {
    left: 24px;
  }
}
@media (max-width: 950px) and (min-width: 769px) {
  .image-on-right .trener-wrap .trener-wrap-img .partner-photo {
    left: 0;
    background-size: 80%;
  }
}
@media screen and (max-width: 768px) {
  .image-on-right .trener-wrap .trener-wrap-img .partner-photo {
    width: 100%;
    height: 500px;
    left: 0;
    top: -80px;
  }
}
@media (max-width: 400px) {
  .image-on-right .trener-wrap .trener-wrap-img .partner-photo {
    background-size: 100%;
  }
}
@media screen and (max-width: 375px) {
  .image-on-right .trener-wrap .trener-wrap-img .partner-photo {
    height: 570px;
  }
}
.image-on-left {
  background-color: #008000;
  width: 100%;
  margin: 0px auto;
  padding-top: 0px;
  background: transparent;
  color: #04232f;
  overflow: hidden;
}
.image-on-left .trener-wrap {
  display: flex;
  flex-direction: row-reverse;
  max-width: 1024px;
  margin: 0px auto;
}
@media (max-width: 1440px) {
  .image-on-left .trener-wrap {
    margin: 0 6%;
  }
}
@media screen and (max-width: 1024px) {
  .image-on-left .trener-wrap {
    margin: 0 9%;
  }
}
@media screen and (max-width: 768px) {
  .image-on-left .trener-wrap {
    margin: 0 3%;
    flex-direction: column;
  }
}
@media screen and (max-width: 375px) {
  .image-on-left .trener-wrap {
    margin: 0 6%;
  }
}
.image-on-left .trener-wrap .trener-wrap-text {
  width: 56%;
  padding-top: 30px;
  padding-bottom: 130px;
}
@media screen and (max-width: 768px) {
  .image-on-left .trener-wrap .trener-wrap-text {
    width: 100%;
    padding-bottom: 30px;
  }
}
.image-on-left .trener-wrap .trener-wrap-text h3.trainer-name {
  font-family: 'GloberBlack';
  font-size: 24px;
  color: #8a73fb;
}
.image-on-left .trener-wrap .trener-wrap-text p.trainer-short {
  font-family: 'GloberRegular';
  font-size: 14px;
  margin-bottom: 50px;
}
.image-on-left .trener-wrap .trener-wrap-text p.trainer-info {
  font-family: 'GloberRegular';
  font-size: 16px;
  line-height: 29px;
}
.image-on-left .trener-wrap .trener-wrap-text .trainer-social-icons-wrap {
  height: 90px;
  width: 140px;
  display: flex;
  justify-content: flex-start;
  padding: 0px;
  padding-top: 20px;
}
.image-on-left .trener-wrap .trener-wrap-text .trainer-social-icons-wrap li {
  height: 35px;
  width: 35px;
  border-radius: 20px;
  list-style-type: none;
  margin-right: 18px;
}
.image-on-left .trener-wrap .trener-wrap-text .trainer-social-icons-wrap li a .icon {
  height: 35px;
  width: 35px;
}
.image-on-left .trener-wrap .trener-wrap-img {
  width: 44%;
  height: auto;
  margin-top: 0px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .image-on-left .trener-wrap .trener-wrap-img {
    width: 100%;
    height: 500px;
  }
}
.image-on-left .trener-wrap .trener-wrap-img .trainer-photo {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 120%;
  height: 120%;
  position: absolute;
  left: -161px;
}
@media screen and (max-width: 768px) {
  .image-on-left .trener-wrap .trener-wrap-img .trainer-photo {
    width: 100%;
    height: 500px;
    left: 0;
  }
}
.image-on-left .trener-wrap .trener-wrap-img .partner-photo {
  background-repeat: no-repeat;
  background-position: center;
  width: 120%;
  height: 120%;
  position: absolute;
  left: -161px;
}
@media screen and (max-width: 768px) {
  .image-on-left .trener-wrap .trener-wrap-img .partner-photo {
    width: 100%;
    height: 500px;
    left: 0;
  }
}
@media screen and (max-width: 375px) {
  .image-on-left .trener-wrap .trener-wrap-img .partner-photo {
    height: 570px;
  }
}
@media (max-width: 1440px) {
  section.trener-wrapper .trener-wrap {
    max-width: 768px;
  }
}
@media (max-width: 1245px) {
  section.trener-wrapper .trener-wrap .trener-wrap-img .trainer-photo {
    width: 105%;
  }
  section.trener-wrapper .trener-wrap .trener-wrap-img .partner-photo {
    width: 105%;
  }
}
@media (max-width: 1024px) {
  section.trener-wrapper .trener-wrap {
    flex-direction: column;
  }
  section.trener-wrapper .trener-wrap .trener-wrap-text {
    width: 100%;
    padding-bottom: 0px;
  }
  section.trener-wrapper .trener-wrap .trener-wrap-img {
    height: 500px;
    width: 100%;
    position: unset;
  }
  section.trener-wrapper .trener-wrap .trener-wrap-img .trainer-photo {
    position: unset;
    width: 100%;
    height: 120%;
  }
  section.trener-wrapper .trener-wrap .trener-wrap-img .partner-photo {
    position: unset;
    width: 100%;
    height: 120%;
  }
}
@media (max-width: 768px) {
  section.trener-wrapper {
    padding: 0px 20px;
  }
  section.trener-wrapper .trener-wrap .trener-wrap-text {
    padding-bottom: 40px;
  }
  section.trener-wrapper .trener-wrap .trener-wrap-img {
    height: 400px;
    width: 100%;
    position: unset;
  }
  section.trener-wrapper .trener-wrap .trener-wrap-img .trainer-photo {
    width: 100%;
    border: 1px solid #f00;
  }
  section.trener-wrapper .trener-wrap .trener-wrap-img .partner-photo {
    width: 100%;
    border: 1px solid #f00;
  }
}
.module-title {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0;
}
.module-title .module-header-title {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1024px;
  margin: 0px auto;
}
.module-title .module-header-title .section-number {
  position: absolute;
  top: 4px;
  left: -170px;
  padding: 40px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.module-title .module-header-title .section-number .line {
  width: 20px;
  position: relative;
}
.module-title .module-header-title .section-number .line:after {
  content: '';
  background-color: #8a73fb;
  width: 20px;
  height: 2px;
  position: absolute;
  left: 0px;
  top: -9px;
}
.module-title .module-header-title .section-number .nr-text {
  display: flex;
  flex-direction: column;
  padding-left: 15px;
}
.module-title .module-header-title .section-number .nr-text .nr-number {
  color: #8a73fb;
  font-family: 'GloberxBold';
  font-size: 16px;
}
.module-title .module-header-title .section-number .nr-text .nr-name {
  color: #fff;
  font-family: 'GloberxBold';
  font-size: 16px;
  max-width: 115px;
}
.module-title .module-header-title h2 {
  font-family: 'GloberxBold';
  color: #fff;
  font-size: 48px;
  padding: 41px 0 0 0;
}
@media (max-width: 1440px) {
  .module-title header.module-header-title {
    max-width: 768px;
  }
  .module-title header.module-header-title .section-number {
    left: -140px;
  }
  .module-title header.module-header-title .section-number .nr-text .nr-name {
    max-width: 90px;
  }
}
@media (max-width: 1245px) {
  .module-title header.module-header-title .section-number {
    left: -120px;
  }
}
@media (max-width: 1024px) {
  .module-title header.module-header-title {
    position: relative;
    flex-direction: column;
    align-items: flex-start;
  }
  .module-title header.module-header-title .section-number {
    position: relative;
    left: 0px !important;
    padding-bottom: 30px;
    margin-left: -40px;
  }
  .module-title header.module-header-title .section-number .line {
    top: 8px;
  }
  .module-title header.module-header-title .section-number .nr-text {
    flex-direction: row;
  }
  .module-title header.module-header-title .section-number .nr-text .nr-name {
    max-width: 250px;
  }
  .module-title header.module-header-title h2 {
    padding: 0;
  }
}
@media (max-width: 768px) {
  .module-title header.module-header-title {
    padding-left: 20px;
  }
  .module-title header.module-header-title .section-number {
    margin-left: 0px;
  }
  .module-title header.module-header-title h2 {
    font-size: 36px;
    width: auto;
    padding-right: 20px;
    margin: 0px;
  }
}
@media (max-width: 375px) {
  .module-title header.module-header-title {
    padding-left: 20px;
  }
  .module-title header.module-header-title .section-number {
    margin-left: 0px;
    padding: 40px 0 30px 0;
  }
  .module-title header.module-header-title h2 {
    font-size: 36px;
    width: auto;
    padding-right: 20px;
    margin: 0px;
  }
}
section.similar-articles-wrapper {
  width: 100%;
  overflow: hidden;
  padding: 20px 0px;
}
section.similar-articles-wrapper ul.similar-articles {
  max-width: 768px;
  margin: 0px auto;
  list-style-type: none;
  display: flex;
  flex-direction: row;
}
section.similar-articles-wrapper ul.similar-articles li.article-pattern {
  width: 365px;
  height: 385px;
  margin: 0px 6.5px;
  padding: 35px 30px 40px 30px;
  border-bottom-left-radius: 25px;
  border: 2px solid #8a73fb;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
  color: #000;
  cursor: pointer;
}
section.similar-articles-wrapper ul.similar-articles li.article-pattern:hover {
  background: #8a73fb;
}
section.similar-articles-wrapper ul.similar-articles li.article-pattern:hover .pattern-main .pattern-header .pattern-category {
  color: #fff;
}
section.similar-articles-wrapper ul.similar-articles li.article-pattern:hover .pattern-main .pattern-header .pattern-author {
  color: #fff;
}
section.similar-articles-wrapper ul.similar-articles li.article-pattern .pattern-main {
  display: flex;
  flex-direction: column;
}
section.similar-articles-wrapper ul.similar-articles li.article-pattern .pattern-main .pattern-header {
  display: flex;
  flex-direction: column;
  padding-bottom: 28px;
}
section.similar-articles-wrapper ul.similar-articles li.article-pattern .pattern-main .pattern-header .pattern-category {
  text-transform: uppercase;
  font-size: 12px;
  font-family: 'GloberRegular';
  color: #8a73fb;
  padding-bottom: 7px;
}
section.similar-articles-wrapper ul.similar-articles li.article-pattern .pattern-main .pattern-header .pattern-author {
  text-transform: uppercase;
  font-size: 14px;
  font-family: 'GloberxBold';
  color: #8a73fb;
}
section.similar-articles-wrapper ul.similar-articles li.article-pattern .pattern-main .pattern-main-text {
  font-family: 'GloberxBold';
  font-size: 24px;
  line-height: 31px;
  padding-top: 54px;
  position: relative;
  width: 95%;
}
section.similar-articles-wrapper ul.similar-articles li.article-pattern .pattern-main .pattern-main-text:after {
  content: '';
  position: absolute;
  width: 100px;
  height: 7px;
  background: #000;
  top: 0;
  left: 0;
}
section.similar-articles-wrapper ul.similar-articles li.article-pattern ul.pattern-footer {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  max-width: 80%;
  flex-wrap: wrap;
}
section.similar-articles-wrapper ul.similar-articles li.article-pattern ul.pattern-footer li.pattern-hashtag {
  font-family: 'GloberRegular';
  font-size: 12px;
  padding: 0px 7px;
}
@media (max-width: 768px) {
  section.similar-articles-wrapper ul.similar-articles {
    flex-direction: column;
    align-items: center;
  }
  section.similar-articles-wrapper ul.similar-articles li.article-pattern {
    margin: 20px;
  }
}
@media (max-width: 420px) {
  section.similar-articles-wrapper ul.similar-articles {
    flex-direction: column;
    align-items: center;
  }
  section.similar-articles-wrapper ul.similar-articles li.article-pattern {
    width: 315px;
  }
  section.similar-articles-wrapper ul.similar-articles li.article-pattern .pattern-main .pattern-main-text {
    width: 100%;
  }
}
.portfolio-wrapper {
  width: 100%;
  background-color: transparent;
}
.portfolio-wrapper .portfolio-elements {
  max-width: 1400px;
  display: flex;
  flex-flow: row;
  margin: 0 auto;
  min-height: 600px;
}
@media (max-width: 1440px) {
  .portfolio-wrapper .portfolio-elements {
    max-width: 1040px;
  }
}
@media screen and (max-width: 1024px) {
  .portfolio-wrapper .portfolio-elements {
    max-width: 836px !important;
  }
}
@media screen and (max-width: 768px) {
  .portfolio-wrapper .portfolio-elements {
    flex-flow: column;
    margin: 0 3%;
  }
}
@media screen and (max-width: 375px) {
  .portfolio-wrapper .portfolio-elements {
    margin: 0 6%;
  }
}
.portfolio-wrapper .portfolio-elements .image {
  width: 100%;
  display: flex;
  flex-flow: column;
}
.portfolio-wrapper .portfolio-elements .image a {
  display: block;
  height: 100%;
}
@media screen and (max-width: 768px) {
  .portfolio-wrapper .portfolio-elements .image a .image2 {
    margin: 70px 0 0 0;
  }
}
.portfolio-wrapper .portfolio-elements .image a .one-image {
  min-height: 470px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 375px) {
  .portfolio-wrapper .portfolio-elements .image a .one-image {
    min-height: 40vh;
  }
}
.portfolio-wrapper .portfolio-elements .image a .one-image .button-element {
  display: none;
  height: 100%;
}
.portfolio-wrapper .portfolio-elements .image a .one-image .button-element .method-coord-img-outline {
  cursor: pointer;
  opacity: 1;
  width: 116px;
  height: 116px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  color: #fff;
  box-shadow: 0px 8px 5px 0px rgba(20,20,20,0.55);
}
@media screen and (max-width: 375px) {
  .portfolio-wrapper .portfolio-elements .image a .one-image .button-element .method-coord-img-outline {
    opacity: 0;
  }
}
.portfolio-wrapper .portfolio-elements .image a .one-image .button-element .method-coord-img-outline .box-knowledge {
  width: 116px;
  height: 58px;
  background: linear-gradient(97deg, #7565d6 0%, #4587cf 100%);
  border-top-left-radius: 95px;
  border-top-right-radius: 95px;
  position: absolute;
  top: 0px;
}
.portfolio-wrapper .portfolio-elements .image a .one-image .button-element .method-coord-img-outline .box-knowledge2 {
  width: 116px;
  height: 58px;
  background: linear-gradient(90deg, #c35149 0%, #4587cf 100%);
  border-bottom-left-radius: 95px;
  border-bottom-right-radius: 95px;
  position: absolute;
  bottom: 0px;
}
.portfolio-wrapper .portfolio-elements .image a .one-image .button-element .method-coord-img-outline .method-coord-img {
  width: 110px;
  height: 110px;
  border-radius: 100%;
  z-index: 2;
  background-color: #04232f;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.portfolio-wrapper .portfolio-elements .image a .one-image .button-element .method-coord-img-outline .method-coord-img .arrow {
  position: absolute;
  top: 15px;
  left: 20px;
  transform: rotate(-180deg);
}
.portfolio-wrapper .portfolio-elements .image a .one-image .button-element .method-coord-img-outline .method-coord-img .arrow img {
  width: 41px;
  height: 2;
}
.portfolio-wrapper .portfolio-elements .image a .one-image .button-element .method-coord-img-outline .method-coord-img .text {
  position: absolute;
  top: 33px;
  left: 20px;
  font-size: 16px;
  width: 50%;
  color: #fff;
  font-family: 'GloberxBold';
  color: #fff;
  z-index: 100;
  width: 100%;
  min-height: 180px;
}
.portfolio-wrapper .portfolio-elements .image a:hover .button-element {
  display: flex;
}
.portfolio-wrapper .portfolio-elements .description .name {
  font-size: 27px;
  font-family: 'GloberRegular';
  padding: 31px 0 35px 0;
}
@media screen and (max-width: 768px) {
  .portfolio-wrapper .portfolio-elements .description .name {
    padding: 31px 0 18px 0;
    font-size: 24px;
  }
}
.portfolio-wrapper .portfolio-elements .description .text-description {
  font-size: 42px;
  font-family: 'GloberxBold';
  line-height: 1;
}
@media screen and (max-width: 768px) {
  .portfolio-wrapper .portfolio-elements .description .text-description {
    font-size: 36px;
  }
}
.jumbotron-tools-alltools {
  border-top: 2px solid #e8e8e8;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 72vh;
  position: relative;
}
.jumbotron-tools-alltools:before {
  content: '';
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, rgba(19,39,48,0.8) 10%, rgba(19,39,48,0.7) 20%, rgba(19,39,48,0.6) 30%, rgba(19,39,48,0.5) 40%, rgba(19,39,48,0) 50%);
}
@media (max-width: 450px) {
  .jumbotron-tools-alltools {
    height: 85vh;
  }
}
.jumbotron-tools-alltools .banner {
  max-width: 1400px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  position: relative;
  justify-content: center;
}
@media (max-width: 1440px) {
  .jumbotron-tools-alltools .banner {
    margin: 0 10%;
  }
}
@media screen and (max-width: 1024px) {
  .jumbotron-tools-alltools .banner {
    margin: 0 10%;
  }
}
@media screen and (max-width: 768px) {
  .jumbotron-tools-alltools .banner {
    margin: 0 3%;
  }
}
@media screen and (max-width: 375px) {
  .jumbotron-tools-alltools .banner {
    margin: 0 6%;
  }
}
.jumbotron-tools-alltools .banner .banner-wrapper {
  display: flex;
  flex-flow: row;
}
@media (max-width: 616px) {
  .jumbotron-tools-alltools .banner .banner-wrapper {
    flex-flow: column;
  }
}
.jumbotron-tools-alltools .banner .banner-wrapper .baner-items {
  width: 100%;
  margin: 0 0 0 0;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
}
@media (max-width: 616px) {
  .jumbotron-tools-alltools .banner .banner-wrapper .baner-items {
    margin: 30px 0 0 3%;
    width: 94%;
  }
}
.jumbotron-tools-alltools .banner .banner-wrapper .baner-items .pleo-h1 {
  font-size: 72px;
  display: flex;
  flex-flow: column;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  font-family: 'GloberHeavy';
  line-height: 1.2;
}
.jumbotron-tools-alltools .banner .banner-wrapper .baner-items .pleo-h1 p {
  display: inline-block;
  padding: 0;
  margin: 0;
  color: #fff;
}
.jumbotron-tools-alltools .banner .banner-wrapper .baner-items .pleo-h1 p:first-line {
  color: #8a73fb;
}
@media screen and (max-width: 1024px) {
  .jumbotron-tools-alltools .banner .banner-wrapper .baner-items .pleo-h1 p {
    font-size: 48px;
  }
}
@media (max-width: 768px) {
  .jumbotron-tools-alltools .banner .banner-wrapper .baner-items .pleo-h1 p {
    font-size: 44px;
  }
}
@media screen and (max-width: 375px) {
  .jumbotron-tools-alltools .banner .banner-wrapper .baner-items .pleo-h1 p {
    font-size: 36px;
  }
}
.jumbotron-tools-alltools .banner .banner-wrapper .baner-items .pleo-h1::before {
  content: '';
  display: block;
  margin: 0 0 20px 0;
  width: 104px;
  height: 7px;
  background-color: #fff;
}
.jumbotron-tools-alltools .banner .banner-wrapper .baner-items .pleo-h4 {
  font-size: 24px;
  padding: 26px 0 0 0;
  width: 60%;
  position: relative;
  color: #04232f;
}
@media screen and (max-width: 1024px) {
  .jumbotron-tools-alltools .banner .banner-wrapper .baner-items .pleo-h4 {
    font-size: 22px;
    width: 60%;
  }
}
@media screen and (max-width: 768px) {
  .jumbotron-tools-alltools .banner .banner-wrapper .baner-items .pleo-h4 {
    font-size: 22px;
    width: 70%;
  }
}
@media screen and (max-width: 375px) {
  .jumbotron-tools-alltools .banner .banner-wrapper .baner-items .pleo-h4 {
    font-size: 18px;
    width: 80%;
  }
}
.jumbotron-tools-alltools .banner .banner-wrapper .baner-items .pleo-h4 p {
  display: inline-block;
  font-family: 'GloberLight';
  color: #fff;
}
.tool-choose-fiolet {
  background-color: #8a73fb !important;
}
@media (max-width: 768px) {
  section.menu-tool-choose-wrapper.sticky {
    position: static !important;
  }
}
section.menu-tool-choose-wrapper.sticky .menu-tool-choose {
  max-height: 85px;
}
section.menu-tool-choose-wrapper.sticky .menu-tool-choose .huge-resolution-tool-wrapper1 {
  max-height: 85px;
}
section.menu-tool-choose-wrapper.sticky .menu-tool-choose .huge-resolution-tool-wrapper2 {
  max-height: 85px;
}
section.menu-tool-choose-wrapper {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}
@media (max-width: 685px) {
  section.menu-tool-choose-wrapper {
    position: static;
  }
}
section.menu-tool-choose-wrapper .menu-tool-choose {
  width: 100%;
  display: flex;
  height: 136px;
}
section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper1 {
  background: #8a73fb;
  height: 100%;
  width: 50%;
  display: flex;
}
section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper1 .designer-tools {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper1 .designer-tools .designer-tools-img {
  background: url("../img/designer-tool-img.png");
  background-size: contain;
  background-repeat: no-repeat;
  height: 50px;
  width: 52px;
  padding-right: 35px;
}
section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper1 .designer-tools h2.designer-tools-text {
  color: #04232f;
  padding-left: 35px;
  font-family: 'GloberxBold';
  font-size: 24px;
  margin-bottom: 0px;
}
section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper2 {
  background: #fff;
  width: 50%;
  display: flex;
}
section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper2 .programmer-tools {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-left: 1px solid #d6d6d6;
}
section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper2 .programmer-tools .programmer-tools-img {
  background: url("../img/programmer-tool-img.png");
  background-size: contain;
  background-repeat: no-repeat;
  width: 47px;
  height: 46px;
  padding-right: 35px;
}
section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper2 .programmer-tools h2.programmer-tools-text {
  color: #04232f;
  padding-left: 35px;
  font-family: 'GloberxBold';
  font-size: 24px;
  margin-bottom: 0px;
}
@media (min-width: 2048px) {
  section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper1 {
    flex-direction: row-reverse;
  }
  section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper1 .designer-tools {
    max-width: 700px;
    justify-content: flex-start;
  }
  section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper2 .programmer-tools {
    max-width: 700px;
    justify-content: flex-end;
  }
}
@media (max-width: 1024px) {
  section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper1 .designer-tools {
    padding: 20px;
  }
  section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper1 .designer-tools .designer-tools-img {
    margin: 0 10px 0 0;
  }
  section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper1 .designer-tools h2.designer-tools-text {
    padding: 0px;
    padding-top: 16px;
  }
  section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper2 .programmer-tools {
    padding: 20px;
  }
  section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper2 .programmer-tools .programmer-tools-img {
    margin: 0 10px 0 0;
    padding: 0px;
  }
  section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper2 .programmer-tools h2.programmer-tools-text {
    padding: 0px;
    padding-top: 16px;
  }
}
@media (max-width: 480px) {
  section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper1 .designer-tools {
    flex-direction: column;
    align-items: flex-start;
  }
  section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper1 .designer-tools-img {
    width: 38px;
    height: 37px;
  }
  section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper1 h2.designer-tools-text {
    font-size: 16px !important;
  }
  section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper2 .programmer-tools {
    flex-direction: column;
    align-items: flex-start;
  }
  section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper2 .programmer-tools .programmer-tools-img {
    width: 37px;
    height: 37px;
  }
  section.menu-tool-choose-wrapper .menu-tool-choose .huge-resolution-tool-wrapper2 .programmer-tools h2.programmer-tools-text {
    font-size: 16px;
  }
}
.filter-visible {
  height: 200px !important;
}
.filter-visible li {
  display: flex !important;
}
section.filter-tools-wrapper {
  width: 100%;
  min-height: 50px;
  padding-top: 60px;
}
section.filter-tools-wrapper .filter-tools {
  max-width: 1140px !important;
  display: flex;
  justify-content: space-between;
  margin: 0px auto !important;
  padding: 0px 0px;
}
section.filter-tools-wrapper .filter-tools .filter-tools-list {
  display: flex;
  align-items: center;
}
section.filter-tools-wrapper .filter-tools .filter-tools-list h3.filter-list-text {
  margin-bottom: 0px;
  text-transform: uppercase;
  font-family: 'GloberxBold';
  font-size: 24px;
  padding-right: 20px;
}
section.filter-tools-wrapper .filter-tools .filter-tools-list .dropdown {
  margin: 0px;
  cursor: pointer;
}
section.filter-tools-wrapper .filter-tools .filter-tools-list .dropdown .dt {
  display: none;
  width: 180px;
  margin-top: 3px;
}
section.filter-tools-wrapper .filter-tools .filter-tools-list .dropdown .dd ul.filter-list {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  z-index: 10;
}
section.filter-tools-wrapper .filter-tools .filter-tools-list .dropdown .dd ul.filter-list li {
  padding-left: 12px;
  padding-right: 12px;
  height: 100%;
  text-transform: uppercase;
  font-family: 'GloberRegular';
  font-size: 14px;
  position: relative;
  cursor: pointer;
  color: #fff;
}
section.filter-tools-wrapper .filter-tools .filter-tools-list .dropdown .dd ul.filter-list li:after {
  content: '';
  position: absolute;
  height: 3px;
  width: calc(100% - 32px - 24px);
  background: #8a73fb;
  top: -7px;
  left: 50%;
  transform: translateX(-50%);
  display: none;
}
section.filter-tools-wrapper .filter-tools .filter-tools-list .dropdown .dd ul.filter-list li:hover:after {
  display: inline-block;
}
section.filter-tools-wrapper .filter-tools .filter-tools-list .dropdown .dd ul.filter-list li a {
  color: #fff;
  font-family: 'GloberRegular';
  font-size: 14px;
}
section.filter-tools-wrapper .filter-tools .filter-tools-list .dropdown .dd ul.filter-list li a:hover {
  color: #8a73fb;
  font-family: 'GloberBlack';
}
section.filter-tools-wrapper .filter-tools .filter-tools-list .dropdown .dd ul.filter-list li p {
  padding: 0px;
  margin: 0px;
  text-transform: uppercase;
  font-family: 'GloberRegular';
  font-size: 14px;
}
section.filter-tools-wrapper .filter-tools .filter-tools-list .dropdown .dd ul.filter-list li p:hover {
  color: #8a73fb;
  font-family: 'GloberBlack';
}
section.filter-tools-wrapper .filter-tools .filter-tools-search {
  position: relative;
}
section.filter-tools-wrapper .filter-tools .filter-tools-search:after {
  content: '';
  position: absolute;
  height: 18px;
  width: 18px;
  border: 2px solid #8a73fb;
  border-radius: 18px;
  top: 9px;
  right: 20px;
}
section.filter-tools-wrapper .filter-tools .filter-tools-search:before {
  content: '';
  position: absolute;
  height: 6px;
  width: 13px;
  border: 2px solid #8a73fb;
  right: 9px;
  top: 15px;
}
section.filter-tools-wrapper .filter-tools .filter-tools-search input.filter-tools-search {
  width: 250px;
  height: 32px;
  border: 2px solid #8a73fb;
  border-radius: 100px;
  font-family: 'GloberRegular';
  font-size: 12px;
  padding-left: 24px;
  background: transparent;
  color: #fff;
}
section.filter-tools-wrapper .filter-tools .filter-tools-search input.filter-tools-search::placeholder {
  color: #fff;
}
@media (max-width: 1145px) {
  section.filter-tools-wrapper .filter-tools {
    justify-content: space-around;
  }
  section.filter-tools-wrapper .filter-tools .filter-tools-list {
    align-items: flex-start;
  }
  section.filter-tools-wrapper .filter-tools .filter-tools-list .dropdown {
    margin: 0px;
  }
  section.filter-tools-wrapper .filter-tools .filter-tools-list .dropdown .dt {
    color: #fff;
    text-transform: uppercase;
    font-family: 'GloberRegular';
    font-size: 14px;
    display: block;
    width: 130px;
    text-decoration: none;
    position: relative;
  }
  section.filter-tools-wrapper .filter-tools .filter-tools-list .dropdown .dt:after {
    content: '';
    z-index: 15;
    position: absolute;
    height: 8px;
    width: 2px;
    background: #fff;
    border-radius: 100px;
    top: 29%;
    right: 0px;
    transform: rotate(-45deg);
  }
  section.filter-tools-wrapper .filter-tools .filter-tools-list .dropdown .dt:before {
    content: '';
    z-index: 15;
    position: absolute;
    height: 8px;
    width: 2px;
    background: #fff;
    border-radius: 100px;
    right: -5px;
    bottom: 32%;
    transform: rotate(45deg);
  }
  section.filter-tools-wrapper .filter-tools .filter-tools-list .dropdown .dd ul.filter-list {
    flex-direction: column;
    align-items: left;
    list-style: none;
    background: #04232f;
    padding: 10px 20px 5px 0px;
    left: 0px;
    top: -5px;
    width: auto;
    min-width: 130px;
    height: 0px;
    transition: 0.2s;
  }
  section.filter-tools-wrapper .filter-tools .filter-tools-list .dropdown .dd ul.filter-list li {
    padding: 10px 0px;
    height: 100%;
    width: 100%;
    text-transform: uppercase;
    font-family: 'GloberRegular';
    font-size: 14px;
    position: relative;
    cursor: pointer;
    color: #fff;
    display: none;
  }
  section.filter-tools-wrapper .filter-tools .filter-tools-list .dropdown .dd ul.filter-list li:hover:after {
    display: none;
  }
  section.filter-tools-wrapper .filter-tools .filter-tools-list .dropdown .dd ul.filter-list li a {
    color: #fff;
  }
  section.filter-tools-wrapper .filter-tools .filter-tools-list .dropdown .dd ul.filter-list li p {
    padding: 0px;
    margin: 0px;
    text-transform: uppercase;
    font-family: 'GloberRegular';
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  section.filter-tools-wrapper .filter-tools {
    flex-direction: column-reverse;
    align-items: center;
  }
  section.filter-tools-wrapper .filter-tools form.filter-tools-search {
    padding-bottom: 25px;
  }
}
section.process-wrapper {
  width: 100%;
  overflow: hidden;
}
section.process-wrapper .process {
  max-width: 1140px;
  margin: 0px auto;
  display: flex;
}
section.process-wrapper .process .process-img-wrapper {
  width: 33.4%;
  position: relative;
}
section.process-wrapper .process .process-img-wrapper .process-img {
  border: none;
  position: absolute;
  right: 0px;
  height: 100%;
  width: 200%;
  background-size: contain !important;
  background-position: right !important;
  background-repeat: no-repeat !important;
}
section.process-wrapper .process .process-text-wrapper {
  width: 66.6%;
}
section.process-wrapper .process .process-text-wrapper .process-text {
  padding-left: 97px;
  display: flex;
  flex-direction: column;
}
section.process-wrapper .process .process-text-wrapper .process-text h2.process-title {
  font-family: 'GloberxBold';
}
section.process-wrapper .process .process-text-wrapper .process-text h2.process-title p {
  margin-bottom: 0px;
}
section.process-wrapper .process .process-text-wrapper .process-text .process-p {
  font-family: 'GloberRegular';
  padding-top: 45px;
  margin: 0px;
}
section.process-wrapper .process .process-text-wrapper .process-text .process-btn {
  padding: 40px 0 0 0;
}
section.process-wrapper .process .process-text-wrapper .process-text .process-btn .form-wrapper {
  display: flex;
}
section.process-wrapper .process .process-text-wrapper .process-text .process-btn .form-wrapper .button-element {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
section.process-wrapper .process .process-text-wrapper .process-text .process-btn .form-wrapper .button-element .button-violet-price {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'GloberSemibold';
  background-color: #8a73fb;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  -webkit-box-shadow: 0px 3px 0px 0px #0b171c;
  -moz-box-shadow: 0px 3px 0px 0px #0b171c;
  box-shadow: 0px 3px 0px 0px #0b171c;
}
section.process-wrapper .process .process-text-wrapper .process-text .process-btn .form-wrapper .button-element .button-violet-price p {
  text-align: center;
  width: 100%;
  height: auto;
  line-height: 2.5;
  padding: 0;
  margin: 0;
}
@media (max-width: 1280px) {
  section.process-wrapper .process {
    padding: 0px 20px;
  }
}
@media (max-width: 1024px) {
  section.process-wrapper .process {
    padding: 0px 20px;
    flex-direction: column;
  }
  section.process-wrapper .process .process-img-wrapper {
    width: 100%;
    height: 500px;
  }
  section.process-wrapper .process .process-img-wrapper .process-img {
    background-position: center !important;
    left: auto;
    position: relative;
    height: 100%;
    width: 100%;
  }
  section.process-wrapper .process .process-text-wrapper {
    width: 100%;
  }
  section.process-wrapper .process .process-text-wrapper .process-text {
    padding: 0px;
  }
}
@media (max-width: 768px) {
  section.process-wrapper .process .process-img-wrapper {
    height: 400px;
  }
  section.process-wrapper .process .proces-text-wrapper .proces-text h1.process-title {
    font-size: 36px;
    margin-bottom: 0px;
  }
  section.process-wrapper .process .proces-text-wrapper .proces-text h1.process-title p {
    margin-bottom: 0px;
  }
}
@media (max-width: 375px) {
  section.process-wrapper .process .process-img-wrapper {
    height: 310px;
  }
}
section.process-wrapper-right {
  width: 100%;
  overflow: hidden;
  height: 100%;
}
section.process-wrapper-right .process {
  max-width: 1140px;
  margin: 0px auto;
  display: flex;
  flex-direction: row-reverse;
}
section.process-wrapper-right .process .process-img-wrapper {
  width: 33.4%;
  position: relative;
  border: none;
}
section.process-wrapper-right .process .process-img-wrapper .process-img {
  position: absolute;
  border: none;
  left: 0px;
  height: 100%;
  width: 200%;
  background-size: contain !important;
  background-position: right !important;
  background-repeat: no-repeat !important;
}
section.process-wrapper-right .process .process-text-wrapper {
  width: 66.6%;
}
section.process-wrapper-right .process .process-text-wrapper .process-text {
  padding-right: 97px;
  display: flex;
  flex-direction: column;
}
section.process-wrapper-right .process .process-text-wrapper .process-text h2.process-title {
  font-family: 'GloberxBold';
}
section.process-wrapper-right .process .process-text-wrapper .process-text h2.process-title p {
  margin-bottom: 0px;
}
section.process-wrapper-right .process .process-text-wrapper .process-text .process-p {
  padding-top: 45px;
}
section.process-wrapper-right .process .process-text-wrapper .process-text .process-btn {
  padding: 40px 0 0 0;
}
section.process-wrapper-right .process .process-text-wrapper .process-text .process-btn .form-wrapper {
  display: flex;
}
section.process-wrapper-right .process .process-text-wrapper .process-text .process-btn .form-wrapper .button-element {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
section.process-wrapper-right .process .process-text-wrapper .process-text .process-btn .form-wrapper .button-element .button-violet-price {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'GloberSemibold';
  background-color: #8a73fb;
  cursor: pointer;
  text-align: center;
  font-size: 12px;
  -webkit-box-shadow: 0px 3px 0px 0px #0b171c;
  -moz-box-shadow: 0px 3px 0px 0px #0b171c;
  box-shadow: 0px 3px 0px 0px #0b171c;
}
section.process-wrapper-right .process .process-text-wrapper .process-text .process-btn .form-wrapper .button-element .button-violet-price p {
  text-align: center;
  width: 100%;
  height: auto;
  line-height: 2.5;
  padding: 0;
  margin: 0;
}
@media (max-width: 1280px) {
  section.process-wrapper-right .process {
    padding: 0px 20px;
  }
}
@media (max-width: 1024px) {
  section.process-wrapper-right .process {
    padding: 0px 20px;
    flex-direction: column;
  }
  section.process-wrapper-right .process .process-img-wrapper {
    border: none;
    width: 100%;
    height: 500px;
  }
  section.process-wrapper-right .process .process-img-wrapper .process-img {
    border: none;
    background-position: center !important;
    left: auto;
    position: relative;
    height: 100%;
    width: 100%;
  }
  section.process-wrapper-right .process .process-text-wrapper {
    width: 100%;
  }
  section.process-wrapper-right .process .process-text-wrapper .process-text {
    padding: 0px;
  }
}
@media (max-width: 768px) {
  section.process-wrapper-right .process .process-img-wrapper {
    border: none;
    height: 400px;
  }
}
@media (max-width: 375px) {
  section.process-wrapper-right .process .process-img-wrapper {
    height: 310px;
    border: none;
  }
}
section.table-of-tools-and-ads-wrapper {
  width: 100%;
  overflow: hidden;
  padding-bottom: 60px;
}
section.table-of-tools-and-ads-wrapper .table {
  display: grid;
  max-width: 1140px;
  margin: 0px auto;
  justify-content: center;
  grid-template-columns: repeat(4, 273px);
  grid-column-gap: 16px;
  grid-row-gap: 47px;
}
section.table-of-tools-and-ads-wrapper .table .table-item {
  height: 440px;
  background: #fff;
  border-radius: 20px;
}
section.table-of-tools-and-ads-wrapper .table .tool {
  width: 273px;
  padding: 25px 22px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
section.table-of-tools-and-ads-wrapper .table .tool:hover .tool-hover-wrap {
  visibility: visible;
}
section.table-of-tools-and-ads-wrapper .table .tool .tool-hover-wrap {
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(19,39,48,0.9);
  border: 3px solid #8a73fb;
  border-radius: 20px;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 18px;
}
section.table-of-tools-and-ads-wrapper .table .tool .tool-hover-wrap .tool-hover-circle {
  cursor: pointer;
  height: 116px;
  width: 116px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  box-shadow: 0px 10px 5px 0px rgba(20,20,20,0.55);
}
section.table-of-tools-and-ads-wrapper .table .tool .tool-hover-wrap .tool-hover-circle .box1-tablica {
  width: 116px;
  height: 58px;
  background: linear-gradient(97deg, #7565d6 0%, #4587cf 100%);
  border-top-left-radius: 95px;
  border-top-right-radius: 95px;
  position: absolute;
  top: 0px;
}
section.table-of-tools-and-ads-wrapper .table .tool .tool-hover-wrap .tool-hover-circle .box2-tablica {
  width: 116px;
  height: 58px;
  background: linear-gradient(90deg, #c35149 0%, #4587cf 100%);
  border-bottom-left-radius: 95px;
  border-bottom-right-radius: 95px;
  position: absolute;
  bottom: 0px;
}
section.table-of-tools-and-ads-wrapper .table .tool .tool-hover-wrap .tool-hover-circle .tool-hover-bg {
  width: 110px;
  height: 110px;
  border-radius: 100%;
  z-index: 2;
  background-color: #04232f;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
section.table-of-tools-and-ads-wrapper .table .tool .tool-hover-wrap .tool-hover-circle .tool-hover-bg:after {
  content: '';
  position: absolute;
  height: 3px;
  width: 40px;
  background: #8a73fb;
  top: 25px;
  left: 21px;
}
section.table-of-tools-and-ads-wrapper .table .tool .tool-hover-wrap .tool-hover-circle .tool-hover-bg:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 6px solid #8a73fb;
  top: 20px;
  left: 61px;
}
section.table-of-tools-and-ads-wrapper .table .tool .tool-hover-wrap .tool-hover-circle .tool-hover-bg p.tool-hover-text {
  color: #fff;
  font-family: 'GloberxBold';
  font-size: 16px;
  width: 70px;
  margin-bottom: -10px;
  line-height: 17px;
}
section.table-of-tools-and-ads-wrapper .table .tool .tool-hover-wrap .tool-hover-info {
  font-family: 'GloberRegular';
  font-size: 16px;
  margin: 0px;
  color: #fff;
}
section.table-of-tools-and-ads-wrapper .table .tool .tool-hover-wrap .tool-hover-info p {
  font-family: 'GloberRegular';
  font-size: 16px;
  margin: 0px;
  color: #fff;
}
section.table-of-tools-and-ads-wrapper .table .tool .tool-main-part .tool-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 45px;
}
section.table-of-tools-and-ads-wrapper .table .tool .tool-main-part .tool-header h4.tool-header-text {
  padding-top: 9px;
  margin: 0px;
  font-family: 'GloberxBold';
  font-size: 12px;
  text-transform: uppercase;
}
section.table-of-tools-and-ads-wrapper .table .tool .tool-main-part .tool-header h4.tool-header-text p {
  margin: 0px;
}
section.table-of-tools-and-ads-wrapper .table .tool .tool-main-part .tool-header .tool-header-img {
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
  height: 23px;
  width: 70px;
}
section.table-of-tools-and-ads-wrapper .table .tool .tool-main-part .tool-content {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
section.table-of-tools-and-ads-wrapper .table .tool .tool-main-part .tool-content h2.tool-content-text {
  font-family: 'GloberxBold';
  font-size: 30px;
  min-height: 98px;
  text-transform: uppercase;
  padding-bottom: 10px;
  margin: 0px;
  color: #8a73fb;
}
section.table-of-tools-and-ads-wrapper .table .tool .tool-main-part .tool-content h2.tool-content-text:first-line {
  color: #000;
}
section.table-of-tools-and-ads-wrapper .table .tool .tool-main-part .tool-content .tool-content-img {
  height: 143px;
  width: auto;
  object-fit: contain;
}
section.table-of-tools-and-ads-wrapper .table .tool ul.tool-footer {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  justify-content: baseline;
  margin-bottom: 0px;
  color: #04232f;
}
section.table-of-tools-and-ads-wrapper .table .tool ul.tool-footer li {
  font-family: 'GloberRegular';
  font-size: 12px;
  color: #04232f;
}
section.table-of-tools-and-ads-wrapper .table .tool ul.tool-footer li:nth-child(even) {
  color: #04232f;
  padding-left: 12px;
}
section.table-of-tools-and-ads-wrapper .table .ads-big {
  width: 563px;
  grid-column: span 2;
  display: flex;
  align-items: center;
}
section.table-of-tools-and-ads-wrapper .table .ads-big .ads-item1 {
  border-radius: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 420px;
  width: 543px;
  margin: 0px auto;
  display: flex !important;
  justify-content: flex-end;
}
section.table-of-tools-and-ads-wrapper .table .ads-big .ads-item1 .ads-item1-blend {
  height: 100%;
  width: 450px;
  background: rgba(138,115,251,0.4);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
section.table-of-tools-and-ads-wrapper .table .ads-big .ads-item1 .ads-item1-blend .ads-title-header {
  padding-top: 120px;
  padding-right: 40px;
}
section.table-of-tools-and-ads-wrapper .table .ads-big .ads-item1 .ads-item1-blend .ads-title-header p {
  font-family: 'GloberHeavy';
  font-size: 40px;
  line-height: 46px;
  text-transform: uppercase;
  text-align: right;
  margin-bottom: 0px;
}
section.table-of-tools-and-ads-wrapper .table .ads-big .ads-item1 .ads-item1-blend h4.ads-text {
  font-family: 'GloberSemibold';
  font-size: 16px;
  text-align: right;
  text-transform: uppercase;
  max-width: 270px;
  padding-right: 40px;
  padding-bottom: 20px;
  margin-bottom: 0px;
}
@media (max-width: 1245px) {
  section.table-of-tools-and-ads-wrapper .table {
    grid-template-columns: repeat(3, 273px);
  }
}
@media (max-width: 888px) {
  section.table-of-tools-and-ads-wrapper .table {
    grid-template-columns: repeat(2, 273px);
  }
}
@media (max-width: 600px) {
  section.table-of-tools-and-ads-wrapper .table {
    grid-template-columns: repeat(1, 335px);
    grid-row-gap: 15px;
  }
  section.table-of-tools-and-ads-wrapper .table .tool {
    width: 335px;
  }
  section.table-of-tools-and-ads-wrapper .table .ads-big {
    width: 335px !important;
    grid-column: span 1 !important;
  }
  section.table-of-tools-and-ads-wrapper .table .ads-big .ads-item1 {
    width: 315px !important;
  }
  section.table-of-tools-and-ads-wrapper .table .ads-big .ads-item1 .ads-item1-blend .ads-title-header {
    font-size: 30px;
    line-height: 40px;
  }
  section.table-of-tools-and-ads-wrapper .table .ads-big .ads-item1 .ads-item1-blend .ads-title-header p {
    font-size: 30px;
    line-height: 40px;
  }
}
@media (max-width: 375px) {
  section.table-of-tools-and-ads-wrapper .table .tool {
    padding: 22px 10px;
    width: 315px;
    justify-self: center;
  }
}
.menu-under-jumbotron-empathy.sticky {
  max-height: 85px;
}
@media (max-width: 769px) {
  .menu-under-jumbotron-empathy.sticky {
    position: static !important;
  }
}
.menu-under-jumbotron-empathy.sticky .menu-purple-element {
  max-height: 85px;
}
.menu-under-jumbotron-empathy {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  outline: none;
  padding: 0;
  margin: 0;
  background-color: #8a73fb;
  min-height: 136px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}
@media (max-width: 685px) {
  .menu-under-jumbotron-empathy {
    position: static;
  }
}
@media screen and (max-width: 375px) {
  .menu-under-jumbotron-empathy {
    min-height: 90px;
  }
}
.menu-under-jumbotron-empathy .menu-purple-element {
  display: flex;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  position: relative;
  outline: none;
}
@media (max-width: 1440px) {
  .menu-under-jumbotron-empathy .menu-purple-element {
    margin: 0 10%;
  }
}
@media screen and (max-width: 1024px) {
  .menu-under-jumbotron-empathy .menu-purple-element {
    margin: 0 9%;
  }
}
@media screen and (max-width: 768px) {
  .menu-under-jumbotron-empathy .menu-purple-element {
    margin: 0 3%;
  }
}
@media screen and (max-width: 375px) {
  .menu-under-jumbotron-empathy .menu-purple-element {
    margin: 0 9%;
  }
}
.menu-under-jumbotron-empathy .menu-purple-element .about-cours {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  color: #04232f;
  font-family: 'GloberHeavy';
  width: 100%;
}
@media (max-width: 685px) {
  .menu-under-jumbotron-empathy .menu-purple-element .about-cours {
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.menu-under-jumbotron-empathy .menu-purple-element .about-cours .term {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: flex-start;
  border: none;
  outline: none;
  margin: none;
}
@media (max-width: 900px) {
  .menu-under-jumbotron-empathy .menu-purple-element .about-cours .term {
    margin: 35px 0;
  }
}
@media screen and (max-width: 375px) {
  .menu-under-jumbotron-empathy .menu-purple-element .about-cours .term {
    display: none;
  }
}
.menu-under-jumbotron-empathy .menu-purple-element .about-cours .term .text {
  height: 100%;
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: flex-start;
  border: none;
  outline: none;
}
.menu-under-jumbotron-empathy .menu-purple-element .about-cours .term .text .project-faze {
  display: flex;
  flex-flow: column;
  justify-content: center;
  font-size: 13px;
  font-family: 'GloberRegular';
  color: #04232f;
  padding: 0 25px 0 0;
  min-height: 100%;
  margin: 0;
}
.menu-under-jumbotron-empathy .menu-purple-element .about-cours .term .text .project-faze p {
  padding: 0;
  margin: 0;
}
.menu-under-jumbotron-empathy .menu-purple-element .about-cours .term .text .project-faze :last-child {
  font-family: 'GloberHeavy';
  margin: 0;
}
.menu-under-jumbotron-empathy .menu-purple-element .about-cours .term .text .description {
  display: flex;
  width: 50%;
  font-size: 13px;
  font-family: 'GloberHeavy';
  text-transform: uppercase;
  color: #04232f;
  padding: 0;
  margin: 0 50px 0 0;
}
@media (max-width: 685px) {
  .menu-under-jumbotron-empathy .menu-purple-element .about-cours .term .text .description {
    margin: 0 50px 0 -20px;
  }
}
.menu-under-jumbotron-empathy .menu-purple-element .about-cours .term .text .description img {
  width: 100px;
  height: 35px;
  margin: 0;
}
.menu-under-jumbotron-empathy .menu-purple-element .about-cours .buttons .total-white {
  background-color: #fff;
  outline: none;
  overflow: hidden;
  height: 33px;
  display: flex;
  font-family: 'GloberxBold';
  flex-flow: row;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  text-transform: lowercase;
  font-size: 12px;
  color: #04232f;
  margin: 0;
  width: 180px;
  padding: 0;
  border: none;
  -webkit-box-shadow: 0px 3px 0px 0px #7461d3;
  -moz-box-shadow: 0px 3px 0px 0px #7461d3;
  box-shadow: 0px 3px 0px 0px #7461d3;
}
@media screen and (max-width: 375px) {
  .menu-under-jumbotron-empathy .menu-purple-element .about-cours .buttons .total-white {
    display: none;
  }
}
.menu-under-jumbotron-empathy .menu-purple-element .about-cours .buttons .total-white .button-elements {
  width: 100%;
  height: 100%;
  display: block;
  text-align: center;
  display: flex;
  flex-flow: row;
  border: none;
  color: #04232f;
  justify-content: center;
  align-items: center;
}
.menu-under-jumbotron-empathy .menu-purple-element .about-cours .buttons .total-white .button-elements .svg {
  margin: 0 10px 0 0;
  display: flex;
  width: 10px;
  height: 10px;
}
.menu-under-jumbotron-empathy .menu-purple-element .about-cours .buttons .total-white .button-elements p {
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: center;
  align-items: center;
  font-family: 'GloberxBold';
  height: 100%;
  color: #04232f !important;
}
.menu-under-jumbotron-empathy .menu-purple-element .about-cours .share {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 685px) {
  .menu-under-jumbotron-empathy .menu-purple-element .about-cours .share {
    justify-content: flex-start;
    margin: 35px 0;
  }
}
@media screen and (max-width: 375px) {
  .menu-under-jumbotron-empathy .menu-purple-element .about-cours .share {
    margin: 0;
  }
}
.menu-under-jumbotron-empathy .menu-purple-element .about-cours .share .text {
  font-size: 13px;
  font-family: 'GloberRegular';
  margin: 0 10px 0 0;
}
.menu-under-jumbotron-empathy .menu-purple-element .about-cours .share ul {
  list-style-type: none;
  display: flex;
  flex-flow: row;
  padding: 0;
  margin: 0;
}
.menu-under-jumbotron-empathy .menu-purple-element .about-cours .share ul li {
  padding: 0;
  margin: 0;
}
.menu-under-jumbotron-empathy .menu-purple-element .about-cours .share ul li a {
  padding: 0;
  margin: 0 10px 0 0;
  color: #fff;
}
.menu-under-jumbotron-empathy .menu-purple-element .about-cours .share ul li a .svg {
  width: 33px;
  height: 32px;
  fill: #fff;
}
.menu-under-jumbotron-empathy .menu-purple-element .about-cours .share ul li a .svg,
.menu-under-jumbotron-empathy .menu-purple-element .about-cours .share ul li a .svg * {
  fill: #fff !important;
}
.menu-under-jumbotron-empathy .menu-purple-element .about-cours .share ul li:hover .svg,
.menu-under-jumbotron-empathy .menu-purple-element .about-cours .share ul li:hover .svg * {
  fill: #04232f !important;
}
.jumbotron-tools {
  display: flex;
  border-top: 2px solid #e8e8e8;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  background-color: #fff;
  width: 100%;
  padding: 0;
}
.jumbotron-tools .container {
  max-width: 1400px;
  min-height: 500px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-rows: fit-content(100px) fit-content(100px) fit-content(100px);
  min-height: 0;
  min-width: 0;
  grid-column-gap: 97px;
  display: grid;
  flex-wrap: wrap;
  margin: 0 auto;
  padding: 0;
  grid-template-areas: "auto arrow" "pictogram-baner header" "pictogram-baner subtitle";
}
@media (max-width: 1440px) {
  .jumbotron-tools .container {
    margin: 0 10%;
  }
}
@media (max-width: 846px) {
  .jumbotron-tools .container {
    grid-column-gap: 37px;
  }
}
@media screen and (max-width: 768px) {
  .jumbotron-tools .container {
    margin: 0 3%;
  }
}
@media (max-width: 649px) {
  .jumbotron-tools .container {
    grid-column-gap: 0;
    margin: 0 6%;
    grid-template-columns: 1fr;
    grid-template-rows: fit-content(100px) fit-content(100px) fit-content(100px) fit-content(100px);
    grid-template-areas: "arrow" "pictogram-baner" "header" "subtitle";
  }
}
@media (max-width: 375px) {
  .jumbotron-tools .container {
    min-height: 400px;
    margin: 30px 6%;
  }
}
.jumbotron-tools .container .pictogram-baner {
  grid-area: pictogram-baner;
  justify-items: start;
  width: 100%;
  margin: 0;
}
@media (max-width: 649px) {
  .jumbotron-tools .container .pictogram-baner {
    width: 50%;
    margin: 0 0 15px 0;
  }
}
.jumbotron-tools .container .pictogram-baner img {
  width: 100%;
  height: 100%;
}
.jumbotron-tools .container .arrow {
  grid-area: arrow;
}
.jumbotron-tools .container .arrow a {
  display: flex;
  flex-flow: column;
  text-decoration: none;
  color: #04232f;
  font-family: 'GloberRegular';
}
.jumbotron-tools .container .arrow a img {
  width: 48px;
  height: 15px;
}
.jumbotron-tools .container .arrow a p {
  font-size: 16px;
  font-family: 'GloberRegular';
}
.jumbotron-tools .container .header {
  grid-area: header;
  font-size: 72px;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  font-family: 'GloberHeavy';
}
@media screen and (max-width: 1024px) {
  .jumbotron-tools .container .header {
    font-size: 48px;
  }
}
@media screen and (max-width: 768px) {
  .jumbotron-tools .container .header {
    font-size: 36px;
  }
}
.jumbotron-tools .container .header ::before {
  content: '';
  display: block;
  margin: 0 0 20px 0;
  width: 104px;
  height: 7px;
  background-color: #04232f;
}
.jumbotron-tools .container .header p {
  padding: 0;
  font-family: 'GloberHeavy' !important;
  margin: 0;
  color: #04232f;
}
@media (max-width: 1440px) {
  .jumbotron-tools .container .header p {
    font-size: 72px;
  }
}
@media (max-width: 1038px) {
  .jumbotron-tools .container .header p {
    font-size: 48px;
  }
}
@media screen and (max-width: 375px) {
  .jumbotron-tools .container .header p {
    font-size: 36px;
  }
}
.jumbotron-tools .container .subtitle {
  grid-area: subtitle;
  padding: 26px 0 0 0 !important;
  width: 100%;
  position: relative;
  font-size: 18px !important;
}
@media screen and (max-width: 768px) {
  .jumbotron-tools .container .subtitle {
    font-size: 14px !important;
  }
}
.jumbotron-tools .container .subtitle p {
  font-size: calc(14px + (24 - 14) * ((100vw - 375px) / (1440 - 375)));
  font-family: 'GloberRegular' !important;
  color: #04232f;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 375px) {
  .jumbotron-tools .container .subtitle p {
    font-size: 14px;
  }
}
.choos-download-tools {
  width: 100%;
  margin: 140px 0 200px 0;
}
@media screen and (max-width: 768px) {
  .choos-download-tools {
    margin: 100px 0 100px 0;
  }
}
@media screen and (max-width: 375px) {
  .choos-download-tools {
    margin: 50px 0;
  }
}
.choos-download-tools .tool-graphic {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-flow: row;
  border: 1px solid transparent;
  border-radius: 20px;
}
@media (max-width: 1440px) {
  .choos-download-tools .tool-graphic {
    margin: 0 10%;
  }
}
@media screen and (max-width: 1024px) {
  .choos-download-tools .tool-graphic {
    margin: 0 10%;
  }
}
@media screen and (max-width: 768px) {
  .choos-download-tools .tool-graphic {
    margin: 0 3%;
  }
}
@media screen and (max-width: 375px) {
  .choos-download-tools .tool-graphic {
    margin: 0 6%;
  }
}
.choos-download-tools .tool-graphic img {
  height: 100%;
  width: 100%;
  border-radius: 20px;
}
.choos-download-tools .tools-element {
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  height: auto;
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  justify-content: space-between;
}
@media (max-width: 1440px) {
  .choos-download-tools .tools-element {
    padding: 0 10%;
  }
}
@media screen and (max-width: 1024px) {
  .choos-download-tools .tools-element {
    padding: 0 9%;
  }
}
@media screen and (max-width: 768px) {
  .choos-download-tools .tools-element {
    padding: 0 4%;
  }
}
@media (max-width: 578px) {
  .choos-download-tools .tools-element {
    margin: 0;
    flex-flow: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 375px) {
  .choos-download-tools .tools-element {
    padding: 0 6%;
  }
}
.choos-download-tools .tools-element .form-download-elements {
  display: flex;
  flex-flow: column;
  width: 100%;
  min-height: 100%;
}
.choos-download-tools .tools-element .form-download-elements h3 {
  padding: 60px 0;
  margin: 0;
  font-size: 24px;
  font-family: 'GloberxBold';
}
@media (max-width: 633px) {
  .choos-download-tools .tools-element .form-download-elements h3 {
    padding: 45px 0;
  }
}
@media (max-width: 578px) {
  .choos-download-tools .tools-element .form-download-elements h3 {
    padding: 20px 0;
  }
}
.choos-download-tools .tools-element .form-download-elements .download-options {
  width: 100%;
  display: flex;
  flex-flow: column;
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements {
  width: 100%;
  align-items: space-between;
  justify-content: space-between;
  margin: 0;
  display: flex;
  flex-flow: row;
}
@media (max-width: 1141px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements {
    margin: 30px 0 0 0;
  }
}
@media screen and (max-width: 1024px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements {
    margin: 0;
    flex-flow: column;
  }
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format {
  margin: 0 25px 0 0;
}
@media (max-width: 578px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format {
    margin: 0;
  }
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format label {
  display: flex;
  font-size: 14px;
  font-family: 'GloberRegular';
  text-transform: uppercase;
  margin: 0 0 25px 0;
}
@media screen and (max-width: 1024px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format label {
    margin: 20px 0;
  }
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format .select-format {
  display: flex;
  width: 100%;
  position: relative;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
@media (max-width: 578px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format .select-format {
    width: 100%;
  }
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format .select-format .button-list-wrapper {
  background-color: #fff;
  border-radius: 20px;
  width: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format .select-format .button-list-wrapper {
    width: 182px;
  }
}
@media (max-width: 578px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format .select-format .button-list-wrapper {
    width: 100%;
    min-height: 43px;
  }
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format .select-format .button-list-wrapper .main-button {
  padding: 0;
  position: relative;
  margin: 0;
  font-size: 12px;
  height: 32px;
  line-height: 3;
  width: 149px;
  border-radius: 20px;
  background-color: #fff;
  color: #04232f;
  font-family: 'GloberxBold';
  text-transform: uppercase;
  padding: 0 0 0 30px;
}
@media screen and (max-width: 1024px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format .select-format .button-list-wrapper .main-button {
    width: 182px;
  }
}
@media (max-width: 578px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format .select-format .button-list-wrapper .main-button {
    width: 100%;
    height: 43px;
    padding: 5px 0 0 30px;
  }
}
@media screen and (max-width: 375px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format .select-format .button-list-wrapper .main-button {
    width: 100%;
  }
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format .select-format .button-list-wrapper .main-button::after {
  content: " \f107";
  font-family: 'Font Awesome 5 Free';
  position: absolute;
  top: 0px;
  right: 20px;
  font-weight: 900;
  color: #8a73fb;
}
@media (max-width: 578px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format .select-format .button-list-wrapper .main-button::after {
    top: 5px;
  }
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format .select-format .button-list-wrapper ul {
  list-style-type: none;
  background-color: #fff;
  height: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  visibility: hidden;
}
@media screen and (max-width: 1024px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format .select-format .button-list-wrapper ul {
    width: 182px;
  }
}
@media (max-width: 578px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format .select-format .button-list-wrapper ul {
    width: 100%;
  }
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format .select-format .button-list-wrapper ul.visible {
  height: 100%;
  visibility: visible;
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format .select-format .button-list-wrapper ul.visible .select-language {
  background-color: #fff;
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format .select-format .button-list-wrapper ul:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format .select-format .button-list-wrapper ul li {
  cursor: pointer;
  line-height: 2.5;
  background-color: #fff;
  border: none;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-format .select-format .button-list-wrapper ul li span {
  display: block;
  color: #04232f;
  font-family: 'GloberxBold';
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px 0 0 30px;
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language {
  margin: 0 25px 0 0;
}
@media (max-width: 578px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language {
    margin: 0;
  }
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language label {
  display: flex;
  font-size: 14px;
  font-family: 'GloberRegular';
  text-transform: uppercase;
  margin: 0 0 25px 0;
}
@media screen and (max-width: 1024px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language label {
    margin: 20px 0;
  }
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language .select-language {
  display: flex;
  width: 100%;
  position: relative;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
@media (max-width: 578px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language .select-language {
    width: 100%;
  }
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language .select-language .button-list-wrapper {
  background-color: #fff;
  border-radius: 20px;
  width: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  -webkit-box-shadow: 0px 3px 0px 0px #0b171c;
  -moz-box-shadow: 0px 3px 0px 0px #0b171c;
  box-shadow: 0px 3px 0px 0px #0b171c;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language .select-language .button-list-wrapper {
    width: 182px;
  }
}
@media (max-width: 578px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language .select-language .button-list-wrapper {
    width: 100%;
    min-height: 43px;
  }
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language .select-language .button-list-wrapper .main-button {
  padding: 0;
  position: relative;
  margin: 0;
  font-size: 12px;
  height: 32px;
  line-height: 3;
  width: 149px;
  border-radius: 20px;
  background-color: #fff;
  color: #04232f;
  font-family: 'GloberxBold';
  text-transform: uppercase;
  padding: 0 0 0 30px;
}
@media screen and (max-width: 1024px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language .select-language .button-list-wrapper .main-button {
    width: 182px;
  }
}
@media (max-width: 578px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language .select-language .button-list-wrapper .main-button {
    width: 100%;
    height: 43px;
    padding: 5px 0 0 30px;
  }
}
@media screen and (max-width: 375px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language .select-language .button-list-wrapper .main-button {
    width: 100%;
  }
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language .select-language .button-list-wrapper .main-button::after {
  content: " \f107";
  font-family: 'Font Awesome 5 Free';
  position: absolute;
  top: 0px;
  right: 20px;
  font-weight: 900;
  color: #8a73fb;
}
@media (max-width: 578px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language .select-language .button-list-wrapper .main-button::after {
    top: 5px;
  }
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language .select-language .button-list-wrapper ul {
  list-style-type: none;
  background-color: #fff;
  height: 0;
  margin: 0;
  position: absolute;
  width: 100%;
  visibility: hidden;
}
@media screen and (max-width: 1024px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language .select-language .button-list-wrapper ul {
    width: 182px;
  }
}
@media (max-width: 578px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language .select-language .button-list-wrapper ul {
    width: 100%;
  }
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language .select-language .button-list-wrapper ul.visible {
  height: 100%;
  visibility: visible;
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language .select-language .button-list-wrapper ul.visible .select-language {
  background-color: #fff;
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language .select-language .button-list-wrapper ul:last-child {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language .select-language .button-list-wrapper ul li {
  cursor: pointer;
  line-height: 2.5;
  background-color: #fff;
  border: none;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .element-language .select-language .button-list-wrapper ul li span {
  display: block;
  color: #04232f;
  font-family: 'GloberxBold';
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px 0 0 30px;
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .button-tools {
  display: flex;
  flex-flow: column;
}
@media screen and (max-width: 1024px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .button-tools {
    margin: 0;
  }
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .button-tools label {
  font-size: 14px;
  font-family: 'GloberRegular';
  text-transform: uppercase;
  margin: 0 0 25px 0;
}
@media screen and (max-width: 1024px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .button-tools label {
    margin: 20px 0;
  }
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .button-tools .button-violet-price {
  color: #04232f;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 182px;
  height: 34px;
  background-color: #8a73fb;
}
@media (max-width: 578px) {
  .choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .button-tools .button-violet-price {
    width: 100%;
    height: 44px;
  }
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .button-tools .button-violet-price .button-elements {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  text-decoration: none;
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .button-tools .button-violet-price a {
  color: #fff;
  display: block;
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .button-tools .button-violet-price #download-link {
  width: 100%;
  text-align: center;
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .button-tools .button-violet-price #download-link .svg {
  width: 10px;
  height: 10px;
  margin: 0 17px 0 0;
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .button-tools .button-violet-price #download-link .svg,
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .button-tools .button-violet-price #download-link .svg * {
  fill: #fff;
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .button-tools .button-violet-price.disabled {
  pointer-events: none;
  cursor: not-allowed;
}
.choos-download-tools .tools-element .form-download-elements .download-options .choose-elements .button-tools .button-violet-price.disabled a {
  color: #04232f;
}
.choos-download-tools .tools-element .process-level {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-family: 'GloberRegular';
}
@media screen and (max-width: 1024px) {
  .choos-download-tools .tools-element .process-level {
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 578px) {
  .choos-download-tools .tools-element .process-level {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.choos-download-tools .tools-element .process-level h3 {
  padding: 60px 0;
  margin: 0;
  font-size: 24px;
  font-family: 'GloberxBold';
}
@media (max-width: 578px) {
  .choos-download-tools .tools-element .process-level h3 {
    padding: 40px 0 0 0;
  }
}
.choos-download-tools .tools-element .process-level .level-element {
  display: flex;
  flex-flow: column;
  margin: 0px 0 20px 0;
}
@media (max-width: 1141px) {
  .choos-download-tools .tools-element .process-level .level-element {
    margin: 18px 0 30px 0;
  }
}
@media (max-width: 1078px) {
  .choos-download-tools .tools-element .process-level .level-element {
    margin: 0px 0 21px 0;
  }
}
@media screen and (max-width: 1024px) {
  .choos-download-tools .tools-element .process-level .level-element {
    margin: 18px 0 21px 0;
  }
}
.choos-download-tools .tools-element .process-level .level-element p {
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}
.choos-download-tools .tools-element .process-level .description {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 18px 0 0 0;
}
@media screen and (max-width: 1024px) {
  .choos-download-tools .tools-element .process-level .description {
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 578px) {
  .choos-download-tools .tools-element .process-level .description {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
@media screen and (max-width: 375px) {
  .choos-download-tools .tools-element .process-level .description {
    padding: 0;
  }
}
.choos-download-tools .tools-element .process-level .description img {
  display: flex;
  height: 40px;
}
.choos-download-tools .tools-element .how-to-use1 {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
}
@media (max-width: 578px) {
  .choos-download-tools .tools-element .how-to-use1 {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.choos-download-tools .tools-element .how-to-use1 h3 {
  padding: 60px 0;
  text-align: right;
  margin: 0;
  font-size: 24px;
  font-family: 'GloberxBold';
}
@media (max-width: 578px) {
  .choos-download-tools .tools-element .how-to-use1 h3 {
    padding: 40px 0;
    text-align: left;
  }
}
.choos-download-tools .tools-element .how-to-use1 .download-pdf-wrapper-btn {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  align-items: flex-end;
  color: #04232f;
  width: 100%;
}
@media (max-width: 578px) {
  .choos-download-tools .tools-element .how-to-use1 .download-pdf-wrapper-btn {
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.choos-download-tools .tools-element .how-to-use1 .download-pdf-wrapper-btn .button-violet-price {
  width: 155px;
  height: 33px;
  color: #04232f;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #8a73fb;
  margin: 45px 0 0 0;
  -webkit-box-shadow: 0px 3px 0px 0px #0b171c;
  -moz-box-shadow: 0px 3px 0px 0px #0b171c;
  box-shadow: 0px 3px 0px 0px #0b171c;
}
@media (max-width: 1304px) {
  .choos-download-tools .tools-element .how-to-use1 .download-pdf-wrapper-btn .button-violet-price {
    margin: 20px 0 0 0;
  }
}
@media (max-width: 1141px) {
  .choos-download-tools .tools-element .how-to-use1 .download-pdf-wrapper-btn .button-violet-price {
    margin: 48px 0 0 0;
  }
}
@media (max-width: 1024px) {
  .choos-download-tools .tools-element .how-to-use1 .download-pdf-wrapper-btn .button-violet-price {
    margin: 65px 0 0 0;
  }
}
@media (max-width: 872px) {
  .choos-download-tools .tools-element .how-to-use1 .download-pdf-wrapper-btn .button-violet-price {
    margin: 32px 0 0 0;
  }
}
@media (max-width: 578px) {
  .choos-download-tools .tools-element .how-to-use1 .download-pdf-wrapper-btn .button-violet-price {
    margin: 0;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
@media screen and (max-width: 375px) {
  .choos-download-tools .tools-element .how-to-use1 .download-pdf-wrapper-btn .button-violet-price {
    width: 100%;
    height: 44px;
    margin: 0;
  }
}
.choos-download-tools .tools-element .how-to-use1 .download-pdf-wrapper-btn .button-violet-price .button-elements {
  width: 100%;
  align: color-interpolation-filters;
  color: #04232f;
  text-align: center;
}
.white-button-shadow {
  z-index: 2;
  -webkit-box-shadow: 0px 5px 0px 0px #f2f2f2;
  -moz-box-shadow: 0px 5px 0px 0px #f2f2f2;
  box-shadow: 0px 5px 0px 0px #f2f2f2;
}
.blue-button-shadow {
  -webkit-box-shadow: 0px 3px 0px 0px #0b171c;
  -moz-box-shadow: 0px 3px 0px 0px #0b171c;
  box-shadow: 0px 3px 0px 0px #0b171c;
}
.background-white {
  background-color: #fff;
}
.background-transparent {
  background-color: transparent;
}
.get-know-the-method {
  width: 100%;
  background-color: #fff;
  overflow: hidden;
}
.get-know-the-method .knowledge-element {
  max-width: 1440px;
  margin: 0 auto;
  height: 100%;
  position: relative;
}
.get-know-the-method .knowledge-element:hover .circle-wrapper {
  display: flex;
}
.get-know-the-method .knowledge-element img {
  width: 100%;
  height: 100%;
}
.get-know-the-method .knowledge-element .circle-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  display: none;
}
@media screen and (max-width: 375px) {
  .get-know-the-method .knowledge-element .circle-wrapper {
    display: block;
  }
}
.get-know-the-method .knowledge-element .circle-wrapper .method-coord-img-outline {
  width: 190px;
  height: 190px;
  box-shadow: none;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.get-know-the-method .knowledge-element .circle-wrapper .method-coord-img-outline .box-knowledge {
  width: 190px;
  height: 96px;
  background: linear-gradient(97deg, #7565d6 0%, #4587cf 100%);
  border-top-left-radius: 95px;
  border-top-right-radius: 95px;
  position: absolute;
  top: 0px;
}
@media screen and (max-width: 768px) {
  .get-know-the-method .knowledge-element .circle-wrapper .method-coord-img-outline .box-knowledge {
    width: 152px;
    height: 76.8px;
    top: 19px;
  }
}
@media (max-width: 387px) {
  .get-know-the-method .knowledge-element .circle-wrapper .method-coord-img-outline .box-knowledge {
    width: 121.6px;
    height: 61.44px;
    top: 34px;
  }
}
@media (max-width: 378px) {
  .get-know-the-method .knowledge-element .circle-wrapper .method-coord-img-outline .box-knowledge {
    top: 35px;
  }
}
.get-know-the-method .knowledge-element .circle-wrapper .method-coord-img-outline .box-knowledge2 {
  width: 190px;
  height: 95px;
  background: linear-gradient(90deg, #c35149 0%, #4587cf 100%);
  border-bottom-left-radius: 95px;
  border-bottom-right-radius: 95px;
  position: absolute;
  bottom: 0px;
}
@media screen and (max-width: 768px) {
  .get-know-the-method .knowledge-element .circle-wrapper .method-coord-img-outline .box-knowledge2 {
    width: 152px;
    height: 76.8px;
    bottom: 19px;
  }
}
@media (max-width: 387px) {
  .get-know-the-method .knowledge-element .circle-wrapper .method-coord-img-outline .box-knowledge2 {
    width: 121.6px;
    height: 61.44px;
    bottom: 34px;
  }
}
@media (max-width: 378px) {
  .get-know-the-method .knowledge-element .circle-wrapper .method-coord-img-outline .box-knowledge2 {
    bottom: 35px;
  }
}
.get-know-the-method .knowledge-element .circle-wrapper .method-coord-img-outline .method-coord-img {
  width: 180px;
  height: 180px;
  border-radius: 100%;
  z-index: 2;
  background-color: #04232f;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
@media screen and (max-width: 768px) {
  .get-know-the-method .knowledge-element .circle-wrapper .method-coord-img-outline .method-coord-img {
    width: 144px;
    height: 144px;
  }
}
@media (max-width: 387px) {
  .get-know-the-method .knowledge-element .circle-wrapper .method-coord-img-outline .method-coord-img {
    width: 115.2px;
    height: 115.2px;
  }
}
.get-know-the-method .knowledge-element .circle-wrapper .method-coord-img-outline .method-coord-img .arrow {
  position: absolute;
  top: 40px;
  left: 75px;
}
@media screen and (max-width: 768px) {
  .get-know-the-method .knowledge-element .circle-wrapper .method-coord-img-outline .method-coord-img .arrow {
    top: 30px;
    left: 55px;
  }
}
@media (max-width: 387px) {
  .get-know-the-method .knowledge-element .circle-wrapper .method-coord-img-outline .method-coord-img .arrow {
    top: 19px;
    left: 40px;
  }
}
.get-know-the-method .knowledge-element .circle-wrapper .method-coord-img-outline .method-coord-img .arrow img {
  width: 82px;
}
@media screen and (max-width: 768px) {
  .get-know-the-method .knowledge-element .circle-wrapper .method-coord-img-outline .method-coord-img .arrow img {
    width: 69px;
  }
}
@media (max-width: 387px) {
  .get-know-the-method .knowledge-element .circle-wrapper .method-coord-img-outline .method-coord-img .arrow img {
    width: 61px;
    top: 40px;
    left: 40px;
  }
}
.get-know-the-method .knowledge-element .circle-wrapper .method-coord-img-outline .method-coord-img .text {
  position: absolute;
  top: 70px;
  left: 40px;
  font-size: 28px;
  font-family: 'GloberxBold';
}
@media screen and (max-width: 768px) {
  .get-know-the-method .knowledge-element .circle-wrapper .method-coord-img-outline .method-coord-img .text {
    font-size: 24px;
    top: 60px;
    left: 32px;
  }
}
@media (max-width: 387px) {
  .get-know-the-method .knowledge-element .circle-wrapper .method-coord-img-outline .method-coord-img .text {
    font-size: 18px;
    top: 50px;
    left: 26px;
  }
}
#blenda-exploration {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 1;
}
#blenda-exploration #blenda-exploration-box {
  background-color: rgba(255,255,255,0.8);
  width: 79%;
  height: 100%;
}
#blenda-prowide {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 1;
}
#blenda-prowide #blenda-prowide-box {
  background-color: rgba(255,255,255,0.8);
  width: 64.3%;
  height: 100%;
}
#blenda-define {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
#blenda-define #blenda-define-box1 {
  width: 100%;
  height: 100%;
  position: relative;
}
#blenda-define #blenda-define-box1 .box1 {
  background-color: rgba(255,255,255,0.8);
  width: 21%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
#blenda-define #blenda-define-box1 .box2 {
  background-color: rgba(255,255,255,0.8);
  width: 64.5%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
#blenda-generate {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
#blenda-generate #blenda-generate:hover .circle-wrapper {
  display: block;
}
#blenda-generate #blenda-generate-box1 {
  width: 100%;
  height: 100%;
  position: relative;
}
#blenda-generate #blenda-generate-box1 .box1 {
  background-color: rgba(255,255,255,0.8);
  width: 35.4%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
#blenda-generate #blenda-generate-box1 .box2 {
  background-color: rgba(255,255,255,0.8);
  width: 35.7%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.how-to-use {
  height: 100%;
  width: 100%;
  padding: 0 0 100px 0;
  margin: 0;
  background-image: linear-gradient(to bottom, #fff 55%, #8a73fb 45%);
}
@media screen and (max-width: 375px) {
  .how-to-use {
    padding: 0;
  }
}
.how-to-use .general-wrapper {
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
  height: 100%;
}
@media (max-width: 1440px) {
  .how-to-use .general-wrapper {
    margin: 0 10%;
  }
}
@media screen and (max-width: 1024px) {
  .how-to-use .general-wrapper {
    margin: 0;
  }
}
@media screen and (max-width: 768px) {
  .how-to-use .general-wrapper {
    margin: 0 3%;
  }
}
@media screen and (max-width: 375px) {
  .how-to-use .general-wrapper {
    margin: 0 6%;
  }
}
.how-to-use .general-wrapper .container {
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 50px;
  display: flex;
  display: grid;
  flex-wrap: wrap;
  background-size: contain;
  background-position: center;
  margin: 50px auto 0 auto;
  padding: 0;
  grid-template-areas: "kard-one kard-big kard-two" "kard-three kard-big kard-four";
  max-width: 1400px;
}
@media screen and (max-width: 1024px) {
  .how-to-use .general-wrapper .container {
    padding: 0 3%;
    grid-column-gap: 15px;
    grid-template-areas: "kard-big kard-one kard-two" "kard-big kard-three kard-four";
  }
}
@media screen and (max-width: 768px) {
  .how-to-use .general-wrapper .container {
    padding: 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-row-gap: 20px;
    grid-template-areas: "kard-big kard-one" "kard-big kard-two" "kard-three kard-four";
  }
}
@media (max-width: 500px) {
  .how-to-use .general-wrapper .container {
    grid-template-columns: 1fr;
    grid-template-rows: 350px;
    grid-row-gap: 20px;
    grid-template-areas: "kard-one" "kard-two" "kard-big" "kard-three" "kard-four";
  }
}
.how-to-use .general-wrapper .container .kard-one {
  grid-area: kard-one;
  flex-flow: column;
  display: flex;
}
.how-to-use .general-wrapper .container .kard-one h1 {
  min-height: 84px;
  font-family: 'GloberxBold';
  color: #04232f;
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 1024px) {
  .how-to-use .general-wrapper .container .kard-one h1 {
    min-height: 83px;
  }
}
@media (max-width: 502px) {
  .how-to-use .general-wrapper .container .kard-one h1 {
    min-height: auto;
    padding: 10px 0;
    width: 70%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 375px) {
  .how-to-use .general-wrapper .container .kard-one h1 {
    width: 100%;
  }
}
.how-to-use .general-wrapper .container .kard-one .wrapper {
  height: 271px;
  width: 100%;
  position: relative;
  -webkit-box-shadow: 0px 13px 0px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 13px 0px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 13px 0px 0px rgba(0,0,0,0.2);
}
@media (max-width: 500px) {
  .how-to-use .general-wrapper .container .kard-one .wrapper {
    width: 70%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 375px) {
  .how-to-use .general-wrapper .container .kard-one .wrapper {
    width: 100%;
    margin: 0 auto;
  }
}
.how-to-use .general-wrapper .container .kard-one .wrapper .cube1 {
  background: #8a73fb;
  background: linear-gradient(68deg, #8a73fb 0%, #2cbad8 63%);
  position: absolute;
  top: 0px;
  height: 50%;
  width: 100%;
}
.how-to-use .general-wrapper .container .kard-one .wrapper .cube2 {
  background: #eb594d;
  background: linear-gradient(68deg, #eb594d 0%, #2cbad8 100%);
  position: absolute;
  bottom: 0px;
  height: 50%;
  width: 100%;
}
.how-to-use .general-wrapper .container .kard-one .wrapper .box-for-image {
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 3;
  height: 260px;
  width: 99%;
}
@media screen and (max-width: 1024px) {
  .how-to-use .general-wrapper .container .kard-one .wrapper .box-for-image {
    width: 98.5%;
  }
}
.how-to-use .general-wrapper .container .kard-one .wrapper .box-for-image .image {
  height: 266px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  margin: 0;
  border-image: linear-gradient(to right, #b861c6 0%, #ffc7c7 100%);
  border-image-slice: 1;
  border-width: 3px;
}
.how-to-use .general-wrapper .container .kard-two {
  flex-flow: column;
  display: flex;
  grid-area: kard-two;
  align-items: flex-end;
}
.how-to-use .general-wrapper .container .kard-two h1 {
  width: 100%;
  text-align: left;
  min-height: 84px;
  font-family: 'GloberxBold';
  color: #04232f;
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 768px) {
  .how-to-use .general-wrapper .container .kard-two h1 {
    margin: 13px 0 0 0;
  }
}
@media (max-width: 502px) {
  .how-to-use .general-wrapper .container .kard-two h1 {
    min-height: auto;
    padding: 10px 0;
    width: 70%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 375px) {
  .how-to-use .general-wrapper .container .kard-two h1 {
    width: 100%;
  }
}
.how-to-use .general-wrapper .container .kard-two .wrapper {
  height: 271px;
  width: 100%;
  position: relative;
  -webkit-box-shadow: 0px 13px 0px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 13px 0px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 13px 0px 0px rgba(0,0,0,0.2);
}
@media (max-width: 500px) {
  .how-to-use .general-wrapper .container .kard-two .wrapper {
    width: 70%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 375px) {
  .how-to-use .general-wrapper .container .kard-two .wrapper {
    width: 100%;
    margin: 0 auto;
  }
}
.how-to-use .general-wrapper .container .kard-two .wrapper .cube1 {
  background: #8a73fb;
  background: linear-gradient(68deg, #8a73fb 0%, #2cbad8 63%);
  position: absolute;
  top: 0px;
  height: 50%;
  width: 100%;
}
.how-to-use .general-wrapper .container .kard-two .wrapper .cube2 {
  background: #eb594d;
  background: linear-gradient(68deg, #eb594d 0%, #2cbad8 100%);
  position: absolute;
  bottom: 0px;
  height: 50%;
  width: 100%;
}
.how-to-use .general-wrapper .container .kard-two .wrapper .box-for-image {
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 3;
  height: 260px;
  width: 99%;
}
@media screen and (max-width: 1024px) {
  .how-to-use .general-wrapper .container .kard-two .wrapper .box-for-image {
    width: 98.5%;
  }
}
.how-to-use .general-wrapper .container .kard-two .wrapper .box-for-image .image {
  height: 266px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  margin: 0;
  border-image: linear-gradient(to right, #b861c6 0%, #ffc7c7 100%);
  border-image-slice: 1;
  border-width: 3px;
}
.how-to-use .general-wrapper .container .kard-big {
  flex-flow: column;
  display: flex;
  grid-area: kard-big;
  align-items: center;
  width: 100%;
}
.how-to-use .general-wrapper .container .kard-big h1 {
  min-height: 84px;
  font-family: 'GloberxBold';
  color: #04232f;
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 1024px) {
  .how-to-use .general-wrapper .container .kard-big h1 {
    width: 90%;
  }
}
@media screen and (max-width: 768px) {
  .how-to-use .general-wrapper .container .kard-big h1 {
    width: 100%;
  }
}
@media (max-width: 502px) {
  .how-to-use .general-wrapper .container .kard-big h1 {
    min-height: auto;
    padding: 10px 0;
    width: 70%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 375px) {
  .how-to-use .general-wrapper .container .kard-big h1 {
    width: 100%;
  }
}
.how-to-use .general-wrapper .container .kard-big .wrapper {
  height: 677px;
  width: 100%;
  position: relative;
  -webkit-box-shadow: 0px 13px 0px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 13px 0px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 13px 0px 0px rgba(0,0,0,0.2);
}
@media (max-width: 500px) {
  .how-to-use .general-wrapper .container .kard-big .wrapper {
    width: 70%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 375px) {
  .how-to-use .general-wrapper .container .kard-big .wrapper {
    width: 100%;
    margin: 0 auto;
  }
}
.how-to-use .general-wrapper .container .kard-big .wrapper .cube1 {
  background: #8a73fb;
  background: linear-gradient(68deg, #8a73fb 0%, #2cbad8 63%);
  position: absolute;
  top: 0px;
  height: 50%;
  width: 100%;
}
.how-to-use .general-wrapper .container .kard-big .wrapper .cube2 {
  background: #eb594d;
  background: linear-gradient(68deg, #eb594d 0%, #2cbad8 100%);
  position: absolute;
  bottom: 0px;
  height: 50%;
  width: 100%;
}
.how-to-use .general-wrapper .container .kard-big .wrapper .box-for-image {
  position: absolute;
  top: 2px;
  left: 3px;
  z-index: 3;
  height: 671px;
  width: 98.5%;
}
.how-to-use .general-wrapper .container .kard-big .wrapper .box-for-image .image {
  height: 671px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  margin: 0;
  border-image: linear-gradient(to right, #b861c6 0%, #ffc7c7 100%);
  border-image-slice: 1;
  border-width: 3px;
}
@media (max-width: 1202px) {
  .how-to-use .general-wrapper .container .kard-big .wrapper .box-for-image .image {
    width: 99%;
  }
}
@media screen and (max-width: 1024px) {
  .how-to-use .general-wrapper .container .kard-big .wrapper .box-for-image .image {
    width: 100%;
  }
}
.how-to-use .general-wrapper .container .kard-three {
  flex-flow: column;
  display: flex;
  grid-area: kard-three;
}
.how-to-use .general-wrapper .container .kard-three h1 {
  min-height: 84px;
  font-family: 'GloberxBold';
  color: #04232f;
  padding: 0;
  margin: 0;
}
@media (max-width: 1366px) {
  .how-to-use .general-wrapper .container .kard-three h1 {
    min-height: 81px;
  }
}
@media (max-width: 502px) {
  .how-to-use .general-wrapper .container .kard-three h1 {
    min-height: auto;
    padding: 10px 0;
    width: 70%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 375px) {
  .how-to-use .general-wrapper .container .kard-three h1 {
    width: 100%;
  }
}
.how-to-use .general-wrapper .container .kard-three .wrapper {
  height: 271px;
  width: 100%;
  position: relative;
  -webkit-box-shadow: 0px 13px 0px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 13px 0px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 13px 0px 0px rgba(0,0,0,0.2);
}
@media (max-width: 500px) {
  .how-to-use .general-wrapper .container .kard-three .wrapper {
    width: 70%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 375px) {
  .how-to-use .general-wrapper .container .kard-three .wrapper {
    width: 100%;
    margin: 0 auto;
  }
}
.how-to-use .general-wrapper .container .kard-three .wrapper .cube1 {
  background: #8a73fb;
  background: linear-gradient(68deg, #8a73fb 0%, #2cbad8 63%);
  position: absolute;
  top: 0px;
  height: 50%;
  width: 100%;
}
.how-to-use .general-wrapper .container .kard-three .wrapper .cube2 {
  background: #eb594d;
  background: linear-gradient(68deg, #eb594d 0%, #2cbad8 100%);
  position: absolute;
  bottom: 0px;
  height: 50%;
  width: 100%;
}
.how-to-use .general-wrapper .container .kard-three .wrapper .box-for-image {
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 3;
  height: 260px;
  width: 99%;
}
@media screen and (max-width: 1024px) {
  .how-to-use .general-wrapper .container .kard-three .wrapper .box-for-image {
    width: 98.5%;
  }
}
.how-to-use .general-wrapper .container .kard-three .wrapper .box-for-image .image {
  height: 266px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  margin: 0;
  border-image: linear-gradient(to right, #b861c6 0%, #ffc7c7 100%);
  border-image-slice: 1;
  border-width: 3px;
}
.how-to-use .general-wrapper .container .kard-four {
  align-items: flex-end;
  flex-flow: column;
  display: flex;
  grid-area: kard-four;
}
.how-to-use .general-wrapper .container .kard-four h1 {
  text-align: left;
  min-height: 84px;
  font-family: 'GloberxBold';
  color: #04232f;
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 1024px) {
  .how-to-use .general-wrapper .container .kard-four h1 {
    min-height: 81px;
  }
}
@media (max-width: 502px) {
  .how-to-use .general-wrapper .container .kard-four h1 {
    min-height: auto;
    padding: 10px 0;
    width: 70%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 375px) {
  .how-to-use .general-wrapper .container .kard-four h1 {
    width: 100%;
  }
}
.how-to-use .general-wrapper .container .kard-four .wrapper {
  height: 271px;
  width: 100%;
  position: relative;
  -webkit-box-shadow: 0px 13px 0px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 13px 0px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 13px 0px 0px rgba(0,0,0,0.2);
}
@media (max-width: 500px) {
  .how-to-use .general-wrapper .container .kard-four .wrapper {
    width: 70%;
    margin: 0 auto;
  }
}
@media screen and (max-width: 375px) {
  .how-to-use .general-wrapper .container .kard-four .wrapper {
    width: 100%;
    margin: 0 auto;
  }
}
.how-to-use .general-wrapper .container .kard-four .wrapper .cube1 {
  background: #8a73fb;
  background: linear-gradient(68deg, #8a73fb 0%, #2cbad8 63%);
  position: absolute;
  top: 0px;
  height: 50%;
  width: 100%;
}
.how-to-use .general-wrapper .container .kard-four .wrapper .cube2 {
  background: #eb594d;
  background: linear-gradient(68deg, #eb594d 0%, #2cbad8 100%);
  position: absolute;
  bottom: 0px;
  height: 50%;
  width: 100%;
}
.how-to-use .general-wrapper .container .kard-four .wrapper .box-for-image {
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 3;
  height: 260px;
  width: 99%;
}
@media screen and (max-width: 1024px) {
  .how-to-use .general-wrapper .container .kard-four .wrapper .box-for-image {
    width: 98.5%;
  }
}
.how-to-use .general-wrapper .container .kard-four .wrapper .box-for-image .image {
  height: 266px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  margin: 0;
  border-image: linear-gradient(to right, #b861c6 0%, #ffc7c7 100%);
  border-image-slice: 1;
  border-width: 3px;
}
.how-to-use .buing-elements {
  max-width: 1400px;
  margin: 50px auto 0 auto;
  display: flex;
  flex-flow: column;
  height: 100%;
}
@media (max-width: 1440px) {
  .how-to-use .buing-elements {
    margin: 0 10%;
  }
}
@media screen and (max-width: 768px) {
  .how-to-use .buing-elements {
    margin: 0 3%;
    padding: 50px 0 0 0;
  }
}
@media screen and (max-width: 375px) {
  .how-to-use .buing-elements {
    margin: 0 6%;
  }
}
.how-to-use .buing-elements .buy {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-flow: row;
  position: relative;
}
@media (max-width: 560px) {
  .how-to-use .buing-elements .buy {
    flex-flow: column;
  }
}
.how-to-use .buing-elements .buy .text-elements {
  width: 100%;
}
.how-to-use .buing-elements .buy .text-elements .header p {
  font-family: 'GloberxBold';
}
.how-to-use .circle-wrapper {
  width: 50%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 560px) {
  .how-to-use .circle-wrapper {
    width: 100%;
    margin: 25px 0 0 0;
    align-items: flex-start;
  }
}
.how-to-use .circle-wrapper .method-coord-img-outline {
  outline: none;
  box-shadow: none;
  width: 190px;
  height: 190px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
}
@media screen and (max-width: 375px) {
  .how-to-use .circle-wrapper .method-coord-img-outline {
    width: 160px;
    height: 160px;
  }
}
.how-to-use .circle-wrapper .method-coord-img-outline .box-knowledge {
  width: 190px;
  height: 96px;
  background: linear-gradient(97deg, #7565d6 0%, #4587cf 100%);
  border-top-left-radius: 95px;
  border-top-right-radius: 95px;
  position: absolute;
  top: 0px;
}
@media screen and (max-width: 375px) {
  .how-to-use .circle-wrapper .method-coord-img-outline .box-knowledge {
    width: 158px;
    height: 79px;
    top: 3px;
  }
}
.how-to-use .circle-wrapper .method-coord-img-outline .box-knowledge2 {
  width: 190px;
  height: 95px;
  background: linear-gradient(360deg, #c35149 0%, #4587cf 100%);
  border-bottom-left-radius: 95px;
  border-bottom-right-radius: 95px;
  position: absolute;
  bottom: 0px;
  -webkit-box-shadow: 0px 7px 0px 0px #7461d3;
  -moz-box-shadow: 0px 7px 0px 0px #7461d3;
  box-shadow: 0px 7px 0px 0px #7461d3;
}
@media screen and (max-width: 375px) {
  .how-to-use .circle-wrapper .method-coord-img-outline .box-knowledge2 {
    width: 158px;
    height: 79px;
    bottom: 3px;
  }
}
.how-to-use .circle-wrapper .method-coord-img-outline .method-coord-img {
  width: 180px;
  height: 180px;
  border-radius: 100%;
  z-index: 2;
  background-color: #fff;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
@media screen and (max-width: 375px) {
  .how-to-use .circle-wrapper .method-coord-img-outline .method-coord-img {
    width: 145px;
    height: 145px;
  }
}
.how-to-use .circle-wrapper .method-coord-img-outline .method-coord-img .text {
  position: absolute;
  width: 100%;
  top: 50px;
  left: 22px;
  font-size: 50px;
  color: #04232f;
  font-family: 'GloberRegular' !important;
}
@media screen and (max-width: 375px) {
  .how-to-use .circle-wrapper .method-coord-img-outline .method-coord-img .text {
    font-size: 45px;
    top: 39px;
  }
}
.how-to-use .circle-wrapper .method-coord-img-outline .method-coord-img .text2 {
  color: #04232f;
  font-family: 'GloberRegular' !important;
  position: absolute;
  top: 110px;
  left: 45px;
  font-size: 21px;
}
@media screen and (max-width: 375px) {
  .how-to-use .circle-wrapper .method-coord-img-outline .method-coord-img .text2 {
    top: 82px;
    left: 27px;
  }
}
.how-to-use .circle-wrapper .button-buy {
  display: flex;
  flex-flow: column;
  margin: 20px 0;
}
@media (max-width: 560px) {
  .how-to-use .circle-wrapper .button-buy {
    padding: 0 0 0 3%;
  }
}
.how-to-use .circle-wrapper .button-buy label {
  font-family: 'GloberRegular';
}
.how-to-use .circle-wrapper .button-buy .form-wrapper .button-violet-price {
  width: 150px;
  color: #04232f;
  margin: 10px 0 0 -10px;
  -webkit-box-shadow: 0px 3px 0px 0px #7461d3;
  -moz-box-shadow: 0px 3px 0px 0px #7461d3;
  box-shadow: 0px 3px 0px 0px #7461d3;
}
.how-to-use .circle-wrapper .button-buy .form-wrapper .button-violet-price .button-elements {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: cnter;
}
.how-to-use .circle-wrapper .button-buy .form-wrapper .button-violet-price .button-elements a {
  text-decoration: none;
  color: #fff;
  font-family: GloberRegular;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.how-to-use .circle-wrapper .button-buy .form-wrapper .button-violet-price .button-elements img {
  width: 5px;
  height: 10px;
  margin: 0 15px 0 0;
}
.general-advertisement-wrapper {
  width: 100%;
  background-size: cover;
  background-repeat: no-re;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 50px 0;
}
.general-advertisement-wrapper .blenda {
  background-color: rgba(4,35,47,0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.general-advertisement-wrapper .advertisement-elements {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  flex-flow: row;
  justify-content: space-between;
}
@media (max-width: 1440px) {
  .general-advertisement-wrapper .advertisement-elements {
    margin: 0 10%;
  }
}
@media screen and (max-width: 1024px) {
  .general-advertisement-wrapper .advertisement-elements {
    margin: 0 10%;
    flex-flow: column;
  }
}
@media screen and (max-width: 768px) {
  .general-advertisement-wrapper .advertisement-elements {
    margin: 0 3%;
  }
}
@media screen and (max-width: 375px) {
  .general-advertisement-wrapper .advertisement-elements {
    margin: 0 6%;
    padding: 50px 0;
  }
}
.general-advertisement-wrapper .advertisement-elements .tutorials {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-flow: row;
  width: 100%;
  position: relative;
  padding: 0 50px 0 0;
}
@media screen and (max-width: 1024px) {
  .general-advertisement-wrapper .advertisement-elements .tutorials {
    width: 100%;
    padding: 0;
  }
}
@media screen and (max-width: 375px) {
  .general-advertisement-wrapper .advertisement-elements .tutorials {
    flex-flow: column;
    padding: 0;
  }
}
.general-advertisement-wrapper .advertisement-elements .tutorials .text-elements {
  width: 100%;
}
.general-advertisement-wrapper .advertisement-elements .tutorials .text-elements .header {
  font-family: 'GloberxBold';
}
.general-advertisement-wrapper .advertisement-elements .tutorials .text-elements .header p {
  font-family: 'GloberxBold';
  text-transform: uppercase;
}
.general-advertisement-wrapper .advertisement-elements .tutorials .text-elements .text {
  width: 100%;
}
.general-advertisement-wrapper .advertisement-elements .tutorials .text-elements .text p {
  width: 100%;
  font-family: 'GloberRegular';
  margin: 0;
}
.general-advertisement-wrapper .advertisement-elements .tutorials .text-elements .button-date {
  display: flex;
  flex-flow: column;
  margin: 74px 20px 0 20px;
}
.general-advertisement-wrapper .advertisement-elements .tutorials .text-elements .button-date .form-wrapper .button-violet-price {
  color: #04232f;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 0 -10px;
  -webkit-box-shadow: 0px 3px 0px 0px #7461d3;
  -moz-box-shadow: 0px 3px 0px 0px #7461d3;
  box-shadow: 0px 3px 0px 0px #7461d3;
}
@media screen and (max-width: 1024px) {
  .general-advertisement-wrapper .advertisement-elements .tutorials .text-elements .button-date .form-wrapper .button-violet-price {
    width: 223px;
    height: 43px;
  }
}
.general-advertisement-wrapper .advertisement-elements .tutorials .text-elements .button-date .form-wrapper .button-violet-price .buttons-element {
  width: 100%;
  text-align: center;
}
.general-advertisement-wrapper .advertisement-elements .tutorials .text-elements .button-date .form-wrapper .button-violet-price .buttons-element a {
  text-decoration: none;
  color: #fff;
  font-size: 12px;
  font-family: 'GloberxBold';
}
@media screen and (max-width: 1024px) {
  .general-advertisement-wrapper .advertisement-elements .tutorials .text-elements .button-date .form-wrapper .button-violet-price .buttons-element a {
    font-size: 18px;
  }
}
.card-wrapper {
  flex-flow: column;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .card-wrapper {
    align-items: flex-start;
  }
}
@media (max-width: 416px) {
  .card-wrapper {
    min-width: 100%;
  }
}
.card-wrapper .card-top {
  max-width: 381px;
  min-width: 381px;
  height: 213px;
  margin: 0 0 20px 0;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0 20px 20px;
}
@media screen and (max-width: 1024px) {
  .card-wrapper .card-top {
    margin: 50px 10px 0 10px;
    text-align: left;
  }
}
@media (max-width: 450px) {
  .card-wrapper .card-top {
    max-width: 100%;
    min-width: 100%;
    margin: 50px 0 0 0;
    padding: 10px 0;
  }
}
.card-wrapper .card-top:hover {
  background-color: #8a73fb;
}
.card-wrapper .card-top:hover .data {
  color: #fff !important;
}
.card-wrapper .card-top:hover .method-coord-img-outline {
  -webkit-box-shadow: 0px 3px 0px 0px #7461d3;
  -moz-box-shadow: 0px 3px 0px 0px #7461d3;
  box-shadow: 0px 3px 0px 0px #7461d3;
}
.card-wrapper .card-top .wrapper-for-data {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 50% !important;
  justify-content: space-between;
}
.card-wrapper .card-top .wrapper-for-data .data {
  color: #8a73fb;
  font-size: 14px;
}
.card-wrapper .card-top .wrapper-for-data .title {
  color: #04232f;
  text-transform: uppercase;
  font-family: 'GloberxBold';
  width: 100%;
}
.card-wrapper .card-top .wrapper-for-data .place {
  font-size: 14px;
  color: #04232f;
}
.circle-wrapper {
  width: 50%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
@media screen and (max-width: 375px) {
  .circle-wrapper {
    width: 100%;
    justify-content: flex-end;
  }
}
.circle-wrapper .method-coord-img-outline {
  cursor: pointer;
  height: 116px;
  width: 116px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  box-shadow: 0px 5px 0px 0px rgba(178,178,178,0.55);
}
.circle-wrapper .method-coord-img-outline .box-cards {
  width: 116px;
  height: 58px;
  background: linear-gradient(97deg, #7565d6 0%, #4587cf 100%);
  border-top-left-radius: 95px;
  border-top-right-radius: 95px;
  position: absolute;
  top: 0px;
}
.circle-wrapper .method-coord-img-outline .box-cards2 {
  width: 116px;
  height: 58px;
  background: linear-gradient(90deg, #c35149 0%, #4587cf 100%);
  border-bottom-left-radius: 95px;
  border-bottom-right-radius: 95px;
  position: absolute;
  bottom: 0px;
}
.circle-wrapper .method-coord-img-outline .method-coord-img {
  width: 110px;
  height: 110px;
  border-radius: 100%;
  z-index: 2;
  background-color: #04232f;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: 'GloberxBold' !important;
}
.circle-wrapper .method-coord-img-outline .method-coord-img .arrow {
  transform: rotate(180deg);
  width: 46px;
  position: absolute;
  top: 25px;
  left: 17px;
}
.circle-wrapper .method-coord-img-outline .method-coord-img .text {
  position: absolute;
  top: 47px;
  left: 17px;
  color: #fff;
  font-size: 16px;
  width: 70%;
  line-height: 1;
  font-family: 'GloberxBold' !important;
}
@media (min-width: 1441px) {
  section.blog-banner-wrapper .blog-wrapper {
    max-width: 1400px !important;
  }
}
section.blog-banner-wrapper {
  width: 100%;
  overflow: hidden;
  padding-top: 40px;
  border-top: 2px solid #e8e8e8;
}
section.blog-banner-wrapper .blog-wrapper {
  max-width: 1140px;
  display: flex;
  margin: 0px auto;
  padding: 0px 0px;
  flex-direction: column;
}
section.blog-banner-wrapper .blog-wrapper .blog-go-back {
  position: relative;
}
section.blog-banner-wrapper .blog-wrapper .blog-go-back:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 40px;
  background: #8a73fb;
  top: -13px;
  left: 6px;
}
section.blog-banner-wrapper .blog-wrapper .blog-go-back:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid #8a73fb;
  top: -18px;
  left: 0px;
}
section.blog-banner-wrapper .blog-wrapper .blog-go-back .blog-go-back-to-main-page {
  position: absolute;
  height: 50px;
  width: 115px;
  top: -18px;
}
section.blog-banner-wrapper .blog-wrapper .blog-go-back p {
  color: #8a73fb;
  font-family: 'GloberRegular';
  font-size: 14px;
  line-height: 17px;
}
section.blog-banner-wrapper .blog-wrapper h1.blog-header {
  font-family: 'GloberHeavy';
  font-size: 72px;
  line-height: 65px;
  position: relative;
  padding-bottom: 5px;
  margin-top: 40px;
}
section.blog-banner-wrapper .blog-wrapper h1.blog-header:after {
  content: '';
  background-color: #fff;
  position: absolute;
  width: 101px;
  height: 7px;
  left: 2px;
  top: -29px;
}
@media (max-width: 1145px) {
  section.blog-banner-wrapper .blog-wrapper {
    padding: 0px 20px;
  }
}
@media (min-width: 1441px) {
  section.blog-content-list-wrapper .blog-content-list {
    max-width: 1400px !important;
  }
  section.blog-content-list-wrapper .blog-navigation-arrows-wrapper .blog-navigation-arrows {
    max-width: 1400px !important;
  }
}
section.blog-content-list-wrapper {
  width: 100%;
  overflow: hidden;
}
section.blog-content-list-wrapper .blog-content-list {
  max-width: 1140px;
  margin: 0px auto;
  padding: 0px 0px;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content {
  list-style-type: none;
  display: flex;
  flex-direction: column;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item {
  width: 100%;
  height: 482px;
  margin: 36px 0px;
  border-bottom-left-radius: 25px;
  display: flex;
  flex-direction: row;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link {
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link:hover .content-text-wrapper {
  background: #8a73fb;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link:hover .content-text-wrapper .main-content .title-wrapper p.post-category {
  color: #fff;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link:hover .content-text-wrapper .blog-post-author-wrap .post-author-info-wrap p.post-author {
  color: #fff;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link:hover .content-text-wrapper .blog-post-author-wrap .post-author-info-wrap p.post-author-quote {
  color: #fff;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link:hover .content-image {
  background-size: 105% !important;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link:hover .content-image .blog-hover-wrap {
  display: flex;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper {
  width: 25%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 35px 30px;
  border-bottom-left-radius: 25px;
  transition: 0.2s;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .main-content {
  display: flex;
  flex-direction: column;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .main-content .title-wrapper {
  width: 100%;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .main-content .title-wrapper p.post-category {
  font-family: 'GloberRegular';
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  color: #8a73fb;
  transition: 0.2s;
  margin-bottom: 25px;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .main-content .quote-wrapper {
  width: 100%;
  position: relative;
  margin-top: 45px;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .main-content .quote-wrapper:before {
  content: '';
  position: absolute;
  height: 7px;
  width: 100px;
  background: #000;
  top: -45px;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .main-content .quote-wrapper p {
  font-family: 'GloberxBold';
  font-size: 24px;
  line-height: 31px;
  color: #04232f;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .blog-post-author-wrap {
  display: flex;
  flex-direction: row;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .blog-post-author-wrap .author-img {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .blog-post-author-wrap .author-img .box-author-blog {
  width: 36px;
  height: 18px;
  background: linear-gradient(97deg, #7565d6 0%, #4587cf 100%);
  border-top-left-radius: 95px;
  border-top-right-radius: 95px;
  position: absolute;
  top: 0px;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .blog-post-author-wrap .author-img .box2-author-blog {
  width: 36px;
  height: 18px;
  background: linear-gradient(90deg, #c35149 0%, #4587cf 100%);
  border-bottom-left-radius: 95px;
  border-bottom-right-radius: 95px;
  position: absolute;
  bottom: 0px;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .blog-post-author-wrap .author-img .blog-author-img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  z-index: 2;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .blog-post-author-wrap .post-author-info-wrap {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .blog-post-author-wrap .post-author-info-wrap p.post-author {
  font-family: 'GloberxBold';
  font-size: 12px;
  line-height: 11px;
  text-transform: uppercase;
  color: #8a73fb;
  transition: 0.2s;
  margin-bottom: 0px;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .blog-post-author-wrap .post-author-info-wrap p.post-author-quote {
  font-family: 'GloberRegular';
  font-size: 10px;
  line-height: 17px;
  color: #000;
  transition: 0.2s;
  margin-bottom: 0px;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-image {
  width: 97%;
  height: 100%;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
  transition: 0.2s ease-in-out;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-image .blog-hover-wrap {
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-image .blog-hover-wrap .blog-hover-circle {
  cursor: pointer;
  height: 116px;
  width: 116px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  box-shadow: 0px 10px 5px 0px rgba(20,20,20,0.55);
}
@media screen and (max-width: 375px) {
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-image .blog-hover-wrap .blog-hover-circle {
    width: 88px;
    height: 88px;
  }
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-image .blog-hover-wrap .blog-hover-circle .box1-blog {
  width: 116px;
  height: 58px;
  background: linear-gradient(97deg, #7565d6 0%, #4587cf 100%);
  border-top-left-radius: 95px;
  border-top-right-radius: 95px;
  position: absolute;
  top: 0px;
}
@media screen and (max-width: 375px) {
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-image .blog-hover-wrap .blog-hover-circle .box1-blog {
    width: 92.8px;
    height: 46.4px;
    top: -2px;
  }
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-image .blog-hover-wrap .blog-hover-circle .box2-blog {
  width: 116px;
  height: 58px;
  background: linear-gradient(90deg, #c35149 0%, #4587cf 100%);
  border-bottom-left-radius: 95px;
  border-bottom-right-radius: 95px;
  position: absolute;
  bottom: 0px;
}
@media screen and (max-width: 375px) {
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-image .blog-hover-wrap .blog-hover-circle .box2-blog {
    width: 92.8px;
    height: 46.4px;
    bottom: -2px;
  }
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-image .blog-hover-wrap .blog-hover-circle .blog-hover-bg {
  width: 110px;
  height: 110px;
  border-radius: 100%;
  z-index: 2;
  background-color: #04232f;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
@media screen and (max-width: 375px) {
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-image .blog-hover-wrap .blog-hover-circle .blog-hover-bg {
    width: 88px;
    height: 88px;
  }
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-image .blog-hover-wrap .blog-hover-circle .blog-hover-bg:after {
  content: '';
  position: absolute;
  height: 3px;
  width: 40px;
  background: #8a73fb;
  top: 25px;
  left: 21px;
}
@media screen and (max-width: 375px) {
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-image .blog-hover-wrap .blog-hover-circle .blog-hover-bg:after {
    width: 32px;
  }
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-image .blog-hover-wrap .blog-hover-circle .blog-hover-bg:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 6px solid #8a73fb;
  top: 20px;
  left: 61px;
}
@media screen and (max-width: 375px) {
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-image .blog-hover-wrap .blog-hover-circle .blog-hover-bg:before {
    left: 53px;
  }
}
section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-image .blog-hover-wrap .blog-hover-circle .blog-hover-bg p.blog-hover-text {
  color: #fff;
  font-family: 'GloberxBold';
  font-size: 16px;
  width: 70px;
  margin-bottom: -10px;
  margin-left: 0;
  line-height: 17px;
}
@media screen and (max-width: 375px) {
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-image .blog-hover-wrap .blog-hover-circle .blog-hover-bg p.blog-hover-text {
    font-size: 12px;
    margin-left: 20px;
    margin-bottom: -14px;
  }
}
section.blog-content-list-wrapper .blog-navigation-arrows-wrapper {
  width: 100%;
  overflow: hidden;
}
section.blog-content-list-wrapper .blog-navigation-arrows-wrapper .blog-navigation-arrows {
  max-width: 1140px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
}
section.blog-content-list-wrapper .blog-navigation-arrows-wrapper .blog-navigation-arrows .blog-arrow-left {
  padding-left: 35px;
  position: relative;
  font-family: 'GloberxBold';
  font-size: 18px;
  cursor: pointer;
  color: #fff;
}
section.blog-content-list-wrapper .blog-navigation-arrows-wrapper .blog-navigation-arrows .blog-arrow-left:hover {
  color: #8a73fb;
}
section.blog-content-list-wrapper .blog-navigation-arrows-wrapper .blog-navigation-arrows .blog-arrow-left:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid #fff;
  top: 9px;
  left: 0px;
}
section.blog-content-list-wrapper .blog-navigation-arrows-wrapper .blog-navigation-arrows .blog-arrow-left:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid #fff;
  bottom: 6px;
  left: 9px;
}
section.blog-content-list-wrapper .blog-navigation-arrows-wrapper .blog-navigation-arrows .blog-arrow-left:hover:before {
  border-right: 6px solid #8a73fb;
}
section.blog-content-list-wrapper .blog-navigation-arrows-wrapper .blog-navigation-arrows .blog-arrow-left:hover:after {
  border-right: 6px solid #8a73fb;
}
section.blog-content-list-wrapper .blog-navigation-arrows-wrapper .blog-navigation-arrows .blog-arrow-right {
  padding-right: 35px;
  position: relative;
  cursor: pointer;
  font-family: 'GloberxBold';
  font-size: 18px;
  color: #fff;
}
section.blog-content-list-wrapper .blog-navigation-arrows-wrapper .blog-navigation-arrows .blog-arrow-right:hover {
  color: #8a73fb;
}
section.blog-content-list-wrapper .blog-navigation-arrows-wrapper .blog-navigation-arrows .blog-arrow-right:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #fff;
  top: 9px;
  right: 0px;
}
section.blog-content-list-wrapper .blog-navigation-arrows-wrapper .blog-navigation-arrows .blog-arrow-right:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #fff;
  bottom: 6px;
  right: 9px;
}
section.blog-content-list-wrapper .blog-navigation-arrows-wrapper .blog-navigation-arrows .blog-arrow-right:hover:before {
  border-left-color: #8a73fb;
}
section.blog-content-list-wrapper .blog-navigation-arrows-wrapper .blog-navigation-arrows .blog-arrow-right:hover:after {
  border-left-color: #8a73fb;
}
@media (max-width: 1145px) {
  section.blog-content-list-wrapper .blog-content-list {
    padding: 0px 20px;
  }
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item {
    height: 416px;
  }
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link {
    position: relative;
  }
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper {
    width: 290px;
    z-index: 1;
  }
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-image {
    position: absolute;
    right: 0;
  }
}
@media (max-width: 860px) {
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item {
    height: 373px;
  }
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper {
    width: 240px;
    z-index: 1;
  }
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .main-content .title-wrapper p.post-author {
    margin-bottom: 20px;
  }
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .main-content .quote-wrapper {
    margin-top: 25px;
  }
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .main-content .quote-wrapper:before {
    top: -25px;
  }
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .main-content .quote-wrapper p {
    font-size: 18px;
    line-height: 24px;
  }
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-image {
    position: absolute;
    right: 0;
  }
}
@media (max-width: 545px) {
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item {
    min-height: 500px;
  }
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link {
    flex-direction: column-reverse;
  }
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper {
    width: 100%;
    z-index: 1;
    min-height: 300px;
    padding: 20px 25px 25px 25px;
  }
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .main-content .title-wrapper p.post-category {
    margin-bottom: 14px;
  }
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .main-content .quote-wrapper {
    margin-top: 42px;
    font-size: 22px;
  }
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .main-content .quote-wrapper:before {
    top: -40px;
    width: 180px;
  }
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .main-content .quote-wrapper p {
    font-size: 22px;
    line-height: 24px;
  }
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-text-wrapper .blog-post-author-wrap .post-author-info-wrap p.post-author {
    margin-bottom: 0px;
  }
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-image {
    position: relative;
    width: 100%;
    max-height: 220px;
  }
  section.blog-content-list-wrapper .blog-content-list ul.blog-content li.blog-content-item .blog-art-link .content-image .blog-hover-wrap {
    display: flex;
  }
  section.blog-content-list-wrapper .blog-navigation-arrows-wrapper .blog-navigation-arrows .blog-arrow-left {
    font-size: 16px;
    padding-left: 25px;
  }
  section.blog-content-list-wrapper .blog-navigation-arrows-wrapper .blog-navigation-arrows .blog-arrow-left:before {
    top: 7px;
  }
  section.blog-content-list-wrapper .blog-navigation-arrows-wrapper .blog-navigation-arrows .blog-arrow-left:after {
    bottom: 5px;
  }
  section.blog-content-list-wrapper .blog-navigation-arrows-wrapper .blog-navigation-arrows .blog-arrow-right {
    font-size: 16px;
    padding-right: 25px;
  }
  section.blog-content-list-wrapper .blog-navigation-arrows-wrapper .blog-navigation-arrows .blog-arrow-right:before {
    top: 7px;
  }
  section.blog-content-list-wrapper .blog-navigation-arrows-wrapper .blog-navigation-arrows .blog-arrow-right:after {
    bottom: 5px;
  }
}
section.blog-author-top-wrapper {
  width: 100%;
  overflow: hidden;
  padding: 0px 0px;
  position: absolute;
  height: 270px;
  background: none;
  z-index: 0;
}
section.blog-author-top-wrapper .blog-author-top {
  max-width: 1400px;
  margin: 0px auto;
  display: flex;
  position: relative;
}
section.blog-author-top-wrapper .blog-author-top .author-top-left {
  width: 190px;
  height: 120px;
  position: absolute;
  top: 13px;
  padding-top: 18px;
}
section.blog-author-top-wrapper .blog-author-top .author-top-left .author-go-back {
  color: #8a73fb;
  font-family: 'GloberRegular';
  font-size: 14px;
  position: relative;
}
section.blog-author-top-wrapper .blog-author-top .author-top-left .author-go-back:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 40px;
  background: #8a73fb;
  top: -13px;
  left: 6px;
}
section.blog-author-top-wrapper .blog-author-top .author-top-left .author-go-back:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid #8a73fb;
  top: -18px;
  left: 0px;
}
section.blog-author-top-wrapper .blog-author-top .author-top-left .author-go-back .blog-button-goback {
  position: absolute;
  width: 115px;
  height: 50px;
  top: -22px;
  left: 0;
}
section.blog-author-top-wrapper .blog-author-top .author-top-left .author-profile {
  position: absolute;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  top: 70px;
}
section.blog-author-top-wrapper .blog-author-top .author-top-left .author-profile:before {
  content: '';
  position: absolute;
  background: #000;
  width: 100px;
  height: 7px;
  top: 0;
}
section.blog-author-top-wrapper .blog-author-top .author-top-left .author-profile .author-img {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  margin: 50px 0px 15px;
}
section.blog-author-top-wrapper .blog-author-top .author-top-left .author-profile .author-img .box-author-blog {
  width: 36px;
  height: 18px;
  background: linear-gradient(97deg, #7565d6 0%, #4587cf 100%);
  border-top-left-radius: 95px;
  border-top-right-radius: 95px;
  position: absolute;
  top: 0px;
}
section.blog-author-top-wrapper .blog-author-top .author-top-left .author-profile .author-img .box2-author-blog {
  width: 36px;
  height: 18px;
  background: linear-gradient(90deg, #c35149 0%, #4587cf 100%);
  border-bottom-left-radius: 95px;
  border-bottom-right-radius: 95px;
  position: absolute;
  bottom: 0px;
}
section.blog-author-top-wrapper .blog-author-top .author-top-left .author-profile .author-img .blog-author-img {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  z-index: 2;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
section.blog-author-top-wrapper .blog-author-top .author-top-left .author-profile .author-name-and-date .author-name {
  font-family: 'GloberxBold';
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #8a73fb;
  width: 50%;
  margin-bottom: 13px;
}
section.blog-author-top-wrapper .blog-author-top .author-top-left .author-profile .author-name-and-date .author-post-date {
  font-family: 'GloberRegular';
  font-size: 12px;
  line-height: 17px;
  color: #04232f;
}
section.blog-author-top-wrapper .blog-author-top .author-top-right {
  display: none;
  flex-direction: column;
  justify-content: flex-end;
}
section.blog-author-top-wrapper .blog-author-top .author-top-right ul.blog-author-social-wrapper {
  display: flex;
  flex-direction: row;
  list-style-type: none;
}
section.blog-author-top-wrapper .blog-author-top .author-top-right ul.blog-author-social-wrapper li.blog-author-social-icon {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background: #fff;
  margin-right: 5px;
  position: relative;
}
section.blog-author-top-wrapper .blog-author-top .author-top-right ul.blog-author-social-wrapper li.blog-author-social-icon a {
  position: absolute;
  font-size: 0px;
  width: 16px;
  height: 16px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
section.blog-author-top-wrapper .blog-author-top .author-top-right .author-post-category {
  font-family: 'GloberRegular';
  font-size: 14px;
  color: #8a73fb;
  text-transform: uppercase;
}
@media (max-width: 1440px) {
  section.blog-author-top-wrapper {
    width: 100%;
    padding: 0px 0px;
  }
  section.blog-author-top-wrapper .blog-author-top {
    max-width: 1140px;
  }
}
@media (max-width: 1245px) {
  section.blog-author-top-wrapper {
    padding-bottom: 0px;
    position: relative;
    height: 155px;
  }
  section.blog-author-top-wrapper .blog-author-top {
    max-width: 768px;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
  }
  section.blog-author-top-wrapper .blog-author-top .author-top-left {
    width: auto;
    height: auto;
    position: relative;
    padding-top: 0px;
    width: 100%;
    top: 0;
  }
  section.blog-author-top-wrapper .blog-author-top .author-top-left .author-go-back {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  section.blog-author-top-wrapper .blog-author-top .author-top-left .author-profile {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    min-height: auto;
    top: 0px;
    padding-top: 40px;
    width: 100%;
  }
  section.blog-author-top-wrapper .blog-author-top .author-top-left .author-profile .author-img {
    margin: 0px;
    margin-right: 20px;
  }
  section.blog-author-top-wrapper .blog-author-top .author-top-left .author-profile .author-name-and-date {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 100%;
  }
  section.blog-author-top-wrapper .blog-author-top .author-top-left .author-profile .author-name-and-date .author-name {
    margin: 0px;
    width: 100%;
  }
  section.blog-author-top-wrapper .blog-author-top .author-top-left .author-profile .author-name-and-date .author-post-date {
    width: 100%;
  }
  section.blog-author-top-wrapper .blog-author-top .author-top-right {
    width: 100%;
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 100%;
    margin-top: 5px;
  }
}
@media (max-width: 768px) {
  section.blog-author-top-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}
section.blog-post-quote-wrapper {
  width: 100%;
  overflow: hidden;
}
section.blog-post-quote-wrapper .blog-post-text-quote {
  margin: 0px auto;
  max-width: 1024px;
  padding: 100px 95px;
  font-family: 'GloberLight';
  font-size: 24px;
  line-height: 41px;
  color: #142832;
}
section.blog-post-quote-wrapper .blog-post-text-quote p strong {
  font-family: 'GloberSemiBold';
}
section.blog-post-quote-wrapper .blog-post-text-quote p a {
  font-family: 'GloberSemiBold';
  cursor: pointer;
}
@media (max-width: 1440px) {
  section.blog-post-quote-wrapper .blog-post-text-quote {
    max-width: 768px;
  }
}
@media (max-width: 768px) {
  section.blog-post-quote-wrapper .blog-post-text-quote {
    padding: 80px 95px;
    font-size: 18px;
    line-height: 1.875;
  }
}
@media (max-width: 545px) {
  section.blog-post-quote-wrapper .blog-post-text-quote {
    padding: 10px 35px;
  }
}
section.blog-post-tytul-artykulu-wrapper {
  width: 100%;
  overflow: hidden;
  border-top: 2px solid #e8e8e8;
}
section.blog-post-tytul-artykulu-wrapper .blog-post-tytul-artykulu {
  max-width: 1410px;
  min-height: 80px;
  margin: 5px auto;
  font-family: 'GloberHeavy';
  line-height: 97px;
  padding-top: 75px;
  padding-bottom: 60px;
  color: #142832;
}
@media (max-width: 1440px) {
  section.blog-post-tytul-artykulu-wrapper .blog-post-tytul-artykulu {
    max-width: 1140px;
  }
}
@media (max-width: 1245px) {
  section.blog-post-tytul-artykulu-wrapper .blog-post-tytul-artykulu {
    max-width: 768px;
  }
}
@media (max-width: 768px) {
  section.blog-post-tytul-artykulu-wrapper .blog-post-tytul-artykulu {
    font-size: 60px;
    line-height: 70px;
    padding: 50px 20px 0px 20px;
    padding-bottom: 0px !important;
  }
}
section.blog-naglowek-wrap {
  width: 100%;
  overflow: hidden;
}
section.blog-naglowek-wrap .blog-naglowek {
  max-width: 960px;
  margin: 0px auto;
}
section.blog-naglowek-wrap .blog-naglowek h3 {
  font-family: 'GloberxBold';
  color: #142832;
}
@media (max-width: 1440px) {
  section.blog-naglowek-wrap .blog-naglowek {
    max-width: 768px;
  }
}
@media (max-width: 768px) {
  section.blog-naglowek-wrap {
    padding-left: 20px;
    padding-right: 20px;
  }
  section.blog-naglowek-wrap .blog-naglowek h3 {
    margin-bottom: 0px;
  }
}
.blog-opis-wrapper {
  max-width: 100%;
  overflow: hidden !important;
  background: transparent;
  z-index: 3;
}
.blog-opis-wrapper .blog-opis-content {
  max-width: 960px;
  margin: 0px auto;
}
.blog-opis-wrapper .blog-opis-content p {
  z-index: 3;
  font-family: 'GloberBook';
  line-height: 45px;
  color: #142832;
}
.blog-opis-wrapper .blog-opis-content p a {
  z-index: 3;
  position: relative;
  color: #8a73fb;
  font-family: 'GloberSemiBold';
  cursor: pointer;
}
.blog-opis-wrapper .blog-opis-content p strong {
  font-family: 'GloberSemiBold';
}
.blog-opis-wrapper .blog-opis-content ul {
  list-style-type: none;
  padding-left: 25px;
  font-family: 'GloberBook' !important;
}
.blog-opis-wrapper .blog-opis-content ul li {
  font-size: 24px;
  font-family: 'GloberBook';
  line-height: 41px;
  position: relative;
  line-height: 1.8;
}
.blog-opis-wrapper .blog-opis-content ul li:before {
  content: '';
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background-color: #8a73fb;
  position: absolute;
  left: -22px;
  top: 18px;
}
.blog-opis-wrapper .blog-opis-content ul li strong {
  font-family: 'GloberSemiBold';
}
.blog-opis-wrapper .blog-opis-content ol {
  padding-left: 25px;
  font-family: 'GloberBook' !important;
}
.blog-opis-wrapper .blog-opis-content ol li {
  font-size: 24px;
  font-family: 'GloberBook';
  line-height: 41px;
  line-height: 1.8;
}
.blog-opis-wrapper .blog-opis-content ol li strong {
  font-family: 'GloberSemiBold';
}
@media (max-width: 1440px) {
  .blog-opis-wrapper .blog-opis-content {
    max-width: 768px;
  }
}
@media (max-width: 768px) {
  .blog-opis-wrapper {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .blog-opis-wrapper .blog-opis-content {
    padding: 20px 20px 0px 20px;
  }
  .blog-opis-wrapper .blog-opis-content p {
    line-height: 1.875;
  }
  .blog-opis-wrapper .blog-opis-content ul {
    font-size: 18px;
  }
  .blog-opis-wrapper .blog-opis-content ul li {
    font-size: 18px;
  }
  .blog-opis-wrapper .blog-opis-content ul li:before {
    top: 14px;
  }
  .blog-opis-wrapper .blog-opis-content ol {
    font-size: 18px;
  }
  .blog-opis-wrapper .blog-opis-content ol li {
    font-size: 18px;
  }
}
section.photo-or-gallery-wrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
section.photo-or-gallery-wrapper a {
  text-decoration: none;
  cursor: pointer;
}
section.photo-or-gallery-wrapper .photo-or-gallery {
  max-width: 1440px;
  width: 100%;
  margin: 0px auto;
}
section.photo-or-gallery-wrapper .podpis-pod-zdjeciem {
  padding: 10px 0;
  font-family: 'GloberBook';
  width: 100%;
  text-align: center;
  color: #04232f;
}
@media (max-width: 768px) {
  section.photo-or-gallery-wrapper .photo-or-gallery {
    max-width: 100%;
    padding: 0px 20px;
  }
}
section.blog-post-footer-wrapper {
  width: 100%;
  overflow: hidden;
  padding-bottom: 20px;
}
section.blog-post-footer-wrapper .blog-post-footer {
  max-width: 1140px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  border-top: 2px solid #dcdcdc;
  height: 137px;
  padding-top: 45px;
}
section.blog-post-footer-wrapper .blog-post-footer .blog-post-footer-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}
section.blog-post-footer-wrapper .blog-post-footer .blog-post-footer-left .blog-post-footer-author-img-wrapper {
  width: 88px;
  height: 88px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
  margin-right: 10px;
}
section.blog-post-footer-wrapper .blog-post-footer .blog-post-footer-left .blog-post-footer-author-img-wrapper .box-post-footer-auth {
  width: 88px;
  height: 44px;
  background: linear-gradient(97deg, #7565d6 0%, #4587cf 100%);
  border-top-left-radius: 95px;
  border-top-right-radius: 95px;
  position: absolute;
  top: 0px;
}
section.blog-post-footer-wrapper .blog-post-footer .blog-post-footer-left .blog-post-footer-author-img-wrapper .box2-post-footer-auth {
  width: 88px;
  height: 44px;
  background: linear-gradient(90deg, #c35149 0%, #4587cf 100%);
  border-bottom-left-radius: 95px;
  border-bottom-right-radius: 95px;
  position: absolute;
  bottom: 0px;
}
section.blog-post-footer-wrapper .blog-post-footer .blog-post-footer-left .blog-post-footer-author-img-wrapper .blog-post-footer-auth-img {
  width: 84px;
  height: 84px;
  border-radius: 100%;
  z-index: 2;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
section.blog-post-footer-wrapper .blog-post-footer .blog-post-footer-left .blog-post-footer-author-info {
  display: flex;
  flex-direction: column;
}
section.blog-post-footer-wrapper .blog-post-footer .blog-post-footer-left .blog-post-footer-author-info p.blog-post-who {
  font-size: 14px;
  color: #000;
  font-family: 'GloberRegular';
  margin-bottom: 16px;
}
section.blog-post-footer-wrapper .blog-post-footer .blog-post-footer-left .blog-post-footer-author-info .blog-post-footer-author-name {
  font-family: 'GloberxBold';
  font-size: 14px;
  color: #8a73fb;
  text-transform: uppercase;
}
section.blog-post-footer-wrapper .blog-post-footer .blog-post-footer-left .blog-post-footer-author-info .blog-post-footer-author-quote {
  font-family: 'GloberRegular';
  font-size: 14px;
  color: #000;
}
section.blog-post-footer-wrapper .blog-post-footer .blog-post-footer-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
section.blog-post-footer-wrapper .blog-post-footer .blog-post-footer-right .blog-post-footer-share-text {
  color: #000;
  margin: 0px 6px;
  font-family: 'GloberRegular';
  font-size: 14px;
  margin-bottom: 20px;
}
section.blog-post-footer-wrapper .blog-post-footer .blog-post-footer-right ul.blog-post-footer-share-icons {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  margin: 0px;
}
section.blog-post-footer-wrapper .blog-post-footer .blog-post-footer-right ul.blog-post-footer-share-icons li.blog-post-footer-share-single-icon {
  width: 38px;
  height: 38px;
  margin: 0px 6px;
  background: #fff;
  border-radius: 38px;
  position: relative;
}
section.blog-post-footer-wrapper .blog-post-footer .blog-post-footer-right ul.blog-post-footer-share-icons li.blog-post-footer-share-single-icon a {
  position: absolute;
  font-size: 0px;
  width: 38px;
  height: 38px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}
@media (max-width: 1440px) {
  section.blog-post-footer-wrapper {
    width: 100%;
    overflow: hidden;
    padding-bottom: 20px;
  }
  section.blog-post-footer-wrapper .blog-post-footer {
    max-width: 768px;
  }
}
@media (max-width: 768px) {
  section.blog-post-footer-wrapper .blog-post-footer {
    padding-left: 20px;
    padding-right: 20px;
    height: auto;
    flex-direction: column;
  }
  section.blog-post-footer-wrapper .blog-post-footer .blog-post-footer-right {
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 20px 0px;
  }
  section.blog-post-footer-wrapper .blog-post-footer .blog-post-footer-right .blog-post-footer-share-text {
    margin: 0px;
  }
}
.video-blog-wrapper {
  width: 100%;
}
.video-blog-wrapper .video-blog-content-wrapper {
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  flex-flow: column;
}
@media (max-width: 1440px) {
  .video-blog-wrapper .video-blog-content-wrapper {
    max-width: 768px;
  }
}
@media screen and (max-width: 768px) {
  .video-blog-wrapper .video-blog-content-wrapper {
    margin: 0 3%;
  }
}
.video-blog-wrapper .video-blog-content-wrapper .blog-video {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
}
.video-blog-wrapper .video-blog-content-wrapper .blog-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.video-blog-wrapper .video-blog-content-wrapper .blog-video-description {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  font-family: 'GloberBook';
  color: #04232f;
}
.wrapper-for-small-image {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 960px;
}
@media (max-width: 1440px) {
  .wrapper-for-small-image {
    max-width: 768px;
  }
}
@media screen and (max-width: 768px) {
  .wrapper-for-small-image {
    padding: 0 20px;
  }
}
.wrapper-for-small-image a {
  cursor: pointer;
  text-decoration: none;
}
.wrapper-for-small-image .image {
  max-width: 100%;
  width: 100%;
  margin: 0px auto;
}
.wrapper-for-small-image .small-image-description {
  padding: 10px 0;
  font-family: 'GloberBook';
  width: 100%;
  text-align: center;
  color: #04232f;
}
@media (max-width: 768px) {
  section.photo-or-gallery-wrapper .photo-or-gallery {
    max-width: 100%;
    padding: 0px 20px;
  }
}
.jumbotron-contact {
  background-color: #04232f;
}
.jumbotron-contact .container-contact {
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 1440px) {
  .jumbotron-contact .container-contact {
    margin: 0 10%;
  }
}
@media screen and (max-width: 768px) {
  .jumbotron-contact .container-contact {
    margin: 0 3%;
  }
}
@media mabi {
  .jumbotron-contact .container-contact {
    margin: 0 6%;
  }
}
@media (max-height: 250px) {
  .jumbotron-contact .container-contact {
    min-height: 200px;
    padding: 0;
  }
}
.jumbotron-contact .container-contact a {
  text-decoration: none;
  color: #fff;
  font-size: calc(20px + (40 - 20) * ((100vw - 300px) / (1440 - 300)));
  width: 80%;
  display: flex;
  flex-direction: column;
  font-family: 'GloberHeavy' !important;
  position: relative;
  padding: 10px 0;
  cursor: pointer;
  margin: 0;
}
@media (max-height: 715px) {
  .jumbotron-contact .container-contact a {
    padding: 5px 0;
  }
}
@media (max-height: 278px) {
  .jumbotron-contact .container-contact a {
    font-size: 10px;
    padding: 10px 0 0 0;
  }
}
@media screen and (max-width: 375px) {
  .jumbotron-contact .container-contact a {
    width: 100%;
  }
}
.jumbotron-contact .container-contact a .question {
  color: #fff;
}
.jumbotron-contact .container-contact a .question p {
  color: #fff;
  padding: 0;
  border: 0;
  margin: 10px 0 0 0;
}
@media (max-height: 715px) {
  .jumbotron-contact .container-contact a .question p {
    margin: 30px 0 0 0;
  }
}
.jumbotron-contact .container-contact a .question::before {
  content: '';
  display: block;
  margin: 0 0 20px 0;
  width: 50px;
  height: 5px;
  background-color: #8a73fb;
  position: absolute;
  left: 0;
  top: 15px;
  z-index: 5;
}
.jumbotron-contact .container-contact a .question::after {
  content: '';
  display: block;
  margin: 0 0 20px 0;
  width: 100%;
  height: 1px;
  background-color: #203b46;
  position: absolute;
  left: 0;
  top: 17px;
}
.jumbotron-contact .container-contact a .question:hover {
  border: none;
  padding: 0 0 0 60px;
}
@media screen and (max-width: 375px) {
  .jumbotron-contact .container-contact a .question:hover {
    padding: 0;
  }
}
.jumbotron-contact .container-contact a .question:hover::before {
  left: 40px;
  background-color: #fff;
}
@media screen and (max-width: 375px) {
  .jumbotron-contact .container-contact a .question:hover::before {
    left: 0;
  }
}
.jumbotron-contact .container-contact a .question:hover::after {
  left: 40px;
}
@media screen and (max-width: 375px) {
  .jumbotron-contact .container-contact a .question:hover::after {
    left: 0;
  }
}
@media (min-width: 768px) {
  .menu-under-jumbotron-contact.sticky {
    max-height: 85px;
  }
  .menu-under-jumbotron-contact.sticky .menu-purple-element {
    max-height: 85px;
  }
  .menu-under-jumbotron-contact.sticky .menu-purple-element a {
    max-height: 100%;
  }
  .menu-under-jumbotron-contact.sticky .menu-purple-element a .menu-element {
    max-height: 87px;
    justify-content: flex-start;
    align-items: center;
  }
  .menu-under-jumbotron-contact.sticky .menu-purple-element a .menu-element .img {
    justify-content: flex-start;
    align-items: center;
  }
  .menu-under-jumbotron-contact.sticky .menu-purple-element a .menu-element .img .svg {
    height: 43px;
    width: 43px;
  }
}
@media (max-width: 768px) {
  .menu-under-jumbotron-contact.sticky {
    display: none;
  }
}
.menu-under-jumbotron-contact {
  width: 100%;
  outline: none;
  padding: 0;
  margin: 0 0 3px 0;
  background-color: #8a73fb;
  min-height: 136px;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
}
@media (max-width: 768px) {
  .menu-under-jumbotron-contact {
    height: auto;
    max-height: auto;
    position: static;
  }
}
@media (max-width: 450px) {
  .menu-under-jumbotron-contact {
    position: static;
  }
}
@media (max-width: 654px) {
  .menu-under-jumbotron-contact {
    display: none;
  }
}
.menu-under-jumbotron-contact .menu-purple-element {
  max-width: 1400px;
  margin: 0 auto;
  display: grid;
  column-gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-template-rows: 135px;
  row-gap: 0;
  height: 136px;
}
@media (max-width: 1440px) {
  .menu-under-jumbotron-contact .menu-purple-element {
    margin: 0 10%;
  }
}
@media screen and (max-width: 1024px) {
  .menu-under-jumbotron-contact .menu-purple-element {
    margin: 0 9%;
  }
}
@media screen and (max-width: 768px) {
  .menu-under-jumbotron-contact .menu-purple-element {
    margin: 0 3%;
    height: auto;
    max-height: auto !important;
  }
}
@media (max-width: 654px) {
  .menu-under-jumbotron-contact .menu-purple-element {
    padding: 10px 0;
  }
}
@media screen and (max-width: 375px) {
  .menu-under-jumbotron-contact .menu-purple-element {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 0.5fr);
    margin: 0 3%;
    row-gap: 10px;
  }
}
.menu-under-jumbotron-contact .menu-purple-element a {
  height: 100%;
  display: flex;
}
@media screen and (max-width: 768px) {
  .menu-under-jumbotron-contact .menu-purple-element a {
    height: 104%;
  }
}
.menu-under-jumbotron-contact .menu-purple-element a .menu-element {
  display: flex;
  height: 103%;
  flex-flow: row;
  column-gap: 30px;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 375px) {
  .menu-under-jumbotron-contact .menu-purple-element a .menu-element {
    height: 100%;
  }
}
.menu-under-jumbotron-contact .menu-purple-element a .menu-element:hover {
  cursor: pointer;
  border-bottom: 3px solid #fff;
}
.menu-under-jumbotron-contact .menu-purple-element a .menu-element:hover .text p {
  color: #fff;
  font-family: 'GloberxBold';
}
.menu-under-jumbotron-contact .menu-purple-element a .menu-element .img {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px 0 0;
}
@media screen and (max-width: 768px) {
  .menu-under-jumbotron-contact .menu-purple-element a .menu-element .img {
    padding: 0 30px 0 0;
  }
}
@media screen and (max-width: 375px) {
  .menu-under-jumbotron-contact .menu-purple-element a .menu-element .img {
    padding: 0 15px 15px 0;
  }
}
.menu-under-jumbotron-contact .menu-purple-element a .menu-element .img .svg {
  width: 60px;
  height: 63px;
}
.menu-under-jumbotron-contact .menu-purple-element a .menu-element .img .svg-menu-size1 {
  width: 59px;
  height: 50px;
}
.menu-under-jumbotron-contact .menu-purple-element a .menu-element .img .svg-menu-size2 {
  width: 53px;
  height: 54px;
}
.menu-under-jumbotron-contact .menu-purple-element a .menu-element .img .svg-menu-size3 {
  width: 61px;
  height: 60px;
}
.menu-under-jumbotron-contact .menu-purple-element a .menu-element .img .svg-menu-size3 {
  width: 73px;
  height: 66px;
}
.menu-under-jumbotron-contact .menu-purple-element a .menu-element .text {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}
.menu-under-jumbotron-contact .menu-purple-element a .menu-element .text p {
  font-family: 'GloberRegular';
  color: #04232f;
  padding: 0;
  margin: 0;
}
.contact-elements-wrapper {
  width: 100%;
  min-height: 517px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 375px) {
  .contact-elements-wrapper {
    min-height: 370px;
  }
}
.contact-elements-wrapper .contact-wrapper {
  max-width: 752px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: grid;
  justify-content: center;
  grid-auto-columns: 1fr;
  grid-template-rows: 127px 127px 127px;
  min-height: 130px;
  column-gap: 0;
  padding: 0;
}
@media (max-width: 1440px) {
  .contact-elements-wrapper .contact-wrapper {
    margin: 0 10%;
  }
}
@media screen and (max-width: 1024px) {
  .contact-elements-wrapper .contact-wrapper {
    margin: 0 9%;
  }
}
@media screen and (max-width: 768px) {
  .contact-elements-wrapper .contact-wrapper {
    margin: 0 3%;
  }
}
@media (max-width: 692px) {
  .contact-elements-wrapper .contact-wrapper {
    column-gap: 30px;
    padding: 15px 0 0 0;
  }
}
@media screen and (max-width: 375px) {
  .contact-elements-wrapper .contact-wrapper {
    margin: 0 3%;
  }
}
.contact-elements-wrapper .contact-wrapper .contact-elements {
  display: flex;
  flex-flow: row;
  width: 100%;
  padding: 10px 0;
  align-items: center;
}
@media (max-width: 534px) {
  .contact-elements-wrapper .contact-wrapper .contact-elements {
    align-items: center;
  }
}
.contact-elements-wrapper .contact-wrapper .contact-elements .img {
  width: 95px;
  height: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #8a73fb;
  margin: 3px 100px 0 0;
  color: #fff;
}
@media (max-width: 692px) {
  .contact-elements-wrapper .contact-wrapper .contact-elements .img {
    margin: 0;
  }
}
@media (max-width: 534px) {
  .contact-elements-wrapper .contact-wrapper .contact-elements .img {
    width: 80px;
    height: 80px;
  }
}
.contact-elements-wrapper .contact-wrapper .contact-elements .img .svg {
  width: 35px;
  fill: #fff !important;
}
.contact-elements-wrapper .contact-wrapper .contact-elements .img .svg,
.contact-elements-wrapper .contact-wrapper .contact-elements .img .svg * {
  fill: #fff !important;
}
.contact-elements-wrapper .contact-wrapper .contact-elements .img .svg-size1 {
  width: 35px;
  height: 35px;
}
.contact-elements-wrapper .contact-wrapper .contact-elements .img .svg-size2 {
  width: 43px;
  height: 37px;
}
.contact-elements-wrapper .contact-wrapper .contact-elements .img .svg-size3 {
  width: 57px;
  height: 44px;
}
.contact-elements-wrapper .contact-wrapper .contact-elements .text {
  display: flex;
  flex-flow: column;
  justify-content: center;
}
@media (max-width: 692px) {
  .contact-elements-wrapper .contact-wrapper .contact-elements .text {
    padding: 0 0 0 50px;
  }
}
@media screen and (max-width: 375px) {
  .contact-elements-wrapper .contact-wrapper .contact-elements .text {
    padding: 0 0 0 25px;
  }
}
.contact-elements-wrapper .contact-wrapper .contact-elements .text .text-regular-elements {
  color: #04232f;
  font-family: 'GloberxBold';
  line-height: 1;
  font-size: 18px;
}
.contact-elements-wrapper .contact-wrapper .contact-elements .text .text-bold-elements {
  font-size: 48px;
  line-height: 1;
  color: #04232f;
  font-family: 'GloberxBold';
}
@media screen and (max-width: 768px) {
  .contact-elements-wrapper .contact-wrapper .contact-elements .text .text-bold-elements {
    font-size: 36px;
  }
}
@media screen and (max-width: 375px) {
  .contact-elements-wrapper .contact-wrapper .contact-elements .text .text-bold-elements {
    font-size: 24px;
  }
}
@media (max-width: 350px) {
  .contact-elements-wrapper .contact-wrapper .contact-elements .text .text-bold-elements {
    font-size: 18px;
  }
}
.contact-elements-wrapper .contact-wrapper .contact-elements .text #element3 {
  cursor: pointer;
  border-bottom: 2px solid #04232f;
}
.contact-us {
  width: 100%;
  min-height: 440px;
  background-color: #e0e0e0;
  padding: 50px 0;
}
@media screen and (max-width: 768px) {
  .contact-us {
    padding: 10px 0;
  }
}
.contact-us .contact-us-wrapper {
  max-width: 912px;
  border: none;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  position: relative;
  padding: 40px 0;
}
@media (max-width: 1440px) {
  .contact-us .contact-us-wrapper {
    margin: 0 10%;
  }
}
@media screen and (max-width: 768px) {
  .contact-us .contact-us-wrapper {
    margin: 0 3%;
    grid-template-columns: 1fr;
    row-gap: 50px;
  }
}
@media mabi {
  .contact-us .contact-us-wrapper {
    margin: 0 6%;
  }
}
.contact-us .contact-us-wrapper:before {
  content: '';
  height: 2px;
  width: 40px;
  background: #8a73fb;
  position: absolute;
  top: 22%;
  left: 0;
}
@media screen and (max-width: 768px) {
  .contact-us .contact-us-wrapper:before {
    top: 10%;
  }
}
.contact-us .contact-us-wrapper .contact-us-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .contact-us .contact-us-wrapper .contact-us-text {
    justify-content: flex-start;
  }
}
.contact-us .contact-us-wrapper .contact-us-text p {
  font-family: 'GloberRegular';
  color: #04232f;
  padding: 0;
}
@media (max-width: 928px) {
  .contact-us .contact-us-wrapper .contact-us-text p {
    padding: 50px 0 0 0;
  }
}
.contact-us .contact-us-wrapper .contact-form-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  flex-flow: column;
  border: none;
}
.contact-us .contact-us-wrapper .contact-form-wrapper .contact-form {
  width: 80%;
  max-width: 636px;
  text-align: center;
  display: grid;
  grid-template-columns: 225px 225px;
  grid-template-rows: 1fr;
  column-gap: 38px;
  margin: 0 0 30px 0;
}
@media screen and (max-width: 768px) {
  .contact-us .contact-us-wrapper .contact-form-wrapper .contact-form {
    grid-template-columns: 1fr 1fr;
    max-width: 100%;
    width: 100%;
  }
}
.contact-us .contact-us-wrapper .contact-form-wrapper .contact-form input {
  font-size: 12px;
  outline: none;
  border: none;
  border-bottom: 2px solid #fff;
  background-color: transparent;
  line-height: 2;
  text-align: center;
}
.contact-us .contact-us-wrapper .contact-form-wrapper .contact-form input:focus {
  border-bottom: 2px solid #8a73fb;
  color: #04232f;
}
.contact-us .contact-us-wrapper .contact-form-wrapper .contact-form input input::placeholder {
  color: #afafaf;
  text-align: center;
  font-family: 'GloberRegular';
  font-size: 12px;
}
.contact-us .contact-us-wrapper .contact-form-wrapper .contact-form input:-webkit-autofill,
.contact-us .contact-us-wrapper .contact-form-wrapper .contact-form input:-webkit-autofill:hover,
.contact-us .contact-us-wrapper .contact-form-wrapper .contact-form input:-webkit-autofill:focus {
  border-bottom: 2px solid #fff;
  text-align: center;
  -webkit-text-fill-color: #04232f;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}
.contact-us .contact-us-wrapper .contact-form-wrapper .contact-form-second {
  width: 80%;
  max-width: 636px;
  text-align: center;
  display: grid;
  column-gap: 38px;
  grid-template-columns: 488px;
}
@media screen and (max-width: 768px) {
  .contact-us .contact-us-wrapper .contact-form-wrapper .contact-form-second {
    grid-template-columns: 1fr;
    max-width: 100%;
    width: 100%;
  }
}
.contact-us .contact-us-wrapper .contact-form-wrapper .contact-form-second textarea {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 0;
  border-bottom: 2px solid #fff;
  text-align: center;
  margin: 0 0 30px 0;
  font-size: 12px;
}
.contact-us .contact-us-wrapper .contact-form-wrapper .contact-form-second textarea:focus {
  border-bottom: 2px solid #8a73fb;
  color: #04232f;
}
.contact-us .contact-us-wrapper .contact-form-wrapper .contact-form-second textarea textarea::placeholder {
  font-size: 12px;
  color: #afafaf;
  text-align: center;
  font-family: 'GloberRegular';
}
.contact-us .contact-us-wrapper .contact-form-wrapper .contact-form-third {
  width: 80%;
  max-width: 636px;
  text-align: center;
  display: grid;
  grid-template-columns: 473px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .contact-us .contact-us-wrapper .contact-form-wrapper .contact-form-third {
    grid-template-columns: 1fr;
    max-width: 100%;
    width: 100%;
  }
}
.contact-us .contact-us-wrapper .contact-form-wrapper .contact-form-third .input-radio {
  padding: 0 0 0 30px;
  display: flex;
  flex-flow: row;
  justify-content: center;
  position: relative;
}
.contact-us .contact-us-wrapper .contact-form-wrapper .contact-form-third .input-radio .defoult-checkbox {
  position: absolute;
  top: 4px;
  left: 3px;
}
.contact-us .contact-us-wrapper .contact-form-wrapper .contact-form-third .input-radio .rodo {
  position: relative;
  color: #04232f;
  text-align: justify;
  padding: 0 0 0 10px;
  width: 100%;
  font-size: 12px;
}
.contact-us .contact-us-wrapper .contact-form-wrapper .contact-form-third .input-radio .rodo a {
  text-decoration: none;
  color: #8a73fb;
}
.contact-us .contact-us-wrapper .contact-form-wrapper .form-button {
  width: 80%;
  text-align: center;
  display: grid;
  grid-template-columns: 225px 225px;
  grid-template-rows: 1fr;
  column-gap: 95px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .contact-us .contact-us-wrapper .contact-form-wrapper .form-button {
    grid-template-columns: 1fr;
    column-gap: 30px;
    max-width: 100%;
    width: 100%;
  }
}
@media (max-width: 519px) {
  .contact-us .contact-us-wrapper .contact-form-wrapper .form-button {
    grid-template-columns: 1fr;
  }
}
.contact-us .contact-us-wrapper .contact-form-wrapper .form-button .recaptcha {
  border: none;
  margin: 0 37px 0 0;
}
@media screen and (max-width: 768px) {
  .contact-us .contact-us-wrapper .contact-form-wrapper .form-button .recaptcha {
    margin: 0 0 20px 0;
  }
}
.contact-us .contact-us-wrapper .contact-form-wrapper .form-button .button-violet {
  cursor: pointer;
  width: 171px;
  color: #fff;
  font-family: 'globersemibold';
  margin: 0;
  padding: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px 5px 0px 0px #bcbcbc;
  -moz-box-shadow: 0px 5px 0px 0px #bcbcbc;
  box-shadow: 0px 5px 0px 0px #bcbcbc;
}
.contact-us .contact-us-wrapper .contact-form-wrapper .form-button .button-violet .button-elements {
  width: 100%;
  display: block;
  text-align: center;
}
.contact-us .contact-us-wrapper .contact-form-wrapper .form-button .button-violet .button-elements .arrow-on-price-button {
  width: 6px;
  height: 9px;
  margin: -4px 11px 0 0;
}
.contact-us .contact-us-wrapper .contact-form-wrapper .form-button .button-violet .button-elements .arrow-on-price-button img {
  width: 6px;
  height: 16px;
}
input[type=checkbox] {
  opacity: 1;
  cursor: pointer;
  position: relative;
  top: 0px;
  left: -13px;
}
@media screen and (max-width: 768px) {
  input[type=checkbox] {
    left: 0px;
    top: -2px;
  }
}
.check-contact-us {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 19px;
  width: 19px;
  background-color: #e0e0e0;
  border: 1px solid #fff;
  border-radius: 2px;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .check-contact-us {
    left: 0px;
    top: 2px;
  }
}
.check-contact-us .crossOne {
  visibility: hidden;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 19px;
  height: 19px;
  z-index: 100;
}
.check-contact-us .crossOne .fa-times {
  color: #8a73fb;
  position: absolute;
  top: 0px;
  left: 2px;
  font-size: 17px;
}
.contact-you {
  width: 100%;
  min-height: 440px;
  background-color: #04232f;
  padding: 50px 0;
}
@media screen and (max-width: 768px) {
  .contact-you {
    padding: 0;
  }
}
.contact-you .contact-you-wrapper {
  max-width: 752px;
  margin: 0 auto;
  position: relative;
  padding: 50px 0;
  display: flex;
  flex-flow: row;
}
@media (max-width: 829px) {
  .contact-you .contact-you-wrapper {
    margin: 0 3%;
    flex-flow: column;
  }
}
@media mabi {
  .contact-you .contact-you-wrapper {
    margin: 0 6%;
  }
}
.contact-you .contact-you-wrapper .left-part .header {
  font-family: 'GloberxBold';
}
@media screen and (max-width: 375px) {
  .contact-you .contact-you-wrapper .left-part .header {
    font-size: 35px !important;
  }
}
.contact-you .contact-you-wrapper .left-part .header p {
  font-family: 'GloberxBold';
  margin: 0 0 35px 0;
}
.contact-you .contact-you-wrapper .left-part .paragraph {
  font-family: 'GloberRegular';
  margin: 0 0 50px 0;
  width: 85%;
}
@media (max-width: 829px) {
  .contact-you .contact-you-wrapper .left-part .paragraph {
    width: 100%;
  }
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper {
  width: 80%;
  display: flex;
  align-items: center;
  flex-flow: column;
  margin: 0;
}
@media (max-width: 829px) {
  .contact-you .contact-you-wrapper .left-part .contact-form-wrapper {
    width: 100%;
    margin: 0 0 50px 0;
  }
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-one {
  width: 100%;
  text-align: center;
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: 225px 225px;
  column-gap: 38px;
  grid-template-rows: 1fr;
  margin: 0 0 50px 0;
}
@media (max-width: 829px) {
  .contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-one {
    grid-template-columns: 1fr 1fr;
  }
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-one input {
  font-size: 12px;
  outline: none;
  border: none;
  border-bottom: 2px solid #fff;
  background-color: transparent;
  border-radius: 0 !important;
  text-align: center;
  color: #afafaf;
  line-height: 2;
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-one input:focus {
  border-bottom: 2px solid #8a73fb;
  color: #8a73fb;
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-one input input::placeholder {
  font-size: 12px;
  color: #afafaf;
  text-align: center;
  font-family: 'GloberRegular';
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-one input:-webkit-autofill,
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-one input:-webkit-autofill:hover,
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-one input:-webkit-autofill:focus {
  text-align: center;
  -webkit-text-fill-color: #afafaf;
  -webkit-box-shadow: 0 0 0px 1000px #04232f inset;
  transition: background-color 5000s ease-in-out 0s;
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-one .termin {
  outline: none;
  border-radius: none;
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-one .termin:focus {
  outline: none;
  border-radius: none;
  border-bottom: 2px solid #8a73fb;
  color: #8a73fb;
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-one .termin:active {
  outline: none;
  border-radius: none;
  border: none;
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-one .termin:hover {
  outline: none;
  border-radius: none;
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-two {
  width: 100%;
  text-align: center;
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: 225px 225px;
  column-gap: 38px;
  grid-template-rows: 1fr;
  margin: 0 0 50px 0;
}
@media (max-width: 829px) {
  .contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-two {
    grid-template-columns: 1fr 1fr;
  }
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-two textarea {
  font-size: 12px;
  outline: none;
  border: none;
  border-bottom: 2px solid #fff;
  background-color: transparent;
  text-align: center;
  color: #afafaf;
  line-height: 2;
  border-radius: 0 !important;
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-two textarea:focus {
  border-bottom: 2px solid #8a73fb;
  color: #8a73fb;
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-two textarea::placeholder {
  font-size: 12px;
  color: #afafaf;
  text-align: center;
  font-family: 'GloberRegular';
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-two #from-date {
  background-color: transparent;
  width: 100%;
  color: #afafaf;
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-third {
  width: 100%;
  max-width: 636px;
  display: grid;
  grid-template-columns: 490px;
  grid-template-rows: 1fr;
  position: relative;
}
@media (max-width: 829px) {
  .contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-third {
    max-width: 100%;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
  }
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-third #cross-element {
  display: flex;
  flex-flow: row;
  justify-content: center;
  position: relative;
  border: none;
  width: 100%;
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-third #cross-element .defoult-checkbox {
  position: absolute;
  top: 4px;
  left: 3px;
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-third #cross-element .rodo {
  color: #fff;
  text-align: justify;
  padding: 0 0 0 40px;
  font-size: 12px;
}
@media (max-width: 829px) {
  .contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-third #cross-element .rodo {
    padding: 0 0 0 32px;
    width: 100%;
  }
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-third #cross-element .rodo p {
  width: 100%;
  font-size: 12px;
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .contact-form-third #cross-element a {
  text-decoration: none;
  color: #8a73fb;
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .form-button {
  width: 100%;
  text-align: center;
  display: grid;
  grid-template-columns: 225px 225px;
  grid-template-rows: 1fr;
  column-gap: 95px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .contact-you .contact-you-wrapper .left-part .contact-form-wrapper .form-button {
    grid-template-columns: 1fr;
  }
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .form-button .recaptcha {
  border: none;
  outline: none;
  margin: 0 37px 0 0;
}
@media screen and (max-width: 768px) {
  .contact-you .contact-you-wrapper .left-part .contact-form-wrapper .form-button .recaptcha {
    margin: 0 0 30px 0;
  }
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .form-button .button-violet {
  cursor: pointer;
  width: 171px;
  color: #fff;
  font-family: 'globersemibold';
  margin: 0;
  padding: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px 3px 0px 0px #0b171c;
  -moz-box-shadow: 0px 3px 0px 0px #0b171c;
  box-shadow: 0px 3px 0px 0px #0b171c;
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .form-button .button-violet .button-elements {
  width: 100%;
  display: block;
  text-align: center;
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .form-button .button-violet .button-elements .arrow-on-price-button {
  width: 6px;
  height: 9px;
  margin: -4px 11px 0 0;
}
.contact-you .contact-you-wrapper .left-part .contact-form-wrapper .form-button .button-violet .button-elements .arrow-on-price-button img {
  width: 6px;
  height: 16px;
}
.contact-you .contact-you-wrapper .contact-you-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-you .contact-you-wrapper .contact-you-image .contact-circle-wrapper {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
@media (max-width: 829px) {
  .contact-you .contact-you-wrapper .contact-you-image .contact-circle-wrapper {
    align-items: flex-start;
  }
}
.contact-you .contact-you-wrapper .contact-you-image .contact-circle-wrapper .contact-circle-img-outline {
  width: 190px;
  height: 190px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
}
.contact-you .contact-you-wrapper .contact-you-image .contact-circle-wrapper .contact-circle-img-outline .box-contact {
  width: 190px;
  height: 96px;
  background: linear-gradient(97deg, #7565d6 0%, #4587cf 100%);
  border-top-left-radius: 95px;
  border-top-right-radius: 95px;
  position: absolute;
  top: 0px;
}
.contact-you .contact-you-wrapper .contact-you-image .contact-circle-wrapper .contact-circle-img-outline .box2-contact {
  width: 190px;
  height: 95px;
  background: linear-gradient(90deg, #c35149 0%, #4587cf 100%);
  border-bottom-left-radius: 95px;
  border-bottom-right-radius: 95px;
  position: absolute;
  bottom: 0px;
}
.contact-you .contact-you-wrapper .contact-you-image .contact-circle-wrapper .contact-circle-img-outline .contact-coord-img {
  width: 180px;
  height: 180px;
  border-radius: 100%;
  z-index: 2;
  background-color: #fff;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.contact-you .contact-you-wrapper .contact-you-image .contact-circle-wrapper .contact-coord-text .contact-coord-text-h3 {
  font-family: 'GloberxBold';
  margin: 20px 0px;
}
.contact-you .contact-you-wrapper .contact-you-image .contact-circle-wrapper .contact-coord-text .contact-coord-text-p {
  font-family: 'GloberRegular';
  line-height: 29px;
  margin-bottom: 20px;
}
.contact-you .contact-you-wrapper .contact-you-image .contact-circle-wrapper .contact-coord-contact h5 {
  font-family: 'GloberxBold';
  color: #8a73fb;
}
.contact-you .contact-you-wrapper .contact-you-image .contact-circle-wrapper .contact-coord-contact p {
  font-family: 'GloberRegular';
  margin: 0px;
}
input[type=checkbox] {
  opacity: 1;
  cursor: pointer;
  position: absolute;
  top: 2.5px;
  left: 0px;
}
@media screen and (max-width: 768px) {
  input[type=checkbox] {
    left: 0px;
  }
}
.check-we-contact-you {
  position: absolute;
  top: 1px;
  left: 0px;
  height: 19px;
  width: 19px;
  background-color: #04232f;
  border: 1px solid #fff;
  border-radius: 2px;
  cursor: pointer;
}
.check-we-contact-you .crossTwo {
  visibility: hidden;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 19px;
  height: 19px;
  z-index: 100;
}
.check-we-contact-you .crossTwo .fa-times {
  color: #8a73fb;
  position: absolute;
  top: 0px;
  left: 2px;
  font-size: 17px;
}
.map-elements-wrapper {
  position: fixed;
  width: 100%;
  min-height: 600px;
  max-height: 1500px;
  background-color: #fff;
  position: relative;
  padding: 100px 0 0 0;
}
.map-elements-wrapper .emement-map-wrapper {
  max-width: 912px;
  min-height: 100%;
  margin: 0 auto;
  align-items: center;
  display: flex;
}
@media (max-width: 1440px) {
  .map-elements-wrapper .emement-map-wrapper {
    margin: 0 10%;
  }
}
@media screen and (max-width: 768px) {
  .map-elements-wrapper .emement-map-wrapper {
    margin: 0 3%;
  }
}
@media (max-width: 500px) {
  .map-elements-wrapper .emement-map-wrapper {
    flex-flow: column;
  }
}
@media mabi {
  .map-elements-wrapper .emement-map-wrapper {
    margin: 0 6%;
  }
}
.map-elements-wrapper .emement-map-wrapper .text {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  line-height: 1;
}
@media (max-width: 850px) {
  .map-elements-wrapper .emement-map-wrapper .text {
    display: none;
  }
}
.map-elements-wrapper .emement-map-wrapper .text h1 p {
  font-family: 'GloberxBold';
  color: #04232f;
  padding: 0;
}
.map-elements-wrapper .emement-map-wrapper .text p {
  font-family: 'GloberRegular';
  color: #04232f;
}
.map-elements-wrapper .emement-map-wrapper .text .form-button {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
}
@media (max-width: 1142px) {
  .map-elements-wrapper .emement-map-wrapper .text .form-button {
    flex-flow: column;
  }
}
.map-elements-wrapper .emement-map-wrapper .text .form-button .button-violet {
  width: 226px;
  color: #fff;
  font-family: 'globersemibold';
  margin: 10px 0 0 0;
  padding: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px 5px 5px 0px #bcbcbc;
  -moz-box-shadow: 0px 5px 5px 0px #bcbcbc;
  box-shadow: 0px 5px 5px 0px #bcbcbc;
}
.map-elements-wrapper .emement-map-wrapper .text .form-button .button-violet .button-elements {
  width: 100%;
  display: block;
  text-align: center;
}
.map-elements-wrapper .emement-map-wrapper .text .form-button .button-violet .button-elements .arrow-on-price-button {
  width: 6px;
  height: 9px;
  margin: -4px 11px 0 0;
}
.map-elements-wrapper .emement-map-wrapper .text .form-button .button-violet .button-elements .arrow-on-price-button img {
  width: 6px;
  height: 16px;
}
.map-elements-wrapper .map-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  min-height: 600px;
  max-height: 1500px;
}
@media (max-width: 850px) {
  .map-elements-wrapper .map-wrapper {
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
.map-elements-wrapper .map-wrapper #map {
  width: 100%;
  height: 600px;
}
.map-elements-wrapper2 {
  width: 100%;
  background-color: #fff;
  position: relative;
  display: none;
}
@media (max-width: 850px) {
  .map-elements-wrapper2 {
    display: flex;
  }
}
.map-elements-wrapper2 .emement-map-wrapper2 {
  max-width: 1045px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 0;
  display: flex;
}
@media (max-width: 1440px) {
  .map-elements-wrapper2 .emement-map-wrapper2 {
    margin: 0 10%;
  }
}
@media screen and (max-width: 768px) {
  .map-elements-wrapper2 .emement-map-wrapper2 {
    margin: 0 3%;
  }
}
@media mabi {
  .map-elements-wrapper2 .emement-map-wrapper2 {
    margin: 0 6%;
  }
}
.map-elements-wrapper2 .emement-map-wrapper2 .text {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.map-elements-wrapper2 .emement-map-wrapper2 .text h1 {
  margin: 0 0 50px 0;
}
.map-elements-wrapper2 .emement-map-wrapper2 .text h1 p {
  font-family: 'GloberxBold';
  color: #04232f;
}
.map-elements-wrapper2 .emement-map-wrapper2 .text p {
  font-family: 'GloberRegular';
  color: #04232f;
  margin: 0;
}
.map-elements-wrapper2 .emement-map-wrapper2 .text .form-button {
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: center;
  cursor: pointer;
}
@media (max-width: 1142px) {
  .map-elements-wrapper2 .emement-map-wrapper2 .text .form-button {
    flex-flow: column;
  }
}
@media screen and (max-width: 768px) {
  .map-elements-wrapper2 .emement-map-wrapper2 .text .form-button {
    margin: 50px 0 0 0;
  }
}
.map-elements-wrapper2 .emement-map-wrapper2 .text .form-button .button-violet {
  cursor: pointer;
  width: 226px;
  color: #fff;
  font-family: 'GloberxBold';
  margin: 10px 0 0 0;
  padding: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px 5px 5px 0px #bcbcbc;
  -moz-box-shadow: 0px 5px 5px 0px #bcbcbc;
  box-shadow: 0px 5px 5px 0px #bcbcbc;
}
.map-elements-wrapper2 .emement-map-wrapper2 .text .form-button .button-violet .button-elements {
  width: 100%;
  display: block;
  text-align: center;
  cursor: pointer;
}
.map-elements-wrapper2 .emement-map-wrapper2 .text .form-button .button-violet .button-elements .arrow-on-price-button {
  width: 6px;
  height: 9px;
  margin: -4px 11px 0 0;
}
.map-elements-wrapper2 .emement-map-wrapper2 .text .form-button .button-violet .button-elements .arrow-on-price-button img {
  width: 6px;
  height: 16px;
}
.social-media-wrapper {
  width: 100%;
  min-height: 361px;
  background-color: #e0e0e0;
  margin: 0 0 200px 0;
  position: relative;
  padding: 95px 0;
}
@media screen and (max-width: 375px) {
  .social-media-wrapper {
    margin: 0 0 110px 0;
  }
}
.social-media-wrapper .social-media-elements {
  max-width: 752px;
  margin: 0 auto;
  height: 100%;
}
@media (max-width: 1440px) {
  .social-media-wrapper .social-media-elements {
    margin: 0 10%;
  }
}
@media screen and (max-width: 768px) {
  .social-media-wrapper .social-media-elements {
    margin: 0 3%;
  }
}
@media (max-width: 500px) {
  .social-media-wrapper .social-media-elements {
    flex-flow: column;
  }
}
@media mabi {
  .social-media-wrapper .social-media-elements {
    margin: 0 6%;
  }
}
.social-media-wrapper .social-media-elements h1 {
  color: #04232f;
  font-family: 'GloberxBold';
  margin: 0 0 60px 0;
  padding: 0;
}
.social-media-wrapper .social-media-elements h1 p {
  padding: 0;
  margin: 0;
}
.social-media-wrapper .social-media-elements ul {
  list-style-type: none;
  display: flex;
  flex-flow: row;
}
.social-media-wrapper .social-media-elements ul li {
  padding: 0;
  margin: 0;
}
.social-media-wrapper .social-media-elements ul li a {
  padding: 0;
  margin: 0 10px 0 0;
  color: #fff !important;
}
.social-media-wrapper .social-media-elements ul li a .svg {
  width: 38px;
  height: 38px;
  fill: #fff !important;
}
.social-media-wrapper .social-media-elements ul li a .svg,
.social-media-wrapper .social-media-elements ul li a .svg * {
  fill: #fff !important;
}
.social-media-wrapper .social-media-elements ul li:hover .svg,
.social-media-wrapper .social-media-elements ul li:hover .svg * {
  fill: #04232f !important;
}
.social-media-wrapper .instagram {
  margin: 0 auto;
  max-width: 1440px;
}
@media (max-width: 1440px) {
  .social-media-wrapper .instagram {
    margin: 0 10%;
  }
}
@media screen and (max-width: 768px) {
  .social-media-wrapper .instagram {
    margin: 0 3%;
  }
}
@media mabi {
  .social-media-wrapper .instagram {
    margin: 0 6%;
  }
}
.social-media-wrapper .instagram .button-element {
  display: flex;
  justify-content: flex-end;
}
@media screen and (max-width: 375px) {
  .social-media-wrapper .instagram .button-element {
    justify-content: flex-start;
  }
}
.social-media-wrapper .instagram .button-element .button-instagram {
  border: 1px solid #04232f;
  width: 225px;
  height: 32px;
  margin: 0;
  -webkit-box-shadow: 0px 5px 5px 0px #bcbcbc;
  -moz-box-shadow: 0px 5px 5px 0px #bcbcbc;
  box-shadow: 0px 5px 5px 0px #bcbcbc;
  color: #04232f;
  font-family: 'GloberxBold';
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 375px) {
  .social-media-wrapper .instagram .button-element .button-instagram {
    bottom: 60px;
    right: auto;
    left: 0;
  }
}
.social-media-wrapper .instagram .button-element .button-instagram a {
  display: block;
  width: 100%;
  height: 100%;
  line-height: 2.5;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: #04232f;
}
.social-media-wrapper .instagram .button-element .button-instagram a p {
  height: 100%;
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 0;
}
.social-media-wrapper #twitter-image-bar {
  height: 283px;
  position: absolute;
  bottom: -241px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  -webkit-column-gap: 10px;
  column-gap: 10px;
  overflow: hidden;
  outline: none;
  border: none;
}
@media screen and (max-width: 375px) {
  .social-media-wrapper #twitter-image-bar {
    column-gap: 5px;
    height: 141.5px !important;
    bottom: -100px;
  }
}
.social-media-wrapper #twitter-image-bar .owl-stage-outer {
  overflow: hidden;
  outline: none;
  border: none;
  width: 6000px;
}
.social-media-wrapper #twitter-image-bar .owl-stage-outer .owl-stage {
  overflow: hidden;
  outline: none;
  border: none;
}
.social-media-wrapper #twitter-image-bar .owl-stage-outer .owl-stage .owl-item {
  width: 283px !important;
  height: 100%;
  margin: 0 10px;
  outline: none;
  border: none;
}
@media screen and (max-width: 375px) {
  .social-media-wrapper #twitter-image-bar .owl-stage-outer .owl-stage .owl-item {
    width: 141.5px !important;
  }
}
.social-media-wrapper #twitter-image-bar .owl-stage-outer .owl-stage .owl-item .kard {
  outline: none;
  border: none;
}
.social-media-wrapper #twitter-image-bar .owl-stage-outer .owl-stage .owl-item .kard .wrapper {
  width: 100%;
  outline: none;
  border: none;
  height: 271px;
  position: relative;
  -webkit-box-shadow: 0 13px 0 0 rgba(0,0,0,0.2);
  box-shadow: 0 13px 0 0 rgba(0,0,0,0.2);
}
.social-media-wrapper #twitter-image-bar .owl-stage-outer .owl-stage .owl-item .kard .wrapper .cube1 {
  background: #8a73fb;
  background: linear-gradient(68deg, #8a73fb 0, #2cbad8 63%);
  position: absolute;
  top: 0;
  height: 50%;
  width: 100%;
  outline: none;
  border: none;
}
.social-media-wrapper #twitter-image-bar .owl-stage-outer .owl-stage .owl-item .kard .wrapper .cube2 {
  background: #eb594d;
  background: linear-gradient(68deg, #eb594d 0, #2cbad8 100%);
  position: absolute;
  bottom: 0;
  height: 50%;
  width: 100%;
  outline: none;
  border: none;
}
.social-media-wrapper #twitter-image-bar .owl-stage-outer .owl-stage .owl-item .kard .box-for-image {
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 3;
  height: 260px;
  outline: none;
  border: none;
  width: 99%;
}
.social-media-wrapper #twitter-image-bar .owl-stage-outer .owl-stage .owl-item .kard .box-for-image .image {
  background-color: #fff;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 266px;
  width: 100%;
  margin: 0;
  outline: none;
  border: none;
  border-width: 3px;
}
@media screen and (max-width: 375px) {
  .social-media-wrapper #twitter-image-bar .owl-stage-outer .owl-stage .owl-item .kard .box-for-image .image {
    height: 137px;
    width: 98%;
  }
}
.popup-wrapper {
  width: 100%;
  height: 100vh;
  background-color: rgba(4,35,47,0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
}
.popup-wrapper .popup-cotent {
  background-color: #e0e0e0;
  width: 590px;
  height: 305px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 20px;
  padding: 60px;
}
.popup-wrapper .popup-cotent .exit-cross {
  position: absolute;
  top: 6px;
  right: 7px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 1000;
  border: 1px solid #8a73fb;
  border-radius: 50px;
  background-color: #8a73fb;
}
.popup-wrapper .popup-cotent .exit-cross::before {
  position: absolute;
  right: 12px;
  top: 6px;
  content: '';
  height: 15px;
  width: 2.5px;
  background-color: #fff;
  transform: rotate(45deg);
}
.popup-wrapper .popup-cotent .exit-cross::after {
  position: absolute;
  right: 12px;
  top: 6px;
  content: '';
  height: 15px;
  width: 2.5px;
  background-color: #fff;
  transform: rotate(-45deg);
}
.popup-wrapper .popup-cotent .main-header {
  width: 100%;
}
.popup-wrapper .popup-cotent .main-header::before {
  content: '';
  display: block;
  margin: 0 0 50px 0;
  width: 50px;
  height: 2px;
  background-color: #8a73fb;
}
.popup-wrapper .popup-cotent .main-header .notice-header {
  line-height: 2;
}
.popup-wrapper .popup-cotent .main-header .notice-header h2 {
  width: 100%;
  color: #04232f;
  font-family: 'GloberxBold';
}
.popup-wrapper .popup-cotent .main-header .notice-header .notice {
  color: #04232f;
  font-family: 'GloberRegular';
}
.error {
  display: flex;
  flex-flow: column;
  padding: 0;
  color: #f00;
}
