section.similar-articles-wrapper
    width: 100%
    overflow: hidden
    padding: 20px 0px
    ul.similar-articles
        max-width: 768px
        margin: 0px auto
        list-style-type: none
        display: flex
        flex-direction: row
        li.article-pattern
            width: 365px
            height: 385px
            margin: 0px 6.5px
            padding: 35px 30px 40px 30px
            border-bottom-left-radius: 25px
            border: 2px solid pleo-fiolet
            display: flex
            flex-direction: column
            justify-content: space-between
            background: #FFF
            color: #000
            cursor: pointer
            &:hover
                background: pleo-fiolet
                .pattern-main
                    .pattern-header
                        .pattern-category
                            color: #fff
                        .pattern-author
                            color: #fff
            .pattern-main
                display: flex
                flex-direction: column
                .pattern-header
                    display: flex
                    flex-direction: column
                    padding-bottom: 28px
                    .pattern-category
                        text-transform: uppercase
                        font-size: 12px
                        font-family: 'GloberRegular'
                        color: pleo-fiolet
                        padding-bottom: 7px
                    .pattern-author
                        text-transform: uppercase
                        font-size: 14px
                        font-family: 'GloberxBold'
                        color: pleo-fiolet
                .pattern-main-text
                    font-family: 'GloberxBold'
                    font-size: 24px
                    line-height: 31px
                    padding-top: 54px
                    position: relative
                    width: 95%
                    &:after
                        content: ''
                        position: absolute
                        width: 100px
                        height: 7px
                        background: #000
                        top: 0
                        left: 0
            ul.pattern-footer
                list-style-type: none
                display: flex
                flex-direction: row
                max-width: 80%
                flex-wrap: wrap
                li.pattern-hashtag
                    font-family: 'GloberRegular'
                    font-size: 12px
                    padding: 0px 7px
                    
@media (max-width: 768px)
   section.similar-articles-wrapper
    ul.similar-articles
        flex-direction: column
        align-items: center
        li.article-pattern
            margin: 20px

@media (max-width: 420px)
   section.similar-articles-wrapper
    ul.similar-articles
        flex-direction: column
        align-items: center
        li.article-pattern
            width: 315px
            .pattern-main
                .pattern-main-text
                    width: 100%





