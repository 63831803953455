.background-white
    background-color: #ffffff
    .module-title
        header.module-header-title
            .section-number
                .nr-text
                    .nr-name 
                        color: pleo-dark-green 
            h2
                color: pleo-dark-green
    .opis-wrapper
        .opis-content
            p
                color: pleo-dark-green

.background-fiolet
    background-color: #8a73fb
    .module-title
        header.module-header-title
            .section-number
                .line
                    &:after
                        background-color: pleo-dark-green
                .nr-text
                    .nr-number
                        color: pleo-dark-green
                    .nr-name 
                        color: #ffffff
            h2
                color: #ffffff 