.how-to-use
    height: 100%
    width: 100%
    padding: 0 0 100px 0
    margin: 0
    background-image: linear-gradient(to bottom, white 55%, #8a73fb 45%)
    @media mobi 
        padding: 0
    .general-wrapper
        max-width: 1400px
        margin: 0 auto
        display: flex
        flex-flow: column
        height: 100%
        @media (max-width: 1440px)
            margin: 0 10%
        @media scr1024
            margin: 0
        @media tablet
            margin: 0 3%
        @media mobi
            margin: 0 6%
        .container
            grid-template-columns: 1fr 1fr 1fr
            grid-template-rows: 1fr 1fr
            grid-column-gap: 30px
            grid-row-gap: 50px
            display: flex
            display: grid
            flex-wrap: wrap
            background-size: contain
            background-position: center
            margin: 50px auto 0 auto
            padding: 0
            grid-template-areas: "kard-one kard-big kard-two" "kard-three kard-big kard-four"
            max-width: 1400px
            @media scr1024
                padding: 0 3%
                grid-column-gap: 15px
                grid-template-areas: "kard-big kard-one kard-two" "kard-big kard-three kard-four"
            @media tablet
                padding: 0
                grid-template-columns: 1fr 1fr
                grid-template-rows: 1fr 1fr 1fr
                grid-row-gap: 20px
                grid-template-areas: "kard-big kard-one"  "kard-big kard-two" "kard-three kard-four"
            @media (max-width: 500px)
                grid-template-columns: 1fr
                grid-template-rows: 350px
                grid-row-gap: 20px
                grid-template-areas: "kard-one" "kard-two" "kard-big" "kard-three" "kard-four"
            .kard-one
                grid-area: kard-one
                howToUseKardOptions()
                h1
                    howToUseHeader()
                    @media scr1024
                        min-height: 83px 
                    @media (max-width: 502px)
                        min-height: auto
                        padding: 10px 0
                        width: 70%
                        margin: 0 auto
                    @media mobi 
                        width: 100%
                .wrapper
                    height: 271px
                    howToUSeWrapper()
                    @media (max-width: 500px) 
                        width: 70%
                        margin: 0 auto
                    @media mobi 
                        width: 100%
                        margin: 0 auto
                    howToUseCubeOptions()
                    .box-for-image
                        position: absolute
                        top: 2px
                        left: 2px
                        z-index: 3
                        height: 260px
                        width: 99%
                        @media scr1024
                            width: 98.5%
                        .image
                            height: 266px
                            howToUseImageOption()
            .kard-two
                howToUseKardOptions()
                grid-area: kard-two
                align-items: flex-end
                h1
                    width: 100%
                    text-align: left
                    howToUseHeader()
                    @media tablet
                        margin: 13px 0 0 0
                    @media (max-width: 502px)
                        min-height: auto
                        padding: 10px 0
                        width: 70%
                        margin: 0 auto
                    @media mobi 
                        width: 100%
                .wrapper
                    height: 271px
                    howToUSeWrapper()
                    @media (max-width: 500px) 
                        width: 70%
                        margin: 0 auto
                    @media mobi 
                        width: 100%
                        margin: 0 auto
                    howToUseCubeOptions()
                    .box-for-image
                        position: absolute
                        top: 2px
                        left: 2px
                        z-index: 3
                        height: 260px
                        width: 99%
                        @media scr1024
                            width: 98.5%
                        .image
                            height: 266px
                            howToUseImageOption()
            .kard-big
                howToUseKardOptions()
                grid-area: kard-big
                align-items: center
                width: 100%
                h1
                    howToUseHeader()
                    @media scr1024
                        width: 90%
                    @media tablet
                        width: 100%
                    @media (max-width: 502px)
                        min-height: auto
                        padding: 10px 0
                        width: 70%
                        margin: 0 auto
                    @media mobi 
                        width: 100%
                .wrapper
                    height: 677px
                    howToUSeWrapper()
                    @media (max-width: 500px) 
                        width: 70%
                        margin: 0 auto
                    @media mobi 
                        width: 100%
                        margin: 0 auto
                    howToUseCubeOptions()
                    .box-for-image
                        position: absolute
                        top: 2px
                        left: 3px
                        z-index: 3
                        height: 671px
                        width: 98.5%
                        .image
                            height: 671px
                            howToUseImageOption()
                            @media (max-width: 1202px)
                                width: 99%
                            @media scr1024
                                width: 100%
            .kard-three
                howToUseKardOptions()
                grid-area: kard-three
                h1
                    howToUseHeader()
                    @media (max-width: 1366px)
                        min-height: 81px
                    @media (max-width: 502px)
                        min-height: auto
                        padding: 10px 0
                        width: 70%
                        margin: 0 auto
                    @media mobi 
                        width: 100%
                .wrapper
                    height: 271px
                    howToUSeWrapper()
                    @media (max-width: 500px) 
                        width: 70%
                        margin: 0 auto
                    @media mobi 
                        width: 100%
                        margin: 0 auto
                    howToUseCubeOptions()
                    .box-for-image
                        position: absolute
                        top: 2px
                        left: 2px
                        z-index: 3
                        height: 260px
                        width: 99%
                        @media scr1024
                            width: 98.5%
                        .image
                            height: 266px
                            howToUseImageOption()
            .kard-four
                align-items: flex-end
                howToUseKardOptions()
                grid-area: kard-four
                h1
                    text-align: left
                    howToUseHeader()
                    @media scr1024
                        min-height: 81px
                    @media (max-width: 502px)
                        min-height: auto
                        padding: 10px 0
                        width: 70%
                        margin: 0 auto
                    @media mobi 
                        width: 100%
                .wrapper
                    height: 271px
                    howToUSeWrapper()
                    @media (max-width: 500px)
                        width: 70%
                        margin: 0 auto
                    @media mobi 
                        width: 100%
                        margin: 0 auto
                    howToUseCubeOptions()
                    .box-for-image
                        position: absolute
                        top: 2px
                        left: 2px
                        z-index: 3
                        height: 260px
                        width: 99%
                        @media scr1024
                            width: 98.5%
                        .image
                            height: 266px
                            howToUseImageOption()

    .buing-elements
        max-width: 1400px
        margin: 50px auto 0 auto
        display: flex
        flex-flow: column
        height: 100%
        @media (max-width: 1440px)
            margin: 0 10%

        @media tablet
            margin: 0 3%
            padding: 50px 0 0 0
        @media mobi
            margin: 0 6%
        .buy
            display: flex
            justify-content: flex-end
            align-items: center
            flex-flow: row
            position: relative
            @media (max-width: 560px) 
                flex-flow: column
            .text-elements
                width: 100%
                .header
                    p
                        font-family: 'GloberxBold'

    .circle-wrapper
        width: 50%
        display: flex
        flex-flow: column
        justify-content: center
        align-items: center
        @media (max-width: 560px) 
            width: 100%
            margin: 25px 0 0 0
            align-items:  flex-start
        .method-coord-img-outline 
            outline: none 
            box-shadow: none
            width: 190px
            height: 190px
            border-radius: 100%
            display: flex
            align-items: center
            justify-content: center
            position: relative
            flex-direction: column
            @media mobi 
                width: 160px
                height: 160px
            .box-knowledge
                width: 190px
                height: 96px
                background: linear-gradient(97deg, rgba(117,101,214,1) 0%, rgba(69,135,207,1) 100%)
                border-top-left-radius: 95px
                border-top-right-radius: 95px
                position: absolute
                top: 0px
                @media mobi 
                    width: 158px
                    height: 79px
                    top: 3px
            .box-knowledge2
                width: 190px
                height: 95px
                background: linear-gradient(360deg, rgba(195,81,73,1) 0%, rgba(69,135,207,1) 100%)
                border-bottom-left-radius: 95px
                border-bottom-right-radius: 95px
                position: absolute
                bottom: 0px
                -webkit-box-shadow: 0px 7px 0px 0px rgba(116,97,211,1)
                -moz-box-shadow: 0px 7px 0px 0px rgba(116,97,211,1)
                box-shadow: 0px 7px 0px 0px rgba(116,97,211,1)
                @media mobi 
                    width: 158px
                    height: 79px
                    bottom: 3px
            .method-coord-img
                width: 180px
                height: 180px
                border-radius: 100%
                z-index: 2
                background-color: white
                background-position: center
                background-size: contain
                background-repeat: no-repeat
                @media mobi 
                    width: 145px
                    height: 145px
                .text
                    position: absolute 
                    width: 100%
                    top: 50px
                    left: 22px
                    font-size: 50px
                    color: pleo-dark-green
                    font-family: 'GloberRegular' !important
                    @media mobi 
                        font-size: 45px
                        top: 39px
                .text2
                    color: pleo-dark-green
                    font-family: 'GloberRegular' !important
                    position: absolute
                    top: 110px
                    left: 45px
                    font-size: 21px
                    @media mobi 
                        top: 82px
                        left: 27px
                    
        .button-buy
            //
            display: flex
            flex-flow: column
            margin: 20px 0
            @media (max-width: 560px)
                padding: 0 0 0 3%
            label
            
                font-family: 'GloberRegular'
            .form-wrapper
                .button-violet-price
                    width: 150px
                    color: pleo-dark-green
                    margin: 10px 0 0 -10px
                    -webkit-box-shadow: 0px 3px 0px 0px rgba(116,97,211,1)
                    -moz-box-shadow: 0px 3px 0px 0px rgba(116,97,211,1)
                    box-shadow: 0px 3px 0px 0px rgba(116,97,211,1)
                    .button-elements
                        width: 100%
                        text-align: center
                        display: flex
                        justify-content: center
                        align-items: cnter
                        a
                            text-decoration: none
                            color: #fff
                            font-family: GloberRegular
                            width: 100%
                            padding: 0
                            margin: 0
                            display: flex
                            text-align: center
                            justify-content: center
                            align-items: center
                        img 
                            width: 5px
                            height: 10px
                            margin: 0 15px 0 0