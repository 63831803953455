section.table-of-tools-and-ads-wrapper
    container-position()
    padding-bottom: 60px
    .table
        display: grid
        max-width: 1140px
        margin: 0px auto
        justify-content: center
        grid-template-columns: repeat(4, 273px)
        grid-column-gap: 16px
        grid-row-gap: 47px
        .table-item
            height: 440px
            background: white
            border-radius: 20px
        .tool
            width: 273px
            padding: 25px 22px
            position: relative
            display: flex
            flex-direction: column
            justify-content: space-between
            &:hover
                .tool-hover-wrap
                    visibility: visible
            .tool-hover-wrap
                visibility: hidden
                position: absolute
                width: 100%
                height: 100%
                background: rgba(#132730,0.9)
                border: 3px solid pleo-fiolet
                border-radius: 20px
                left: 0
                top: 0
                display: flex
                flex-direction: column
                justify-content: space-between
                padding: 30px 18px
                .tool-hover-circle
                    cursor: pointer
                    height: 116px
                    width: 116px
                    border-radius: 100%
                    display: flex
                    align-items: center
                    justify-content: center
                    position: relative
                    flex-direction: column
                    box-shadow: 0px 10px 5px 0px rgba(20,20,20,0.55)
                    .box1-tablica
                        width: 116px
                        height: 58px
                        background: linear-gradient(97deg, rgba(117,101,214,1) 0%, rgba(69,135,207,1) 100%)
                        border-top-left-radius: 95px
                        border-top-right-radius: 95px
                        position: absolute
                        top: 0px
                    .box2-tablica 
                        width: 116px
                        height: 58px
                        background: linear-gradient(90deg, rgba(195,81,73,1) 0%, rgba(69,135,207,1) 100%)
                        border-bottom-left-radius: 95px
                        border-bottom-right-radius: 95px
                        position: absolute
                        bottom: 0px
                    .tool-hover-bg
                        width: 110px
                        height: 110px
                        border-radius: 100%
                        z-index: 2
                        background-color: pleo-dark-green
                        background-position: center
                        background-size: contain
                        background-repeat: no-repeat
                        display: flex
                        justify-content: center
                        align-items: center
                        position: relative
                        &:after
                            content: ''
                            position: absolute
                            height: 3px
                            width: 40px
                            background: pleo-fiolet
                            top: 25px
                            left: 21px
                        &:before
                            content: ''
                            position: absolute
                            width: 0
                            height: 0
                            border-top: 7px solid transparent
                            border-bottom: 7px solid transparent
                            border-left: 6px solid pleo-fiolet
                            top: 20px
                            left: 61px
                        p.tool-hover-text
                            color: white
                            font-family: 'GloberxBold'
                            font-size: 16px
                            width: 70px
                            margin-bottom: -10px
                            line-height: 17px
                .tool-hover-info
                    font-family: 'GloberRegular'
                    font-size: 16px
                    margin: 0px
                    color: white
                    p
                        font-family: 'GloberRegular'
                        font-size: 16px 
                        margin: 0px
                        color: white
            .tool-main-part
                .tool-header
                    display: flex
                    justify-content: space-between
                    padding-bottom: 45px
                    h4.tool-header-text
                        padding-top: 9px
                        margin: 0px
                        font-family: 'GloberxBold'
                        font-size: 12px
                        text-transform: uppercase
                        p
                            margin: 0px
                    .tool-header-img
                        background-repeat: no-repeat !important
                        background-size: contain !important
                        background-position: center !important
                        height: 23px
                        width: 70px
                .tool-content
                    display: flex
                    flex-direction: column
                    align-items: baseline
                    h2.tool-content-text
                        font-family: 'GloberxBold'
                        font-size: 30px
                        min-height: 98px
                        text-transform: uppercase
                        padding-bottom: 10px
                        margin: 0px
                        color: pleo-fiolet
                        &:first-line
                                color: black
                    .tool-content-img
                        height: 143px 
                        width: auto
                        object-fit: contain
            ul.tool-footer
                display: flex
                flex-wrap: wrap
                list-style-type: none
                justify-content: baseline
                margin-bottom: 0px
                color: pleo-dark-green
                li
                    font-family: 'GloberRegular'
                    font-size: 12px
                    color: pleo-dark-green
                    &:nth-child(even)
                        color: pleo-dark-green
                        padding-left: 12px
        .ads-big
            width: 563px
            grid-column: span 2
            display: flex
            align-items: center
            .ads-item1
                border-radius: 20px
                background-position: center
                background-repeat: no-repeat
                background-size: cover
                height: 420px
                width: 543px
                margin: 0px auto
                display: flex !important
                justify-content: flex-end
                .ads-item1-blend
                    height: 100%
                    width: 450px
                    background: rgba(pleo-fiolet, 0.4)
                    border-radius: 20px
                    display: flex
                    flex-direction: column
                    align-items: flex-end
                    justify-content: space-between
                    .ads-title-header
                        padding-top: 120px
                        padding-right: 40px
                        p
                            font-family: 'GloberHeavy'
                            font-size: 40px
                            line-height: 46px
                            text-transform: uppercase
                            text-align: right
                            margin-bottom: 0px
                    h4.ads-text
                        font-family: 'GloberSemibold'
                        font-size: 16px
                        text-align: right
                        text-transform: uppercase
                        max-width: 270px
                        padding-right: 40px
                        padding-bottom: 20px
                        margin-bottom: 0px

@media (max-width: 1245px)
    section.table-of-tools-and-ads-wrapper
        .table
            grid-template-columns: repeat(3, 273px)
@media (max-width: 888px)
    section.table-of-tools-and-ads-wrapper
        .table
            grid-template-columns: repeat(2, 273px)
@media (max-width: 600px)
    section.table-of-tools-and-ads-wrapper
        .table
            grid-template-columns: repeat(1, 335px)
            grid-row-gap: 15px
            .tool
                width: 335px
            .ads-big
                width: 335px !important
                grid-column: span 1 !important
                .ads-item1
                    width: 315px !important
                    .ads-item1-blend
                        .ads-title-header
                            font-size: 30px
                            line-height: 40px
                            p
                                font-size: 30px
                                line-height: 40px
@media (max-width: 375px)
    section.table-of-tools-and-ads-wrapper
        .table
            .tool
                padding: 22px 10px
                width: 315px
                justify-self: center


