.general-advertisement-wrapper
    width: 100%
    background-size: cover
    background-repeat: no-re
    display: flex
    justify-content: center
    align-items: center
    position: relative
    padding: 50px 0
    .blenda
        background-color: rgba(4, 35, 47, .8)
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
    .advertisement-elements
        height: 100%
        display: flex
        justify-content: center
        align-items: center
        max-width: 1400px
        margin: 0 auto
        flex-flow: row
        justify-content: space-between
        @media (max-width: 1440px)
            margin: 0 10%
        @media scr1024
            margin: 0 10%
            flex-flow: column        
        @media tablet
            margin: 0 3%
        @media mobi
            margin: 0 6%
            padding: 50px 0
        .tutorials
            display: flex
            justify-content: flex-end
            align-items: flex-end
            flex-flow: row
            width: 100%
            position: relative
            padding: 0 50px 0 0 
            @media scr1024
                width: 100%
                padding: 0
            @media mobi 
                flex-flow: column
                padding: 0
            .text-elements
                width: 100%
                .header
                    font-family: 'GloberxBold'
                    p
                        font-family: 'GloberxBold'
                        text-transform: uppercase
                .text
                    width: 100%
                    p
                        width: 100%
                        font-family: 'GloberRegular'
                        margin: 0
                .button-date
                    display: flex
                    flex-flow: column
                    margin: 74px 20px 0 20px
                    .form-wrapper
                        .button-violet-price
                            color: pleo-dark-green
                            display: flex
                            justify-content: center
                            align-items: center
                            margin: 10px 0 0 -10px
                            -webkit-box-shadow: 0px 3px 0px 0px rgba(116,97,211,1);
                            -moz-box-shadow: 0px 3px 0px 0px rgba(116,97,211,1);
                            box-shadow: 0px 3px 0px 0px rgba(116,97,211,1)
                            @media scr1024
                                width: 223px
                                height: 43px
                            .buttons-element
                                width: 100%
                                text-align: center
                                a
                                    text-decoration: none
                                    color: white
                                    font-size: 12px
                                    font-family: 'GloberxBold'
                                    @media scr1024
                                        font-size: 18px