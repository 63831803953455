@media (min-width: 768px)
    .menu-under-jumbotron-contact.sticky
        max-height: 85px
        .menu-purple-element
            max-height: 85px
            a
                max-height: 100%
                .menu-element
                    max-height: 87px
                    justify-content: flex-start
                    align-items: center
                    .img
                        justify-content: flex-start
                        align-items: center
                        .svg
                            height: 43px
                            width: 43px

@media (max-width: 768px)
    .menu-under-jumbotron-contact.sticky
            display: none


.menu-under-jumbotron-contact
    width: 100%
    outline: none
    padding: 0
    margin: 0 0 3px 0
    background-color: pleo-fiolet
    min-height: 136px
    position: -webkit-sticky;
    position: sticky
    bottom: 0
    @media (max-width: 768px)
        height: auto
        max-height: auto 
        position: static
    @media (max-width: 450px)
        position: static
    @media (max-width: 654px)
        display: none
    .menu-purple-element
        max-width: 1400px 
        margin: 0 auto
        display: grid
        column-gap: 5px
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr))
        grid-template-rows: 135px
        row-gap: 0
        height: 136px
        @media (max-width: 1440px)
            margin: 0 10%
        @media scr1024
            margin: 0 9%
        @media tablet
            margin: 0 3%
            height: auto
            max-height: auto !important
        @media (max-width: 654px)
            padding: 10px 0
        @media mobi
            grid-template-columns: 1fr
            grid-template-rows: repeat(4, 0.5fr)
            margin: 0 3%
            row-gap: 10px
        a
            height: 100%
            display: flex
            @media tablet
                height: 104%
            .menu-element
                display: flex
                height: 103%
                flex-flow: row
                column-gap: 30px
                justify-content: center
                align-items: center
                @media mobi 
                    height: 100%
                &:hover
                    cursor: pointer
                    border-bottom: 3px solid white
                    .text
                        p
                            color: white
                            font-family: 'GloberxBold'
                .img
                    height:100%
                    display: flex
                    justify-content: center
                    align-items: center
                    padding: 0 30px 0 0
                    @media tablet 
                        padding: 0 30px 0 0
                    @media mobi 
                        padding: 0 15px 15px 0
                    .svg
                        width: 60px
                        height: 63px
                    .svg-menu-size1
                        width: 59px
                        height: 50px
                    .svg-menu-size2
                        width: 53px
                        height: 54px
                    .svg-menu-size3
                        width: 61px
                        height: 60px
                    .svg-menu-size3
                        width: 73px
                        height: 66px

                .text
                    display: flex
                    flex-flow: column
                    justify-content: center
                    align-items: center
                    padding: 0
                    margin: 0
                    p
                        font-family: 'GloberRegular'
                        color: pleo-dark-green
                        padding: 0
                        margin: 0