@media (min-width: 1441px)
    section.blog-content-list-wrapper
        .blog-content-list
            max-width: 1400px !important
        .blog-navigation-arrows-wrapper
            .blog-navigation-arrows
                max-width: 1400px !important

section.blog-content-list-wrapper 
    container-position()
    .blog-content-list
        max-width: 1140px
        margin: 0px auto
        padding: 0px 0px
        ul.blog-content 
            list-style-type: none
            display: flex
            flex-direction: column
            li.blog-content-item
                width: 100%
                height: 482px
                margin: 36px 0px
                border-bottom-left-radius: 25px
                display: flex
                flex-direction: row
                .blog-art-link
                    cursor: pointer
                    height: 100%
                    width: 100%
                    display: flex
                    flex-direction: row 
                    &:hover
                        .content-text-wrapper
                            background: pleo-fiolet
                            .main-content
                                .title-wrapper
                                    p.post-category
                                        color: white
                            .blog-post-author-wrap
                                .post-author-info-wrap
                                    p.post-author
                                        color: white
                                    p.post-author-quote
                                        color: white
                        .content-image
                            background-size: 105% !important
                            .blog-hover-wrap
                                display: flex
                    .content-text-wrapper
                        width: 25%
                        background: white
                        display: flex
                        flex-direction: column
                        justify-content: space-between
                        padding: 35px 30px
                        border-bottom-left-radius: 25px
                        transition: .2s
                        .main-content
                            display: flex
                            flex-direction: column
                            .title-wrapper
                                width: 100%
                                p.post-category
                                    font-family: 'GloberRegular'
                                    font-size: 12px
                                    font-weight: 600
                                    text-transform: uppercase
                                    color: pleo-fiolet
                                    transition: .2s
                                    margin-bottom: 25px
                            .quote-wrapper
                                width: 100%
                                position: relative
                                margin-top: 45px
                                &:before
                                    content: ''
                                    position: absolute
                                    height: 7px
                                    width: 100px
                                    background: #000000
                                    top: -45px
                                p
                                    font-family: 'GloberxBold'
                                    font-size: 24px
                                    line-height: 31px
                                    color: pleo-dark-green
                        .blog-post-author-wrap
                            display: flex
                            flex-direction: row
                            .author-img
                                width: 36px
                                height: 36px
                                border-radius: 100%
                                display: flex
                                align-items: center
                                justify-content: center
                                position: relative
                                flex-direction: column
                                .box-author-blog
                                    width: 36px
                                    height: 18px
                                    background: linear-gradient(97deg, rgba(117,101,214,1) 0%, rgba(69,135,207,1) 100%)
                                    border-top-left-radius: 95px
                                    border-top-right-radius: 95px
                                    position: absolute
                                    top: 0px
                                .box2-author-blog
                                    width: 36px
                                    height: 18px
                                    background: linear-gradient(90deg, rgba(195,81,73,1) 0%, rgba(69,135,207,1) 100%)
                                    border-bottom-left-radius: 95px
                                    border-bottom-right-radius: 95px
                                    position: absolute
                                    bottom: 0px
                                .blog-author-img
                                    width: 32px
                                    height: 32px
                                    border-radius: 100%
                                    z-index: 2
                                    background-position: center !important
                                    background-size: cover !important
                                    background-repeat: no-repeat !important
                            .post-author-info-wrap
                                margin-left: 10px
                                display: flex
                                flex-direction: column
                                justify-content: center
                                p.post-author
                                    font-family: 'GloberxBold'
                                    font-size: 12px
                                    line-height: 11px
                                    text-transform: uppercase
                                    color: pleo-fiolet
                                    transition: .2s
                                    margin-bottom: 0px
                                p.post-author-quote
                                    font-family: 'GloberRegular'
                                    font-size: 10px
                                    line-height: 17px
                                    color: black
                                    transition: .2s
                                    margin-bottom: 0px

                        // .hashtag-wrapper
                        //     width: 100%
                        //     ul.hashtag-list
                        //         display: flex
                        //         flex-wrap: wrap
                        //         list-style-type: none
                        //         justify-content: baseline
                        //         margin-bottom: 2px
                        //         li
                        //             font-family: 'GloberRegular'
                        //             font-size: 12px
                        //             color: pleo-dark-green
                        //             padding: 1px 6px
                    .content-image
                        width: 97%
                        height: 100%
                        background-position: center !important
                        background-repeat: no-repeat !important
                        background-size: 100% !important
                        transition: .2s ease-in-out
                        .blog-hover-wrap
                            width: 100%
                            height: 100%
                            display: none
                            align-items: center
                            justify-content: center
                            .blog-hover-circle
                                cursor: pointer
                                height: 116px
                                width: 116px
                                border-radius: 100%
                                display: flex
                                align-items: center
                                justify-content: center
                                position: relative
                                flex-direction: column
                                box-shadow: 0px 10px 5px 0px rgba(20,20,20,0.55)
                                @media mobi 
                                    width: 88px
                                    height: 88px
                                .box1-blog
                                    width: 116px
                                    height: 58px
                                    background: linear-gradient(97deg, rgba(117,101,214,1) 0%, rgba(69,135,207,1) 100%)
                                    border-top-left-radius: 95px
                                    border-top-right-radius: 95px
                                    position: absolute
                                    top: 0px
                                    @media mobi 
                                        width: 92.8px
                                        height: 46.4px
                                        top: -2px
                                .box2-blog 
                                    width: 116px
                                    height: 58px
                                    background: linear-gradient(90deg, rgba(195,81,73,1) 0%, rgba(69,135,207,1) 100%)
                                    border-bottom-left-radius: 95px
                                    border-bottom-right-radius: 95px
                                    position: absolute
                                    bottom: 0px
                                    @media mobi 
                                        width: 92.8px
                                        height: 46.4px
                                        bottom: -2px
                                .blog-hover-bg
                                    width: 110px
                                    height: 110px
                                    border-radius: 100%
                                    z-index: 2
                                    background-color: pleo-dark-green
                                    background-position: center
                                    background-size: contain
                                    background-repeat: no-repeat
                                    display: flex
                                    justify-content: center
                                    align-items: center
                                    position: relative
                                    @media mobi 
                                        width: 88px
                                        height: 88px
                                    &:after
                                        content: ''
                                        position: absolute
                                        height: 3px
                                        width: 40px
                                        background: pleo-fiolet
                                        top: 25px
                                        left: 21px
                                        @media mobi 
                                            width: 32px
                                    &:before
                                        content: ''
                                        position: absolute
                                        width: 0
                                        height: 0
                                        border-top: 7px solid transparent
                                        border-bottom: 7px solid transparent
                                        border-left: 6px solid pleo-fiolet
                                        top: 20px
                                        left: 61px
                                        @media mobi 
                                            left: 53px
                                    p.blog-hover-text
                                        color: white
                                        font-family: 'GloberxBold'
                                        font-size: 16px
                                        width: 70px
                                        margin-bottom: -10px
                                        margin-left: 0
                                        line-height: 17px
                                        @media mobi 
                                            font-size: 12px
                                            margin-left: 20px
                                            margin-bottom: -14px
    .blog-navigation-arrows-wrapper
        width: 100%
        overflow: hidden
        .blog-navigation-arrows
            max-width: 1140px
            margin: 0px auto
            display: flex
            justify-content: space-between
            padding: 0px 20px
            .blog-arrow-left
                padding-left: 35px
                position: relative
                font-family: 'GloberxBold'
                font-size: 18px
                cursor: pointer
                color: white
                &:hover
                    color: pleo-fiolet
                &:before
                    content: ''
                    position: absolute
                    width: 0
                    height: 0
                    border-top: 6px solid transparent
                    border-bottom: 6px solid transparent
                    border-right: 6px solid white
                    top: 9px
                    left: 0px
                &:after
                    content: ''
                    position: absolute
                    width: 0
                    height: 0
                    border-top: 6px solid transparent
                    border-bottom: 6px solid transparent
                    border-right: 6px solid white
                    bottom: 6px
                    left: 9px
                &:hover:before
                    border-right: 6px solid pleo-fiolet
                &:hover:after
                    border-right: 6px solid pleo-fiolet
            .blog-arrow-right
                padding-right: 35px
                position: relative 
                cursor: pointer
                font-family: 'GloberxBold'
                font-size: 18px
                color: white
                &:hover
                    color: pleo-fiolet
                &:before
                    content: ''
                    position: absolute
                    width: 0
                    height: 0
                    border-top: 6px solid transparent
                    border-bottom: 6px solid transparent
                    border-left: 6px solid white
                    top: 9px
                    right: 0px
                &:after
                    content: ''
                    position: absolute
                    width: 0
                    height: 0
                    border-top: 6px solid transparent
                    border-bottom: 6px solid transparent
                    border-left: 6px solid white
                    bottom: 6px
                    right: 9px
                &:hover:before
                    border-left-color: pleo-fiolet
                &:hover:after
                    border-left-color: pleo-fiolet

@media (max-width: 1145px)
    section.blog-content-list-wrapper
        .blog-content-list
            padding: 0px 20px
            ul.blog-content
                li.blog-content-item
                    height: 416px
                    .blog-art-link
                        position: relative
                        .content-text-wrapper
                            width: 290px
                            z-index: 1
                        .content-image
                            position: absolute
                            right: 0
@media (max-width: 860px)
    section.blog-content-list-wrapper
        .blog-content-list
            ul.blog-content
                li.blog-content-item
                    height: 373px
                    .blog-art-link
                        .content-text-wrapper
                            width: 240px
                            z-index: 1
                            .main-content
                                .title-wrapper
                                    p.post-author
                                        margin-bottom: 20px
                                .quote-wrapper
                                    margin-top: 25px
                                    &:before
                                        top: -25px
                                    p
                                        font-size: 18px
                                        line-height: 24px

                        .content-image
                            position: absolute
                            right: 0
@media (max-width: 545px )
    section.blog-content-list-wrapper
        .blog-content-list
            ul.blog-content
                li.blog-content-item
                    min-height: 500px
                    .blog-art-link
                        flex-direction: column-reverse
                        .content-text-wrapper
                            width: 100%
                            z-index: 1
                            min-height: 300px
                            padding: 20px 25px 25px 25px
                            .main-content
                                .title-wrapper
                                    p.post-category
                                        margin-bottom: 14px
                                .quote-wrapper
                                    margin-top: 42px
                                    font-size: 22px
                                    &:before
                                        top: -40px
                                        width: 180px
                                    p
                                        font-size: 22px
                                        line-height: 24px
                            .blog-post-author-wrap
                                .post-author-info-wrap
                                    p.post-author
                                        margin-bottom: 0px
                        .content-image
                            position: relative 
                            width: 100%
                            max-height: 220px
                            .blog-hover-wrap
                                display: flex

        .blog-navigation-arrows-wrapper 
            .blog-navigation-arrows
                .blog-arrow-left
                    font-size: 16px
                    padding-left: 25px
                    &:before
                        top: 7px
                    &:after
                        bottom: 5px
                .blog-arrow-right
                    font-size: 16px
                    padding-right: 25px
                    &:before
                        top: 7px
                    &:after
                        bottom: 5px

                        

