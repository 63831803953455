section.blog-post-quote-wrapper
    container-position()
    .blog-post-text-quote
        margin: 0px auto
        max-width: 1024px
        padding: 100px 95px
        font-family: 'GloberLight'
        font-size: 24px
        line-height: 41px
        color: pleo-blog-font
        p
            strong 
                font-family: 'GloberSemiBold'
            a
                font-family: 'GloberSemiBold'
                cursor pointer

@media (max-width: 1440px)
    section.blog-post-quote-wrapper
        .blog-post-text-quote
            max-width: 768px
        
@media (max-width: 768px)
    section.blog-post-quote-wrapper
        .blog-post-text-quote
            padding: 80px 95px
            font-size: 18px
            line-height: 1.875

@media (max-width: 545px)
    section.blog-post-quote-wrapper
        .blog-post-text-quote
            padding: 10px 35px

    