@media (max-width: 769px)
    .menu-under-jumbotron.sticky
        position: static !important
@media (min-width: 769px)
    .menu-under-jumbotron.sticky
            max-height: 85px
			
            .menu-white-element
                max-height: 85px
                @media (max-width: 1024px)
                    margin: 0 5% !important
                @media (min-width: 887px)
                    margin: 0 9% 0 2% !important
                .about-cours
                    max-height: 85px
					
                    .term
                        max-height: 85px
                        line-height: 1
						
                        @media (max-width: 1160px)
                            display: none !important
                    .basic-iformation
                        border: none
                        background-color: white
                        min-height: 85px
                        margin: 0
                        width: 80% !important
                        @media (max-width: 1160px)
                            margin: 0 !important
                            width: 100% !important
						
                        padding: 0px
                        .information
                            max-height: 60px
                        .button-white-grey-border
                            border: none
                            margin: 0 !important
                            line-height: 1
                            font-size: 12px !important
                            padding: 0 0 0 25px
                            @media (max-width: 773px)
                                padding: 0 11px !important
                    .price
						
                        max-height: 85px
                        display: flex
                        flex-direction: row
                        padding-left: 25px
                        @media (max-width: 1100px)
                            padding-left: 10px !important
                            width: 35% !important
                        @media (max-width: 807px)
                            padding-left: 10px !important
                            width: 29% !important
                        .price-amound
                                flex-direction: column
                            span
                                padding: 0px !important
                                margin:  -10px 0 0 0!important
                        a
                            width: 72px
                            padding-left: 10px
                            @media (max-width: 860px)
                                padding: 0
                            .button-violet-price
                                width: 100px
                                min-width: 100px
                                margin: 0 0 0 50px
                                .button-elements
                                    img
										display: none

.menu-under-jumbotron
	width: 100%
	background: linear-gradient(284deg, rgba(138,115,251,1) 0%, rgba(138,115,251,1) 37%, rgba(255,255,255,1) 58%)
	@media (min-width: 768px)
		position: -webkit-sticky
		position: sticky
		bottom: 0
	.menu-white-element      
		max-width: 1400px 
		margin: 0 auto !important
		@media (max-width: 1440px)
			margin: 0 10% !important
		@media scr1024
			margin: 0 9% 0 3% !important
		@media tablet
			margin: 0 3% !important
		@media (max-width: 626px) 
			margin: 0 !important
		.about-cours
			
			display: flex
			@media (max-width: 626px) 
				flex-flow: column
			.term
				border: 1px solid
				width: 30%
				display: flex
				flex-direction: column
				justify-content: center
				position: relative
				outline: none
				background-color: white
				&::after
					position: absolute
					content: ""
					height: 100%
					width: 2px
					background-color: pleo-dark-green
					left: 185px
				@media scr1024
					display: none
				.description-header
					font-size: 21px
					font-family: 'GloberHeavy'
					text-transform: uppercase 
					color: pleo-dark-green
				.description
					font-size: 17px
					color: pleo-dark-green
					font-family: 'GloberRegular'
				.more-date
					display: flex
					font-family: 'GloberHeavy'
					flex-flow: row wrap
					color: pleo-dark-green
					p
						padding: 0
						margin: 0
						font-size: 14px
						:hover
							text-decoration: underline pleo-fiolet
							padding: 4px 0 0 0 
			.basic-iformation
				line-height: 1
				display: flex
				flex-flow: row wrap
				align-items: center
				justify-content: flex-start
				width: 100%
				padding: 25px 0
				background-color: #ffffff
				@media (max-width: 1160px)
					margin: 0
					width: 70%
				@media (max-width: 1277px) 
					margin: 0 0 0 60px
				@media (max-width: 1024px) 
					margin: 0
				@media (max-width: 626px) 
					width: 100%
					padding: 25px 0 35px 3%
				.information
					width: 100%
					height: 100%
					display: flex
					flex-flow: row wrap
					line-height: 1
					align-items: center 
					.place
						p::first-letter
							text-transform: uppercase !important
					.button-white-grey-border
						font-size: 14px
						display: flex
						justify-content: center
						align-items: center
						margin: 9px 10px 0 0
						font-family: 'GloberRegular'
						color: pleo-dark-green
						padding: 0 20px
						@media scr1024
							margin: 9px 10px 0 0
						@media tablet
							margin: 5px 5px 0 0
							padding: 0 15px
						.fas
							width: 20px
							height: 20px
							margin: 2px 3px
						p
							padding: 0 10px
							margin: 0
			.price
				background: pleo-fiolet
				display: flex
				flex-flow: column
				justify-content: center
				align-items: center
				padding: 0 0 0 35px
				width: 30%
				@media scr1024
					width: 40%
				@media (max-width: 646px)
					width: 60%
					text-align: right
					padding: 0 3%
				@media (max-width: 626px) 
					text-align: left
					align-items: flex-start
					justify-content: space-between
					text-align: left
					width: 100% 
					padding: 20px 3%
					flex-flow: row
				.price-amound 
					width: 100%
					align-items: center
					justify-content: center
					display: flex
					flex-flow: row
					font-size: 30px
					font-family: 'GloberHeavy' 
					color: pleo-dark-green
					line-height: 2
					text-align: center
					@media (max-width: 626px) 
						text-align: left
						align-items: center
						justify-content: flex-start
						text-align: left
						line-height: 1
					@media mobi 
						flex-flow: column
						align-items: flex-start
					span 
						font-size: 14px
						font-family: 'GloberRegular'
						padding: 11px 0 0 10px
						color #ffffff
						line-height: 1
						@media mobi 
							padding: 0

				a
					display: flex
					flex-flow: row
					align-items: center
					justify-content: center
					width: 100%
					margin: 0
					@media (max-width: 626px) 
						text-align: left
						align-items: flex-start
						justify-content: flex-end
						text-align: left
					@media mobi 
						margin: 5px 0 0 0
					.button-violet-price
						cursor: pointer
						width: 171px
						color: pleo-dark-green 
						background-color: #fff
						font-family: 'globersemibold' 
						font-size: 12px
						margin: 0
						padding: 0
						text-align: center
						align-items: center
						justify-content: center
						-webkit-box-shadow: 0px 3px 0px 0px rgba(116,97,211,1);
						-moz-box-shadow: 0px 3px 0px 0px rgba(116,97,211,1);
						box-shadow: 0px 3px 0px 0px rgba(116,97,211,1)
						.button-elements
							width: 100%
							display: block
							text-align: center
							.arrow-on-price-button
								width: 6px
								height: 9px
								margin: -4px 11px 0 0 
								img
									width: 6px
									height: 16px
