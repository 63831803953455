.choos-download-tools
    width: 100%
    margin: 140px 0 200px 0
    @media tablet
        margin: 100px 0 100px 0
    @media mobi
        margin: 50px 0
    .tool-graphic
        max-width: 1400px
        margin: 0 auto
        display: flex
        flex-flow: row
        border: 1px solid transparent 
        border-radius: 20px
        @media (max-width: 1440px)
            margin: 0 10%
        @media scr1024
            margin: 0 10%
        @media tablet
            margin: 0 3%
        @media mobi
            margin: 0 6%
        img 
            height: 100%
            width: 100%
            border-radius: 20px
    .tools-element
        margin: 0 auto
        width: 100%
        max-width: 1400px
        height: auto
        display: flex
        flex-flow: row
        align-items: flex-start
        justify-content: space-between
        @media (max-width: 1440px)
            padding: 0 10%
        @media scr1024
            padding: 0 9%
        @media tablet
            padding: 0 4%
        @media (max-width: 578px)
            margin: 0
            flex-flow: column
            align-items: flex-start
            justify-content: flex-start
        @media mobi
            padding: 0 6%
        .form-download-elements
            display: flex
            flex-flow: column
            
            width: 100%
            min-height: 100%
            h3
                padding: 60px 0
                margin: 0
                font-size: 24px
                font-family: 'GloberxBold'
                @media (max-width: 633px) 
                    padding: 45px 0
                @media (max-width: 578px) 
                    padding: 20px 0
            .download-options
                width: 100%
                display: flex
                flex-flow: column
                .choose-elements
                    width: 100%
                    align-items: space-between
                    justify-content: space-between
                    margin: 0
                    display: flex
                    flex-flow: row
                    @media (max-width: 1141px)
                        margin: 30px 0 0 0
                    @media scr1024 
                        margin: 0
                        flex-flow: column           
                    .element-format
                        margin: 0 25px 0 0
                        @media (max-width: 578px)
                            margin: 0
                        label
                            display: flex
                            font-size: 14px
                            font-family: 'GloberRegular'
                            text-transform: uppercase
                            margin: 0 0 25px 0
                            @media scr1024 
                                margin: 20px 0
                        .select-format
                            display: flex
                            width: 100%
                            position: relative
                            border-top-left-radius: 20px
                            border-top-right-radius: 20px
                            @media (max-width: 578px)
                                width: 100%
                            .button-list-wrapper
                                background-color: white
                                border-radius: 20px
                                width: 100%
                                border-bottom-left-radius: 20px
                                border-bottom-right-radius: 20px
                                border-top-left-radius: 20px
                                border-top-right-radius: 20px
                                // -webkit-box-shadow: 0px 3px 0px 0px rgba(11,23,28,1);
                                // -moz-box-shadow: 0px 3px 0px 0px rgba(11,23,28,1);
                                // box-shadow: 0px 3px 0px 0px rgba(11,23,28,1);
                                position: relative
                                @media scr1024
                                    width: 182px
                                @media (max-width: 578px)
                                    width: 100%
                                    min-height: 43px
                                .main-button
                                    padding: 0
                                    position: relative
                                    margin: 0
                                    font-size: 12px
                                    height: 32px
                                    line-height: 3
                                    width: 149px
                                    border-radius: 20px
                                    background-color: white
                                    color: pleo-dark-green
                                    font-family: 'GloberxBold'
                                    text-transform: uppercase
                                    padding: 0 0 0 30px
                                    @media scr1024
                                        width: 182px
                                    @media (max-width: 578px)
                                        width: 100%
                                        height: 43px
                                        padding: 5px 0 0 30px
                                    @media mobi
                                        width: 100%
                                    &::after
                                        content:" \f107"
                                        font-family: 'Font Awesome 5 Free'
                                        position: absolute
                                        top: 0px
                                        right: 20px
                                        font-weight: 900
                                        color: pleo-fiolet
                                        @media (max-width: 578px)
                                            top: 5px
                                ul
                                    list-style-type none
                                    background-color: white
                                    height 0
                                    margin: 0
                                    position: absolute
                                    width: 100%
                                    visibility hidden
                                    @media scr1024
                                        width: 182px
                                    @media (max-width: 578px)
                                        width: 100%

                                    &.visible
                                        height 100%
                                        visibility: visible
                                        .select-language
                                            background-color: white
                                    &:last-child
                                        border-bottom-left-radius: 20px
                                        border-bottom-right-radius: 20px
                                    li
                                        cursor: pointer
                                        line-height: 2.5
                                        background-color: white
                                        border: none
                                        border-bottom-left-radius: 20px
                                        border-bottom-right-radius: 20px
                                        span
                                            display: block
                                            color: pleo-dark-green
                                            font-family: 'GloberxBold'
                                            text-transform: uppercase
                                            font-size: 12px
                                            padding: 5px 0 0 30px
                    .element-language
                        margin: 0 25px 0 0
                        @media (max-width: 578px)
                            margin: 0
                        label
                            display: flex
                            font-size: 14px
                            font-family: 'GloberRegular'
                            text-transform: uppercase
                            margin: 0 0 25px 0
                            @media scr1024 
                                margin: 20px 0
                        .select-language
                            display: flex
                            width: 100%
                            position: relative
                            border-top-left-radius: 20px
                            border-top-right-radius: 20px
                            @media (max-width: 578px)
                                width: 100%
                            .button-list-wrapper
                                background-color: white
                                border-radius: 20px
                                width: 100%
                                border-bottom-left-radius: 20px
                                border-bottom-right-radius: 20px
                                border-top-left-radius: 20px
                                border-top-right-radius: 20px
                                -webkit-box-shadow: 0px 3px 0px 0px rgba(11,23,28,1);
                                -moz-box-shadow: 0px 3px 0px 0px rgba(11,23,28,1);
                                box-shadow: 0px 3px 0px 0px rgba(11,23,28,1);
                                position: relative
                                @media scr1024
                                    width: 182px
                                @media (max-width: 578px)
                                    width: 100%
                                    min-height: 43px
                                .main-button
                                    padding: 0
                                    position: relative
                                    margin: 0
                                    font-size: 12px
                                    height: 32px
                                    line-height: 3
                                    width: 149px
                                    border-radius: 20px
                                    background-color: white
                                    color: pleo-dark-green
                                    font-family: 'GloberxBold'
                                    text-transform: uppercase
                                    padding: 0 0 0 30px
                                    @media scr1024
                                        width: 182px
                                    @media (max-width: 578px)
                                        width: 100%
                                        height: 43px
                                        padding: 5px 0 0 30px
                                    @media mobi
                                        width: 100%
                                    &::after
                                        content:" \f107"
                                        font-family: 'Font Awesome 5 Free'
                                        position: absolute
                                        top: 0px
                                        right: 20px
                                        font-weight: 900
                                        color: pleo-fiolet
                                        @media (max-width: 578px)
                                            top: 5px
                                ul
                                    list-style-type none
                                    background-color: white
                                    height 0
                                    margin: 0
                                    position: absolute
                                    width: 100%
                                    visibility hidden
                                    @media scr1024
                                        width: 182px
                                    @media (max-width: 578px)
                                        width: 100%

                                    &.visible
                                        height 100%
                                        visibility: visible
                                        .select-language
                                            background-color: white
                                    &:last-child
                                        border-bottom-left-radius: 20px
                                        border-bottom-right-radius: 20px
                                    li
                                        cursor: pointer
                                        line-height: 2.5
                                        background-color: white
                                        border: none
                                        border-bottom-left-radius: 20px
                                        border-bottom-right-radius: 20px
                                        span
                                            display: block
                                            color: pleo-dark-green
                                            font-family: 'GloberxBold'
                                            text-transform: uppercase
                                            font-size: 12px
                                            padding: 5px 0 0 30px
                    .button-tools
                        display: flex
                        flex-flow: column
                        @media scr1024
                            margin: 0
                            
                        label
                            font-size: 14px
                            font-family: 'GloberRegular'
                            text-transform: uppercase
                            margin: 0 0 25px 0
                            @media scr1024
                                margin: 20px 0
                        .button-violet-price
                            color: pleo-dark-green
                            display flex        
                            justify-content: center
                            align-items: center
                            width: 182px
                            height: 34px
                            background-color: #8a73fb
                            @media (max-width: 578px)
                                width: 100%
                                height: 44px
                            .button-elements
                                flex-flow: row
                                justify-content: center
                                align-items: center
                                width: 100%
                                height: 100%
                                display: none
                                text-decoration: none
                            a
                                color: white
                                display: block
                            #download-link
                                width: 100%
                                text-align: center
                                .svg
                                    width: 10px
                                    height: 10px
                                    margin: 0 17px 0 0
                                    &, & * 
                                        fill: white
                            &.disabled
                                pointer-events:none;
                                cursor: not-allowed
                                a
                                    color: pleo-dark-green
        .process-level
            display: flex
            flex-flow: column
            justify-content: center
            align-items: center
            width: 100%
            font-size: 14px
            font-family: 'GloberRegular'
            @media scr1024
                justify-content: center
                align-items: center
            @media (max-width: 578px)
                align-items: flex-start
                justify-content: flex-start
            h3
                padding: 60px 0
                margin: 0
                font-size: 24px
                font-family: 'GloberxBold'
                @media (max-width: 578px) 
                    padding: 40px 0 0 0
            .level-element
                // 
                display: flex
                flex-flow: column
                margin: 0px 0 20px 0
                @media (max-width: 1141px)
                    margin: 18px 0 30px 0
                @media (max-width: 1078px)
                    margin: 0px 0 21px 0
                @media scr1024
                    margin: 18px 0 21px 0
                p
                    text-transform: uppercase 
                    padding: 0
                    margin: 0
            .description
                width: 100%
                height: 100%
                display: flex
                justify-content: center
                align-items: flex-start
                padding: 18px 0 0 0
                @media scr1024
                    justify-content: center
                    align-items: center
                @media (max-width: 578px)
                    justify-content: flex-start
                    align-items: flex-start
                @media mobi 
                    padding: 0
                img 
                    display: flex
                    height: 40px
                // 
        .how-to-use1
            display: flex
            flex-flow: column
            justify-content: flex-end
            align-items: flex-end
            width: 100%
            
            @media (max-width: 578px)
                justify-content: flex-start
                align-items: flex-start
            h3
                padding: 60px 0
                text-align: right
                margin: 0
                font-size: 24px
                font-family: 'GloberxBold'
                @media (max-width: 578px) 
                    padding: 40px 0
                    text-align: left
            .download-pdf-wrapper-btn
                display: flex
                flex-flow: column
                justify-content: flex-end
                align-items: flex-end
                color: pleo-dark-green
                width: 100%
                @media (max-width: 578px) 
                    justify-content: flex-start
                    align-items: flex-start
                .button-violet-price
                    width: 155px
                    height: 33px
                    color: pleo-dark-green
                    text-align: center
                    justify-content: center
                    align-items: center
                    display: flex
                    background-color: #8a73fb
                    margin: 45px 0 0 0
                    -webkit-box-shadow: 0px 3px 0px 0px rgba(11,23,28,1);
                    -moz-box-shadow: 0px 3px 0px 0px rgba(11,23,28,1);
                    box-shadow: 0px 3px 0px 0px rgba(11,23,28,1);
                    @media(max-width: 1304px)
                        margin: 20px 0 0 0
                    @media(max-width: 1141px)
                        margin: 48px 0 0 0
                    @media(max-width: 1024px)
                        margin: 65px 0 0 0
                    @media (max-width: 872px) 
                        margin: 32px 0 0 0
                    @media (max-width: 578px) 
                        margin: 0
                        justify-content: flex-start
                        align-items: flex-start
                    @media mobi
                        width: 100%
                        height: 44px
                        margin: 0
                    .button-elements  
                        width: 100%
                        align: color-interpolation-filters
                        color: pleo-dark-green
                        text-align: center

.white-button-shadow
    z-index: 2
    -webkit-box-shadow: 0px 5px 0px 0px rgba(242,242,242,1);
    -moz-box-shadow: 0px 5px 0px 0px rgba(242,242,242,1);
    box-shadow: 0px 5px 0px 0px rgba(242,242,242,1);
.blue-button-shadow
    -webkit-box-shadow: 0px 3px 0px 0px rgba(11,23,28,1);
    -moz-box-shadow: 0px 3px 0px 0px rgba(11,23,28,1);
    box-shadow: 0px 3px 0px 0px rgba(11,23,28,1);

.background-white
    background-color: white
.background-transparent
    background-color: transparent

