.card-wrapper
    flex-flow: column
    display: flex
    justify-content: center
    align-items: center
    z-index: 1
    width: 100%
    @media scr1024
        align-items: flex-start
    @media (max-width: 416px) 
        min-width: 100%
    .card-top
        max-width: 381px 
        min-width: 381px 
        height: 213px 
        margin: 0 0 20px 0
        border-radius: 20px
        background-color: white
        display: flex
        flex-flow: row
        justify-content: space-between
        align-items: center
        justify-content: flex-end
        padding: 20px 0 20px 20px
        @media scr1024
            margin: 50px 10px 0 10px
            text-align: left
        @media (max-width: 450px)
            max-width: 100%
            min-width: 100%
            margin: 50px 0 0 0
            padding: 10px 0
        &:hover
            background-color: pleo-fiolet
            .data
                color: white !important
            .method-coord-img-outline
                -webkit-box-shadow: 0px 3px 0px 0px rgba(116,97,211,1);
                -moz-box-shadow: 0px 3px 0px 0px rgba(116,97,211,1);
                box-shadow: 0px 3px 0px 0px rgba(116,97,211,1)
        .wrapper-for-data
            display: flex
            flex-flow: column
            height: 100%
            width: 50% !important
            justify-content: space-between
            .data
                color: pleo-fiolet
                font-size: 14px

            .title
                color: pleo-dark-green
                text-transform: uppercase
                font-family: 'GloberxBold'
                width: 100%
            .place
                font-size: 14px
                color: pleo-dark-green
     
.circle-wrapper
    width: 50%
    display: flex
    flex-flow: column
    justify-content: center
    align-items: center
    z-index: 1
    @media mobi 
        width: 100%
        justify-content: flex-end
    .method-coord-img-outline
        cursor: pointer
        height: 116px
        width: 116px
        border-radius: 100%
        display: flex
        align-items: center
        justify-content: center
        position: relative
        flex-direction: column
        box-shadow: 0px 5px 0px 0px rgba(178,178,178, 0.55)
        .box-cards
            width: 116px
            height: 58px
            background: linear-gradient(97deg, rgba(117,101,214,1) 0%, rgba(69,135,207,1) 100%)
            border-top-left-radius: 95px
            border-top-right-radius: 95px
            position: absolute
            top: 0px
        .box-cards2
            width: 116px
            height: 58px
            background: linear-gradient(90deg, rgba(195,81,73,1) 0%, rgba(69,135,207,1) 100%)
            border-bottom-left-radius: 95px
            border-bottom-right-radius: 95px
            position: absolute
            bottom: 0px
        .method-coord-img
            width: 110px
            height: 110px
            border-radius: 100%
            z-index: 2
            background-color: pleo-dark-green
            background-position: center
            background-size: contain
            background-repeat: no-repeat
            display: flex
            justify-content: center
            align-items: center
            position: relative
            font-family: 'GloberxBold' !important
            .arrow
                transform: rotate(180deg)
                width: 46px
                position: absolute
                top: 25px
                left: 17px
            .text
                position: absolute
                top: 47px
                left: 17px
                color: white
                font-size: 16px
                width: 70%
                line-height: 1
                font-family: 'GloberxBold' !important