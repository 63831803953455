.blog-opis-wrapper
    max-width: 100%
    overflow: hidden !important
    background: transparent
    z-index: 3
    .blog-opis-content
        max-width: 960px
        margin: 0px auto
        p
            z-index: 3
            font-family: 'GloberBook'
            line-height: 45px 
            color: pleo-blog-font
            a
                z-index: 3
                position relative
                color: pleo-fiolet
                font-family: 'GloberSemiBold'
                cursor: pointer  
            strong 
                font-family: 'GloberSemiBold' 
        ul
            list-style-type: none
            padding-left: 25px
            font-family: 'GloberBook' !important
            li
                font-size: 24px
                font-family: 'GloberBook'
                line-height: 41px
                position: relative
                line-height: 1.8
                &:before
                    content:''
                    width: 7px
                    height: 7px
                    border-radius: 100%
                    background-color: pleo-fiolet
                    position: absolute
                    left: -22px
                    top: 18px
                strong 
                    font-family: 'GloberSemiBold' 
        ol
            // list-style-type: none
            padding-left: 25px
            font-family: 'GloberBook' !important
            li
                font-size: 24px
                font-family: 'GloberBook'
                line-height: 41px
                line-height: 1.8
                strong 
                    font-family: 'GloberSemiBold' 

@media (max-width: 1440px)
    .blog-opis-wrapper
        .blog-opis-content
            max-width: 768px

@media (max-width: 768px)
    .blog-opis-wrapper
        padding-top: 0px
        padding-bottom: 0px
        .blog-opis-content
            padding: 20px 20px 0px 20px
            p
                line-height: 1.875
            ul
                font-size: 18px
                li
                    font-size: 18px
                    &:before
                        top: 14px
            ol
                font-size: 18px
                li
                    font-size: 18px

