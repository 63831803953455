.course-plan-wrapper
    display: flex
    flex-direction: column
    align-items: center 
    position: relative
    overflow: hidden
    margin-top: -44px
    .plan-outline
        position: relative
        display: flex
        flex-direction: column
        align-items: center 
        position: relative 
        width: 100%
        max-width: 1024px
        ul.plan-ul
            list-style-type: none
            width: 100%
            li.course-plan
                width: 100%
                margin: 0px auto
                border-bottom: 1px solid pleo-fiolet
                position: relative
                overflow: hidden
                z-index: none
                .article-title
                    display: flex
                    flex-direction: row
                    justify-content: space-between
                    align-items: center
                    padding: 35px 0px 
                    margin: 0px auto
                    cursor: pointer
                    .article-title-text
                        display: flex
                        flex-direction: row
                        align-items: flex-end
                        width: calc(100% - 43px)
                        .title-number  
                            font-family: 'GloberxBold'
                            margin-right: 10px
                            margin-bottom: 0px
                        .title-date
                            font-family: 'GloberSemiBold'
                            font-size: pleo-h3
                    .title-arrow
                        width: 43px
                        height: 43px
                        border-radius: 100%
                        background-color: transparent
                        position: relative
                        .box-plan
                            width: 43px
                            height: 22px
                            background: linear-gradient(97deg, rgba(117,101,214,1) 0%, rgba(69,135,207,1) 100%)
                            border-top-left-radius: 95px
                            border-top-right-radius: 95px
                            position: absolute
                            top: 0px
                        .box2-plan
                            width: 43px
                            height: 21px
                            background: linear-gradient(90deg, rgba(195,81,73,1) 0%, rgba(69,135,207,1) 100%)
                            border-bottom-left-radius: 95px
                            border-bottom-right-radius: 95px
                            position: absolute
                            bottom: 0px
                        &:before
                            content: ''
                            width: 2px
                            height: 23px
                            background-color: pleo-fiolet
                            position: absolute
                            bottom: 50%
                            left: 50%
                            transform: translate(-50%,50%) rotate(0deg)
                            transform-origin: center
                            transition: transform 0.3s
                            z-index: 1
                        &:after
                            content: ''
                            width: 23px
                            height: 2px
                            background-color: pleo-fiolet
                            position: absolute
                            top: 50%
                            left: 50%
                            transform: translate(-50%,-50%) rotate(0deg)
                            transform-origin: center
                            transition: transform .3s 

                        .title-arrow-bg
                            position: absolute
                            width: 41px
                            height: 41px
                            border-radius: 100%
                            background-color: pleo-dark-green
                            top: 0
                            right: 0
                            bottom: 0
                            left: 0
                            margin: auto
                ul.article-content
                    flex-direction: column
                    max-height: 0
                    overflow: hidden
                    padding: 0px
                    transition: .3s
                    li
                        max-width: 1140px
                        margin: 0px auto
                        display: flex
                        flex-direction: row
                        align-items: flex-start
                        max-width: 1140px
                        width: 100%
                        border-top: 1px solid rgba(255,255,255,0.1)
                        border-bottom: 1px solid #2d3f47
                        &:last-child
                            border-bottom: none
                        .tag-title
                            font-family: 'GloberxBold' !important
                            width: 40%
                            padding: 40px
                            padding-left: 0px
                            color: pleo-fiolet
                        .tag-text
                            width: 60%
                            padding: 40px 30px
                            p.tag-content
                                margin: 0px
                                line-height: 35px
        .download-pdf-wrapper-btn
            font-family: 'GloberBlack'
            width: 100%
            padding: 100px 0 0 0
            margin: 0px auto
            display: flex
            align-items: center
            justify-content: center
            .form-wrapper
                display: flex
                flex-flow: row
                z-index: 9
                padding: 5px
                .button-violet-price
                    cursor: pointer
                    width: 171px
                    color: #ffffff
                    font-family: 'globersemibold'
                    background-color: #8a73fb 
                    font-size: 12px
                    margin: 0
                    padding: 0
                    text-align: center
                    align-items: center
                    justify-content: center
                    -webkit-box-shadow: 0px 3px 0px 0px rgba(11,23,28,1);
                    -moz-box-shadow: 0px 3px 0px 0px rgba(11,23,28,1);
                    box-shadow: 0px 3px 0px 0px rgba(11,23,28,1);
                    text-align: center
                    .button-elements
                        width: 100%
                        text-align: center
                        display: flex
                        justify-content: center
                        align-items: center
                        line-height: 2
                        .arrow-on-price-button
                            width: 6px
                            height: 9px
                            margin: 2px 9px 0 0 
                            transform: rotate(90deg)
                            img
                                width: 6px
                                height: 16px

@media(max-width: 1440px)
    .course-plan-wrapper
        .plan-outline
            max-width: 768px

@media(max-width: 1245px)
    .course-plan-wrapper
        .plan-outline
            ul.plan-ul
                .course-plan
                    ul.article-content
                        li
                            .tag-title
                                width: 50%
                                padding: 40px 10px 40px 0px
                                h3
                                    font-size: 24px
                            .tag-text
                                width: 50%
@media(max-width: 1024px)
    .course-plan-wrapper
        .plan-outline
            ul.plan-ul
                .course-plan
                    width: 100%
                    padding: 0px
                    .article-title
                        padding: 35px 0px    
                        .article-title-text
                            flex-direction: row
                            align-items: flex-end
                    ul.article-content 
                        li
                            flex-direction: row
                            .tag-title
                                width: 50%
                                padding: 35px 10px 40px 0px
                            .tag-text
                                width: 50%
                                padding: 35px 20px 40px 0px

@media (max-width: 768px)
    .course-plan-wrapper
        .plan-outline
            width: 100%
            ul.plan-ul
                width: 100%
                padding-left: 20px
                padding-right: 20px
                .course-plan
                    .article-title
                        padding: 35px 25px
                        h1.title-number 
                            font-size: 36px !important
                        h2.title-date
                            font-size: 18px !important
                    ul.article-content
                        li
                            flex-direction: column
                            &:nth-child(odd)
                                background: transparent
                            .tag-title
                                width: 100%
                                padding: 35px 30px 10px 30px
                                h3
                                    font-size: 18px !important
                            .tag-text
                                width: 100%
                                padding: 10px 30px 40px 30px
                                p
                                    font-size: 14px !important 
                .download-pdf-wrapper-btn
                    width: 100%
@media (max-width: 540px)
    .course-plan-wrapper
        .plan-outline
            ul.plan-ul 
                width: 100%
                padding-left: 20px
                padding-right: 20px
                li.course-plan
                    .article-title
                        padding-bottom: 20px 
                        .article-title-text
                            flex-direction: column
                            align-items: flex-start
                    ul.article-content
                        li
                            flex-direction: column
                            .tag-title
                                padding: 40px 0px 0px 0px
                                width: 100%
                            .tag-text
                                padding: 0px 0px 40px 0px
                                width: 100%