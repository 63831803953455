section.six-pic-wrap
    max-width: 1440px
    margin: 0px auto
    padding-top: 0px
    overflow: hidden
    ul.six-pic
        list-style-type: none
        margin: 0px auto
        padding-top: 100px
        display: grid
        grid-template-columns: auto auto auto
        grid-template-rows: auto auto
        grid-row-gap: 60px
        justify-items: center
        align-items: center
        li.six-pic-item
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            width: 300px
            .six-pic-p
                text-align: center
                max-width: 300px
                margin-bottom: 0px
                margin: 0px auto
                padding-top: 50px

@media (max-width: 1440px)
    section.six-pic-wrap
        header.six-pic-title
            max-width: 768px
            .section-number
                left: -140px
        ul.six-pic
            margin: 0px auto
            padding: 100px 100px 0px 100px


@media (max-width: 1245px) 
    section.six-pic-wrap
        ul.six-pic
            margin: 0px auto
            padding: 0px
            padding-top: 100px

@media (max-width: 1024px)
    section.six-pic-wrap
        ul.six-pic
            grid-template-columns: auto auto
            grid-template-rows: auto auto auto
            li.six-pic-item
                display: flex
                .six-pic-p
                    padding-top: 40px

@media (max-width: 767px)
    section.six-pic-wrap
        padding-bottom: 0px
        header.six-pic-title
            padding-left: 25px
            .section-number
                margin-left: 0px
                padding-bottom: 60px
            h3
                font-size: 36px
                width: auto
                padding-right: 20px
                margin: 0px
        ul.six-pic
            padding: 30px 30px 0 0
            grid-template-columns: auto
            justify-content: flex-start
            grid-row-gap: 0px
            width: 100%
            li.six-pic-item
                padding: 5px 0
                width: 100%
                flex-direction: row
                justify-content: flex-start
                min-height: 100px
                .six-pic-img
                    width: 100px
                    min-width: 101px
                    display: flex
                    justify-content: center
                    img
                        transform: scale(0.6)
                .six-pic-p
                    text-align: left
                    padding-left: 10px
                    padding-top: 0px
                    max-width: 100%
                    margin: 0px 0px
                    font-size: 18px !important
