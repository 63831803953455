.map-elements-wrapper
    position: fixed
    width: 100%
    min-height: 600px
    max-height: 1500px
    background-color: white
    position: relative
    padding: 100px 0 0 0
    .emement-map-wrapper
        max-width: 912px
        min-height: 100%
        margin: 0 auto
        align-items: center
        display: flex
        @media (max-width: 1440px)
            margin: 0 10%
        @media tablet
            margin: 0 3%
        @media (max-width: 500px)
            flex-flow: column
        @media mabi
            margin: 0 6%
        .text
            display: flex
            flex-flow: column
            justify-content: flex-start
            align-items: flex-start
            line-height: 1
            @media (max-width: 850px)
                display: none
            h1
                p
                    font-family: 'GloberxBold' 
                    color: pleo-dark-green
                    padding: 0
            p
                font-family: 'GloberRegular' 
                color: pleo-dark-green
            .form-button
                width: 100%
                display: flex
                flex-flow: row
                justify-content: flex-start
                align-items: flex-start
                text-align: center
                @media (max-width: 1142px)
                    flex-flow: column
                .button-violet
                    width: 226px
                    color: #ffffff
                    font-family: 'globersemibold'
                    margin: 10px 0 0 0
                    padding: 0
                    text-align: center
                    align-items: center
                    justify-content: center
                    -webkit-box-shadow: 0px 5px 5px 0px rgba(188,188,188,1);
                    -moz-box-shadow: 0px 5px 5px 0px rgba(188,188,188,1);
                    box-shadow: 0px 5px 5px 0px rgba(188,188,188,1);
                    .button-elements
                        width: 100%
                        display: block
                        text-align: center
                        .arrow-on-price-button
                            width: 6px
                            height: 9px
                            margin: -4px 11px 0 0 
                            img
                                width: 6px
                                height: 16px 
    .map-wrapper
        position: absolute
        top: 0
        right:0
        width: 50%
        min-height: 600px
        max-height: 1500px
        @media (max-width: 850px)
            bottom: 0
            left: 0
            width: 100%
        #map
            width: 100%
            height: 600px




.map-elements-wrapper2
    width: 100%
    background-color: white
    position: relative
    display: none
    @media (max-width: 850px)
        display: flex
    .emement-map-wrapper2
        max-width: 1045px
        width: 100%
        margin: 0 auto
        padding: 50px 0
        display: flex
        @media (max-width: 1440px)
            margin: 0 10%
        @media tablet
            margin: 0 3%
        @media mabi
            margin: 0 6%
        .text
            display: flex
            flex-flow: column
            justify-content: flex-start
            align-items: flex-start
            h1
                margin: 0 0 50px 0
                p
                    font-family: 'GloberxBold' 
                    color: pleo-dark-green
            p
                font-family: 'GloberRegular'
                color: pleo-dark-green
                margin: 0
            .form-button
                width: 100%
                display: flex
                flex-flow: row
                justify-content: flex-start
                align-items: flex-start
                text-align: center
                cursor: pointer
                @media (max-width: 1142px)
                    flex-flow: column
                @media tablet
                    margin: 50px 0 0 0
                .button-violet
                    cursor: pointer
                    width: 226px
                    color: #ffffff
                    font-family: 'GloberxBold'
                    margin: 10px 0 0 0
                    padding: 0
                    text-align: center
                    align-items: center
                    justify-content: center
                    -webkit-box-shadow: 0px 5px 5px 0px rgba(188,188,188,1);
                    -moz-box-shadow: 0px 5px 5px 0px rgba(188,188,188,1);
                    box-shadow: 0px 5px 5px 0px rgba(188,188,188,1);
                    .button-elements
                        width: 100%
                        display: block
                        text-align: center
                        cursor: pointer
                        .arrow-on-price-button
                            width: 6px
                            height: 9px
                            margin: -4px 11px 0 0 
                            img
                                width: 6px
                                height: 16px 