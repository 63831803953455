footer 
    max-width: 1440px
    margin: 0px auto
    #scroll-up 
        display: none; 
        position: fixed; 
        z-index: 199; 
        border: none; 
        outline: none; 
        background-color: pleo-filoet2 
        font-size: 18px; 
        position: fixed;
        bottom: 20px
        right: 90px
        -webkit-transition: .8s all ease;
        transition: .8s all ease;
        z-index: 99;
        border-radius: 50%
        cursor: pointer;
        padding: 0;
        height: 42px;
        width: 42px;
        -webkit-box-shadow: 0px 3px 0px 0px rgba(116,97,211,1);
        -moz-box-shadow: 0px 3px 0px 0px rgba(116,97,211,1);
        box-shadow: 0px 3px 0px 0px rgba(116,97,211,1);
        @media mobi 
            display: none !important
        .fa-chevron-up
            font-size: 12px
            color: white
            padding: 0 0 12px 0
    .footer-wrapper
        margin: 0px auto
        display: flex
        flex-direction: column
        .contact-wrapper
            border-bottom: 1px solid pleo-fiolet
            .contact
                width: 1245px
                display: flex
                flex-direction: row
                justify-content: space-between
                padding: 160px 16px 70px 16px
                margin: 0px auto
                ul
                    list-style-type: none
                    margin-bottom: 0px
                    li
                        font-family: 'GloberRegular'
                        font-size: 19px
                        &:first-child
                            margin-bottom: 35px
                        p
                            margin-bottom: 0px !important
                        a
                            color: white
                            cursor: pointer

        .pleo-group
            width: 1245px
            margin: 0px auto
            padding: 50px 0 50px 16px
            display: flex
            flex-direction: row
            justify-content: space-between
            align-items: center
            .pleo-group-list
                .pleo-group-napis
                    padding: 0
                    // border: 1px solid red
                    font-family: 'GloberRegular'
                    font-size: 19px
            .logo-wrapper
                display: flex
                flex-flow: row
                width: 100%
                :nth-child(4)
                    overflow: hidden
                    .footer-img
                        width: 100%
                        height: 86%;
                a
                    width: 100px
                    // height: 17px
                    margin: 0 16px
                    padding: 0
                    display: flex
                    .footer-img
                        width: 100%
                        height: 100%
                        padding: 0
                        margin: 0
            .all-rights  
                font-family: 'GloberRegular'
                font-size: 11px
                padding: 0px
                margin: 0px
                text-align: right
                width: auto


@media (max-width: 1440px)
    footer 
        .footer-wrapper
            .contact-wrapper
                .contact
                    max-width: 1024px
            .pleo-group
                max-width: 1024px
@media (max-width: 1245px)
    footer 
        .footer-wrapper
            .contact-wrapper
                .contact
                    width: auto
                    justify-content: flex-start
                    flex-wrap: wrap
                    padding-top: 100px
@media (max-width: 1024px)
    footer 
        .footer-wrapper
            .contact-wrapper
                .contact
                    padding: 160px 0px 70px 0px
                    ul
                        padding: 20px 20px
            .pleo-group
                width: 100%
                padding: 50px 20px
                flex-flow: column wrap
                align-items: flex-start
                .logo-wrapper
                    // width: 60%
                    flex-flow: row wrap
                    justify-content: space-between
                    padding: 40px 40px  25px 40px
                    a
                        margin: 40px 16px
                .all-rights
                    width: 100%
                    padding: 0 16px
@media (max-width: 375px)
    footer 
        .footer-wrapper
            .pleo-group
                width: 100%
                padding: 50px 5px
                flex-flow: column wrap
                align-items: flex-start
                .pleo-group-list
                    .pleo-group-napis
                        padding: 0 20px
                        // border: 1px solid red
                        font-family: 'GloberRegular'
                        font-size: 19px
                .logo-wrapper
                    // width: 60%
                    flex-flow: row wrap
                    padding: 0px 40px  25px 10px
                    a
                        margin: 30px 16px
                .all-rights
                    width: 100%
@media (max-width: 360px)
    footer 
        .footer-wrapper
            .pleo-group
                .logo-wrapper
                    padding: 40px 10px  25px 10px
// @media (max-width: 880px)
//     footer 
//         .footer-wrapper
//             .pleo-group
//                 flex-flow: column
//                 align-items: flex-start
//                 .logo-wrapper
//                     // width: 60%
//                     padding: 50px 40px 
                    // a
                    //     margin: 40px 160px 4px 0
                // padding-top: 100px
            //     ul.pleo-group-list
            //         justify-content: flex-start
            //         flex-wrap: wrap
            //         position: relative
            //         li
            //             width: 50%
            //             padding: 0px 40px
            //             margin-bottom: 35px
            //             &:first-child
            //                 position: absolute
            //                 top: -35px
            //                 padding-left: 20px
            //             p
            //                 margin-top: -37px
                

// @media (max-width: 768px)
//     footer 
//         .footer-wrapper
//             .contact-wrapper
//                 .contact
//                     padding-top: 60px
//                     ul
//                         width: 100%
//                         li
//                             &:first-child
//                                 margin: 0px
//                                 font-weight: 600
            // .pleo-group
            //     padding-top: 80px
                    
            