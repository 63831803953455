section.blog-post-tytul-artykulu-wrapper
    container-position()
    border-top: 2px solid #e8e8e8
    .blog-post-tytul-artykulu
        max-width: 1410px
        min-height: 80px
        margin: 5px auto
        font-family: 'GloberHeavy'
        line-height: 97px
        padding-top: 75px
        padding-bottom: 60px
        color: pleo-blog-font
        

@media (max-width: 1440px)
    section.blog-post-tytul-artykulu-wrapper
        .blog-post-tytul-artykulu
            max-width: 1140px

@media (max-width: 1245px)
    section.blog-post-tytul-artykulu-wrapper
        .blog-post-tytul-artykulu
            max-width: 768px

@media (max-width: 768px) 
    section.blog-post-tytul-artykulu-wrapper
        .blog-post-tytul-artykulu
            font-size: 60px
            line-height: 70px
            padding: 50px 20px 0px 20px
            padding-bottom: 0px !important






