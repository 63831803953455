section.faq-wrapper
    max-width: 1024px
    margin: 0px auto
    .faq-content
        display: flex
        flex-direction: row
        ul.faq-questions
            width: 56%
            list-style-type: none
            padding-top: 25px
            li.faq-questions-item
                // border-top: 1px solid #2d3f47
                // border-bottom: 1px solid #2d3f47
                &:first-child
                    header.faq-quest-title
                        border-top: 1px solid transparent
                &:nth-last-child(2)
                    border-bottom: 1px solid #2d3f47
                &:last-child
                    border-bottom: 1px solid #2d3f47
                    display: none
                header.faq-quest-title
                    cursor: pointer
                    height: 60px
                    display: flex
                    flex-direction: row
                    justify-content: space-between
                    align-items: center
                    border-top: 1px solid #2d3f47
                    border-bottom: 1px solid transparent
                    .faq-quest-text
                        display: flex
                        align-items: center
                        width: calc(100% - 42px) !important
                        h5.faq-quest-text-name
                            font-family: 'GloberxBold'
                            color: pleo-fiolet
                            margin: 0px
                    span.title-arrow
                        width: 42px
                        height: 42px
                        border-radius: 100%
                        background-color: transparent
                        position: relative
                        background: transparent
                        border: 1px solid pleo-fiolet
                        &:before
                            content: ''
                            width: 2px
                            height: 23px
                            background-color: pleo-fiolet
                            position: absolute
                            bottom: 50%
                            left: 50%
                            transform: translate(-50%,50%) rotate(0deg)
                            transform-origin: center
                            transition: transform 0.3s
                            z-index: 1
                        &:after
                            content: ''
                            width: 23px
                            height: 2px
                            background-color: pleo-fiolet
                            position: absolute
                            top: 50%
                            left: 50%
                            transform: translate(-50%,-50%) rotate(0deg)
                            transform-origin: center
                            transition: transform .3s 
                .faq-quest-answer
                    display: flex 
                    margin-bottom: 0px
                    max-height: 0px
                    overflow: hidden
                    padding: 0px
                    transition: .3s
                    font-family: 'GloberRegular'
                    line-height: 29px
        article.faq-coord-wrapper
            width: 44%
            padding-left: 95px
            margin-top: -85px
            .faq-coord-img-outline
                width: 190px
                height: 190px
                border-radius: 100%
                display: flex
                align-items: center
                justify-content: center
                position: relative
                flex-direction: column
                .box-faq
                    width: 190px
                    height: 96px
                    background: linear-gradient(97deg, rgba(117,101,214,1) 0%, rgba(69,135,207,1) 100%)
                    border-top-left-radius: 95px
                    border-top-right-radius: 95px
                    position: absolute
                    top: 0px
                .box2-faq
                    width: 190px
                    height: 95px
                    background: linear-gradient(90deg, rgba(195,81,73,1) 0%, rgba(69,135,207,1) 100%)
                    border-bottom-left-radius: 95px
                    border-bottom-right-radius: 95px
                    position: absolute
                    bottom: 0px
                .faq-coord-img
                    width: 180px
                    height: 180px
                    border-radius: 100%
                    z-index: 2
                    background-color: white
                    background-position: center
                    background-size: contain
                    background-repeat: no-repeat
            .faq-coord-text
                h4.faq-coord-text-h4
                    font-family: 'GloberxBold'
                    font-size: 24px
                    margin: 20px 0px
                .faq-coord-text-p
                    font-family: 'GloberRegular'
                    margin-bottom: 20px     
            .faq-coord-contact
                .p1
                    font-family: 'GloberxBold'
                    color: pleo-fiolet
                .p2
                    font-family: 'GloberRegular'
                    margin: 0px 

@media (max-width: 1440px)
    section.faq-wrapper
        .faq-content
            max-width: 768px
            margin: 0px auto
            // ul.faq-questions
            //     li.faq-questions-item
            //         header.faq-quest-title   
            //             .faq-quest-text   
            //                 h3.faq-quest-text-name
            //                     max-width: 375px

@media (max-width: 768px) 
    section.faq-wrapper
        padding: 0px 20px
        header.faq-title
            .section-number
                margin-left: 0px
                padding-bottom: 60px
            h1
                font-size: 36px
                width: auto
                padding-right: 20px
        .faq-content
            flex-direction: column
            ul.faq-questions
                width: 100%
            article.faq-coord-wrapper
                width: 100%
                margin-top: 0px
                padding-left: 0px
                padding-top: 50px
                .faq-coord-text
                    max-width: 70%
// @media (max-width: 475px)
//     section.faq-wrapper
//         .faq-content
//             ul.faq-questions
//                 li.faq-questions-item
//                     header.faq-quest-title   
//                         .faq-quest-text   
//                             h3.faq-quest-text-name
//                                 max-width: 270px
//                                 font-size: 14px