.wrapper-for-small-image
    container-position()
    display: flex
    flex-flow: column
    align-items: center
    justify-content: center
    margin: 0 auto
    max-width: 960px
    @media (max-width: 1440px)
        max-width: 768px
    @media tablet
        padding: 0 20px
    a
        cursor: pointer
        text-decoration: none
    .image
        max-width: 100%
        width: 100%
        margin: 0px auto
    .small-image-description
        padding: 10px 0
        font-family: 'GloberBook'
        width: 100%
        text-align: center
        color: pleo-dark-green

@media (max-width: 768px)
    section.photo-or-gallery-wrapper
        .photo-or-gallery
            max-width: 100%
            padding: 0px 20px