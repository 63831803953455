section.photo-or-gallery-wrapper
    container-position()
    display: flex
    flex-flow: column
    align-items: center
    justify-content: center
    a
        text-decoration: none
        cursor: pointer
    .photo-or-gallery
        max-width: 1440px
        width: 100%
        margin: 0px auto
    .podpis-pod-zdjeciem
        padding: 10px 0
        font-family: 'GloberBook'
        width: 100%
        text-align: center
        color: pleo-dark-green
@media (max-width: 768px)
    section.photo-or-gallery-wrapper
        .photo-or-gallery
            max-width: 100%
            padding: 0px 20px
