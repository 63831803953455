section.blog-post-footer-wrapper
    container-position()
    padding-bottom: 20px
    .blog-post-footer 
        max-width: 1140px
        margin: 0px auto
        display: flex
        justify-content: space-between
        border-top: 2px solid #dcdcdc
        height: 137px
        padding-top: 45px
        .blog-post-footer-left
            display: flex
            flex-direction: row
            align-items: center
            .blog-post-footer-author-img-wrapper
                width: 88px
                height: 88px
                border-radius: 100%
                display: flex
                align-items: center
                justify-content: center
                position: relative
                flex-direction: column
                margin-right: 10px
                .box-post-footer-auth
                    width: 88px
                    height: 44px
                    background: linear-gradient(97deg, rgba(117,101,214,1) 0%, rgba(69,135,207,1) 100%)
                    border-top-left-radius: 95px
                    border-top-right-radius: 95px
                    position: absolute
                    top: 0px
                .box2-post-footer-auth
                    width: 88px
                    height: 44px
                    background: linear-gradient(90deg, rgba(195,81,73,1) 0%, rgba(69,135,207,1) 100%)
                    border-bottom-left-radius: 95px
                    border-bottom-right-radius: 95px
                    position: absolute
                    bottom: 0px
                .blog-post-footer-auth-img
                    width: 84px
                    height: 84px
                    border-radius: 100%
                    z-index: 2
                    background-position: center !important
                    background-size: cover !important
                    background-repeat: no-repeat !important
            .blog-post-footer-author-info 
                display: flex
                flex-direction: column
                p.blog-post-who
                    font-size: 14px
                    color: #000
                    font-family: 'GloberRegular'
                    margin-bottom: 16px
                .blog-post-footer-author-name
                    font-family: 'GloberxBold'
                    font-size: 14px
                    color: pleo-fiolet
                    text-transform: uppercase
                .blog-post-footer-author-quote
                    font-family: 'GloberRegular'
                    font-size: 14px
                    color: #000
        .blog-post-footer-right
            display: flex
            flex-direction: column
            align-items: flex-end
            justify-content: flex-end
            .blog-post-footer-share-text
                color: #000
                margin: 0px 6px
                font-family: 'GloberRegular'
                font-size: 14px
                margin-bottom: 20px
            ul.blog-post-footer-share-icons
                list-style-type: none
                display: flex
                flex-direction: row
                margin: 0px
                li.blog-post-footer-share-single-icon
                    width: 38px
                    height: 38px
                    margin: 0px 6px
                    background: white
                    border-radius: 38px
                    position: relative
                    a
                        position: absolute
                        font-size: 0px
                        width: 38px
                        height: 38px
                        background-size: contain !important
                        background-repeat: no-repeat !important
                        background-position: center !important

@media (max-width: 1440px)
    section.blog-post-footer-wrapper
        width: 100%
        overflow: hidden
        padding-bottom: 20px
        .blog-post-footer 
            max-width: 768px
            
@media (max-width: 768px)
    section.blog-post-footer-wrapper
        .blog-post-footer 
            padding-left: 20px
            padding-right: 20px
            height: auto
            flex-direction: column
            .blog-post-footer-right
                flex-direction: row
                justify-content: flex-start
                align-items: flex-end
                padding: 20px 0px
                .blog-post-footer-share-text
                    margin: 0px

                    
                    