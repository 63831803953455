.contact-us
    width: 100%
    min-height: 440px
    background-color: #e0e0e0
    padding: 50px 0
    @media tablet
        padding: 10px 0
    .contact-us-wrapper
        max-width: 912px
        border: none
        margin: 0 auto
        display: grid
        grid-template-columns: 1fr 1fr
        grid-template-rows: 1fr
        position: relative
        padding: 40px 0
        @media (max-width: 1440px)
            margin: 0 10%
        @media tablet
            margin: 0 3%
            grid-template-columns: 1fr
            row-gap: 50px
        @media mabi
            margin: 0 6%
        &:before
            content: ''
            height: 2px
            width: 40px
            background: pleo-fiolet
            position: absolute
            top: 22%
            left: 0
            @media tablet
                top: 10%
        .contact-us-text
            display: flex
            justify-content: center
            align-items: center
            width: 100%
            @media tablet
                justify-content: flex-start
            p
                font-family: 'GloberRegular'
                color: pleo-dark-green
                padding: 0
                @media(max-width: 928px)
                    padding: 50px 0 0 0
        .contact-form-wrapper
            width: 100%
            display: flex
            align-items: center
            flex-flow: column
            border: none
            .contact-form
                width: 80%
                max-width: 636px
                text-align: center
                display: grid
                grid-template-columns: 225px 225px
                grid-template-rows: 1fr
                column-gap: 38px
                margin: 0 0 30px 0
                @media tablet
                    grid-template-columns: 1fr 1fr 
                    max-width: 100%
                    width: 100%
                input
                    font-size: 12px
                    outline: none
                    border: none
                    border-bottom: 2px solid white
                    background-color: transparent
                    line-height: 2
                    text-align: center
                    &:focus
                        border-bottom: 2px solid pleo-fiolet
                        color: pleo-dark-green
                    input::placeholder
                        color: #afafaf
                        text-align: center
                        font-family: 'GloberRegular'
                        font-size: 12px
                    &:-webkit-autofill,
                    &:-webkit-autofill:hover,
                    &:-webkit-autofill:focus
                        border-bottom: 2px solid #ffffff
                        text-align: center
                        -webkit-text-fill-color: pleo-dark-green;
                        -webkit-box-shadow: 0 0 0px 1000px transparent inset;
                        transition: background-color 5000s ease-in-out 0s;
                        
            .contact-form-second
                width: 80%
                max-width: 636px
                text-align: center
                display: grid
                column-gap: 38px
                grid-template-columns: 488px
                @media tablet
                    grid-template-columns: 1fr
                    max-width: 100%
                    width: 100%
                textarea
                    border: none
                    outline: none
                    background-color: transparent
                    padding: 0
                    border-bottom: 2px solid white
                    text-align: center
                    margin: 0 0 30px 0
                    font-size: 12px
                    &:focus
                        border-bottom: 2px solid pleo-fiolet
                        color: pleo-dark-green
                    textarea::placeholder
                        font-size: 12px
                        color: #afafaf
                        text-align: center
                        font-family: 'GloberRegular'
            .contact-form-third
                width: 80%
                max-width: 636px
                text-align: center
                display: grid
                grid-template-columns: 473px
                position: relative
                @media tablet
                    grid-template-columns: 1fr
                    max-width: 100%
                    width: 100%
                .input-radio
                    padding: 0 0 0 30px 
                    display: flex
                    flex-flow: row
                    justify-content: center
                    position: relative
                    .defoult-checkbox
                        position: absolute
                        top: 4px
                        left: 3px
                    .rodo
                        position: relative
                        color: pleo-dark-green 
                        text-align: justify
                        padding: 0 0 0 10px
                        width: 100%
                        font-size: 12px
                        a
                            text-decoration: none
                            color: pleo-fiolet
            .form-button
                width: 80%
                text-align: center
                display: grid
                grid-template-columns: 225px 225px
                grid-template-rows: 1fr
                column-gap: 95px
                position: relative
                @media tablet
                    grid-template-columns: 1fr
                    column-gap: 30px
                    max-width: 100%
                    width: 100%
                @media (max-width:519px)
                    grid-template-columns: 1fr
                .recaptcha
                    border: none
                    margin: 0 37px 0 0
                    @media tablet
                        margin: 0 0 20px 0
                .button-violet
                    cursor: pointer
                    width: 171px
                    color: #ffffff
                    font-family: 'globersemibold' 
                    margin: 0
                    padding: 0
                    text-align: center
                    align-items: center
                    justify-content: center
                    -webkit-box-shadow: 0px 5px 0px 0px rgba(188,188,188,1);
                    -moz-box-shadow: 0px 5px 0px 0px rgba(188,188,188,1);
                    box-shadow: 0px 5px 0px 0px rgba(188,188,188,1);
                    .button-elements
                        width: 100%
                        display: block
                        text-align: center
                        .arrow-on-price-button
                            width: 6px
                            height: 9px
                            margin: -4px 11px 0 0 
                            img
                                width: 6px
                                height: 16px



input[type=checkbox] 
    opacity: 1
    cursor: pointer
    position: relative
    top: 0px
    left: -13px
    @media tablet
        left: 0px
        top: -2px
.check-contact-us
    position: absolute
    top: 0px
    left: 0px
    height: 19px
    width: 19px
    background-color: #e0e0e0
    border: 1px solid #fff
    border-radius: 2px   
    cursor: pointer
    @media tablet
        left: 0px
        top: 2px
    .crossOne
        visibility: hidden
        position: absolute
        left: 0px
        top: 0px
        width: 19px
        height: 19px
        z-index: 100
        .fa-times
            color: pleo-fiolet
            position: absolute
            top: 0px
            left: 2px
            font-size: 17px
