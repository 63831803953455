

.filter-visible
    height: 200px !important
    li
        display: flex !important

// @media (min-width: 1440px)
//     section.filter-tools-wrapper
//         .filter-tools
//             max-width: 1400px !important


section.filter-tools-wrapper
    width: 100%
    min-height: 50px
    padding-top: 60px
    // border: 1px solid red
    // padding-bottom: 60px
    .filter-tools
        max-width: 1140px !important
        display: flex
        justify-content: space-between
        margin: 0px auto !important
        padding: 0px 0px
        // border: 1px solid green
        .filter-tools-list
            display: flex
            align-items: center
            h3.filter-list-text
                margin-bottom: 0px
                text-transform: uppercase
                font-family: 'GloberxBold'
                font-size: 24px
                padding-right: 20px
            .dropdown
                margin: 0px
                cursor: pointer
                .dt
                    display: none
                    width: 180px
                    margin-top: 3px
                .dd
                    ul.filter-list
                        list-style-type: none
                        display: flex
                        align-items: center
                        margin-bottom: 0px
                        z-index: 10
                        li
                            padding-left: 12px
                            padding-right: 12px
                            height: 100%
                            text-transform: uppercase
                            font-family: 'GloberRegular'
                            font-size: 14px
                            position: relative
                            cursor: pointer
                            color: white
                            &:after
                                content: ''
                                position: absolute
                                height: 3px
                                width: calc(100% - 32px - 24px) //100% - 16px na strone z psd - 12px na strone padding
                                background: pleo-fiolet
                                top: -7px
                                left: 50%
                                transform: translateX(-50%)
                                display: none
                            &:hover:after
                                display: inline-block
                            a
                                color: white
                                font-family: 'GloberRegular'
                                font-size: 14px
                                &:hover
                                    color: pleo-fiolet
                                    font-family:'GloberBlack'
                            p
                                padding: 0px
                                margin: 0px
                                text-transform: uppercase
                                font-family: 'GloberRegular'
                                font-size: 14px
                                &:hover
                                    color: pleo-fiolet
                                    font-family:'GloberBlack'
        .filter-tools-search
            position: relative
            &:after
                content:''
                position: absolute
                height: 18px
                width: 18px
                border: 2px solid pleo-fiolet
                border-radius: 18px
                top: 9px
                right: 20px
            &:before
                content:'' 
                position: absolute
                height: 6px
                width: 13px
                border: 2px solid pleo-fiolet
                right: 9px
                top: 15px
            input.filter-tools-search
                width: 250px
                height: 32px
                border: 2px solid pleo-fiolet
                border-radius: 100px
                font-family: 'GloberRegular'
                font-size: 12px
                padding-left: 24px
                background: transparent
                color: white
                &::placeholder
                    color: white

@media (max-width: 1145px)
    section.filter-tools-wrapper
        .filter-tools
            justify-content: space-around
            .filter-tools-list
                align-items: flex-start
                .dropdown
                    margin: 0px
                    .dt
                        color: #FFF
                        text-transform: uppercase
                        font-family: 'GloberRegular'
                        font-size: 14px
                        display: block
                        width: 130px
                        text-decoration: none
                        position: relative
                        &:after
                            content: ''
                            z-index: 15
                            position: absolute
                            height: 8px
                            width: 2px
                            background: #FFF
                            border-radius: 100px
                            top: 29%
                            right: 0px
                            transform: rotate(-45deg)
                        &:before
                            content: ''
                            z-index: 15
                            position: absolute
                            height: 8px
                            width: 2px
                            background: #FFF
                            border-radius: 100px
                            right: -5px
                            bottom: 32%
                            transform: rotate(45deg)

                    .dd
                        ul.filter-list
                            flex-direction: column
                            align-items: left
                            list-style:none
                            background: pleo-dark-green
                            padding: 10px 20px 5px 0px
                            left: 0px
                            top: -5px
                            width: auto 
                            min-width:130px

                            height: 0px
                            transition: .2s
                            li
                                padding: 10px 0px
                                height: 100%
                                width: 100%
                                text-transform: uppercase
                                font-family: 'GloberRegular'
                                font-size: 14px
                                position: relative
                                cursor: pointer
                                color: white

                                display: none
                                &:hover:after
                                    display: none
                                a
                                    color: white
                                p
                                    padding: 0px
                                    margin: 0px
                                    text-transform: uppercase
                                    font-family: 'GloberRegular'
                                    font-size: 14px   
@media (max-width: 768px)
    section.filter-tools-wrapper
        .filter-tools
            flex-direction: column-reverse
            align-items: center
            form.filter-tools-search
                padding-bottom: 25px
            




