.co-zyskujesz-wrapper
    background: transparent
    padding: 0px
    padding-top: 85px
    position: relative 
    ul.co-zyskujesz-list
        max-width: 1440px
        margin: 0px auto
        display: flex
        flex-direction: row
        flex-wrap: wrap
        justify-content: space-around
        padding: 0px 120px 0 100px
        @media (max-width: 1440px)
            margin: 0 6%
        @media scr1024
            margin: 0 9%
        @media tablet
            margin: 0 3%
        @media mobi
            margin: 0 6%

        li
            height: 250px
            width: 250px
            margin: 20px 30px
            margin-left: 45px
            list-style-type: none
            display: flex
            align-items: center
            justify-content: center
            padding: 40px
            color: pleo-dark-green
            border-radius: 100%
            position: relative
            &:hover
                .arrow-wrapper
                    background: linear-gradient(235deg, rgba(138,115,251,1) 0%, rgba(44,186,216,1) 68%, rgba(235,89,77,1) 100%)
                    .arrow1
                        animation: rotate .2s linear 1
                        transform-origin: bottom
                        animation-direction: alternate     
                        animation-fill-mode: forwards  
            .arrow-wrapper
                height: 170px
                width: 170px
                border-radius: 100%
                background: transparent
                position: absolute
                left: 0
                right: 0
                margin-left: auto
                margin-right: auto 
                display: flex
                flex-direction: column
                align-items: center
                justify-content: center
                .arrow-wrapper-bg
                    height: 168px
                    width: 168px
                    border-radius: 100%
                    background-color: #ffffff
                    position: absolute
                    top: 0
                    right: 0
                    bottom: 0
                    left: 0
                    margin: auto
                .arrow1
                    position: absolute
                    width: 1px
                    height: 57%
                    left: 50%
                    right: 50%
                    bottom: 50%
                    animation: no-rotate .2s linear 1
                    transform-origin: bottom
                    animation-direction: alternate     
                    animation-fill-mode: forwards  
                    span
                        width: 16px
                        height: 26px
                        background: url(../img/right-b-arrow.svg)
                        background-repeat: no-repeat
                        position: absolute
                        left: -10px    
                        transform: rotate(180deg)
                .arrow2
                    position: absolute
                    width: 1px
                    height: 57%
                    left: 50%
                    right: 50%
                    bottom: 50%
                    span
                        background: url(../img/right-v-arrow.svg)
                        background-repeat: no-repeat
                        width: 16px
                        height: 26px
                        position: absolute
                        left: -10px
                .text6 
                    display: flex
                    // border: 1px solid green
                    justify-content: center
                    align-items: center
                    height: 60px
                    position: relative
                    width: 100%
                    .textp2
                        font-size: 18px
                        text-align: center
                        max-height: 60px
                        position: absolute
                        left: 50%
                        top: 60%
                        transform: translate(-50%, -1%)
                        text-justify: top
                        width: 300px
                        top: -12px
                        line-height: 42px !important

@keyframes rotate { 
            0% {transform: rotate(0deg)}
            100% {transform: rotate(-180deg)}
        }

@keyframes no-rotate { 
            0% {transform: rotate(-180deg)}
            100% {transform: rotate(-360deg)}
        }


@media ( max-width: 1245px )
    .co-zyskujesz-wrapper
        ul.co-zyskujesz-list
            padding: 0px
            li
                margin-left: 30px

@media ( max-width: 768px )
    .co-zyskujesz-wrapper
        padding-top: 25px
        ul.co-zyskujesz-list
            flex-direction: column
            padding: 0px
            justify-content: flex-start
            align-items: center
            li  
                margin: 0px
                justify-content: flex-end
                flex-direction: row-reverse
                border-radius: 0px
                width: 100%
                height: 100px
                padding-left: 10px
                &:hover
                    .arrow-wrapper
                        background: transparent
                        .arrow1
                            animation: none
                            transform: none
                .arrow-wrapper
                    justify-content: flex-start
                    flex-direction: row
                    margin: 0px
                    height: 100%
                    width: 100%
                    position: relative
                    border-radius: 0px
                    .arrow-wrapper-bg
                        display: none
                    .arrow1
                        width: 0px
                        position: relative
                        left: 15px
                        right: 0px
                        bottom: 0px
                        height: 100px
                        animation: none
                        span
                            left: 0px
                            transform: translateY(-50%)
                            top: 50%
                    .arrow2
                        width: 0px
                        position: relative
                        left: 15px
                        right: 0px
                        bottom: 0px
                        height: 100px
                        span
                            left: 0px
                            transform: translateY(-50%)
                            top: 50%
                    .text6
                        position: relative
                        width: 100%
                        justify-content: flex-start
                        min-width: 300px
                        height: 100px
                        align-items: center
                        padding-left: 60px
                        .textp2
                            text-align: left
                            top: 0px
                            margin-bottom: 0px
                            max-height: inherit
                            position: relative
                            padding-bottom: 5px
                            padding-left: 20px
                            width: 100%
                            line-height: 27px !important
                            font-size: 18px !important

                            
                        



