section.square-header-text-wrap
    max-width: 100%
    margin: 0px auto
    position: relative
    padding: 0 0 90px 0
    .img-photo
        position: absolute
        background-repeat: no-repeat
        background-size: cover
        top: -156px
        right: 0px
        width: 555px
        height: 534px
        overflow: hidden
        @media(max-width: 1440px)
            right: -210px
        @media(max-width: 1245px)
            display: none
        img
            transform: rotate(90deg);
    .sq-box //overflow: hidden na element jestęmatrix
        position: absolute
        height: 800px
        background: pleo-dark-green
        left: calc(50% + 600px)
        bottom: -1px
        width: 100%
    .square-color-box
        display: block
        position: absolute
        background: transparent
        height: 100%
        width: 80%
        top: 0
    .square-content-wrap
        max-width: 1024px
        margin: 0px auto
        display: flex
        flex-direction: row
        position: inherit
        .square-text-wrap
            padding-top: 40px
            width: 70%
            .square-text
                padding-right: 90px
                p
                    margin-bottom: 0px

            
@media (max-width: 1440px)
    section.square-header-text-wrap
        .sq-box
            left: calc(50% + 600px)
        .square-color-box
            width: 100%
        header.square-title
            max-width: 768px
            .section-number
                left: -140px
        .square-content-wrap
            max-width: 768px
            .square-text-wrap
                width: 80%
                .square-text
                    padding-right: 0px

@media (max-width: 1245px)
    section.square-header-text-wrap
        .test
            display: none
        .square-content-wrap
            max-width: 768px
            .square-text-wrap
                width: 100%


@media (max-width: 1024px)
    section.square-header-text-wrap
        header.square-title
            position: relative
            flex-direction: column
            align-items: flex-start
            .section-number
                position: relative
                left: 0px !important
                padding-bottom: 40px
                margin-left: -40px
                .line
                    top: 8px
                .nr-text
                    flex-direction: row

@media (max-width: 768px)
    section.square-header-text-wrap
        header.square-title
            padding-left: 25px
            .section-number
                margin-left: 0px
                padding-bottom: 60px
            h3
                font-size: 36px
                width: auto
        .square-content-wrap
            padding: 0px 20px
            .square-text-wrap
                padding-top: 35px
                .square-text
                    padding-right: 0px