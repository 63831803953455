.image-on-right
    width: 100%
    margin: 0px auto
    padding-top: 0px
    background: transparent
    color: pleo-dark-green
    overflow: hidden
    height: auto
    .trener-wrap
        height: auto
        display: flex
        flex-direction: row
        max-width: 1024px
        margin: 0px auto
        @media (max-width: 1440px)
            max-width: 771px
        @media scr1024
            // margin: 0 9%
        @media tablet
            margin: 0 3%
            flex-flow: column
        @media mobi
            margin: 0 6%
        .trener-wrap-text
            width: 56%
            padding-top: 30px
            padding-bottom: 130px
            @media tablet
                width: 100%
                padding-bottom: 30px
            h3.trainer-name
                font-family: 'GloberBlack'
                color: pleo-fiolet
                margin-bottom: 0px
            p.trainer-short
                font-family: 'GloberRegular'
                margin-bottom: 50px
            p.trainer-info
                font-family: 'GloberRegular'
            .trainer-social-icons-wrap    
                height: 90px
                width: 140px 
                display: flex
                justify-content: flex-start
                padding: 0px
                padding-top: 20px
                li
                    height: 35px
                    width: 35px 
                    border-radius: 20px
                    list-style-type: none
                    margin-right: 18px
                    a
                        .icon
                            height: 35px
                            width: 35px
        .trener-wrap-img
            width: 44%
            height: auto
            margin-top: 0px
            position: relative
            @media tablet
                width: 100%
                height: 500px
            .trainer-photo
                background-repeat: no-repeat
                background-size: contain
                background-position: center
                width: 120%
                height: 120%
                position: absolute
                left: 97px
                @media tablet
                    width: 100%
                    height: 500px
                    left: 0
                @media mobi
                    height: 570px
            .partner-photo
                background-repeat: no-repeat
                background-position: center
                width: 120%
                height: 120%
                position: absolute
                left: 97px
                top:-150px
                @media(max-width:1080px)
                    left: 24px
                @media(max-width:950px) and (min-width:769px)
                    left: 0
                    background-size: 80%
                @media tablet
                    width: 100%
                    height: 500px
                    left: 0
                    top:-80px
                @media (max-width:400px)
                    background-size: 100%
                @media mobi
                    height: 570px
.image-on-left
    background-color: green
    width: 100%
    margin: 0px auto
    padding-top: 0px
    background: transparent
    color: pleo-dark-green
    overflow: hidden
    .trener-wrap
        display: flex
        flex-direction: row-reverse
        max-width: 1024px
        margin: 0px auto
        @media (max-width: 1440px)
            margin: 0 6%
        @media scr1024
            margin: 0 9%
        @media tablet
            margin: 0 3%
            flex-direction: column
        @media mobi
            margin: 0 6%

        .trener-wrap-text
            width: 56%
            padding-top: 30px
            padding-bottom: 130px
            @media tablet
                width: 100%
                padding-bottom: 30px
            h3.trainer-name
                font-family: 'GloberBlack'
                font-size: 24px
                color: pleo-fiolet
            p.trainer-short
                font-family: 'GloberRegular'
                font-size: 14px
                margin-bottom: 50px
            p.trainer-info
                font-family: 'GloberRegular'
                font-size: 16px
                line-height: 29px
            .trainer-social-icons-wrap    
                height: 90px
                width: 140px 
                display: flex
                justify-content: flex-start
                padding: 0px
                padding-top: 20px
                li
                    height: 35px
                    width: 35px 
                    border-radius: 20px
                    list-style-type: none
                    margin-right: 18px
                    a
                        .icon
                            height: 35px
                            width: 35px
        .trener-wrap-img
            width: 44%
            height: auto
            margin-top: 0px
            position: relative
            @media tablet
                width: 100%
                height: 500px
            .trainer-photo
                background-repeat: no-repeat
                background-size: contain
                background-position: center
                width: 120%
                height: 120%
                position: absolute
                left: -161px
                @media tablet
                    width: 100%
                    height: 500px
                    left: 0
            .partner-photo
                background-repeat: no-repeat
                background-position: center
                width: 120%
                height: 120%
                position: absolute
                left: -161px
                @media tablet
                    width: 100%
                    height: 500px
                    left: 0
                @media mobi
                    height: 570px
        
@media (max-width: 1440px)
    section.trener-wrapper
        .trener-wrap
            max-width: 768px

@media (max-width: 1245px) 
    section.trener-wrapper
        .trener-wrap
            .trener-wrap-img
                .trainer-photo
                    width: 105%
                .partner-photo
                    width: 105%
@media (max-width: 1024px) 
    section.trener-wrapper
        .trener-wrap
            flex-direction: column
            .trener-wrap-text
                width: 100%
                padding-bottom: 0px
            .trener-wrap-img
                height: 500px
                width: 100%
                position: unset 
                .trainer-photo
                    position: unset
                    width: 100%
                    height: 120%
                .partner-photo
                    position: unset
                    width: 100%
                    height: 120%
@media (max-width: 768px) 
    section.trener-wrapper
        padding: 0px 20px
        .trener-wrap
            .trener-wrap-text
                padding-bottom: 40px
            .trener-wrap-img
                height: 400px
                width: 100%
                position: unset
                .trainer-photo
                    width: 100% 
                    border: 1px solid red
                .partner-photo
                    width: 100% 
                    border: 1px solid red