.gallery
    display: grid 
    border: 1px solid transparent
    grid-template-columns: 1fr 1fr 1fr
    grid-template-rows: 1fr 1fr
    grid-template-areas: "Big-Photo Big-Photo Foto-2" "Big-Photo Big-Photo Foto-3"
    width: 100% 
    grid-column-gap: 15px
    grid-row-gap: 15px
    max-width: 1024px
    height 500px
    margin: 0 auto
    @media (max-width: 768px)
        grid-template-columns: 1fr 1fr
        grid-template-rows: 1fr 1fr
        grid-template-areas: "Big-Photo Big-Photo" "Foto-2 Foto-3"
        height: 800px
    @media (max-width: 376px)
        grid-template-columns: 1fr 1fr
        grid-template-rows: 300px 200px
        grid-template-areas: "Big-Photo Big-Photo" "Foto-2 Foto-3"
    @media (max-width: 376px)
        grid-template-columns: 1fr 1fr
        grid-template-rows: 300px 200px
        grid-template-areas: "Big-Photo Big-Photo" "Foto-2 Foto-3"
        height: 550px
    :hover
        .fa-search
            color: white
            transform: rotate(90deg)
            position: absolute
            top: 50%
            left: 50%
            display: flex
    a
        display: none
        background-position: center
        background-size: cover
        position: relative
        .fa-search
            display: none

        &:nth-child(1)
            display: grid
            grid-area: Big-Photo

        &:nth-child(2)
            display: grid
            grid-area: Foto-2

        &:nth-child(3)
            display: grid
            grid-area: Foto-3
        .wrapper-for-box
            .box-header-one
                top: -60px
                left: -85px
                position: absolute
                border: 1px solid white
                color: white
                width: 100px
                height: 45px
                display: flex
                justify-content: center
                align-items: center
                z-index: 100
                padding: 5px 0 0 0
            .box-header-two
                padding: 5px 0 0 0
                top: -28px
                left: 0px
                position: absolute
                border: 1px solid white
                color: white
                width: 100px
                height: 45px
                display: flex
                justify-content: center
                align-items: center
                z-index: 100
                position: absolute

.blueimp-gallery
    background-color: pleo-dark-green
    .next,
    .prev,
    .close
        color: pleo-fiolet

a:not([href]):not([tabindex])
    color: pleo-fiolet

