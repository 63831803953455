section.blog-author-top-wrapper
    container-position()
    padding: 0px 0px
    position: absolute
    height: 270px
    background: none
    // background-color: lightgreen
    z-index 0
    .blog-author-top
        max-width: 1400px
        margin: 0px auto
        display: flex
        position: relative
        .author-top-left
            width: 190px
            height: 120px
            position: absolute
            top: 13px
            padding-top: 18px
            .author-go-back
                color: pleo-fiolet
                font-family: 'GloberRegular'
                font-size: 14px
                position: relative
                &:after
                    content: ''
                    position: absolute
                    height: 2px
                    width: 40px
                    background: pleo-fiolet
                    top: -13px
                    left: 6px
                &:before
                    content: ''
                    position: absolute
                    width: 0
                    height: 0
                    border-top: 6px solid transparent
                    border-bottom: 6px solid transparent
                    border-right: 6px solid pleo-fiolet
                    top: -18px
                    left: 0px
                .blog-button-goback
                    position: absolute
                    width: 115px
                    height: 50px
                    top: -22px
                    left: 0
            .author-profile
                position: absolute
                display: flex
                flex-direction: column
                min-height: 200px
                top: 70px
                &:before
                    content: ''
                    position: absolute
                    background: #000
                    width: 100px
                    height: 7px
                    top: 0
                .author-img
                    width: 36px
                    height: 36px
                    border-radius: 100%
                    display: flex
                    align-items: center
                    justify-content: center
                    position: relative
                    flex-direction: column
                    margin: 50px 0px 15px
                    .box-author-blog
                        width: 36px
                        height: 18px
                        background: linear-gradient(97deg, rgba(117,101,214,1) 0%, rgba(69,135,207,1) 100%)
                        border-top-left-radius: 95px
                        border-top-right-radius: 95px
                        position: absolute
                        top: 0px
                    .box2-author-blog
                        width: 36px
                        height: 18px
                        background: linear-gradient(90deg, rgba(195,81,73,1) 0%, rgba(69,135,207,1) 100%)
                        border-bottom-left-radius: 95px
                        border-bottom-right-radius: 95px
                        position: absolute
                        bottom: 0px
                    .blog-author-img
                        width: 32px
                        height: 32px
                        border-radius: 100%
                        z-index: 2
                        background-position: center !important
                        background-size: cover !important
                        background-repeat: no-repeat !important
                .author-name-and-date
                    .author-name
                        font-family: 'GloberxBold'
                        font-size: 14px
                        line-height: 17px
                        text-transform: uppercase
                        color: pleo-fiolet
                        width: 50%
                        margin-bottom: 13px
                    .author-post-date
                        font-family: 'GloberRegular'
                        font-size: 12px
                        line-height: 17px
                        color: pleo-dark-green
        .author-top-right
            display: none
            flex-direction: column
            justify-content: flex-end
            ul.blog-author-social-wrapper
                display: flex
                flex-direction: row
                list-style-type: none
                li.blog-author-social-icon
                    width: 16px
                    height: 16px
                    border-radius: 16px
                    background: #fff
                    margin-right: 5px
                    position: relative
                    a
                        position: absolute
                        font-size: 0px
                        width: 16px
                        height: 16px
                        background-size: contain !important
                        background-repeat: no-repeat !important
                        background-position: center !important
            .author-post-category
                font-family: 'GloberRegular'
                font-size: 14px
                color: pleo-fiolet
                text-transform: uppercase
@media (max-width: 1440px)
    section.blog-author-top-wrapper
        width: 100%
        padding: 0px 0px
        .blog-author-top
            max-width: 1140px

@media (max-width: 1245px)
    section.blog-author-top-wrapper
        padding-bottom: 0px
        position: relative
        height: 155px;
        .blog-author-top
            max-width: 768px
            margin: 0px auto
            display: flex
            flex-direction: column
            .author-top-left
                width: auto
                height: auto
                position: relative
                padding-top: 0px
                width: 100%
                top: 0
                .author-go-back
                    margin-bottom: 20px
                    margin-top: 20px
                .author-profile
                    position: relative
                    display: flex
                    flex-direction: row
                    align-items: flex-end
                    min-height: auto
                    top: 0px  
                    padding-top: 40px
                    width: 100%
                    .author-img
                        margin: 0px
                        margin-right: 20px
                    .author-name-and-date
                        display: flex
                        flex-direction: column
                        align-items: flex-end
                        width: 100%
                        .author-name
                            margin: 0px       
                            width: 100%
                        .author-post-date
                            width: 100%    
            .author-top-right
                width: 100%
                justify-content: space-between
                flex-direction: row-reverse
                width: 100%
                margin-top: 5px

@media (max-width: 768px)
    section.blog-author-top-wrapper
        padding-left: 20px
        padding-right: 20px




