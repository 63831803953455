.wrapper-1440px
    max-width: 1400px 
    margin: 0 auto
    min-height: 125px
    display: flex
    align-items center
    justify-content: center
    @media (max-width: 1440px)
        margin: 0 10%
    @media scr1024
        margin: 0 10%
    @media tablet
        margin: 0 3%
    @media mobi
        margin: 0 6%
    .top-bar
        width: 100%
        height: 100%
        margin: 0 auto
        padding: 0
        display: flex
        flex-flow: row wrap
        align-items: center
        justify-content: space-between
        position: relative
        max-height: 130px
        .logo
            width: 209px
            min-height: 67px 
        nav
            display: flex 
            flex-flow: row
            align-items: center
            .top-menu  
                font-size: 14px
                padding: 10px 0 0 0
                cursor: pointer
                @media (max-width: 1600px)
                    font-size: 12px
                li
                    font-size: 14px
                    cursor: pointer
                    @media (max-width: 1600px)
                        font-size: 12px
                    a
                        padding: 33px 0 40px 32px
                        font-size: 14px
                        font-family: 'GloberRegular'
                        @media (max-width: 1600px)
                            font-size: 12px
                    :hover
                        color: pleo-fiolet
                        font-size: 14px
                        font-family: 'GloberxBold'
                        @media (max-width: 1600px)
                            font-size: 12px
                @media (max-width: 876px)
                    display: none      
            .collaps-menu-icon
                display: none
                width: 40px
                height: 40px
                cursor: pointer
                @media (max-width: 876px)
                    display: block 
                .svg 
                    width: 100%
                    height: 100% 
                    fill: white
                    &, & * 
                        fill: white

// hamburger menu
.hamburger-menu
    display: flex
    flex-flow: column
    padding: 0
    width: 100%
    height: 0
    height: auto
    visibility: hidden
    position: relative
    z-index 999
    cursor: pointer
    .collaps-menu  
        display: flex
        position: absolute
        background-color: pleo-dark-green
        flex-flow: column
        font-size: 14px
        width: 100%
        min-height: 100vh
        cursor: pointer
        @media (max-width: 1600px)
            font-size: 12px
        li
            text-transform: lowercase
            cursor: pointer
            a
                padding: 15px 0
                display: block
                text-align: center
                font-family: 'GloberxBold'
                width: 100%
            :hover
                color: pleo-fiolet
        .menu-down-elemets
            display: none
            .button
                margin: 0 0 10px 0
                a
                    color: white
                    text-decoration: none
                    font-family: 'GloberRegular'
                    padding: 0
                    margin: 0
                    display: flex
                    justify-content: center
                    align-items: center
                    .button-elements
                        width: 100%
                        height: 100%
                        padding: 7px 0 0  0 
                        margin: 0
                        display: block
                        text-align: center
            @media (max-width: 850px)
                display: flex
                justify-content: center

.main-menu
    background-color: #ffffff
    width: 100%
    max-height: 60px
    min-height: 60px
    display: flex
    align-items: center
    @media(max-width: 840px)
        display: none
    nav
        max-width: 1400px 
        width: 100%
        height: 100%
        margin: 0 auto
        display: flex
        align-items center
        flex-flow: row wrap
        align-items: center
        background-color: #ffffff
        justify-content: space-between
        @media (max-width: 1440px)
            margin: 0 10%
        @media scr1024
            margin: 0 10%
        @media tablet
            margin: 0 3%
        @media mobi
            margin: 0 6%
        .bottom-menu
            min-height: 100%
            display: flex
            align-items: center
            li
                height: 100%
                margin: 0
                font-weight: 400px
                display: flex
                justify-content: center
                align-items: center
                :hover
                    text-decoration: underline pleo-fiolet 
                    color: pleo-fiolet
                a
                    text-transform: uppercase
                    line-height: 1
                    color: pleo-dark-green
                    display: flex
                    height: 100%
                    font-weight: 400
                    font-size: 14px
                    font-family: 'GloberxBold'
                    padding: 0
                    margin: 0 20px 0 0
                    @media (max-width: 1600px)
                        font-size: 12px
        .client-panel
            display: flex
            justify-content: flex-end
            li
                height: 100%
                a
                    color: pleo-dark-green
                    font-size: 14px
                    font-family: 'GloberRegular'
                    padding: 0
                    margin: 0 10px 0 0
                    outline: none
                    line-height: 1
                    @media (max-width: 1600px)
                        font-size: 12px
                    @media (max-width: 934px)
                        display: none
                .button-violet
                    -webkit-box-shadow: 0px 5px 0px 0px rgba(218,218,218,1);
                    -moz-box-shadow: 0px 5px 0px 0px rgba(218,218,218,1);
                    box-shadow: 0px 5px 0px 0px rgba(218,218,218,1);
                    outline: none
                    font-family: 'GloberRegular'
                    display: flex
                    justify-content: center
                    align-items: center
                    a
                        padding: 0
                        margin: 0
                        color: white
                        text-decoration: none
                        font-family: 'GloberRegular'
                        height: 100%
                        width: 100%
                        display: flex
                        justify-content: center
                        align-items: center
                        .button-elements
                            width: 100%
                            display: block
                            text-align: center
