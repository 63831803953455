.jumbotron-tools
    display: flex
    border-top: 2px solid #e8e8e8
    background-position: center 
    background-repeat: no-repeat
    background-size: cover 
    margin: 0
    background-color: #fff
    width: 100%
    padding: 0
    .container
        max-width: 1400px
        min-height:500px
        margin: 0 auto
        display: grid
        grid-template-columns: 200px 1fr
        grid-template-rows: fit-content(100px) fit-content(100px) fit-content(100px)
        min-height: 0
        min-width: 0
        grid-column-gap: 97px
        display: grid
        flex-wrap: wrap
        margin: 0 auto
        padding: 0
        grid-template-areas: "auto arrow" "pictogram-baner header" "pictogram-baner subtitle"
        @media (max-width: 1440px)
            margin: 0 10%
        @media (max-width: 846px)
            grid-column-gap: 37px
        @media tablet
            margin: 0 3%
        @media (max-width: 649px)
            grid-column-gap: 0
            margin: 0 6%
            grid-template-columns: 1fr
            grid-template-rows: fit-content(100px) fit-content(100px) fit-content(100px) fit-content(100px)
            grid-template-areas: "arrow" "pictogram-baner" "header" "subtitle"
        @media (max-width: 375px)
            min-height:400px
            margin: 30px 6%
        .pictogram-baner
            grid-area: pictogram-baner
            justify-items: start
            width: 100%
            margin: 0
            @media (max-width: 649px) 
                width: 50%
                margin: 0 0 15px 0
            img 
                width: 100%
                height: 100%
        .arrow
            grid-area: arrow
            a
                display: flex
                flex-flow: column
                text-decoration: none
                color: pleo-dark-green
                font-family: 'GloberRegular'
                img 
                    width: 48px
                    height: 15px
                p
                    font-size: 16px
                    font-family: 'GloberRegular'
                    @media mobi 
                        14px
        .header
            grid-area: header
            font-size: 72px 
            padding: 0
            margin: 0
            text-transform: uppercase
            font-family: 'GloberHeavy'
            @media scr1024
                font-size: 48px
            @media tablet
                font-size: 36px
            ::before
                content: ''
                display: block
                margin: 0 0 20px 0
                width: 104px
                height: 7px
                background-color: pleo-dark-green
            p
                padding: 0
                font-family: 'GloberHeavy' !important
                margin: 0
                color: pleo-dark-green
                @media (max-width: 1440px)
                    font-size: 72px
                @media (max-width: 1038px)
                    font-size: 48px
                @media mobi
                    font-size: 36px
        .subtitle
            grid-area: subtitle
            padding: 26px 0 0 0 !important
            width: 100%
            position: relative
            font-size: 18px !important
            @media tablet
                font-size: 14px !important
            p
                font-size: calc(14px + (24 - 14) * ((100vw - 375px) / (1440 - 375)))
                font-family: 'GloberRegular' !important
                color: pleo-dark-green
                margin: 0
                padding: 0
                @media mobi 
                    font-size: 14px