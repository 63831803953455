.get-know-the-method
    width: 100%
    background-color: white
    overflow: hidden
    .knowledge-element
        max-width: 1440px
        margin: 0 auto
        height: 100%
        position: relative
        &:hover
            .circle-wrapper
                display: flex
        img
            width: 100%
            height: 100%
        .circle-wrapper
            width: 100%
            height: 100%
            position: absolute
            right: 0
            top: 0
            display: none
            @media mobi
                display: block
            .method-coord-img-outline
                width: 190px
                height: 190px
                box-shadow: none
                border-radius: 100%
                display: flex
                align-items: center
                justify-content: center
                position: relative
                flex-direction: column
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-50%, -50%)
                .box-knowledge
                    width: 190px
                    height: 96px
                    background: linear-gradient(97deg, rgba(117,101,214,1) 0%, rgba(69,135,207,1) 100%)
                    border-top-left-radius: 95px
                    border-top-right-radius: 95px
                    position: absolute
                    top: 0px
                    @media tablet
                        width: 152px
                        height: 76.8px
                        top: 19px
                    @media (max-width: 387px)
                        width: 121.6px
                        height: 61.44px
                        top: 34px
                    @media (max-width: 378px)
                        top: 35px
                .box-knowledge2
                    width: 190px
                    height: 95px
                    background: linear-gradient(90deg, rgba(195,81,73,1) 0%, rgba(69,135,207,1) 100%)
                    border-bottom-left-radius: 95px
                    border-bottom-right-radius: 95px
                    position: absolute
                    bottom: 0px
                    @media tablet
                        width: 152px
                        height: 76.8px
                        bottom: 19px
                    @media (max-width: 387px)
                        width: 121.6px
                        height: 61.44px
                        bottom: 34px
                    @media (max-width: 378px)
                        bottom: 35px
                .method-coord-img
                    width: 180px
                    height: 180px
                    border-radius: 100%
                    z-index: 2
                    background-color: pleo-dark-green
                    background-position: center
                    background-size: contain
                    background-repeat: no-repeat
                    @media tablet
                        width: 144px
                        height: 144px
                    @media (max-width: 387px)
                        width: 115.2px
                        height: 115.2px
                    .arrow
                        position: absolute
                        top: 40px
                        left: 75px
                        @media tablet
                            top: 30px
                            left: 55px
                        @media (max-width: 387px)
                            top: 19px;
                            left: 40px;
                        img
                            width: 82px
                            @media tablet
                                width:69px
                            @media (max-width: 387px)
                                width: 61px
                                top: 40px
                                left: 40px
                    .text
                        position: absolute
                        top: 70px
                        left: 40px
                        font-size: 28px
                        font-family: 'GloberxBold'
                        @media tablet
                            font-size: 24px
                            top: 60px
                            left: 32px
                        @media (max-width: 387px)
                            font-size: 18px;
                            top: 50px;
                            left: 26px;
#blenda-exploration
    width: 100%
    height: 100%
    position: absolute
    top: 0
    left: 0
    display: flex
    justify-content: flex-end
    align-items: flex-end
    z-index: 1
    #blenda-exploration-box
        background-color: rgba(255,255,255, 0.8)
        width: 79%
        height: 100%

#blenda-prowide
    width: 100%
    height: 100%
    position: absolute
    top: 0
    left: 0
    display: flex
    justify-content: flex-start
    align-items: flex-start
    z-index: 1
    #blenda-prowide-box
        background-color: rgba(255,255,255, 0.8)
        width: 64.3%
        height: 100%

#blenda-define
    width: 100%
    height: 100%
    position: absolute
    top: 0
    left: 0
    #blenda-define-box1
        width: 100%
        height: 100%
        position: relative
        .box1
            background-color: rgba(255,255,255, 0.8)
            width: 21%
            height: 100%
            position: absolute
            top: 0
            left: 0
        .box2
            background-color: rgba(255,255,255, 0.8)
            width: 64.5%
            height: 100%
            position: absolute
            top: 0
            right: 0

#blenda-generate
    width: 100%
    height: 100%
    position: absolute
    top: 0
    left: 0
    #blenda-generate:hover
        .circle-wrapper
            display: block
    #blenda-generate-box1
        width: 100%
        height: 100%
        position: relative
        .box1
            background-color: rgba(255,255,255, 0.8)
            width: 35.4%
            height: 100%
            position: absolute
            top: 0
            left: 0
        .box2
            background-color: rgba(255,255,255, 0.8)
            width: 35.7%
            height: 100%
            position: absolute
            top: 0
            right: 0


