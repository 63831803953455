.tool-choose-fiolet
    background-color: pleo-fiolet !important

section.menu-tool-choose-wrapper.sticky
    @media (max-width: 768px)
        position: static !important
    .menu-tool-choose
        max-height: 85px 
        .huge-resolution-tool-wrapper1
            max-height: 85px 
        .huge-resolution-tool-wrapper2 
            max-height: 85px 
            
section.menu-tool-choose-wrapper
    position: -webkit-sticky; 
    position: sticky
    bottom: 0
    @media (max-width: 685px)
        position: static
    .menu-tool-choose
        width: 100%
        display: flex
        height: 136px
        .huge-resolution-tool-wrapper1
            background: pleo-fiolet
            height: 100%
            width: 50%
            display: flex
            .designer-tools
                width: 100%
                display: flex
                align-items: center
                justify-content: center
                cursor: pointer
                .designer-tools-img
                    background: url(../img/designer-tool-img.png)
                    background-size: contain
                    background-repeat: no-repeat
                    height: 50px
                    width: 52px
                    padding-right: 35px
                h2.designer-tools-text
                    color: pleo-dark-green
                    padding-left: 35px
                    font-family: 'GloberxBold'
                    font-size: 24px
                    margin-bottom: 0px
        .huge-resolution-tool-wrapper2
            background:white
            width: 50%
            display: flex
            .programmer-tools
                width: 100%
                display: flex
                align-items: center
                justify-content: center
                cursor: pointer
                border-left: 1px solid #d6d6d6;
                .programmer-tools-img
                    background: url(../img/programmer-tool-img.png)
                    background-size: contain
                    background-repeat: no-repeat
                    width: 47px
                    height: 46px
                    padding-right: 35px
                h2.programmer-tools-text
                    color: pleo-dark-green
                    padding-left: 35px
                    font-family: 'GloberxBold'
                    font-size: 24px
                    margin-bottom: 0px
@media (min-width: 2048px)
    section.menu-tool-choose-wrapper
        .menu-tool-choose
            .huge-resolution-tool-wrapper1
                flex-direction: row-reverse
                .designer-tools 
                    max-width: 700px
                    justify-content: flex-start
            .huge-resolution-tool-wrapper2
                .programmer-tools 
                    max-width: 700px
                    justify-content: flex-end


@media (max-width: 1024px)
    section.menu-tool-choose-wrapper
        .menu-tool-choose
            .huge-resolution-tool-wrapper1
                .designer-tools
                    // align-items: flex-start
                    padding: 20px
                    .designer-tools-img
                        // padding: 0px
                        margin: 0 10px 0 0 
                    h2.designer-tools-text
                        padding: 0px
                        padding-top: 16px
            .huge-resolution-tool-wrapper2
                .programmer-tools
                    // flex-direction: column
                    // align-items: flex-start
                    padding: 20px
                    .programmer-tools-img
                        margin: 0 10px 0 0
                        padding: 0px
                    h2.programmer-tools-text
                        padding: 0px
                        padding-top: 16px
@media (max-width: 480px)
    section.menu-tool-choose-wrapper
        .menu-tool-choose
            .huge-resolution-tool-wrapper1
                .designer-tools
                        flex-direction: column;
                        align-items: flex-start 
                    .designer-tools-img
                        width: 38px
                        height: 37px
                    h2.designer-tools-text
                        font-size: 16px !important
            .huge-resolution-tool-wrapper2
                .programmer-tools
                    flex-direction: column;
                    align-items: flex-start 
                    .programmer-tools-img
                        width: 37px
                        height: 37px
                    h2.programmer-tools-text
                        font-size: 16px
