section.process-wrapper
    container-position()
    .process
        max-width: 1140px
        margin: 0px auto
        display: flex
        .process-img-wrapper
            width: 33.4%
            position: relative
            .process-img
                border: none
                position: absolute
                right: 0px
                height: 100%
                width: 200%
                background-size: contain !important
                background-position: right !important
                background-repeat: no-repeat !important
        .process-text-wrapper
            width: 66.6%
            .process-text
                padding-left: 97px
                display: flex
                flex-direction: column
                h2.process-title
                    font-family: 'GloberxBold'
                    p
                        margin-bottom: 0px
                .process-p
                    font-family: 'GloberRegular'
                    padding-top: 45px
                    margin: 0px
                .process-btn
                    padding: 40px 0 0 0
                    .form-wrapper
                        display: flex
                        .button-element
                            display: flex
                            justify-content: center
                            align-items: center
                            text-align: center
                            .button-violet-price
                                display: flex
                                justify-content: center
                                align-items: center
                                font-family: 'GloberSemibold'
                                background-color: #8a73fb
                                cursor: pointer
                                text-align: center
                                font-size: 12px
                                -webkit-box-shadow: 0px 3px 0px 0px rgba(11,23,28,1);
                                -moz-box-shadow: 0px 3px 0px 0px rgba(11,23,28,1);
                                box-shadow: 0px 3px 0px 0px rgba(11,23,28,1);
                                p
                                    text-align: center
                                    width: 100%
                                    height: auto
                                    line-height: 2.5
                                    padding: 0
                                    margin: 0

@media (max-width: 1280px)
    section.process-wrapper
        .process
            padding: 0px 20px
@media (max-width: 1024px)
    section.process-wrapper
        .process
            padding: 0px 20px
            flex-direction: column
            .process-img-wrapper
                width: 100%
                height: 500px
                .process-img
                    background-position: center !important
                    left: auto
                    position: relative
                    height: 100%
                    width: 100%
            .process-text-wrapper
                width: 100%
                .process-text
                    padding: 0px

@media (max-width: 768px)
    section.process-wrapper
        .process
            .process-img-wrapper
                height: 400px
            .proces-text-wrapper
                .proces-text
                    h1.process-title
                        font-size: 36px
                        margin-bottom: 0px
                        p
                            margin-bottom: 0px
@media (max-width: 375px)
    section.process-wrapper
        .process
            .process-img-wrapper
                height: 310px

                
        
    
    