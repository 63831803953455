.purple-counting-down 
    width: 100%
    display: flex
    flex-flow: row
    color: pleo-fiolet
    justify-content: center
    align-items: center
    font-family: 'GloberxBold'
    font-size: 16px
    width: 100%
    height: 53px
    padding: 10px 0
    margin: 0
    border: 0
    outline: 0
    background-color: purple-counting-down-color
    @media (max-width: 1600px)
        font-size: 14px
    .how-much-left
        display: flex
        flex-flow: row
        align-items: center
        margin: 0 10px
        .number-of-place
            p
                margin: 0 
        .how-much-let-avatars
            margin: 0 10px
            display: flex
            flex-flow: row
            align-items: center
            @media (max-width: 478px) 
                display: none
            .avatar1
                width: 40px
                height: 40px
                padding: 0 0 0 20px
