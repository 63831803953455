.contact-elements-wrapper
    width: 100%
    min-height: 517px
    background-color: white
    display: flex
    align-items: center
    justify-content: center
    @media mobi 
        min-height: 370px
    .contact-wrapper
        max-width: 752px
        width: 100% 
        height: 100%
        margin: 0 auto
        display: grid
        justify-content: center
        grid-auto-columns: 1fr
        grid-template-rows: 127px 127px 127px
        min-height: 130px
        column-gap:0
        padding: 0
        @media (max-width: 1440px)
            margin: 0 10%
        @media scr1024
            margin: 0 9%
        @media tablet
            margin: 0 3%
        @media (max-width: 692px)
            column-gap: 30px
            padding: 15px 0 0 0 
        @media mobi
            margin: 0 3%
        .contact-elements
            display: flex
            flex-flow: row
            width: 100%
            padding: 10px 0
            align-items: center
            @media (max-width: 534px)
                align-items: center
            .img 
                width: 95px
                height: 95px
                display: flex
                justify-content: center
                align-items: center
                border-radius: 50%
                background-color: pleo-fiolet
                margin: 3px 100px 0 0
                color: white
                @media (max-width: 692px)
                    margin: 0 
                @media (max-width: 534px)
                    width: 80px
                    height: 80px
                .svg
                    width: 35px
                    fill: white !important
                    &, & * 
                        fill: white !important
                .svg-size1
                    width: 35px
                    height: 35px
                .svg-size2
                    width: 43px
                    height: 37px
                .svg-size3
                    width: 57px
                    height: 44px
            .text
                display: flex
                flex-flow: column
                justify-content: center
                @media (max-width: 692px)
                    padding: 0 0 0 50px
                @media mobi
                    padding: 0 0 0 25px
                .text-regular-elements
                    color: pleo-dark-green
                    font-family: 'GloberxBold'
                    line-height: 1
                    font-size: 18px
                .text-bold-elements
                    font-size: 48px
                    line-height: 1
                    color: pleo-dark-green
                    font-family: 'GloberxBold'
                    @media tablet
                        font-size: 36px
                    @media mobi
                        font-size: 24px
                    @media (max-width: 350px)
                        font-size: 18px
                #element3
                    cursor: pointer
                    border-bottom: 2px solid pleo-dark-green

