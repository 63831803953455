.module-title
    max-width: 1400px
    margin: 0 auto
    padding: 0
    .module-header-title
        display: flex
        flex-direction: column
        position: relative
        max-width: 1024px
        margin: 0px auto
        .section-number
            position: absolute
            top: 4px
            left: -170px
            padding: 40px 0 0 0
            display: flex
            flex-direction: row
            align-items: center
            justify-content: space-between
            .line
                width: 20px
                position: relative
                &:after
                    content: ''
                    background-color: pleo-fiolet
                    width: 20px
                    height: 2px
                    position: absolute
                    left: 0px
                    top: -9px
            .nr-text
                display: flex
                flex-direction: column
                padding-left: 15px;
                .nr-number
                    color: pleo-fiolet
                    font-family: 'GloberxBold'
                    font-size: 16px
                .nr-name
                    color: #ffffff
                    font-family: 'GloberxBold'
                    font-size: 16px
                    max-width: 115px
        h2
            font-family: 'GloberxBold'
            color: #ffffff
            font-size: 48px
            padding: 41px 0 0 0


@media (max-width: 1440px)
    .module-title
        header.module-header-title
            max-width: 768px
            .section-number
                left: -140px
                .nr-text
                    .nr-name
                        max-width: 90px

@media (max-width: 1245px) 
    .module-title
        header.module-header-title
            .section-number
                left: -120px

@media (max-width: 1024px)
    .module-title
        header.module-header-title
            position: relative
            flex-direction: column
            align-items: flex-start
            .section-number
                position: relative
                left: 0px !important
                padding-bottom: 30px
                margin-left: -40px
                .line
                    top: 8px
                .nr-text
                    flex-direction: row
                    .nr-name
                        max-width: 250px
            h2
                padding: 0

@media (max-width: 768px)
    .module-title
         header.module-header-title
            padding-left: 20px
            .section-number
                margin-left: 0px
            h2
                font-size: 36px
                width: auto
                padding-right: 20px
                margin: 0px
                
@media (max-width: 375px)
    .module-title
         header.module-header-title
            padding-left: 20px
            .section-number
                margin-left: 0px
                padding: 40px 0 30px 0
            h2
                font-size: 36px
                width: auto
                padding-right: 20px
                margin: 0px
