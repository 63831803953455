.video-blog-wrapper
	width: 100%
	.video-blog-content-wrapper
		max-width: 960px
		margin: 0 auto
		display: flex
		flex-flow: column
		@media (max-width: 1440px)
			max-width: 768px
		@media tablet
			margin: 0 3%
		.blog-video
			position: relative
			width: 100%
			padding-bottom: 56.25%
			// padding-top: 25px
			height: 0
			iframe 
				position: absolute
				top: 0
				left: 0
				height: 100%
				width: 100%
		.blog-video-description
			width: 100%
			text-align: center
			padding: 10px 0
			font-family: 'GloberBook'
			color: pleo-dark-green

