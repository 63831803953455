
.jumbotron-tools-alltools
    border-top: 2px solid #e8e8e8
    background-position: center 
    background-repeat: no-repeat
    background-size: cover
    margin: 0
    align-items: center 
    justify-content: center
    width: 100%
    height: 72vh
    position: relative
    &:before
        content:''
        position: absolute
        display: block
        top: 0
        left: 0
        height: 100%
        width: 100%
        background: linear-gradient(to right,rgba(19,39,48,.8) 10%,rgba(19,39,48,.7) 20%,rgba(19,39,48,.6) 30%,rgba(19,39,48,.5) 40%,rgba(19,39,48,0) 50%)
    @media (max-width: 450px)
        height: 85vh
    .banner 
        max-width: 1400px
        height: 100%
        margin: 0 auto
        display: flex
        flex-flow: column
        align-items: flex-start
        position: relative
        justify-content: center
        @media (max-width: 1440px)
            margin: 0 10%
        @media scr1024
            margin: 0 10%
        @media tablet
            margin: 0 3%
        @media mobi
            margin: 0 6%
        .banner-wrapper
            display: flex
            flex-flow: row
            @media (max-width: 616px)
                flex-flow: column
            .baner-items
                width: 100%
                margin: 0 0 0 0
                height: 100%
                display: flex
                flex-flow: column
                justify-content: center
                @media (max-width: 616px) 
                    margin: 30px 0 0 3%
                    width: 94%
                .pleo-h1
                    font-size: 72px
                    display: flex
                    flex-flow: column 
                    padding: 0
                    margin: 0
                    text-transform: uppercase
                    font-family: 'GloberHeavy'
                    line-height: 1.2
                    p
                        display: inline-block
                        padding: 0
                        margin: 0
                        color: white
                        &:first-line
                            color: pleo-fiolet
                        @media scr1024
                            font-size: 48px
                        @media (max-width: 768px)
                            font-size: 44px
                        @media mobi
                            font-size: 36px
                    &::before
                        content: ''
                        display: block
                        margin: 0 0 20px 0
                        width: 104px
                        height: 7px
                        background-color: white
                .pleo-h4
                    font-size: 24px
                    padding: 26px 0 0 0
                    width: 60%
                    position: relative
                    color: pleo-dark-green
                    @media scr1024
                        font-size: 22px
                        width: 60%
                    @media tablet
                        font-size: 22px
                        width: 70%
                    @media mobi
                        font-size: 18px
                        width: 80%
                    p
                        display: inline-block
                        font-family: 'GloberLight'
                        color: white


