.social-media-wrapper
    width: 100%
    min-height: 361px
    background-color: #e0e0e0 
    margin: 0 0 200px 0
    position: relative
    padding: 95px 0
    @media mobi 
        margin: 0 0 110px 0
    .social-media-elements
        max-width: 752px
        margin: 0 auto
        height: 100%
        @media (max-width: 1440px)
            margin: 0 10%
        @media tablet
            margin: 0 3%
        @media (max-width: 500px)
            flex-flow: column
        @media mabi
            margin: 0 6%
        h1
            color: pleo-dark-green
            font-family: 'GloberxBold' 
            margin: 0 0 60px 0 
            padding: 0
            p
                padding: 0
                margin: 0
        ul
            list-style-type none
            display: flex
            flex-flow: row
            li
                padding: 0
                margin: 0
                a
                    padding: 0
                    margin: 0 10px 0 0
                    color: white !important
                    .svg
                        width: 38px
                        height: 38px
                        fill: white !important
                        &, & * 
                            fill: white !important
                &:hover
                    .svg
                        &, & * 
                            fill: pleo-dark-green !important
    .instagram
        margin: 0 auto
        max-width: 1440px
        @media (max-width: 1440px)
            margin: 0 10%
        @media tablet
            margin: 0 3%
        @media mabi
            margin: 0 6%
        .button-element
            display: flex
            justify-content: flex-end
            @media mobi 
                justify-content: flex-start
            .button-instagram
                border: 1px solid pleo-dark-green
                width: 225px
                height: 32px
                margin: 0
                -webkit-box-shadow: 0px 5px 5px 0px rgba(188,188,188,1);
                -moz-box-shadow: 0px 5px 5px 0px rgba(188,188,188,1);
                box-shadow: 0px 5px 5px 0px rgba(188,188,188,1);
                color: pleo-dark-green 
                font-family: 'GloberxBold'
                cursor: pointer
                display: flex
                justify-content: center
                align-items: center
                @media mobi 
                    bottom: 60px
                    right: auto
                    left: 0
                a
                    display: block
                    width: 100%
                    height: 100%
                    line-height: 2.5
                    display: flex
                    justify-content: center
                    align-items: center
                    text-align: center
                    text-decoration: none
                    color: pleo-dark-green
                    p
                        height: 100%
                        width: 100%
                        text-align: center
                        padding: 0
                        margin: 0
    #twitter-image-bar
        height: 283px
        position: absolute
        bottom: -241px
        display: grid
        grid-template-columns: repeat(6,1fr)
        -webkit-column-gap: 10px
        column-gap: 10px
        overflow hidden
        outline: none
        border: none
        @media mobi 
            column-gap: 5px
            height: 141.5px !important
            bottom: -100px
        .owl-stage-outer
            overflow hidden
            outline: none
            border: none
            width: 6000px
            .owl-stage
                overflow hidden
                outline: none
                border: none
                .owl-item
                    width: 283px !important
                    height: 100%
                    margin: 0 10px
                    outline: none
                    border: none
                    @media mobi 
                        width: 141.5px !important
                    .kard
                        outline: none
                        border: none
                        .wrapper
                            width: 100%
                            outline: none
                            border: none
                            height: 271px
                            position: relative
                            -webkit-box-shadow: 0 13px 0 0 rgba(0,0,0,.2)
                            box-shadow: 0 13px 0 0 rgba(0,0,0,.2)
                            .cube1
                                background: #8a73fb
                                background: linear-gradient(68deg,#8a73fb 0,#2cbad8 63%)
                                position: absolute
                                top: 0
                                height: 50%
                                width: 100%
                                outline: none
                                border: none
                            .cube2
                                background: #eb594d
                                background: linear-gradient(68deg,#eb594d 0,#2cbad8 100%)
                                position: absolute
                                bottom: 0
                                height: 50%
                                width: 100%
                                outline: none
                                border: none
                        .box-for-image
                            position: absolute
                            top: 2px
                            left: 2px
                            z-index: 3
                            height: 260px
                            outline: none
                            border: none
                            width: 99%
                            .image
                                background-color: white
                                background-size: cover
                                background-position: center
                                background-repeat: no-repeat
                                height: 266px
                                width: 100%
                                margin: 0
                                outline: none
                                border: none
                                border-width: 3px
                                @media mobi 
                                    height: 137px
                                    width: 98% 
