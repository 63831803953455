.contact-you
    width: 100%
    min-height: 440px
    background-color: pleo-dark-green
    padding: 50px 0
    @media tablet
        padding: 0
    .contact-you-wrapper
        max-width: 752px
        margin: 0 auto
        position: relative
        padding: 50px 0
        display: flex
        flex-flow: row
        @media (max-width: 829px)
            margin: 0 3%
            flex-flow: column
        @media mabi
            margin: 0 6%
        .left-part
            .header
                font-family: 'GloberxBold'
                @media mobi 
                    font-size: 35px !important
                p
                    font-family: 'GloberxBold'
                    margin: 0 0 35px 0
            .paragraph
                font-family: 'GloberRegular'
                margin: 0 0 50px 0
                width: 85%
                @media (max-width: 829px)
                    width: 100%
            .contact-form-wrapper
                width: 80%
                display: flex
                align-items: center
                flex-flow: column
                margin: 0
                @media (max-width: 829px)
                    width: 100%
                    margin: 0 0 50px 0
                .contact-form-one
                    width: 100%
                    text-align: center
                    display: grid
                    column-gap: 20px
                    row-gap: 20px
                    grid-template-columns: 225px 225px
                    column-gap: 38px
                    grid-template-rows: 1fr
                    margin: 0 0 50px 0
                    @media (max-width: 829px)
                        grid-template-columns: 1fr 1fr
                    input
                        font-size: 12px
                        outline: none
                        border: none
                        border-bottom: 2px solid white
                        background-color: transparent
                        border-radius: 0 !important
                        text-align: center
                        color: #afafaf
                        line-height: 2
                        &:focus
                            border-bottom: 2px solid pleo-fiolet
                            color: pleo-fiolet
                        input::placeholder
                            font-size: 12px
                            color: #afafaf
                            text-align: center
                            font-family: 'GloberRegular'
                        &:-webkit-autofill,
                        &:-webkit-autofill:hover,
                        &:-webkit-autofill:focus
                            text-align: center
                            -webkit-text-fill-color: #afafaf;
                            -webkit-box-shadow: 0 0 0px 1000px #04232f inset;
                            transition: background-color 5000s ease-in-out 0s;
                    .termin
                        outline: none
                        border-radius: none
                        &:focus
                            outline: none
                            border-radius: none
                            border-bottom: 2px solid pleo-fiolet
                            color: pleo-fiolet
                        &:active
                            outline: none
                            border-radius: none
                            border: none
                        &:hover
                            outline: none
                            border-radius: none
                .contact-form-two
                    width: 100%
                    text-align: center
                    display: grid
                    column-gap: 20px
                    row-gap: 20px
                    grid-template-columns: 225px 225px
                    column-gap: 38px
                    grid-template-rows: 1fr
                    margin: 0 0 50px 0
                    @media (max-width: 829px)
                        grid-template-columns: 1fr 1fr
                    textarea
                        font-size: 12px
                        outline: none
                        border: none
                        border-bottom: 2px solid white
                        background-color: transparent
                        text-align: center
                        color: #afafaf
                        line-height: 2
                        border-radius: 0 !important
                        &:focus
                            border-bottom: 2px solid pleo-fiolet
                            color: pleo-fiolet
                    textarea::placeholder
                        font-size: 12px
                        color: #afafaf
                        text-align: center
                        font-family: 'GloberRegular'
                    #from-date
                        background-color: transparent
                        width: 100%
                        color: #afafaf
                .contact-form-third
                    width: 100%
                    max-width: 636px
                    display: grid
                    grid-template-columns: 490px
                    grid-template-rows: 1fr
                    position: relative
                    @media (max-width: 829px)
                        max-width: 100%
                        grid-template-rows: 1fr
                        grid-template-columns: 1fr
                    #cross-element
                        display: flex
                        flex-flow: row
                        justify-content: center
                        position: relative
                        border: none
                        width: 100%
                        .defoult-checkbox
                            position: absolute
                            top: 4px
                            left: 3px
                        .rodo
                            color: white
                            text-align: justify
                            padding: 0 0 0 40px
                            font-size: 12px
                            @media (max-width:829px)
                                padding: 0 0 0 32px
                                width: 100%
                            p
                                width: 100%
                                font-size: 12px
                        a
                            text-decoration: none
                            color: pleo-fiolet

                .form-button
                    width: 100%
                    text-align: center
                    display: grid
                    grid-template-columns: 225px 225px
                    grid-template-rows: 1fr
                    column-gap: 95px
                    position: relative
                    @media tablet
                        grid-template-columns: 1fr
                    .recaptcha
                        border: none 
                        outline: none
                        margin: 0 37px 0 0
                        @media tablet
                            margin: 0 0 30px 0
                    .button-violet
                        cursor: pointer
                        width: 171px
                        color: #ffffff
                        font-family: 'globersemibold'
                        margin: 0
                        padding: 0
                        text-align: center
                        align-items: center
                        justify-content: center
                        -webkit-box-shadow: 0px 3px 0px 0px rgba(11,23,28,1);
                        -moz-box-shadow: 0px 3px 0px 0px rgba(11,23,28,1);
                        box-shadow: 0px 3px 0px 0px rgba(11,23,28,1);
                        .button-elements
                            width: 100%
                            display: block
                            text-align: center
                            
                            .arrow-on-price-button
                                width: 6px
                                height: 9px
                                margin: -4px 11px 0 0 
                                img
                                    width: 6px
                                    height: 16px
        .contact-you-image
            display: flex
            justify-content: center
            align-items: center
            .contact-circle-wrapper
                width: 100%
                display: flex
                flex-flow: column
                justify-content: center
                align-items: center
                @media (max-width: 829px)
                    align-items: flex-start
                .contact-circle-img-outline
                    width: 190px
                    height: 190px
                    border-radius: 100%
                    display: flex
                    align-items: center
                    justify-content: center
                    position: relative
                    flex-direction: column
                    .box-contact
                        width: 190px
                        height: 96px
                        background: linear-gradient(97deg, rgba(117,101,214,1) 0%, rgba(69,135,207,1) 100%)
                        border-top-left-radius: 95px
                        border-top-right-radius: 95px
                        position: absolute
                        top: 0px
                    .box2-contact
                        width: 190px
                        height: 95px
                        background: linear-gradient(90deg, rgba(195,81,73,1) 0%, rgba(69,135,207,1) 100%)
                        border-bottom-left-radius: 95px
                        border-bottom-right-radius: 95px
                        position: absolute
                        bottom: 0px
                    .contact-coord-img
                        width: 180px
                        height: 180px
                        border-radius: 100%
                        z-index: 2
                        background-color: white
                        background-position: center
                        background-size: contain
                        background-repeat: no-repeat
                .contact-coord-text
                    .contact-coord-text-h3
                        font-family: 'GloberxBold'
                        margin: 20px 0px
                    .contact-coord-text-p
                        font-family: 'GloberRegular'
                        line-height: 29px
                        margin-bottom: 20px     
                .contact-coord-contact
                    h5
                        font-family: 'GloberxBold'
                        color: pleo-fiolet
                    p
                        font-family: 'GloberRegular'
                        margin: 0px


input[type=checkbox] 
    opacity: 1
    cursor: pointer
    position: absolute
    top: 2.5px
    left: 0px
    @media tablet
        left: 0px

.check-we-contact-you
    position: absolute
    top: 1px
    left: 0px
    height: 19px
    width: 19px
    background-color: pleo-dark-green 
    border: 1px solid white
    border-radius: 2px
    cursor: pointer
    .crossTwo
        visibility: hidden
        position: absolute
        left: 0px
        top: 0px
        width: 19px
        height: 19px
        z-index: 100
        .fa-times
            color: pleo-fiolet
            position: absolute
            top: 0px
            left: 2px
            font-size: 17px


