.jumbotron-contact
    background-color: pleo-dark-green
    .container-contact
        max-width: 1400px
        width: 100%
        margin: 0 auto
        @media (max-width: 1440px)
            margin: 0 10%
        @media tablet
            margin: 0 3%
        @media mabi
            margin: 0 6%
        @media (max-height: 250px)
            min-height: 200px
            padding: 0
        a
            text-decoration: none
            color: white
            font-size: calc(20px + (40 - 20) * ((100vw - 300px) / (1440 - 300)));
            width: 80%
            display: flex
            flex-direction: column
            font-family: 'GloberHeavy' !important
            position: relative
            padding: 10px 0
            cursor: pointer
            margin: 0
            @media (max-height:715px)
                padding: 5px 0
            @media (max-height:278px)
                font-size: 10px
                padding: 10px 0 0 0
            @media mobi 
                width: 100%
            .question
                color: white
                p
                    color: white
                    padding: 0
                    border: 0
                    margin: 10px 0 0 0
                    @media (max-height:715px)
                        margin: 30px 0 0 0 
                &::before
                    content: ''
                    display: block
                    margin: 0 0 20px 0
                    width: 50px
                    height: 5px
                    background-color: pleo-fiolet
                    position: absolute
                    left: 0
                    top: 15px
                    z-index: 5
                &::after
                    content: ''
                    display: block
                    margin: 0 0 20px 0
                    width: 100%
                    height: 1px
                    background-color: #203b46
                    position: absolute
                    left: 0
                    top: 17px
                &:hover
                    border: none
                    padding: 0 0 0 60px
                    @media mobi
                        padding: 0
                    &::before
                        left: 40px
                        background-color: #fff
                        @media mobi
                            left: 0
                    &::after
                        left: 40px
                        @media mobi
                            left: 0