.opis-wrapper
    max-width: 100%
    margin: 0px auto
    padding: 20px 0 0 0
    overflow: hidden !important
    .opis-content
        padding: 20px 0px 0 0px  
        max-width: 1024px
        margin: 0px auto
        ul
            list-style-type: none
            padding-left: 25px
            li
                font-family: 'GloberRegular'
                line-height: 41px
                position: relative
                &:after
                    content:''
                    width: 5px
                    height: 5px
                    border-radius: 100%
                    background-color: pleo-dark-green
                    position: absolute
                    left: -21px
                    top: 19px
                &:before
                    content:''
                    width: 7px
                    height: 7px
                    border-radius: 100%
                    background: linear-gradient(45deg, rgba(195,80,73,1) 20%, rgba(69,135,207,1) 55%, rgba(94,117,211,1) 92%);
                    position: absolute
                    left: -22px
                    top: 18px
        ol
            // list-style-type: none
            padding-left: 25px
            li
                font-family: 'GloberRegular'
                line-height: 41px
                position: relative
                

@media (max-width: 1440px)
    .opis-wrapper
        .opis-content
            max-width: 768px

@media (max-width: 768px)
    .opis-wrapper
        padding-top: 0px
        padding-bottom: 0px
        .opis-content
            padding: 35px 20px 0px 20px
