.popup-wrapper
    width: 100%
    height: 100vh
    background-color: rgba(4,35,47, .5)
    position: absolute
    top:0
    left: 0
    z-index: 99999
    display: flex
    justify-content: center
    align-items: center
    position: fixed
    .popup-cotent
        background-color: #e0e0e0
        width: 590px
        height: 305px
        display: flex
        justify-content: center
        align-items: center
        position: relative
        border-radius: 20px
        padding: 60px
        .exit-cross
            position absolute
            top: 6px
            right: 7px
            width: 30px
            height: 30px
            cursor: pointer
            z-index: 1000
            border: 1px solid pleo-fiolet
            border-radius: 50px
            background-color: pleo-fiolet
            &::before
                position: absolute
                right: 12px
                top: 6px
                content: ''
                height: 15px
                width: 2.5px
                background-color: white
                transform: rotate(45deg)
            &::after
                position: absolute
                right: 12px
                top: 6px
                content: ''
                height: 15px
                width: 2.5px
                background-color: white
                transform: rotate(-45deg)
        .main-header
            width: 100%
            &::before
                content: ''
                display: block
                margin: 0 0 50px 0
                width: 50px
                height: 2px
                background-color: pleo-fiolet
            .notice-header
                line-height: 2
                h2
                    width: 100%
                    color: pleo-dark-green
                    font-family: 'GloberxBold' 
                .notice
                    color: pleo-dark-green
                    font-family: 'GloberRegular' 

.error
    display: flex
    flex-flow: column
    padding: 0
    color: red