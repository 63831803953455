.opinie-wrapper
    max-width: 100%
    margin: 0px auto
    padding-top: 20px
    padding-bottom: 0px
    overflow: hidden !important
    ul.opinie-list
        display: flex
        flex-direction: row
        align-items: flex-start
        justify-content: flex-start
        padding: 65px 0px 35px 0px  
        flex-wrap: wrap
        max-width: 1024px
        margin: 0px auto
        li
            height: auto
            width: 50%
            list-style-type: none
            padding: 30px 0px
            display: flex
            flex-direction: column
            align-items: flex-start 
            &:nth-child(3)
                width: 98%
            &:nth-child(4)
                width: 98%
            p
                text-align: left
                margin-top: 16px
            .quote-name
                border-left: 2px solid pleo-fiolet
                height: 30px
                padding-left: 15px
                color: pleo-fiolet
                display: flex
                p
                    margin: 0px
                    margin-top: -5px
                    font-weight: 600
                    font-size: 18px

@media (max-width: 1440px)
    .opinie-wrapper
        ul.opinie-list
            max-width: 768px

@media (max-width: 1245px) 
    .opinie-wrapper
        ul.opinie-list
            li
                width: 49%
                &:nth-child(3)
                    width: 98%
                &:nth-child(4)
                    width: 98%

@media (max-width: 1024px)
    .opinie-wrapper
        ul.opinie-list
            li
                width: 49%
                &:nth-child(3)
                    width: 98%
                &:nth-child(4)
                    width: 98%
                    
@media (max-width: 768px)
    .opinie-wrapper
        padding-top: 0px
        padding-bottom: 0px
        ul.opinie-list
            padding: 35px 20px 0px 20px
            li
                width: auto
                padding-left: 5px