section.blog-naglowek-wrap
    container-position()
    .blog-naglowek
        max-width: 960px
        margin: 0px auto
        h3
            font-family: 'GloberxBold'
            color: pleo-blog-font
@media (max-width: 1440px)
    section.blog-naglowek-wrap
        .blog-naglowek
            max-width: 768px

@media (max-width: 768px)
    section.blog-naglowek-wrap
        padding-left: 20px
        padding-right: 20px
        .blog-naglowek
            h3
                margin-bottom: 0px