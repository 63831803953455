.video-wrapper
    width: 100%
    height: 550px
    position: relative
    display: inline-block
    video 
        width: 100%
        height: 100%
        cursor: pointer
    .video-blend
        background-color: rgba(#8372d7,0.8)
        background-size: cover
        background-repeat: no-repeat
        background-position: center
        position: absolute
        top: 0px
        left: 0px
        height: 100%
        width: 100%
        display: flex  
        opacity: 1
        overflow: hidden
        transition-property: height, opacity
        transition-duration: .1s, .3s
        transition-delay: .3s, 0s
        transition-timing-function: ease-in-out
        &:before
            content: ''
            position: absolute
            top: 0
            left: 0
            height: 100%
            width: 100%
            background-color: rgba(#8372d7, 0.8)

        .play-wrapper
            padding-right: 60px
            width: 50%
            display: flex
            align-items: center
            justify-content: flex-end
            p.video-time
                margin-bottom: 0px
            .play-button
                background-color: rgba(#aba4fb,0.6)
                background-image: url('../img/arrow-play.png')
                background-repeat: no-repeat
                background-position: center
                background-size: 12px
                box-shadow: 0px 4px 0px 0px rgba(0,0,0,0.21)
                width: 60px
                height: 60px
                border-radius: 100%
                margin: 20px
                cursor: pointer
        .info-wrapper
            padding-left: 15px
            padding-right: 280px
            width: 50%
            display: flex
            flex-direction: column
            align-items: flex-start
            justify-content: center
            h2
                font-family: 'GloberxBold'
                line-height: 53px
                color: pleo-dark-green
                padding-bottom: 20px




@media (max-width: 1024px)
    .video-wrapper
        height: 300px
        .video-blend
            .play-wrapper
                padding-right: 0px
                justify-content: center
            .info-wrapper
                padding-left: 0px
                padding-right: 70px
@media (max-width: 768px)
    .video-wrapper
        height: auto
        display: flex
        flex-direction: column
        .info-wrapper-rwd
            height: 325px
            display: inline-block
            .info-wrapper2
                display: flex
                flex-direction: column
                padding: 90px 20px 0px 20px
                h1
                    color: #ffffff
                    font-family: 'GloberxBold'
                    font-size: 40px !important
                p
                    font-family: 'GloberRegular'
                    font-size: 18px !important
        video
            height: 225px
        .video-blend     
            height: 225px
            top: 325px 
            .play-wrapper
                width: 100%
            .info-wrapper  
               display: none
            


